import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import { getOrganizationId } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { updateButtonText } = store.mainState;
  return { updateButtonText };
};

function UpdateLeave({ updateButtonText, item }) {
  // console.log("the l")
  const [myEmployeeId, setEmployeeId] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [notes, setNotes] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [leaveid, setLeaveId] = useState(null);
  const organizationId = getOrganizationId();

  const dispatch = useDispatch();

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    if (leaveType === null) {
      toast.error("Select at least one leave type");
    } else if (myEmployeeId === null) {
      toast.error("Select at least one employee");
    } else if (notes.length <= 0) {
      toast.error("Enter description");
    } else if (notes.length >= 200) {
      toast.error("Description charecters shouldn't greater than 200");
    } else if (notes.length <= 0) {
      toast.error("Enter description");
    } else if (leaveStartDate === null) {
      toast.error("Enter leave start date");
    } else if (leaveEndDate === null) {
      toast.error("Enter leave end date");
    } else {
      dispatch({
        type: "UPDATE_LEAVE_REQUEST",
        payload: {
          organizationId: organizationId,
          leaveId: leaveid,
          employeeId: myEmployeeId,
          startDate: dayjs(leaveStartDate).format("YYYY-MM-DD"),
          endDate: dayjs(leaveEndDate).format("YYYY-MM-DD"),
          notes: notes,
          leaveType: leaveType,
        },
      });
    }
  };
  useEffect(() => {
    setNotes(item.notes);
    setLeaveStartDate(new Date(item.startDate));
    setLeaveEndDate(new Date(item.endDate));
    setEmployeeId(item.employeeId);
    setLeaveType(item.leaveType);
    setLeaveId(item.leaveId);
  }, [item]);

  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Update Leave</p>
      </div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Leave Type* : </label>
              <select
                name="pageSize"
                onChange={(e) => {
                  setLeaveType(e.target.value);
                }}
                value={leaveType}
              >
                <option value="SICK_LEAVE">SICK_LEAVE</option>
                <option value="ANNUAL_LEAVE">ANNUAL_LEAVE</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Notes* : </label>
              <textarea
                style={{ minHeight: "60px", padding: "10px" }}
                type="text"
                name="title"
                value={notes}
                placeholder="Description"
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </p>
          </div>
          <div className="customInput--title">
            <p>Start Date * :</p>
            <p>
              <DatePicker
                selected={leaveStartDate}
                onChange={(date) => setLeaveStartDate(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
                maxDate={leaveEndDate}
              />
            </p>
          </div>
          <div className="customInput--title">
            <p>End Date * :</p>
            <p>
              <DatePicker
                selected={leaveEndDate}
                onChange={(date) => setLeaveEndDate(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
                minDate={leaveStartDate}
              />
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              onClick={handleAddCategorySubmit}
              style={{ marginBottom: "50px" }}
            >
              {updateButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(UpdateLeave);
