import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getOrganizationId } from "../../FrontendHelper";
import { useHistory } from "react-router";

const mapStateToProps = (store) => {
  const { buttonText, addPayrunDatePeriod, isSuccessRunPayrollError } =
    store.mainState;
  return { buttonText, addPayrunDatePeriod, isSuccessRunPayrollError };
};

function AddPayroller({
  buttonText,
  addPayrunDatePeriod,
  isSuccessRunPayrollError,
}) {
  const [payRunPeriod, setPayRunPeriod] = useState("");
  const [payPeriodEndDate, setPayPeriodEndDate] = useState("");
  const [payPeriodStartDate, setPayPeriodStartDate] = useState("");

  const [paymentDate, setPaymentDate] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const organizationId = getOrganizationId();
  useEffect(() => {
    if (
      isSuccessRunPayrollError ===
      "Please validate payment before running payroll."
    ) {
      toast.info("Redirecting to the subscription page !");
      setTimeout(() => {
        history.push("/billing-subscription");
      }, 2000);
    }
  }, [isSuccessRunPayrollError]);

  useEffect(() => {
    dispatch({
      type: "FETCH_ADD_PAYRUN_DATA_REQUEST",
      payload: {
        organizationId,
      },
    });
  }, []);
  useEffect(() => {
    setPayRunPeriod(addPayrunDatePeriod.payPeriod);
    setPayPeriodEndDate(new Date(addPayrunDatePeriod.payrollEndPeriod));
    setPayPeriodStartDate(new Date(addPayrunDatePeriod?.startDate));
  }, [addPayrunDatePeriod]);

  useEffect(() => {
    var today = new Date();

    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    setPaymentDate(tomorrow);
  }, [addPayrunDatePeriod]);

  const handleRunPayroll = (e) => {
    e.preventDefault();
    if (payPeriodEndDate === null) {
      toast.error("Pay period end date can not be empty");
    } else if (paymentDate === null) {
      toast.error("Payment date can not be empty");
    } else {
      dispatch({
        type: "POST_PAY_RUN_REQUEST",
        payload: {
          period: payRunPeriod,
          payPeriodEnding: dayjs(payPeriodEndDate).format("YYYY-MM-DD"),
          paymentDate: dayjs(paymentDate).format("YYYY-MM-DD"),
          organizationId: organizationId,
        },
      });
    }
  };

  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Add New Pay Run</p>
      </div>
      <div className="payPeriod--container">
        <div className="payPeriod--container--para">
          <p>Let's pay your Employees !!!</p>
          <p>Pay Period</p>
          <p>
            {/* {dayjs(addPayrunDatePeriod.startDate).format("DD-MMM-YYYY")} ~{" "}
            {dayjs(addPayrunDatePeriod.payrollEndPeriod).format("DD-MMM-YYYY")} */}
            {dayjs(payPeriodStartDate).format("DD-MMM-YYYY")} ~{" "}
            {dayjs(payPeriodEndDate).format("DD-MMM-YYYY")}
          </p>
        </div>
      </div>
      <div className="Modal--form" style={{ marginTop: "-20px" }}>
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Pay Run Period * : </label>
              <select
                onChange={(e) => {
                  setPayRunPeriod(e.target.value);
                  if (e.target.value === "WEEKLY") {
                    setPayPeriodStartDate(
                      dayjs(payPeriodEndDate).subtract(1, "weeks")
                    );
                  } else if (e.target.value === "FORTNIGHTLY") {
                    setPayPeriodStartDate(
                      dayjs(payPeriodEndDate).subtract(2, "weeks")
                    );
                  } else if (e.target.value === "MONTHLY") {
                    setPayPeriodStartDate(
                      dayjs(payPeriodEndDate).subtract(1, "months")
                    );
                  }
                }}
                value={payRunPeriod}
              >
                <option value="WEEKLY">Weekly</option>
                <option value="FORTNIGHTLY">Fortnightly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </p>
          </div>
          <div className="customInput--title">
            <p>Pay Period Ending * :</p>
            <p>
              <DatePicker
                selected={payPeriodEndDate}
                onChange={(date) => {
                  if (payRunPeriod === "WEEKLY") {
                    setPayPeriodStartDate(dayjs(date).subtract(1, "weeks"));
                  } else if (payRunPeriod === "FORTNIGHTLY") {
                    setPayPeriodStartDate(dayjs(date).subtract(2, "weeks"));
                  } else if (payRunPeriod === "MONTHLY") {
                    setPayPeriodStartDate(dayjs(date).subtract(1, "months"));
                  }
                  setPayPeriodEndDate(date);
                }}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
              />
            </p>
          </div>

          <div className="customInput--title">
            <p>Payment Date * :</p>
            <p>
              <DatePicker
                selected={paymentDate}
                onChange={(date) => setPaymentDate(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
              />
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              onClick={handleRunPayroll}
              style={{ marginBottom: "50px" }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddPayroller);

// import React from 'react'

// function AddPayroller() {
//   return (
//     <div>AddPayroller</div>
//   )
// }

// export default AddPayroller
