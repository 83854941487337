import React from 'react'

import myLogo from '../../../Images/active account_white-02.png'
import congratulationImg from '../../../Images/Congratulation-PNG-Image.png'

function EmployeeInviteSuccessPage() {

    return (
        <div className="information">
            <div className="information--container">
                <div className="success-invitation">
                    <div>
                    <p><img src={congratulationImg} alt="img" /></p>
                    <p>Your have successfully accepted invitation.</p>
                    </div>
                </div>
            </div>
            <div className="information--logo">
                <p>Manage your Employee Payment in Easy and Fast Way</p>
                <p><img src={myLogo} alt="Logo" /></p>
            </div>
        </div>
    )
}

export default EmployeeInviteSuccessPage