import cookie from 'js-cookie'

// set in cookie
export const setCookie = (key,value)=> {
    if(window !== "undefined") {
         cookie.set(key,value,{
            expires: 1
        })
    }
       
}
// remove from cookie
export const removeCookie = key=> {
    if(window !== "undefined") {
        cookie.remove(key,{
            expires: 1
        })
    }
}
// get from cookie such as stored token
export const getCookie = key=> {
    if(window !== "undefined") {
        return cookie.get(key)
    }
}
// will be useful when we need to make request to server
// set in localstorage
export const setLocalStorage = (key,value)=> {
    if(window !== 'undefined') {
        localStorage.setItem(key,JSON.stringify(value))
    }
}
// remove from localStoreage
export const removeLocalStorage = key=> {
    if(window !== 'undefined'){
        localStorage.removeItem(key)
    }
}
// authenticate user for two step Verification
export const authenticateTwoSVAdmin = (response,next)=> {
    setCookie('tsvtoken',response.data?.token);
    next();
};
// authenticate user by passing data to cookie and localStorage
export const authenticateAdmin = (response,next)=> {
    setCookie('token',response.token);
    setLocalStorage('adminProfilefirstName',response.adminFirstName);
    setLocalStorage('adminProfilelastName',response.adminLastName);
    setLocalStorage('organizationName',response.organizationName);
    setLocalStorage('organizationId',response.organizationId);
    next();
};
// authenticate user by passing data to cookie and localStorage
export const authenticateAdminFromSkip = (response)=> {
    setCookie('token',response?.data?.token);

    if(response?.data?.organizationId) {
        setLocalStorage('adminProfilefirstName',response.data?.adminFirstName);
        setLocalStorage('adminProfilelastName',response?.data?.adminLastName);
        setLocalStorage('organizationName',response?.data?.organizationName);
        setLocalStorage('organizationId',response?.data?.organizationId);
        setLocalStorage('loginType',response?.data?.loginType);
    } else {
        setLocalStorage("accountantType",response?.data?.accountantType)
        setLocalStorage('adminProfilefirstName',response?.data?.adminFirstName);
        setLocalStorage('adminProfilelastName',response?.data?.adminLastName);
        setLocalStorage('originalAccFirstName',response?.data?.adminFirstName);
        setLocalStorage('originalAccLastName',response?.data?.adminLastName);
        setLocalStorage('loginType',response?.data?.loginType);
        setLocalStorage('admin_accountant',false);
    };

};
export const pushToAccountantPage = (response)=> {
    setLocalStorage("admin_accountant",false);
    setLocalStorage('adminProfilefirstName',response?.firstName);
    setLocalStorage('adminProfilelastName',response?.lastName);
}
// validate email and get token for login
export const validateUserAdmin = (response,next)=> {
    console.log("the validation helper on signp response",response)
    setCookie('token',response.token);
    next();
};
// validate email and get token for login
export const getNameAdmin = (response,next)=> {
    setLocalStorage('adminProfilefirstName',response?.firstName);
    setLocalStorage('adminProfilelastName',response?.lastName);
    next();
};
// // get admin Type
// export const getNameAdmin = (response,next)=> {
//     setLocalStorage('adminProfilefirstName',response?.firstName);
//     setLocalStorage('adminProfilelastName',response?.lastName);
//     next();
// };
// validate otp for forgot password and save in local
export const validateOTPForgotPassword = (response,next)=> {
    setCookie('myToken',response.token,{ sameSite: 'None', secure: false });
    next();
}

// access user info from localStorage
export const isAuth = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('adminProfilefirstName')) {
                return JSON.parse(localStorage.getItem("adminProfilefirstName"))
            } else {
                return false
            }
        }
    }
}
// access user info from localStorage
export const isAuth2 = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('adminProfilelastName')) {
                return JSON.parse(localStorage.getItem("adminProfilelastName"))
            } else {
                return false
            }
        }
    }
}
// access organization name from localstorage
export const getOrganizationName = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('organizationName')) {
                return JSON.parse(localStorage.getItem("organizationName"))
            } else {
                return false
            }
        }
    }
}
// access organization id
export const getOrganizationId = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('organizationId')) {
                return JSON.parse(localStorage.getItem("organizationId"))
            } else {
                return false
            }
        }
    }
}
// accountant section

// get loginType
export const getAdminType = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('organizationId')) {
                return JSON.parse(localStorage.getItem("organizationId"))
            } else {
                return false
            }
        }
    }
}
// get loginType
export const getLoginType = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('loginType')) {
                return JSON.parse(localStorage.getItem("loginType"))
            } else {
                return false
            }
        }
    }
}


// get orgainzation details on clicking orgainzation in accountant admin
export const getOrganizationLoginRecepies = (response)=> {
        setLocalStorage('adminProfilefirstName',response.adminProfilefirstName);
        setLocalStorage('adminProfilelastName',response?.adminProfilelastName);
        setLocalStorage('organizationName',response?.organizationName);
        setLocalStorage('organizationId',response?.organizationId);
        setLocalStorage('loginType',response?.loginType);
        setLocalStorage('admin_accountant',response?.admin_accountant);
};

// get loginType
export const getLoginTypeUser = ()=> {
    if(window !== 'undefined') {
            if(localStorage.getItem('loginTypeUser')) {
                return JSON.parse(localStorage.getItem("loginTypeUser"))
            } else {
                return false
            }

    }
}

// get accountant type
export const getAdmin_accountant = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('admin_accountant')) {
                return JSON.parse(localStorage.getItem("admin_accountant"))
            } else {
                return false
            }
        }
    }
}
// get accountant type
export const getAccountantType = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('accountantType')) {
                return JSON.parse(localStorage.getItem("accountantType"))
            } else {
                return false
            }
        }
    }
}
// get original acc name
export const getOrginalAccFirstName = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('originalAccFirstName')) {
                return JSON.parse(localStorage.getItem("originalAccFirstName"))
            } else {
                return false
            }
        }
    }
}
// get original acc name
export const getOrginalAccLastName = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('originalAccLastName')) {
                return JSON.parse(localStorage.getItem("originalAccLastName"))
            } else {
                return false
            }
        }
    }
}