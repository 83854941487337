import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import DatePicker from "react-datepicker";
import dayjs from "dayjs"

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { getOrganizationId } from '../../FrontendHelper';


const mapStateToProps = store => {
    const { buttonText, isEnabledUsers } = store.mainState
    return { buttonText, isEnabledUsers }
}

function InviteEmployeeForm({ buttonText, isEnabledUsers }) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [employeeStartDate, setEmployeeStartDate] = useState("")
    const [email, setEmail] = useState("")
    const [jobTittle, setJobTitle] = useState("")
    const [contributionPercentage,setContributionPercentage] = useState(10)

    // Employment Details 
    const [employeType, setEmployeeType] = useState("FULL_TIME")
    const [weekyWHorus, setWeeklyWHours] = useState(null)
    const [leaveLoading, setLeaveLoading] = useState(0)
    const [payRate, setPayRate] = useState("")
    const [ratePer, setRatePer] = useState("PER_HOUR")

    const dispatch = useDispatch();

    const handleAddCategorySubmit = (e) => {
        const organizationId = getOrganizationId()
        e.preventDefault();
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(employeeStartDate === "") {
            toast.error("Enter emplpoyee start date")
        }
        else if(firstName === "") {
            toast.error("Enter your first name")
        }
        else if(lastName === "") {
            toast.error("Enter your last name")
        }
        else if(jobTittle === "") {
            toast.error("Enter your job title")
        }
        else if(email === "") {
            toast.error("Enter your email address")
        }
        else if(!email.match(regexEmail)) {
            toast.error("Enter your email address containing special charecters")
        }
        else if(jobTittle === "") {
            toast.error("Enter your job title")
        }
        else if(weekyWHorus === null) {
            toast.error("Enter weekly work hours")
        }
        else if(weekyWHorus <=0) {
            toast.error("Weekly work hours should be greater than 0")
        }
        else if(leaveLoading <0) {
            toast.error("Leave loading shouldn't be less than 0")
        }
        else if(payRate === null) {
            toast.error("Enter employee payRate")
        }
        else if(payRate <=0) {
            toast.error("Pay rate shouldn't be less than 0")
        }
        else if(contributionPercentage <=0) {
            toast.error("Contribution percentage shouldn't be less than 0")
        }
        else if(contributionPercentage >= 100) {
            toast.error("Contributin percetage shouldn't greater than 100 %")
        }
        else {
            dispatch({
                type: "INVITE_EMPLOYEE_REQUEST",
                payload: {
                    "organizationId": organizationId,
                    "firstName": firstName,
                    "lastName": lastName,
                    "jobTitle": jobTittle,
                    "email": email,
                    "startDate": dayjs(employeeStartDate).format("YYYY-MM-DD"),
                    "payPeriod": "weekly",
                    "contributionPercentage": 10,
                    "employmentType": employeType,
                    "weeklyHours": parseFloat(weekyWHorus),
                    "leaveLoading": leaveLoading,
                    "payRate": payRate,
                    "selectAnOption": ratePer,
                    "contributionPercentage": contributionPercentage
    
                }
            })
        }


    }
    useEffect(() => {
        if (isEnabledUsers === "employeeInvited") {
            let currentPage = 1;
            let postsPerPage = 10
            dispatch({ type: "INVITE_EMPLOYEE_MODAL" });
            dispatch({
                type: "FETCH_EMPLOYEE_DATA_REQUEST",
                payload: { currentPage, postsPerPage }
            })
            setFirstName("")
            setLastName("")
            setEmail("")
            setJobTitle("")
            setEmployeeStartDate("")

            setEmployeeType("FULL_TIME")
            setWeeklyWHours("")
            setLeaveLoading("")
            setPayRate("")
            setRatePer("PER_HOUR")
            setContributionPercentage(10)
        }
    }, [isEnabledUsers])
    return (
        <div>
            <div className="Modal--heading">
                <p>Invite your employee </p>
            </div>
            <div className="organization">
                <div className="organixation--information--container">
                    <form>
                        <div className="form--container">
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>Start Date* : </p>
                                    <p className="org--inputDate"><DatePicker
                                        selected={employeeStartDate}
                                        onChange={(date) => setEmployeeStartDate(date)}
                                        disabledKeyboardNavigation
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="dd / mm / yyyy"
                                        dateFormat="dd / MM / yyyy"
                                    /></p>
                                </div>
                                <div className="setting--organization--field--para">
                                </div>
                            </div>
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>First Name* : </p>
                                    <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="setting--organization--field--para">
                                    <p>Last Name* : </p>
                                    <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                            </div>
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>Job Title* : </p>
                                    <input type="text" placeholder="Job Title" value={jobTittle} onChange={(e) => setJobTitle(e.target.value)} />
                                </div>
                                <div className="setting--organization--field--para">
                                    <p>Email* : </p>
                                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <hr />
                            <p className="sub--title">Employment Details :-</p>
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>Employment Type* : </p>
                                    <select onChange={(e) => setEmployeeType(e.target.value)}>
                                        <option value="FULL_TIME">Full Time</option>
                                        <option value="PART_TIME">Part Time</option>
                                        <option value="CASUAL">Casual</option>
                                    </select>
                                </div>
                                <div className="setting--organization--field--para">
                                </div>
                            </div>
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>Weekly Work hours* : </p>
                                    <input type="number" placeholder="Weekly Work hours" value={weekyWHorus} onChange={(e) => setWeeklyWHours(e.target.value)} />
                                </div>
                                <div className="setting--organization--field--para">
                                    <p>Leave Loading* : </p>
                                    <input type="number" placeholder="Leave Loading" value={leaveLoading} onChange={(e) => setLeaveLoading(e.target.value)} />
                                </div>
                            </div>
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>Option* : </p>
                                    <select value={ratePer} onChange={(e) => setRatePer(e.target.value)}>
                                        <option value="PER_HOUR">Hour</option>
                                        <option value="PER_DAY">Day</option>
                                        <option value="PER_ANNUM">Annum</option>
                                    </select>
                                </div>
                                <div className="setting--organization--field--para">
                                    <p>Pay Rate* : </p>
                                    <input type="number" placeholder="Pay Rate" value={payRate} onChange={(e) => setPayRate(e.target.value)} />
                                </div>
                            </div>
                            <div className="setting--organization--field">
                                <div className="setting--organization--field--name">
                                    <p>Contribution Percentage* : </p>
                                    <input type="number" placeholder="ontribution Percentage" value={contributionPercentage} onChange={(e) => setContributionPercentage(e.target.value)} />
                                </div>
                            </div>

                            <hr />
                        </div>
                        <div className="modal--container__group--buttons">
                            <button type="submit" onClick={handleAddCategorySubmit} style={{ marginBottom: "50px" }}>
                                {buttonText}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(InviteEmployeeForm)

