import { mergeMap, catchError, tap, flatMap, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { API } from "../BaseUrlProvider";
import { APITSV } from "../BaseUrlProvider2";
import { ADMIN_AUTH_REQUEST } from "./actionType";
import { from, of } from "rxjs";
import { loginFailure, loginSuccess } from "./actions";
import { getLoginType, getOrganizationId } from "../FrontendHelper";
import { APISTP, APISTPUPDATE } from "../BaseUrlProvider3";

const userType = getLoginType();
const organizationId = getOrganizationId();

// get email for signup
export const getEmailSignUpEpic = (action$) =>
  action$.pipe(
    ofType("GET_EMAIL_SIGNUP_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/${action.payload.userType}/signup`, action.payload.value)
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "GET_EMAIL_SIGNUP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "GET_EMAIL_SIGNUP_FAILURE", payload: error.response })
        )
      )
    )
  );

// validate otp for signup
export const validateOtpEpic = (action$) =>
  action$.pipe(
    ofType("VALIDATE_OTP_SIGNUP_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/${action.payload.userType}/validate-otp`,
          action.payload.value
        )
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "VALIDATE_OTP_SIGNUP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "VALIDATE_OTP_SIGNUP_FAILURE", payload: error.response })
        )
      )
    )
  );
// validate otp for two step verification
export const validateOtpTSVEpic = (action$) =>
  action$.pipe(
    ofType("VALIDATE_OTP_TSV_SIGNUP_REQUEST"),
    mergeMap((action) =>
      from(
        APITSV.post(
          `/${action.payload.userType}/validate-otp/login`,
          action.payload.data
        )
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "VALIDATE_OTP_TSV_SIGNUP_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "VALIDATE_OTP_TSV_SIGNUP_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// forgot authentication two step verification
export const forgotOtpTSVEpic = (action$) =>
  action$.pipe(
    ofType("FORGOT_AUTHENTICATION_TSV_REQUEST"),
    mergeMap((action) =>
      from(APITSV.get(`/${action.payload}/forgot-second-auth`)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "FORGOT_AUTHENTICATION_TSV_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FORGOT_AUTHENTICATION_TSV_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// resend otp
export const resendOtppic = (action$) =>
  action$.pipe(
    ofType("RESEND_OTP_REQUEST"),
    mergeMap((action) =>
      from(
        APITSV.post(
          `/${action.payload.userType}/resend-otp`,
          action.payload.data
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "RESEND_OTP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "RESEND_OTP_FAILURE", payload: error.response })
        )
      )
    )
  );
// resend otp from forgot password
export const resendOtpForgotPasspic = (action$) =>
  action$.pipe(
    ofType("RESEND_OTP_FORPASS_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/${action.payload.userType}/resend-otp`, action.payload.data)
      ).pipe(
        mergeMap((response) =>
          of({ type: "RESEND_OTP_FORPASS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "RESEND_OTP_FORPASS_FAILURE", payload: error.response })
        )
      )
    )
  );
// addtional information for signup
export const additionalInfoEpic = (action$) =>
  action$.pipe(
    ofType("ADDITIONAL_INFO_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/${action.payload.userType}/add-information`,
          action.payload.value
        )
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "ADDITIONAL_INFO_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADDITIONAL_INFO_FAILURE", payload: error.response })
        )
      )
    )
  );
// Update Admin
export const updateAdminDataEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADMIN_USERS_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/update-profile", action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "UPDATE_ADMIN_USERS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_ADMIN_USERS_FAILURE", payload: error.response })
        )
      )
    )
  );

//authenticate admin epic
export const authenticateAdminEpic = (action$) =>
  action$.pipe(
    ofType(ADMIN_AUTH_REQUEST),
    mergeMap((action) =>
      from(
        API.post(
          `/${action.payload.adminCredencial.userType}/login`,
          action.payload.adminCredencial.adminCredencial
        )
      ).pipe(
        mergeMap((response) => of(loginSuccess(response.data))),
        catchError((error) => of(loginFailure(error.response)))
      )
    )
  );
// verify two step verification code
export const verifyTwoSVEpic = (action$) =>
  action$.pipe(
    ofType("VERIFY_TWO_SV_REQUEST"),
    mergeMap((action) =>
      from(
        APITSV.post(
          `/${action.payload.userType}/add-second-step-login`,
          action.payload.data
        )
      ).pipe(
        mergeMap((res) =>
          of({ type: "VERIFY_TWO_SV_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "VERIFY_TWO_SV_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch all payrun data dashboard
export const fetchDashboardDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DASHBOARD_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/payroll/get-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "FETCH_DASHBOARD_DATA_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_DASHBOARD_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );

// fetch employee data list
export const fetchEmployeeDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_EMPLOYEE_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/employee/${userType}/get-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&organizationId=${organizationId}`
        )
      ).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "FETCH_EMPLOYEE_DATA_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_EMPLOYEE_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// posting Emplyee
export const postingEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("POST_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/employee/${userType}/add`, action.payload)).pipe(
        // tap((response) => console.log("the response data from epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// // updating Employee
export const updatingEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("PUT_EMPLOYEE_REQUEST"),
    switchMap((action) =>
      from(API.put(`/employee/${userType}/update`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "PUT_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "PUT_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );

// // fetch employee dropdown
export const fetchEmployeDropDowneEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_EMPLOYEE_DROPDOWN_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/employee/drop-down?organizationId=${organizationId}`)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_EMPLOYEE_DROPDOWN_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_EMPLOYEE_DROPDOWN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// // // fetch Leave list
// export const fetchLeaveEpic = action$ => action$.pipe(
//     ofType("FETCH_LEAVE_REQUEST"),
//     mergeMap(action=>
//     from(API.get(`/leave/${userType}?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`)).pipe(
//         // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
//         mergeMap(response => of({type: "FETCH_LEAVE_SUCCESS",payload: response.data})),
//         catchError(error=> of({type: "FETCH_LEAVE_REQUEST",payload: error.response}))
//     ))
// )
// // fetch Leave list
export const fetchLeaveEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_LEAVE_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/leave/${userType}?page=1&pageSize=10&organizationId=${organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_LEAVE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_LEAVE_REQUEST", payload: error.response })
        )
      )
    )
  );
// // Post leave
export const postLeaveEpic = (action$) =>
  action$.pipe(
    ofType("POST_LEAVE_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/leave/${userType}`, action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_LEAVE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_LEAVE_FAILURE", payload: error.response })
        )
      )
    )
  );
// Update leave
export const updateLeaveEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_LEAVE_REQUEST"),
    mergeMap((action) =>
      from(API.put(`/leave/${userType}`, action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "UPDATE_LEAVE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_LEAVE_FAILURE", payload: error.response })
        )
      )
    )
  );
// Delete Leave
export const deleteLeaveEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_LEAVE_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(`/leave/${userType}?leaveId=${action.payload.deletingId}`)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "DELETE_LEAVE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DELETE_LEAVE_FAILURE", payload: error.response })
        )
      )
    )
  );

// // Fetch payroll data
export const fetchPayRollEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PAYROLL_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/payroll/get-all?organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_PAYROLL_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PAYROLL_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch add payrun date and period
export const fetchAddPayrunDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ADD_PAYRUN_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/payroll/info?organizationId=${action.payload.organizationId}`)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_ADD_PAYRUN_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ADD_PAYRUN_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch add payroll info
export const fetchPayrollInfoEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PAYROLL_INFO_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/payroll/get-detail?payrollId=${action.payload.id}`,
          action.payload
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_PAYROLL_INFO_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PAYROLL_INFO_FAILURE", payload: error.response })
        )
      )
    )
  );
// // Add Pay run
export const postPayRunEpic = (action$) =>
  action$.pipe(
    ofType("POST_PAY_RUN_REQUEST"),
    mergeMap((action) =>
      from(API.post("/payroll/run", action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_PAY_RUN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_PAY_RUN_FAILURE", payload: error.response })
        )
      )
    )
  );
// // edit button payroll
export const editPayRunEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_PAYROLL_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/payroll/get-draft?payrollId=${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "EDIT_PAYROLL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "EDIT_PAYROLL_FAILURE", payload: error.response })
        )
      )
    )
  );
// // post adust payroll data
export const postAdjustPayrollEpic = (action$) =>
  action$.pipe(
    ofType("POST_ADJUST_PAYROLL_REQUEST"),
    mergeMap((action) => {
      console.log("data from the items", action.pa);
      return from(API.post(`/payroll/${userType}/adjust`, action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_ADJUST_PAYROLL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_ADJUST_PAYROLL_FAILURE", payload: error.response })
        )
      );
    })
  );
// // update adust payroll data
export const updateAdjustPayrollEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADJUST_PAYROLL_REQUEST"),
    mergeMap((action) => {
      // console.log("post request payload", action.payload);
      return from(API.put(`/payroll/${userType}/adjust`, action.payload)).pipe(
        // tap((response) =>
        //   console.log("response data", response.data)
        // ),
        mergeMap((response) =>
          of({ type: "UPDATE_ADJUST_PAYROLL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_ADJUST_PAYROLL_FAILURE", payload: error.response })
        )
      );
    })
  );

// // post adust payroll data from dashboard
export const postAdjustPayroll2Epic = (action$) =>
  action$.pipe(
    ofType("POST_ADJUST_PAYROLL2_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/payroll/info-added?organizationId=${action.payload.organizationId}`,
          action.payload.data
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_ADJUST_PAYROLL2_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_ADJUST_PAYROLL2_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch organization setting
export const fetchOrganizationEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ORGANIZATION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/organization/${userType}?organizationId=${organizationId}`,
          action.payload
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_ORGANIZATION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ORGANIZATION_FAILURE", payload: error.response })
        )
      )
    )
  );
// // post organization setting
export const postOrganizationEpic = (action$) =>
  action$.pipe(
    ofType("POST_ORGANIZATION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/organization/${userType}`, action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_ORGANIZATION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_ORGANIZATION_FAILURE", payload: error.response })
        )
      )
    )
  );
// // post personal setting
export const postPersonalEpic = (action$) =>
  action$.pipe(
    ofType("POST_PERSONAL_SETTING_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/${userType}/update-information`, action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_PERSONAL_SETTING_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_PERSONAL_SETTING_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch employee payment Data
export const fetchPaymentSettingEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PAYMENT_SETTING_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/employee-payment/${userType}?organizationId=${organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_PAYMENT_SETTING_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PAYMENT_SETTING_FAILURE", payload: error.response })
        )
      )
    )
  );
// // post employee payment setting
export const postEmployeePaymentSettingEpic = (action$) =>
  action$.pipe(
    ofType("POST_PAYMENT_SETTING_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/employee-payment/${userType}`, action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "POST_PAYMENT_SETTING_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_PAYMENT_SETTING_FAILURE", payload: error.response })
        )
      )
    )
  );

// invite employee
export const inviteEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("INVITE_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/employee/${userType}/own-information`, action.payload)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "INVITE_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "INVITE_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// Submit invited employee data
export const submitInvitedEmployeeDataEpic = (action$) =>
  action$.pipe(
    ofType("SUBMIT_INVITED_EMPLOYEE_DATA_REQUEST"),
    mergeMap((action) =>
      from(API.post("/employee/employee/own-information", action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({
            type: "SUBMIT_INVITED_EMPLOYEE_DATA_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SUBMIT_INVITED_EMPLOYEE_DATA_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// get Privacy terms condition
export const fetchPrivacyEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRIVACY_REQUEST"),
    mergeMap((action) =>
      from(API.get("/privacy-terms")).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_PRIVACY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PRIVACY_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch payslip data
export const fetchPayslipDateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PAYSLIP_DATA_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/payroll/pay-slip?payrollId=${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_PAYSLIP_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PAYSLIP_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// Upload organization logo
export const UploadOrgLogoEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_ORG_LOGO_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/organization/${userType}/image?organizationId=${organizationId}`,
          action.payload
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "UPLOAD_ORG_LOGO_SUCCESS", payload: response.data })
        ),
        // mergeMap(response => of({type: "FETCH_ORGANIZATION_REQUEST"})),
        catchError((error) =>
          of({ type: "UPLOAD_ORG_LOGO_FAILURE", payload: error.response })
        )
      )
    )
  );
// report stp
export const reportStpEpic = (action$) =>
  action$.pipe(
    ofType("REPORT_STP_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/payroll/${userType}/report-stp?payrollId=${action.payload.id}&signatoryIdentifier=${action.payload.signatoryIdentifier}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "REPORT_STP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "REPORT_STP_FAILURE", payload: error.response })
        )
      )
    )
  );
// send payslip
export const sendPayslipEpic = (action$) =>
  action$.pipe(
    ofType("SEND_PAYSLIP_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/payroll/send-payslip?payrollId=${action.payload.id}`)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "SEND_PAYSLIP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEND_PAYSLIP_FAILURE", payload: error.response })
        )
      )
    )
  );
// pay button funtionality
export const payToEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("PAY_TO_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(API.put("/payroll/complete", action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "PAY_TO_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "PAY_TO_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// get email for forgot password
export const getEmailForForgotPasswordEpic = (action$) =>
  action$.pipe(
    ofType("GET_EMAIL_FOR_FORGOT_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/${action.payload.userType}/resend-otp`, action.payload.data)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({
            type: "GET_EMAIL_FOR_FORGOT_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_EMAIL_FOR_FORGOT_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// validate otp for forgot password
export const validateOtpforForgotPassowordEpic = (action$) =>
  action$.pipe(
    ofType("VALIDATE_OTP_FORGOT_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/${action.payload.userType}/validate-otp`,
          action.payload.data
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({
            type: "VALIDATE_OTP_FORGOT_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "VALIDATE_OTP_FORGOT_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Enable Employee
export const enableEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/employee/${userType}/enable?employeeId=${action.payload.id}`)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "ENABLE_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ENABLE_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// disable Employee
export const diableEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/employee/${userType}/disable?employeeId=${action.payload.id}`)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "DISABLE_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DISABLE_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// Approve Employee
export const approveEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/employee/${userType}/own-information/approve?employeeId=${action.payload.id}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "APPROVE_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "APPROVE_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// delete employeee
export const deleteEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/employee/${userType}/delete?employeeId=${action.payload.id}`)
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "DELETE_EMPLOYEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DELETE_EMPLOYEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// resend employee invitation
export const resendInvitationEpic = (action$) =>
  action$.pipe(
    ofType("RESEND_INVITATION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/employee/${userType}/employee/own-information/resend?employeeId=${action.payload.id}&employeeEmail=${action.payload.emailId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "RESEND_INVITATION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "RESEND_INVITATION_FAILURE", payload: error.response })
        )
      )
    )
  );
// delete payroll
export const deletePayrollEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_PAYROLL_REQUEST"),
    mergeMap((action) =>
      from(API.delete(`/payroll/delete?payrollId=${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "DELETE_PAYROLL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DELETE_PAYROLL_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch report data
export const fetchReportEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_REPORT_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/report?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_REPORT_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_REPORT_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch leave balance report
export const fetchLeaveBalanceReportEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_LEAVE_BALANCE_REPORT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/report/leave-balance?employeeId=${action.payload.employeeId}&effectiveDate=${action.payload.efectiveDate}&leaveType=${action.payload.leaveType}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({
            type: "FETCH_LEAVE_BALANCE_REPORT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_LEAVE_BALANCE_REPORT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// fetch employee summary report data
export const fetchEmployeeSummaryReportEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_EMPLOYEE_SUMMARY_REPORT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/report/employee-summary?period=${action.payload.eSPeriod}&startDate=${action.payload.esStartDateX}&endDate=${action.payload.eSEndDateX}&employeeId=${action.payload.eSEmployeeId}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({
            type: "FETCH_EMPLOYEE_SUMMARY_REPORT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_EMPLOYEE_SUMMARY_REPORT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// fetch payroll activiry summary report
export const fetchActivitySummaryReportEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ACTIVITY_SUMMARY_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/report/activity-summary?employeeId=${action.payload.aSEmployeeId}&period=${action.payload.aSPeriod}&startDate=${action.payload.esStartDateX}&endDate=${action.payload.eSEndDateX}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap((response) =>
          of({ type: "FETCH_ACTIVITY_SUMMARY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "FETCH_ACTIVITY_SUMMARY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Search Company Name for Orgainzation details
export const searchCompanyNameEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COMPANY_NAME_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/organization/abn-by-name?name=${action.payload.searchWord}`)
      ).pipe(
        mergeMap((response) =>
          of({ type: "SEARCH_COMPANY_NAME_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_COMPANY_NAME_FAILURE", payload: error.response })
        )
      )
    )
  );
// Search Super fund name and details
export const searchSuperFundNameEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_SUPERFUND_NAME_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/employee/find-super-name?name=${action.payload.searchWord}`)
      ).pipe(
        mergeMap((response) =>
          of({ type: "SEARCH_SUPERFUND_NAME_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_SUPERFUND_NAME_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch activity log
export const fetchActivityLogEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ACTIVITY_LOG_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/activity-log?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&organizationId=${organizationId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_ACTIVITY_LOG_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ACTIVITY_LOG_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch billing subscription
export const fetchProductInfoEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRODUCT_INFO_REQUEST"),
    mergeMap((action) =>
      from(API.get("/product-information")).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_PRODUCT_INFO_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PRODUCT_INFO_FAILURE", payload: error.response })
        )
      )
    )
  );
// calculate billing price
export const fetchBillingPriceEpic = (action$) =>
  action$.pipe(
    ofType("CALCULATE_BILLING_PRICE_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/product-information/calculate-price?employeeCount=${action.payload.employeeCount}&duration=${action.payload.duration}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "CALCULATE_BILLING_PRICE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CALCULATE_BILLING_PRICE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// fetch checkout url
export const fetchCheckOutUrlEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CHECKOUT_URL_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/product-information/billing-url?employeeCount=${action.payload.employeeCount}&duration=${action.payload.duration}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_CHECKOUT_URL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_CHECKOUT_URL_FAILURE", payload: error.response })
        )
      )
    )
  );
// post payment successfull endpoint
export const postPaymentSuccessfullEpic = (action$) =>
  action$.pipe(
    ofType("POST_PAYMENT_SUCCESSFULL_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/product-information/payment-successful", action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "POST_PAYMENT_SUCCESSFULL_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "POST_PAYMENT_SUCCESSFULL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// fetch payment history
export const fetchPaymentHistoryEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PAYMENT_HISTORY_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/stripe-payments/detail?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&organizationId=${action.payload.organizationId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_PAYMENT_HISTORY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_PAYMENT_HISTORY_FAILURE", payload: error.response })
        )
      )
    )
  );
// Cancel Subscription
export const cancelSubscriptionEpic = (action$) =>
  action$.pipe(
    ofType("CANCEL_SUBSCRIPTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/stripe-payments/cancel-subscription?organizationId=${organizationId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "CANCEL_SUBSCRIPTION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "CANCEL_SUBSCRIPTION_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch accountant information
export const fetchOrgAccountantEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ORG_ACCOUNTANT_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/link/linked-accountant-information`)).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_ORG_ACCOUNTANT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ORG_ACCOUNTANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// add accountant
export const inviteOrgAccountantEpic = (action$) =>
  action$.pipe(
    ofType("INVITE_ORG_ACCOUNTANT_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/link/organization-add`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "INVITE_ORG_ACCOUNTANT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "INVITE_ORG_ACCOUNTANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// remove accountant
export const removeAccountantEpic = (action$) =>
  action$.pipe(
    ofType("REMOVE_ACCOUNTANT_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/link/remove-link/organization`)).pipe(
        mergeMap((response) =>
          of({ type: "REMOVE_ACCOUNTANT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "REMOVE_ACCOUNTANT_FAILURE", payload: error.response })
        )
      )
    )
  );

//fetech finalise employee stp

export const fetchEmployeeFinaliseStp = (action$) =>
  action$.pipe(
    ofType("FETCH_EMPLOYEE_FINALISE_STP_REQUEST"),
    mergeMap((action) =>
      // from(APISTP.get(`/payroll/get-finalize-stp?startYear=2018&endYear=2019`)).pipe(

      from(
        APISTP.get(
          `/payroller/v2/api/payroll/get-finalize-stp?startYear=${action.payload.startYear}&endYear=${action.payload.endYear}`
        )
      ).pipe(
        // tap(response=> console.log("checking the response of the finalise stp",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_EMPLOYEE_FINALISE_STP_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_EMPLOYEE_FINALISE_STP_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// update finalise stp employee
export const updateAdjustEmployeeFinaliseStp = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADJUST_EMPLOYEE_FINALISESTP_REQUEST"),
    mergeMap((action) =>
      from(
        APISTP.post(
          `/payroller/v2/api/payroll/finalize-stp?saveAction=false`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_ADJUST_EMPLOYEE_FINALISESTP_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_ADJUST_EMPLOYEE_FINALISESTP_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const saveEmployeeFinaliseStp = (action$) =>
  action$.pipe(
    ofType("SAVE_EMPLOYEE_FINALISE_STP_REQUEST"),
    mergeMap((action) =>
      from(
        APISTP.post(
          `/payroller/v2/api/payroll/finalize-stp?saveAction=true`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "SAVE_EMPLOYEE_FINALISE_STP_REQUEST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SAVE_EMPLOYEE_FINALISE_STP_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
