import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import logoImage from '../../Images/active account_white-02.png'
import { Link } from 'react-router-dom'
import Footer from '../Z_Footer/Footer'

const mapStateToProps = (store) => {
    const { privacyData } = store.mainState;
    return { privacyData }
}

function TermsCondition({ privacyData }) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: "FETCH_PRIVACY_REQUEST" })
    }, [])
    return (
        <div>
            <div className="privacy--container">
                <div className="privacy--container__navbar">
                    <div className="logo--container">
                        <img src={logoImage} alt="logo" />
                    </div>
                    <div className="navigation--container">
                        <Link to="/privacy-policy" style={{textDecoration: "none"}}><p>Privacy-Policy</p></Link>
                        <Link to="/" style={{textDecoration: "none"}}><p>Login</p></Link>
                    </div>
                </div>
                <div className="privacy--container__body">
                    <div className="body--header">
                        <p>Terms of Service</p>
                    </div>
                    <div className="body--text">
                        <div dangerouslySetInnerHTML={{__html: privacyData.termsAndCondition}} />
                    </div>
                </div>
                <div className="privacy--container__footer">
                <Footer />
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(TermsCondition)
// <p>{privacyData.termsAndCondition}</p>

