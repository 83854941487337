import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
  },
  boxStyle: {
    backgroundColor: "white",
    padding: "10px",
    width: "160px",
    height: "120px",
  },
  boxStyleEmployee: {
    backgroundColor: "white",
    padding: "10px",
    width: "180px",
    height: "120px",
  },
  paraContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  employeeDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    lineHeight: "2px",
  },
  textEmployee: {
    color: "black",
    padding: "2px 0",
    fontSize: "9px",
  },
  text: {
    color: "black",
    padding: "7px 0",
    fontSize: "9px",
  },
});

function ShowStartDatetime({ myData }) {
  // console.log("myDataEEE",myData.periodStarting)
  return (
    <View style={styles.container}>
      <View style={styles.boxStyleEmployee}>
        <View style={styles.employeeDetails}>
          <Text style={styles.textEmployee}>Employee Name : </Text>
          <Text style={styles.textEmployee}>{myData.employeeName}</Text>
        </View>
        <View style={styles.employeeDetails}>
          <Text style={styles.textEmployee}>Role : </Text>
          <Text style={styles.textEmployee}>{myData.employeeJobTitle}</Text>
        </View>
        <View style={styles.employeeDetails}>
          <Text style={styles.textEmployee}>Address : </Text>
          <Text style={styles.textEmployee}>
            {myData.employeeAddressOne} {myData.employeeAddressTwo}
          </Text>
        </View>
        <View style={styles.employeeDetails}>
          <Text style={styles.textEmployee}>State : </Text>
          <Text style={styles.textEmployee}>{myData.mystate}</Text>
        </View>
        <View style={styles.employeeDetails}>
          <Text style={styles.textEmployee}>Postcode : </Text>
          <Text style={styles.textEmployee}>{myData.postCode}</Text>
        </View>
      </View>
      <View style={styles.boxStyle}>
        <View style={styles.paraContainer}>
          <Text style={styles.text}>Starting Period</Text>
          <Text style={styles.text}>{myData.periodStarting}</Text>
        </View>
        <View style={styles.paraContainer}>
          <Text style={styles.text}>Ending Period</Text>
          <Text style={styles.text}>{myData.periodEnding}</Text>
        </View>
        <View style={styles.paraContainer}>
          <Text style={styles.text}>Payment Date</Text>
          <Text style={styles.text}>{myData.payDate}</Text>
        </View>
        <View style={styles.paraContainer}>
          <Text style={styles.text}>Gross Earning</Text>
          <Text style={styles.text}>${myData.grossEarning}</Text>
        </View>
        <View style={styles.paraContainer}>
          <Text style={styles.text}>Net Pay</Text>
          <Text style={styles.text}>${myData.netPay}</Text>
        </View>
        <View style={styles.paraContainer}>
          <Text style={styles.text}>Superannuation</Text>
          <Text style={styles.text}>${myData.supernnuation}</Text>
        </View>
      </View>
    </View>
  );
}

export default ShowStartDatetime;
