import axios from "axios";
import { getCookie } from "./FrontendHelper";

const Token = getCookie("token");

export const APISTP = axios.create({
  baseURL: "http://3.109.137.244:8080",

  // baseURL: "https://api.activaccount.com.au:8443",
  // baseURL: "http://localhost:9195/v1/api",
  // baseURL: `${process.env.REACT_APP_API}/payroller/v1/api`,
  headers: {
    Authorization: `Bearer ${Token}`,
  },
});
