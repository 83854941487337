import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { getCookie } from "../../../FrontendHelper";
import axios from "axios";
import { authenticateAdminFromSkip } from "../../../FrontendHelper";

function ValidateOtpTSV() {
  const history = useHistory();
  const { userType } = useParams();
  const tsvtoken = getCookie("tsvtoken");
  const { userName } = useSelector((state) => state.mainState.adminCredencial);
  const [isEnabledUsers, setIsEnableUsers] = useState("");
  const [responseOnPostingEmail, setResponseOnPostingEmail] = useState(null);
  // const responseOnPostingEmail = useSelector((state) => state.mainState.responseOnPostingEmail);
  const twoStepOtpVerifyFlag = useSelector(
    (state) => state.mainState.twoStepOtpVerifyFlag
  );
  const resendButtonText = useSelector(
    (state) => state.mainState.resendButtonText
  );
  const [myVerificationCode, setMyVerificationCode] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    setIsEnableUsers("a");
    axios({
      method: "POST",
      url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/resend-otp`,
      data: {
        email: userName,
        otpFor: "LOGIN",
      },
      headers: {
        Authorization: `Bearer ${tsvtoken}`,
      },
    })
      .then((response) => {
        setIsEnableUsers("b");
        setResponseOnPostingEmail(response);
        toast.success("Verification code has been sent successfully");
      })
      .catch((error) => {
        setIsEnableUsers("");
        if (error !== undefined) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something wrong happened. Try Again Later !!!");
        }
      });
  }, []);
  const handleResendCode = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/resend-otp`,
      data: {
        email: userName,
        otpFor: "LOGIN",
      },
      headers: {
        Authorization: `Bearer ${tsvtoken}`,
      },
    })
      .then((response) => {
        setIsEnableUsers("b");
        setResponseOnPostingEmail(response);
        toast.success("Verification code has been sent successfully");
      })
      .catch((error) => {
        if (error !== undefined) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something wrong happened. Try Again Later !!!");
        }
      });

    setMyVerificationCode("");
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if (myVerificationCode === "") {
      toast.error("Enter your OTP.");
    } else if (myVerificationCode.length < 6 || myVerificationCode.length > 6) {
      toast.error("Verification code must be exatly 6 digit");
    } else {
      axios({
        method: "POST",
        url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/validate-otp/login`,
        data: {
          adminId: responseOnPostingEmail?.data?.adminId,
          otp: myVerificationCode,
          otpFor: "LOGIN",
        },
        headers: {
          Authorization: `Bearer ${tsvtoken}`,
        },
      })
        .then((response) => {
          authenticateAdminFromSkip(response);
          // console.log("The response",response)
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 100);
        })
        .catch((error) => {
          if (error !== undefined) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something wrong happened. Try Again Later !!!");
          }
        });
    }
    setMyVerificationCode("");
  };

  console.log("twoStepOtpVerifyFlag", twoStepOtpVerifyFlag);
  useEffect(() => {
    if (twoStepOtpVerifyFlag) {
      setTimeout(() => {
        window.location.replace("/dashboard");
      }, 1000);
    }
  }, [twoStepOtpVerifyFlag]);

  return (
    <>
      <ToastContainer />
      <div className="adminlogin--container">
        <div className="circle--container">
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
        </div>

        <form>
          <div className="twoStep--verification">
            <div className="twoStep--verification--container">
              <div className="twostep--verification--heading">
                <p>Two Step Verification</p>
                <hr />
              </div>
              {isEnabledUsers === "b" ? (
                <p>
                  *Verification code has been sent to your email address. Enter
                  that code below.
                </p>
              ) : (
                <p style={{ color: "red" }}>*Verification code Error !!!</p>
              )}
              <div className="verification--input">
                <p>
                  {isEnabledUsers === "b" ? (
                    <input
                      type="text"
                      placeholder="Verification Code"
                      value={myVerificationCode}
                      onChange={(e) => setMyVerificationCode(e.target.value)}
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Verification Code"
                      disabled
                    />
                  )}
                </p>
              </div>
              <p>
                {isEnabledUsers === "b" ? (
                  <button
                    className="vefify--code--button"
                    type="submit"
                    onClick={handleVerify}
                  >
                    Login
                  </button>
                ) : (
                  <button
                    className="vefify--code--button"
                    type="submit"
                    disabled
                  >
                    Login
                  </button>
                )}
                <button
                  style={{ marginLeft: "20px" }}
                  className="vefify--code--button"
                  type="submit"
                  onClick={handleResendCode}
                >
                  {resendButtonText}
                </button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ValidateOtpTSV;
