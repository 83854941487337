import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import myLogo from "../../Images/active account_white-02.png";
import { Hero } from "../../Utils";
import axios from "axios";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";

function InviteOrgAccountantAddInfo({ match }) {
  const myPricateToken = match.params.jwtToken;

  const userEmail = match.params.userEmail;

  const dispatch = useDispatch();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [fullName, setFullName] = useState("");
  const [myPassword, setMyPassword] = useState("");

  const handleSubmitButton = (e) => {
    e.preventDefault();
    if (fullName === "") {
      toast.error("Enter your username first");
    } else if (myPassword === "") {
      toast.error("Enter your password");
    } else {
      setShowProgressBar(true);
      axios({
        method: "POST",
        url: `http://3.109.137.244:8080/payroller/v1/api/link/accountant-accept`,
        // url: "http://13.127.139.254:8080/payroller/v1/api/employee/employee/own-information",
        data: {
          userName: fullName,
          password: myPassword,
        },
        headers: {
          Authorization: `Bearer ${myPricateToken}`,
        },
      })
        .then((response) => {
          toast.success("Accountant invitation accepted successfully.");
          setShowProgressBar(false);
          setTimeout(() => {
            window.location.replace("/");
          }, 2000);
        })
        .catch((error) => {
          setShowProgressBar(false);
          if (error.response === undefined) {
            toast.error("Internal Server Error. Try Again Later !!!");
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (userEmail) {
      setFullName(userEmail);
    }
  }, [userEmail]);
  return (
    <div className="information">
      {showProgressBar && Hero()}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="information--container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className="main--title">Accountant Details</p>
              <label htmlFor="" style={{ marginLeft: "20px" }}>
                Please provide your accountant details.
              </label>
            </div>
          </div>
          <div
            className="form--container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="organization--field"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                className="organization--field--name"
                style={{ width: "100%" }}
              >
                <p>User Email* : </p>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="User Name"
                />
              </div>
              <div
                className="organization--field--para"
                style={{ width: "100%" }}
              >
                <p>Password*: </p>
                <input
                  type="password"
                  style={{ width: "100%" }}
                  placeholder="Password"
                  value={myPassword}
                  onChange={(e) => setMyPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="submit--button">
            <button type="submit" onClick={handleSubmitButton}>
              Submit
            </button>
            <p>
              By clicking Submit, you agree to our{" "}
              <a
                href="https://app.activaccount.com.au/terms-of-service"
                target="_blank"
                rel="noreferrer noopener"
              >
                terms of services
              </a>{" "}
              and{" "}
              <a
                href="https://app.activaccount.com.au/privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                privacy policy
              </a>
              .
            </p>
          </div>
        </form>
      </div>
      <div className="information--logo">
        <p>Manage your Employee Payment in Easy and Fast Way with..</p>
        <p>
          <img src={myLogo} alt="Logo" />
        </p>
      </div>
    </div>
  );
}

export default InviteOrgAccountantAddInfo;
