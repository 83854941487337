import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  heading: {
    fontSize: "10px",
    fontStyle: "bold",
    paddingLeft: 8,
    textAlign: "left",
    // backgroundColor: "yellow",
    marginTop: "20px",
    // justifyContent: "flex-start"
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: "9px",
  },
  description: {
    width: "50%",
    textAlign: "left",
    paddingLeft: 8,
    fontSize: "9px",
  },
  qty: {
    width: "15%",

    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "15%",

    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
  amount: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const PaymentTableRowData = ({ items, desc }) => {
  return (
    <>
      <View style={styles.row} key={items.abnNumber}>
        {items.lkjds !== null ? (
          <Text style={styles.description}>
            {items.bankAccountName}-{items.accountNumber}
          </Text>
        ) : (
          <Text style={styles.description}>Cash</Text>
        )}
        <Text style={styles.qty}></Text>
        <Text style={styles.qty}></Text>
        <Text style={styles.rate}></Text>
        <Text style={styles.amount}>${items.subTotal}</Text>
      </View>
    </>
  );
};

export default PaymentTableRowData;
