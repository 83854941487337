import axios from "axios";
import { getCookie } from "./FrontendHelper";

// FOR ADMIN LOGIN
const Token = getCookie("token");
// console.log("checking the access token",Token)
export const API = axios.create({
  // baseURL:"https://api.activaccount.com.au:8443/payroller/v1/api",
  baseURL: "http://3.109.137.244:8080/payroller/v1/api",
  // baseURL: "http://localhost:9195/v1/api",
  //
  headers: {
    Authorization: `Bearer ${Token}`,
  },
});

// Remember to change base url
// InvitationForm page for inviting employee
// auth/signUpinformation at button
// auth/TsvResetAuth
// auth/signin/NewPassword

// in https go to signup information page and change url from http to httpsss
// email=> codermadan15hrs@gmail.com psd: Madan@987
