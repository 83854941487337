import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { toast } from 'react-toastify'
import { isAuth, isAuth2 } from '../../FrontendHelper'

const mapStateToProps = store => {
    const { buttonText, organizationSettingData, isEnabledUsers } = store.mainState
    return { buttonText, organizationSettingData, isEnabledUsers }
}

function PersonalSetting({ buttonText, organizationSettingData, isEnabledUsers }) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const dispatch = useDispatch();

    const handleSubmitButton = (e) => {
        e.preventDefault()
        if(firstName === "") {
            toast.error("Enter first name")
        }
        else if(lastName === ""){
            toast.error("Enter last name")
        }
        else {
            dispatch({
                type: "POST_PERSONAL_SETTING_REQUEST",
                payload: {
                    "firstName": firstName,
                    "lastName": lastName
                }
            })
        }


    }

    useEffect(() => {
        setFirstName(isAuth())
        setLastName(isAuth2())
    }, [])
    useEffect(()=> {
        if(isEnabledUsers === "x") {
            setTimeout(()=> {
                dispatch({
                type: "OPEN_ADD_PERSONAL_MODAL"
            })
            },1000)
        }

    },[isEnabledUsers])

    return (
        <div>
            <div className="Modal--heading">
                <p style={{ color: "black" }}>Personal Setting</p>
            </div>
            <form>
                <div className="personal">
                    <div className="personal--container">
                        <div className="personal--container--firstName">
                            <p>First Name :-</p>
                            <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>Last Name :-</p>
                            <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="modal--container__group--buttons">
                            <button type="submit" onClick={handleSubmitButton} style={{ marginTop: "20px" }}>
                                {buttonText}
                            </button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default connect(mapStateToProps, null)(PersonalSetting)

