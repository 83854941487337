import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        // borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        paddingRight: "20"
    },
    description: {
        width: '70%',
        textAlign: 'left',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
});

const AmountPaidYdt =({items})=> {
    return (
        <View style={styles.row}>
            <Text style={styles.description}></Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.qty}>${items.amountPaid || 0}</Text>
        </View>
    )
}

export default AmountPaidYdt

