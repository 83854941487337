import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import Navbar from '../Components/A_Navbar/Navbar'
import Sidebar from '../Components/A_Sidebar/Sidebar'
// import '../c/my-app.css'
import '../sass/my-app.css'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify'
import { getAdmin_accountant, getLoginType } from '../FrontendHelper'
import AccNavbar from '../AccComponent/AccNavbar/AccNavbar'
import AccSidebar from '../AccComponent/AccSidebar/AccSidebar'

const mapStateToProps = store=> {
    const {isToogleSidebar} = store.mainState;
    return {isToogleSidebar}
}

function Layout({children,isToogleSidebar}) {
    const loginType = getLoginType()
    const admin_accountant = getAdmin_accountant()
    return (
        <Fragment>
        <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {loginType === "admin" && <>
            <section id={`${isToogleSidebar ? "navbar_with_sidebar":"navbar"}`}> <Navbar /></section>
            <section id={`${isToogleSidebar ? "my_children_sidebar--colaps": "my_children"}`}><div>{ children }</div></section>
            <section ><Sidebar /></section>
            </>}
            {(loginType === "accountant" & admin_accountant === false ) && <>
            <section id={`${isToogleSidebar ? "navbar_with_sidebar":"navbar"}`}><AccNavbar /></section>
            <section id={`${isToogleSidebar ? "my_children_sidebar--colaps": "my_children"}`}><div>{ children }</div></section>
            <section > <AccSidebar /></section>
            </>}
            {(loginType === "accountant" & admin_accountant === true ) && <>
            <section id={`${isToogleSidebar ? "navbar_with_sidebar":"navbar"}`}> <Navbar  /></section>
            <section id={`${isToogleSidebar ? "my_children_sidebar--colaps": "my_children"}`}><div>{ children }</div></section>
            <section ><Sidebar /></section>
            </>}
        </Fragment>
    )
}

export default connect(mapStateToProps,null) (Layout)
