import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import businessLogo from "../../Images/63964-distance-business-rocket-sitting-vector-education-man.png";
import { Link } from "react-router-dom";

import { AiOutlinePlus } from "react-icons/ai";
import {BiX} from "react-icons/bi"

import Layout from "../../Layout/Layout";
import {  getOrganizationLoginRecepies } from "../../FrontendHelper";
import { Hero } from "../../Utils";

function AccDashboard() {

  const [containerClassName,setContainerClassName] = useState("leaveBalance_SM--containerXX")
  const dispatch = useDispatch();
  const [showInviteClientSm,setShowInviteClientSm] = useState(false)
  const [inviteOrganization,setInviteOrganization] = useState({
    organizationAdminName: "",
    organizationEmail: ""
  })

  const { clientsList, isLoadingOnRequest,isPostUpdateCompleted,isProgressShow } = useSelector(
    (state) => state.accountantState
  );


  const handleInviteOrgChange = (e)=> {
    const {name,value} = e.target
    setInviteOrganization({
      ...inviteOrganization,
      [name]:value
    })
  }
  const handleInviteClientSubmit = (e)=> {
    e.preventDefault();
    Hero()
    setInviteOrganization({
      organizationAdminName: "",
      organizationEmail: ""
    })
    dispatch({
      type: "INVITE_ORGANIZATION_REQUEST",
      payload: inviteOrganization
    })

  }

  useEffect(() => {
    dispatch({
      type: "FETCH_CLIENT_REQUEST",
    });
  }, []);
  useEffect(()=> {
    if(isPostUpdateCompleted) {
      setContainerClassName("leaveBalance_SM--containerXX")
      setTimeout(()=> {
        setShowInviteClientSm(false)
      },300)
    }
  }, [isPostUpdateCompleted])

  return (
    <Layout>
      {isProgressShow && Hero()}
      <div className="dashboard">
        <div className="dashboard--heading">
          <p>Clients</p>
          <button onClick={() => {
            setShowInviteClientSm(true)
            setTimeout(()=> {
              setContainerClassName("leaveBalance_SM--container")
            },100)
          }}>
            <AiOutlinePlus style={{ marginRight: "5px" }} /> Invite Clients
          </button>
        </div>

        <div className="dashboard--body" style={{ minHeight: "300px" }}>
          {clientsList.length > 0 ? (
            <div className="dashboard__table-container">
              <table>
                <thead>
                  <tr>
                    <th className="runPayRoll--status">S.N</th>
                    <th className="runPayRoll--period">OrganisationName</th>
                    <th className="runPayRoll--period">SubscriptionStatus</th>
                  </tr>
                </thead>
                <tbody>
                  {clientsList?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        getOrganizationLoginRecepies({
                          organizationName: item.organizationName,
                          organizationId: item.linkedOrganizationId,
                          loginType: "accountant",
                          adminProfilefirstName: "Madan",
                          adminProfilelastName: "Rijal",
                          admin_accountant: true,
                        });
                        setTimeout(() => {
                          window.location.replace("/dashboard");
                        }, 100);
                      }} style={{cursor: "pointer"}}>
                      <td>{index + 1}</td>
                      <td>{item.organizationName}</td>
                      <td><label className={item.subscriptionStatus === "ACTIVE" ? "activStatus": "not_activStatus"}></label>{item.subscriptionStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isLoadingOnRequest && (
                <div className="dashboard--loading">
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                  <p>6</p>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="empty--employee">
                <div className="empty--employee_container">
                  <div
                    className="businessLogo--container"
                    style={{ marginTop: "40px" }}>
                    <img src={businessLogo} />
                  </div>
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {" "}
                    Welcome to ActivAccount
                  </div>
                  <div> Add your all clients before New Pay Run</div>
                  <div> So you can pay them with ActivAccount</div>
                  <Link to="#" >
                    <button onClick={() => {
            setShowInviteClientSm(true)
            setTimeout(()=> {
              setContainerClassName("leaveBalance_SM--container")
            },100)
          }}>Click Here to Add clients</button>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* invite clients modal */}
      {showInviteClientSm && <div className="leaveBalance_SM">
        <div className={containerClassName}>
          <div className="leavBalance_sm--heading">
            <p>Invite Clients</p>
            <p onClick={()=>  {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowInviteClientSm(false)
              },300)
            }
              }><BiX /></p>
          </div>
          <hr />
          <form>
              <>
              <div className="leavBalance_sm--body">
            <div className="leaveBalance--inputs">
              <p>Organisation Admin Name</p>
              <input type="text" name="organizationAdminName" placeholder="First Name" value={inviteOrganization.organizationAdminName} onChange={(e)=> handleInviteOrgChange(e)} />
            </div>
            <div className="leaveBalance--inputs">
              <p>Organisation Admin Email</p>
              <input type="text" name="organizationEmail" placeholder="Admin Email" value={inviteOrganization.organizationEmail} onChange={(e)=> handleInviteOrgChange(e)} />
            </div>

          </div>
          <div className="leaveBalance--buttons">
            <button onClick={(e)=> {
              e.preventDefault()
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowInviteClientSm(false)
              },300)
              }}>Close</button>
            <button type="submit" onClick={handleInviteClientSubmit}>Submit</button>
          </div></>
          </form>
         
        </div>
      </div>}
      {/* {Hero()} */}
    </Layout>
  );
}

export default AccDashboard;
