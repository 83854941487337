import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import EmployeeHeader from './EmployeeHeader';
import EmployeeContactsRowData from './EmployeeContactsRowData';
import {useSelector} from 'react-redux'

const tableRowsCount = 1;

const styles = StyleSheet.create({
    tableMargin: {
        marginTop: "30px"
    }
})

function EmployeeContactsReportTable({reportData}) {
    console.log("thre report data in comp",reportData)


    return (
        <View style={styles.tableMargin}>
            <EmployeeHeader />
            <EmployeeContactsRowData reportData={reportData} />
            {/* <LeaveTableRowData  desc="Sick Leave"  acured={item.sickLeaveAcquired} taken={item.sickLeaveTaken} remaining={item.sickLeaveRemaning} />
            <LeaveTableRowData  desc="Annual Leave" acured={item.annualLeaveAcquired} taken={item.annualLeaveTaken} remaining={item.annualLeaveRemaning} /> */}
        </View>
    )
}

export default EmployeeContactsReportTable


