import React,{useEffect,useState} from 'react'
// import axios from 'axios'
import {useDispatch,useSelector} from "react-redux"
import { getOrganizationId } from '../../../../FrontendHelper'

export default function useFetchPaymentHistory(currentPage) {
    let postsPerPage = 5
    const dispatch = useDispatch()
    // dashboardData
    const {paymentList,latestPayment} = useSelector((state) => state.mainState.paymentHistoryData)
    const loading = useSelector((state)=> state.mainState.isLoadingOnLogin)
    const [paymentsData,setPaymentData] = useState([])
    const [hasMore,setHasMore] = useState(true)
    const organizationId = getOrganizationId()


    useEffect(()=> {
        if(hasMore) {
            dispatch({
                type: "FETCH_PAYMENT_HISTORY_REQUEST",
                payload: { currentPage, postsPerPage,organizationId },
              });
        }
    },[currentPage])
    
    useEffect(()=> {
        if(paymentList?.data !== undefined) {
            setPaymentData(prePayment=> {
                return [...new Set([...prePayment,...paymentList?.data])]
            })
            setHasMore(paymentList?.data.length > 0 ? true:false)
        }
    },[currentPage,paymentList])
    return {loading,paymentsData,hasMore,latestPayment}
}
