import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import OrganizationDetails from "./OrganizationDetails";
import FirstTable from "./FirstTable";
import ShowStartDatetime from "./ShowStartDateTime";
import LeaveTable from "./LeaveTable/LeaveTable";
import PaymentTable from "./PaymentTable/PaymentTable";
import myLogo from "../../Images/ActivAccount_logo_final-01-01.png";
import LeaveYdt from "./LeaveTable/LeaveYdt";
import LeaveYdt2 from "./LeaveTable/LeaveYdt2";
import CompensationTable from "./CompensationTable";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    fontSize: "10px",
    padding: "20px",
    textAlign: "right",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  text: {
    fontSize: "10px",
    color: "yellow",
  },
  firstBox: {
    width: "300px",
    padding: "10px",
    textAlign: "left",
    backgroundColor: "#3e445ead",
    fontSize: "10px",
    lineHeight: "1.5px",
  },
  imgContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  logo: {
    width: "120px",
    height: "50px",
    objectFit: "cover",
  },
});

const mapStateToProps = (store) => {
  const { buttonText, payslipData } = store.mainState;
  return { buttonText, payslipData };
};

function ShowPaySlip({ buttonText, id, payslipData }) {
  const [myEmployee, setEmployee] = useState([1, 3, 4, 6, 7]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_PAYSLIP_DATA_REQUEST",
      payload: {
        id,
      },
    });
  }, [id]);

  console.log("testing saroj", payslipData.employeePayslip);

  // Create Document Component
  const MyDocument = () => (
    <Document className="document">
      {payslipData.employeePayslip !== undefined &&
        payslipData.employeePayslip.map((item) => (
          <Page size="A4" style={styles.page} wrap>
            <View style={styles.imgContainer}>
              {/* <Image style={styles.logo} src="https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg" /> */}
              {/* <Image style={styles.logo} src={item.organizationLogo} /> */}
            </View>
            <OrganizationDetails
              companyName={item.organizationFullName}
              abn={item.abnNumber}
              address={{
                one: item.employeeAddressOne,
                two: item.employeeAddressTwo,
              }}
              emState={item.employeeState}
              empostCode={item.employeePostCode}
            />
            <ShowStartDatetime
              myData={{
                periodStarting: item.periodStarting,
                periodEnding: item.periodEnding,
                payDate: item.payDate,
                grossEarning: item.grossEarning,
                netPay: item.subTotal,
                supernnuation: item.supernnuation,
                employeeName: item.employeeFullName,
                employeeAddressOne: item.employeeAddressOne,
                employeeAddressTwo: item.employeeAddressTwo,
                mystate: item.employeeState,
                postCode: item.employeePostCode,
                employeeJobTitle: item.employeeJobTitle,
              }}
            />
            <FirstTable item={item} />
            <LeaveTable item={item} />

            {item.sickLeaveEarning !== "0.00" && <LeaveYdt items={item} />}
            {item.annualLeaveEarning !== "0.00" && <LeaveYdt2 items={item} />}

            {/* {item.workerCompensation>0 && <CompensationTable item={item} />} */}
            <PaymentTable item={item} />
          </Page>
        ))}
    </Document>
  );

  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>View PaySlip</p>
      </div>
      <div className="pdf--container">
        <PDFViewer className="pdf--container__pdf">
          <MyDocument />
        </PDFViewer>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(ShowPaySlip);
