import { toast } from "react-toastify";
const initialState = {
    buttonText: "Submit",
    isLoadingOnRequest: false,
    isEnabledUsers: "",
    isProgressShow: false,
    asyncState: "",
    isPostUpdateCompleted: false,
    clientsList: [],
    bulkPayrollOrgList: [],
    organizationList: {},
    accountantList: [],
    accPersonalData: {}
}

const accountReducer = (state = initialState, action) => {

        // fetch client 
        if (action.type === "FETCH_CLIENT_REQUEST") {
            return { ...state, isLoadingOnRequest: true,}
        }
        if (action.type === "FETCH_CLIENT_SUCCESS") {
            return { ...state,isLoadingOnRequest: false,clientsList: action.payload,isEnabledUsers: "" }
        }
        if (action.type === "FETCH_CLIENT_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isLoadingOnRequest: false}
        }
        // add organizations
        if (action.type === "INVITE_ORGANIZATION_REQUEST") {
            return { ...state, isProgressShow: true,isPostUpdateCompleted: false}
        }
        if (action.type === "INVITE_ORGANIZATION_SUCCESS") {
            toast.success("Your organization invited successfully")
            return { ...state,isProgressShow: false, isPostUpdateCompleted: true}
        }
        if (action.type === "INVITE_ORGANIZATION_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isProgressShow: false,isPostUpdateCompleted: false}
        }
        // invite accountants
        if (action.type === "INVITE_CLIENTS_REQUEST") {
            return { ...state, isProgressShow: true,isPostUpdateCompleted: false}
        }
        if (action.type === "INVITE_CLIENTS_SUCCESS") {
            toast.success("Your accountant invited successfully")
            return { ...state,isProgressShow: false, isPostUpdateCompleted: true}
        }
        if (action.type === "INVITE_CLIENTS_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isProgressShow: false,isPostUpdateCompleted: false}
        }
        // DELETE ACCOUNTATN
        if (action.type === "DELETE_ACCOUNTANT_REQUEST") {
            return { ...state, isProgressShow: true,isPostUpdateCompleted: false,isEnabledUsers: "a"}
        }
        if (action.type === "DELETE_ACCOUNTANT_SUCCESS") {
            toast.success("Your accountant deleted successfully")
            return { ...state,isProgressShow: false, isPostUpdateCompleted: true, isEnabledUsers: "b"}
        }
        if (action.type === "DELETE_ACCOUNTANT_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isProgressShow: false,isPostUpdateCompleted: false,isEnabledUsers: ""}
        }
        // fetch accountants lists
        if (action.type === "FETCH_ACCOUNTANT_LIST_REQUEST") {
            return { ...state, isLoadingOnRequest: true}
        }
        if (action.type === "FETCH_ACCOUNTANT_LIST_SUCCESS") {
            return { ...state,isLoadingOnRequest: false,accountantList: action.payload,isEnabledUsers:""}
        }
        if (action.type === "FETCH_ACCOUNTANT_LIST_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isLoadingOnRequest: false}
        }
        // post accountant invite details
        if (action.type === "POST_INVITE_ACCOUNTANT_REQUEST") {
            return { ...state, isProgressShow: true,isPostUpdateCompleted: false}
        }
        if (action.type === "POST_INVITE_ACCOUNTANT_SUCCESS") {
            toast.success("You have successfully accepted accountant invitation")
            return { ...state,isProgressShow: false,accountantList: action.payload,isPostUpdateCompleted: true}
        }
        if (action.type === "POST_INVITE_ACCOUNTANT_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isProgressShow: false,isPostUpdateCompleted: false}
        }
        // fetch accounttn personal data
        if (action.type === "FETCH_ACC_PERSONALDATA_REQUEST") {
            return { ...state, isProgressShow: true,}
        }
        if (action.type === "FETCH_ACC_PERSONALDATA_SUCCESS") {

            return { ...state,isProgressShow: false,accPersonalData: action.payload}
        }
        if (action.type === "FETCH_ACC_PERSONALDATA_FAILURE") {
            if (action.payload !== undefined) {
                toast.error(action.payload.data.message)
            } else {
                toast.error("Something Went Wrong. Try Again.")
            }
            return { ...state, isProgressShow: false,isPostUpdateCompleted: false}
        }


        if(action.type === "SEND_BULK_PAYROLL_LIST") {
            return {
                ...state,bulkPayrollOrgList: action.payload
            }
        }


    return state;
}
export default accountReducer