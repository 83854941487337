import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import SalaryAndWage from "./SalaryAndWage/SalaryAndWage";
import SalaryAndWageYdt from "./SalaryAndWage/SalaryAndWageYdt";
import ActivitySummaryTax from "./TaxEmployeeSummary/ActivitySummaryTax";
import ActivitySummaryTaxYdt from "./TaxEmployeeSummary/ActivitySummaryTaxYdt";
import ActivitySuperContri from "./ActivitySuperContri/ActivitySuperContri";
import ActivitySuperContriYdt from "./ActivitySuperContri/ActivitySuperContriYdt";
import ActivityNetPay from "./ActivityNetPay/ActivityNetPay";
import ActivityNetPayYdt from "./ActivityNetPay/ActivityNetPayYdt";
import AmountPaid from "./AmountPaid/AmountPaid";
import AmountPaidYdt from "./AmountPaid/AmountPaidYdt";
import ActivitySummarySickLeave from "./ActivitySummarySickLeave/ActivitySummarySickLeave";
import ActivitySummarySickLeaveYdt from "./ActivitySummarySickLeave/ActivitySummarySickLeaveYdt";
import ActivitySummaryAnnualLeave from "./ActivitySummaryAnnualLeave/ActivitySummaryAnnualLeave";
import ActicitySummaryAnnualLeaveYdt from "./ActivitySummaryAnnualLeave/ActicitySummaryAnnualLeaveYdt";
import ActivitySExtraHours from "./ExtraHours/ActivitySExtraHours";
import ActivitySExtraHoursYdt from "./ExtraHours/ActivitySExtraHoursYdt";
import ActivityCommBonous from "./ExtraHours/ActivityCommBonous/ActivityCommBonous";
import ActivityCommBonousYdt from "./ExtraHours/ActivityCommBonous/ActivityCommBonousYdt";
import ActivityAllowance from "./ExtraHours/Activity Allowance/ActivityAllowance";
import ActivityAllowanceYdt from "./ExtraHours/Activity Allowance/ActivityAllowanceYdt";
import ActivityDeduction from "./ExtraHours/Activity Deduction/ActivityDeduction";
import ActivityDeductionYdt from "./ExtraHours/Activity Deduction/ActivityDeductionYdt";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  heading: {
    fontSize: "10px",
    fontStyle: "bold",
    paddingLeft: 8,
    textAlign: "left",
    marginTop: "20px",
  },
  row: {
    padding: "20px",
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: "9px",
  },
  name: {
    width: "30%",
    textAlign: "left",
    paddingLeft: 8,
    fontSize: "9px",
  },
  email: {
    width: "15%",

    textAlign: "center",
    paddingRight: 8,
  },
  phone: {
    width: "15%",

    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
  address: {
    width: "40%",

    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const ActivitySummaryReportRowData = ({ activitySummaryReportData }) => {
  // console.log("the activitySummaryReportData jpt Rocker",activitySummaryReportData)
  return (
    <>
    <SalaryAndWage items={activitySummaryReportData} heading="Salary & Wage" desc="Ordinary Hours" />
    <SalaryAndWageYdt items={activitySummaryReportData} />
    <ActivitySummarySickLeave items={activitySummaryReportData} heading="Sick Leave" desc="Sick Leave" />
    <ActivitySummarySickLeaveYdt items={activitySummaryReportData} />
    <ActivitySummaryAnnualLeave items={activitySummaryReportData} heading="Annual Leave" desc="Annual Leave" />
    <ActicitySummaryAnnualLeaveYdt items={activitySummaryReportData} />
    {activitySummaryReportData.extraHours !==undefined && <><ActivitySExtraHours items={activitySummaryReportData} heading="Extra Hours" desc="Extra Hours" />
    <ActivitySExtraHoursYdt items={activitySummaryReportData} />
    </>
    }
    {activitySummaryReportData.bonousAmount !==undefined && <><ActivityCommBonous items={activitySummaryReportData} heading="Commission & Bonus" desc="Commission & Bonus" />
    <ActivityCommBonousYdt items={activitySummaryReportData} />
    </>
    }
    {activitySummaryReportData.allowanceAmount !==undefined && <><ActivityAllowance items={activitySummaryReportData} heading="Allowance" desc="Allowance" />
    <ActivityAllowanceYdt items={activitySummaryReportData} />
    </>
    }
    {activitySummaryReportData.deductionAmount !==undefined && <><ActivityDeduction items={activitySummaryReportData} heading="Deduction" desc="Deduction" />
    <ActivityDeductionYdt items={activitySummaryReportData} />
    </>
    }
    
    
    <ActivitySummaryTax items={activitySummaryReportData} heading="Tax" desc="PAYG" />
    <ActivitySummaryTaxYdt items={activitySummaryReportData} />
    <ActivitySuperContri items={activitySummaryReportData} heading="Super Contributions" desc="Super Guarantee" />
    <ActivitySuperContriYdt items={activitySummaryReportData} />
    <ActivityNetPay items={activitySummaryReportData} heading="Net Pay" desc="Net Pay" />
    <ActivityNetPayYdt items={activitySummaryReportData} />
    <AmountPaid items={activitySummaryReportData} heading="Amount Paid" desc="Amount Paid" />
    <AmountPaidYdt items={activitySummaryReportData} />
    </>
  );
};

export default ActivitySummaryReportRowData;

