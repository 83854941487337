import { View,Text,StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import dayjs from 'dayjs'

const styles = StyleSheet.create({
    container: {
        padding: "20",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        fontStyle: 'bold',
    },
    text: {
        fontSize: "14"
    },
    textDate: {
        marginTop: "5",
        fontSize: "12",
        color:"#975466"
    }
})

function ActivitySummaryTitle({activitySummaryReportData,companyName}) {

    return (
        <View style={styles.container}>
            <View>
            <Text style={styles.text}>{companyName}</Text>
            {/* <Text style={styles.textDate}>StartDate - EndDate</Text> */}
            <Text style={styles.textDate}>{dayjs(activitySummaryReportData.startDate).format("DD-MMM-YYYY")} - {dayjs(activitySummaryReportData.endDate).format("DD-MMM-YYYY")}</Text>
            </View>
            <Text style={styles.text}>Payroll Activity Summary Report</Text>
        </View>
    )
}

export default ActivitySummaryTitle

