import React,{useEffect,useState} from 'react'
import {useHistory} from 'react-router'

function LoadingToRedirect() {
    const [count,setCount] = useState(5);
    const history = useHistory()
    useEffect(()=> {
        if(count >0) {
            const interval = setInterval(()=> {
                setCount((currentCount)=> --currentCount)
            },1000);
            count === 1 && history.push("/");
        // cleanup
        return ()=> clearInterval(interval)
        }
        // redirect to login page when count is zero
        
    },[count])
    return (
        <div className="protectRoute--counting">
            Redirecting in {count} seconds
        </div>
    )
}

export default LoadingToRedirect
