import React from 'react'
import companyImg from '../../Images/ausnep.7f1c3edc.png'
function Footer() {
    return (
        <div className="footer">
            © 2022 POWERED BY <img src={companyImg}/>
        </div>
    )
}

export default Footer
