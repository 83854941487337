import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

function TSVResetAuth({ match }) {
  const myPricateToken = match.params.token;

  var url = window.location.href;
  const userType = url.split("=")[1];
  const [resetButton, setResetButton] = useState("Reset");

  const handleReset = () => {
    setResetButton("Reseting");
    axios({
      method: "GET",
      url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/remove-second-auth`,
      headers: {
        Authorization: `Bearer ${myPricateToken}`,
      },
    })
      .then((response) => {
        toast.success("Your two step verification system has been removed.");
        setResetButton("Reseted");
        setTimeout(() => {
          window.location.replace("/");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.data.message === undefined) {
          toast.error(
            "Couldn't reset two-step-verification system. Try again later!!!"
          );
        } else {
          toast.error(error.response.data.message);
        }
        setResetButton("Reset");
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="adminlogin--container">
        <div className="circle--container">
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
        </div>
        <div
          style={{ minHeight: "min-content" }}
          className="twoStep--verification"
        >
          <div className="twoStep--verification--container">
            <p style={{ color: "red" }}>
              *By clicking Reset button your Two-Step-Verification system will
              get removed.
            </p>
            <hr />
            <div className="tsv--resetButton">
              <button onClick={handleReset}>{resetButton}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TSVResetAuth;
