import React, { useState, useEffect } from "react";
import Layout from "../../../Layout/Layout";
import { connect, useDispatch } from "react-redux";
import { IoMdInfinite } from 'react-icons/io'

import {AiFillCheckSquare} from 'react-icons/ai'
import {BiX} from 'react-icons/bi'
import {HiEmojiHappy} from 'react-icons/hi'
import { useHistory } from "react-router";

import "tippy.js/dist/tippy.css";


const mapStateToProps = (store) => {
  const { productInfoData,billingPriceData } = store.mainState;
  return { productInfoData,billingPriceData };
};

function BillingSubscription({ productInfoData,billingPriceData,isMissionSuccess }) {
  const dispatch = useDispatch();
  const history  = useHistory()
  const [selectPlan,setSelectPlan] = useState("monthly")

  const [containerClassName,setContainerClassName] = useState("leaveBalance_SM--containerXX")
  const [showGetEmployeeNumberModal,setShowGetEmployeeNumberModal] = useState(false)
  const [employeeNumber,setEmployeeNumber] = useState(null)

  useEffect(()=> {
    dispatch({
      type: "FETCH_PRODUCT_INFO_REQUEST"
    })
  },[])

  const {monthly,yearly} = productInfoData

  return (
    <Layout>
       <div>

        <div className="subscription--scheme">
        <h1>Subscription Scheme :-</h1>
        <p>Plan : <select value={selectPlan} onChange={(e)=> setSelectPlan(e.target.value)}>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select></p>
        </div>
        {selectPlan === "monthly" ?         
        <div className="subscription">
          {monthly?.productTierInformation.length >0 && monthly?.productTierInformation.map((item)=> <div className="subscription-plan">
            <div className="subscription-plan__heading">
              <p> {item.fromEmployee} - {item.uptoEmployee === 9223372036854776000? <IoMdInfinite style={{marginRight: "10px"}}/>: item.uptoEmployee} Employee</p>
            </div>
            <div className="priceing__container">
            <p className="billing--price">${item.chargePerEmployee} <span>/ Employee</span></p>
            <p className="perMonth--text">Per Month</p>
            </div>
            <div className="subscription-plan__body">
            
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon" /> Leave Management</p>
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Employee Payment Management</p>
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Payroll & STP on Web</p>
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Download Report</p>
            </div>
            <div className="subscription-plan__footer">
              <p> <span><a href="https://app.activaccount.com.au/terms-of-service" style={{textDecoration: "none"}} target="_blank" rel="noreferrer noopener">*Terms & Condition</a></span> apply</p>
            </div>
            <div className="billing-submitButton" onClick={()=> {
                setShowGetEmployeeNumberModal(true)
                setTimeout(()=> {
                  setContainerClassName("leaveBalance_SM--container")
                },2)
              }}>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              Select Plan
            </div>
            
          </div>)}
          
        </div> :         
        <div className="subscription">
          {yearly?.productTierInformation.length >0 && yearly?.productTierInformation.map((item)=> <div className="subscription-plan">
            <div className="subscription-plan__heading">
              <p> {item.fromEmployee} - {item.uptoEmployee === 9223372036854776000? <IoMdInfinite style={{marginRight: "10px"}}/>: item.uptoEmployee} Employee</p>
            </div>
            <div className="priceing__container">
            <p className="billing--price">${item.chargePerEmployee} <span>/ Employee</span></p>
            <p className="perMonth--text">Per Month</p>
            </div>
            <div className="subscription-plan__body">
            
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon" /> Leave Management</p>
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Employee Payment Management</p>
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Payroll & STP on Web</p>
              <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Download Report</p>
            </div>
            <div className="subscription-plan__footer">
              <p> <span><a href="https://app.activaccount.com.au/terms-of-service" style={{textDecoration: "none"}} target="_blank" rel="noreferrer noopener">*Terms & Condition</a></span> apply</p>
            </div>
            <div className="billing-submitButton" onClick={()=> {
                setShowGetEmployeeNumberModal(true)
                setTimeout(()=> {
                  setContainerClassName("leaveBalance_SM--container")
                },300)
              }}>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              Select Plan
            </div>
          </div>)}
          
        </div>}

        
        
      </div> 
      {showGetEmployeeNumberModal && <div className="leaveBalance_SM">
        <div className={containerClassName}>
          <div className="leavBalance_sm--heading">
            <p>ActivAccount Says <HiEmojiHappy /><HiEmojiHappy /></p>
            <p onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowGetEmployeeNumberModal(false)
              },300)
            }}><BiX /></p>
          </div>
          <div className="leavBalance_sm--body">
            <div className="leaveBalance--inputs">
              <div className="getEmployee--count">
              <p>How much employee do you have ?</p>
              <input type="number" value={employeeNumber} onChange={(e)=> setEmployeeNumber(e.target.value)} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} placeholder="e.g : 10" />
              </div>
            </div>
          </div>
          <div className="leaveBalance--buttons">
            <button onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowGetEmployeeNumberModal(false)
              },300)
              }}>Close</button>
            <button onClick={()=> {
              if(employeeNumber >0) {
                setContainerClassName("leaveBalance_SM--containerXX")
                setTimeout(()=> {
                  let employeeCount = employeeNumber
                let duration = selectPlan
                setShowGetEmployeeNumberModal(false)
                history.push(`/product-price-info/${employeeCount}/${duration}`)
                },300)
              }
            }}>Submit</button>
          </div>
        </div>
      </div>}
    </Layout>
  );
}

export default connect(mapStateToProps, null)(BillingSubscription);
