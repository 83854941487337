import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useLocation } from "react-router-dom";
import myLogo from "../../../Images/ActivAccount_logo_final-01-01.png";

const mapStateToProps = (store) => {
  const {
    buttonText,
    responseOnPostingEmail,
    responseAfterValidateOtp,
    resendButtonText,
  } = store.mainState;
  return {
    buttonText,
    responseOnPostingEmail,
    responseAfterValidateOtp,
    resendButtonText,
  };
};
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function SignUp({
  buttonText,
  responseOnPostingEmail,
  responseAfterValidateOtp,
  resendButtonText,
}) {
  let query = useQuery();
  let accountantUUID = query.get("accountantUUID");

  const dispatch = useDispatch();
  const { userType } = useParams();

  const [getEmail, setGetEmail] = useState("");
  const [getOtp, setGetOtp] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (getEmail === "") {
      toast.error("Enter your email address");
    } else if (!getEmail.match(regexEmail)) {
      toast.error("Enter valid email address");
    } else {
      dispatch({
        type: "GET_EMAIL_SIGNUP_REQUEST",
        payload: {
          userType: userType,
          value: {
            email: getEmail,
          },
        },
      });
    }
  };
  const handleValidateOtp = (e) => {
    e.preventDefault();
    if (getOtp === "") {
      toast.error("Enter your OTP.");
    } else {
      dispatch({
        type: "VALIDATE_OTP_SIGNUP_REQUEST",
        payload: {
          userType: userType,
          value: {
            adminId: responseOnPostingEmail.adminId,
            otp: getOtp,
            otpFor: "REGISTRATION_EMAIL",
          },
        },
      });
    }
  };
  const handleResendOtp = (e) => {
    e.preventDefault();
    dispatch({
      type: "RESEND_OTP_REQUEST",
      payload: {
        userType: userType,
        data: {
          email: getEmail,
          otpFor: "REGISTRATION_EMAIL",
        },
      },
    });
  };

  const validateOtp = () => {
    if (responseOnPostingEmail.adminId !== undefined) {
      return (
        <form>
          <div className="signup--getEmail--container">
            <>
              <div className="signup--heading">
                <div className="signup--heading__para">Validate OTP</div>
                <div className="signup--heading__image">
                  <img src={myLogo} alt="mylogo" />
                </div>
              </div>
              <p>
                <input
                  type="text"
                  placeholder="Enter Your OTP Pin"
                  value={getOtp}
                  onChange={(e) => setGetOtp(e.target.value)}
                />
              </p>
              <button type="submit" onClick={handleValidateOtp}>
                {buttonText}
              </button>
              <button
                type="submit"
                style={{ marginLeft: "20px" }}
                onClick={handleResendOtp}
              >
                {resendButtonText}
              </button>
            </>
          </div>
        </form>
      );
    }
  };
  useEffect(() => {
    if (responseAfterValidateOtp.token !== undefined) {
      if (accountantUUID !== null) {
        setTimeout(() => {
          window.location.replace(
            `/${userType}/add-information?accountantUUID=${accountantUUID}`
          );
        }, 1000);
      } else {
        setTimeout(() => {
          window.location.replace(`/${userType}/add-information`);
        }, 1000);
      }
    }
  }, [responseAfterValidateOtp]);
  return (
    <div className="signup--getEmail">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {responseOnPostingEmail.adminId === undefined ? (
        <form>
          <div className="signup--getEmail--container">
            <>
              <div className="signup--heading">
                <div className="signup--heading__para">Sign Up</div>
                <div className="signup--heading__image">
                  <img src={myLogo} alt="mylogo" />
                </div>
              </div>
              <p>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  value={getEmail}
                  onChange={(e) => setGetEmail(e.target.value)}
                />
              </p>
              <button type="submit" onClick={handleSubmit}>
                {buttonText}
              </button>
            </>
          </div>
        </form>
      ) : (
        validateOtp()
      )}
    </div>
  );
}

export default connect(mapStateToProps, null)(SignUp);
