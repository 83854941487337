import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        paddingRight: "20",
    },
    description: {
        width: '70%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
    },
});

const SalaryAndWageYdt =({items})=> {
    console.log("The next level of data",items)
    return (
        <View style={styles.row}>
            <Text style={styles.description}></Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.qty}>${items.ordinaryHoursAmount || 0}</Text>
        </View>
    )
}

export default SalaryAndWageYdt

