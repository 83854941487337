import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import myLogo from "../../Images/active account_white-02.png";
import { DiCelluloid } from "react-icons/di";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { BiSearch } from "react-icons/bi";
import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = (store) => {
  const { buttonText, isLoadingOnLogin, isEnabledUsers } = store.mainState;
  return { buttonText, isLoadingOnLogin, isEnabledUsers };
};
function InvitationForm({
  buttonText,
  match,
  isLoadingOnLogin,
  isEnabledUsers,
}) {
  const myPricateToken = match.params.token;

  const [searchWord, setSearchWord] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchSuperFundName, setSearchSuperFundName] = useState([]);
  const [showLoadingFlag, setShowLoadingFlag] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState(null);
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostcode] = useState("");
  const [myState, setMyState] = useState("");
  const [myButtonTxt, setMyButtonTxt] = useState("Submit");

  // Bank and Super details
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [superFundType, setSuperFundType] = useState(
    "Regulated_Superannuation_Fund"
  );
  const [paymentMethodAccountName, setPaymentMethodAccountName] =
    useState(null);
  const [paymentMethodBsb, setPaymentMethodBsb] = useState(null);
  const [paymentMethodAccNumber, setPaymentMethodAccNumber] = useState(null);

  const [superFundName, setSuperFundName] = useState(null);
  const [superFundAbn, setSuperfundAbn] = useState(null);
  const [employerNumber, setEmployeeNumber] = useState(null);
  const [eca, setEca] = useState(null);
  const [superFundAccountName, setSuperFundAccountName] = useState(null);
  const [superFundBankAccNumber, setSuperFundBankAccNumer] = useState(null);
  const [superFundBsb, setSuperFundBsb] = useState(null);
  // Tax detail section
  const [dob, setDob] = useState(null);
  const [residency, setResidency] = useState("RESIDENCE");
  const [taxFileNumber, setTaxFileNumber] = useState(null);
  const [tFexemtion, setTfnExemtion] = useState("HAS_TFN");
  const [claimTaxFreeThreshold, setTaxFreeThreshold] = useState(false);
  const [hasTsl, setHasTsl] = useState(false);
  const [hasFinancialDebt, setHasFinancialDept] = useState(false);
  const [hasSSuL, setHasSSuL] = useState(false);

  const [tFnDeclation, setTfnDeclaration] = useState(true);

  const [superFundUSI, setSuperFundUSI] = useState(null);
  const [superFundMemberNumber, setSuperFundMemberNumber] = useState(null);

  const [haveTfn, setHaveTfn] = useState(true);
  const [showInviteForm, setShowInviteForm] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      // url: `${process.env.REACT_APP_API}/payroller/v1/api/employee/employee/own-information`,
      url: "http://3.109.137.244:8080/payroller/v1/api/employee/employee/own-information",

      headers: {
        Authorization: `Bearer ${myPricateToken}`,
      },
    })
      .then((response) => {
        setShowInviteForm(true);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
      })
      .catch((error) => {
        setShowInviteForm(false);
        toast.error(
          "You can not use this link to add your Information. Please Contact Organization Admin to get New Link !!!"
        );
      });
  }, []);

  const handleSearchFundName = () => {
    setShowLoadingFlag(true);
    axios({
      method: "GET",
      url: `http://3.109.137.244:8080/payroller/v1/api/employee/find-super-name/own-info?name=${searchWord}`,
      // url: "https://cf94-2400-1a00-b050-d326-721-cf21-470d-6df.ngrok.io/payroller/v1/api/employee/employee/own-information",
      headers: {
        Authorization: `Bearer ${myPricateToken}`,
      },
    })
      .then((response) => {
        setShowSearchResult(true);
        setSearchSuperFundName(response.data);
        setShowLoadingFlag(false);
      })
      .catch((error) => {
        setShowSearchResult(false);
        toast.error("Could not find Super Fund Name!!!");
        setShowLoadingFlag(false);
      });
  };
  const dispatch = useDispatch();
  const handleSubmitButton = (e) => {
    e.preventDefault();
    if (firstName === "") {
      toast.error("Enter your first name");
    } else if (firstName.length >= 30) {
      toast.error("Your first name is too long");
    } else if (lastName === "") {
      toast.error("Enter your last name");
    } else if (lastName.length >= 30) {
      toast.error("Your last name is too long");
    } else if (addressOne === "") {
      toast.error("Enter your address one");
    } else if (addressOne.length >= 255) {
      toast.error("Your address one is too logn");
    } else if (suburb === "") {
      toast.error("Enter your suburb");
    } else if (postCode === "") {
      toast.error("Enter your postcode");
    } else if (postCode.length !== 4) {
      toast.error("Post code must be exactly 4 digit");
    } else if (contactNo === "") {
      toast.error("Enter your contact number");
    } else if (contactNo.length < 9 || contactNo.length > 10) {
      toast.error("Contact number must be 9 digit or 10 digit");
    } else if (myState === "") {
      toast.error("Select your state");
    }
    // Add Bank & Super Details :-
    else if (paymentMethod === "BANK" && paymentMethodAccountName == null) {
      toast.error("Enter your payment method bank account name");
    } else if (paymentMethod === "BANK" && paymentMethodBsb == null) {
      toast.error("Enter your payment method BSB");
    } else if (paymentMethod === "BANK" && paymentMethodBsb <= 0) {
      toast.error("Payment method BSB can not be nagative");
    } else if (paymentMethod === "BANK" && paymentMethodBsb.length !== 11) {
      toast.error("Payment method BSB must be 11 digit");
    } else if (paymentMethod === "BANK" && paymentMethodAccNumber == null) {
      toast.error("Enter your payment method account number");
    } else if (paymentMethod === "BANK" && paymentMethodAccNumber <= 0) {
      toast.error("Payment method account number can not be nagative");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundName == null
    ) {
      toast.error("Enter your super fund name");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundAbn == null
    ) {
      toast.error("Enter your super fund ABN");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundAbn.length !== 11
    ) {
      toast.error("ABN number must be 11 digit");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundAbn.length !== 11
    ) {
      toast.error("ABN number must be 11 digit");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundAccountName == null
    ) {
      toast.error("Enter your super fund bank account name");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundBsb == null
    ) {
      toast.error("Enter your super fund BSB");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundBsb.length !== 6
    ) {
      toast.error("Super fund BSB must be exactly 6 digit");
    } else if (
      superFundType === "Self_Managed_Superannuation_Fund" &&
      superFundBankAccNumber == null
    ) {
      toast.error("Enter your super fund bank account number");
    } else if (dob === null) {
      toast.error("DOB is Empty !!");
    } else if (haveTfn == true && taxFileNumber === null) {
      toast.error("Tax file number is empty");
    } else if (haveTfn == true && taxFileNumber.length >= 10) {
      toast.error("Tax file number shouldn't greater than 9");
    } else if (haveTfn == true && taxFileNumber.length <= 8) {
      toast.error(
        "Your TFN is less than 9 digit. It should be exactly 9 digit"
      );
    } else if (haveTfn !== true && tFexemtion == null) {
      toast.error("Select at least one tax file number exemption");
    } else if (haveTfn !== true && tFexemtion == null) {
      toast.error("Select at least one tax file number exemption");
    } else {
      setMyButtonTxt("Submitting");
      axios({
        method: "POST",
        // url: `${process.env.REACT_APP_API}/payroller/v1/api/employee/employee/own-information`,
        url: "http://3.109.137.244:8080/payroller/v1/api/employee/employee/own-information",
        data: {
          personal: {
            firstName: firstName,
            lastName: lastName,
            addressOne: addressOne,
            addressTwo: addressTwo,
            suburb: suburb,
            postCode: postCode,
            state: myState,
            contactNumber: contactNo,
          },
          bankAndSuper: {
            paymentMethod: paymentMethod,
            paymentMethodAccountName: paymentMethodAccountName,
            paymentMethodBsd: paymentMethodBsb,
            paymentMethodAccountNumber: paymentMethodAccNumber,
            superFundType: superFundType,
            superFundUSI: superFundUSI,
            superFundMemberNumber: superFundMemberNumber,
            superFundName: superFundName,
            superFundAbn: superFundAbn,
            electronicServiceAddress: eca,
            employerNumber: employerNumber,
            bankDetailAccountName: superFundAccountName,
            bankDetailBsd: superFundBsb,
            bankDetailAccountNumber: superFundBankAccNumber,
          },
          tax: {
            dateOfBirth: dayjs(dob).format("YYYY-MM-DD"),
            statusOfResidency: residency,
            tfnNumber: taxFileNumber,
            tfnExemption: tFexemtion,
            claimTaxFreeThreshold: claimTaxFreeThreshold,
            hasHECS: hasSSuL,
            hasTSL: hasTsl,
            lodgeTFN: "true",
            hasFinancialDebt: hasFinancialDebt,
          },
        },
        headers: {
          Authorization: `Bearer ${myPricateToken}`,
        },
      })
        .then((response) => {
          toast.success("Your information has been saved successfully.");
          setMyButtonTxt("Submit");
          setTimeout(() => {
            window.location.replace("/invitaion-success");
          }, 2000);
        })
        .catch((error) => {
          setMyButtonTxt("Submit");
          if (error.response === undefined) {
            toast.error("Internal Server Error. Try Again Later !!!");
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (buttonText === "Submitted") {
      setTimeout(() => {
        window.location.replace("/dashboard");
      }, 2000);
    }
  }, [buttonText]);

  useEffect(() => {
    setSuperFundName(null);
    setSuperfundAbn(null);
  }, [superFundType]);

  return (
    <div className="information">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="information--container">
        {showInviteForm ? (
          <form>
            <p className="main--title">
              <DiCelluloid /> Personal Details
            </p>
            <label htmlFor="" style={{ marginLeft: "20px" }}>
              Add your personal details so your employer has a way to contact
              you if needed.
            </label>
            <div className="form--container">
              <div className="organization--field">
                <div className="organization--field--name">
                  <p>First Name* : </p>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
                <div className="organization--field--para">
                  <p>Last Name*: </p>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="organization--field">
                <div className="organization--field--name">
                  <p>Address One* : </p>
                  <input
                    type="text"
                    placeholder="Address One"
                    value={addressOne}
                    onChange={(e) => setAddressOne(e.target.value)}
                  />
                </div>
                <div className="organization--field--para">
                  <p>Address Two(Opt) : </p>
                  <input
                    type="text"
                    placeholder="Address Two"
                    value={addressTwo}
                    onChange={(e) => setAddressTwo(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="organization--field--name">
                  <p>Suburb* : </p>
                  <input
                    type="text"
                    placeholder="Suburb"
                    value={suburb}
                    onChange={(e) => setSuburb(e.target.value)}
                  />
                </div>
                <div className="organization--field--para">
                  <p>Post Code* : </p>
                  <input
                    type="number"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={(e) => setPostcode(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="organization--field--name">
                  <p>Contact Number* : </p>
                  <input
                    type="number"
                    placeholder="Contact Number"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                  />
                </div>
                <div className="organization--field--para">
                  <p>State* : </p>
                  <select onChange={(e) => setMyState(e.target.value)}>
                    <option>Select</option>
                    <option value="ACT">ACT</option>
                    <option value="NSW">NSW</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                  </select>
                </div>
              </div>
              <hr />
              <p className="sub--title">Add Bank & Super Details :-</p>
              <div
                className="organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="organization--field--para">
                  <p>Payment Method* : </p>
                  <select onChange={(e) => setPaymentMethod(e.target.value)}>
                    <option value="CASH">Cash</option>
                    <option value="BANK">Bank</option>
                  </select>
                </div>
                <div className="organization--field--name"></div>
              </div>
              {paymentMethod === "BANK" && (
                <>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Account Name* : </p>
                      <input
                        type="text"
                        placeholder="Account Name"
                        value={paymentMethodAccountName}
                        onChange={(e) =>
                          setPaymentMethodAccountName(e.target.value)
                        }
                      />
                    </div>
                    <div className="organization--field--para">
                      <p>BSB* : </p>
                      <input
                        type="text"
                        placeholder="BSB"
                        value={paymentMethodBsb}
                        onChange={(e) => setPaymentMethodBsb(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Account Number* : </p>
                      <input
                        type="number"
                        placeholder="Account Number"
                        value={paymentMethodAccNumber}
                        onChange={(e) =>
                          setPaymentMethodAccNumber(e.target.value)
                        }
                      />
                    </div>
                    <div className="organization--field--para"></div>
                  </div>
                </>
              )}
              <p className="sub--title">Super Fund Details :-</p>
              <div
                className="organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="organization--field--para">
                  <p>Super Fund Type* : </p>
                  <select onChange={(e) => setSuperFundType(e.target.value)}>
                    <option value="Regulated_Superannuation_Fund">
                      Regulated Superannuation Fund
                    </option>
                    <option value="Self_Managed_Superannuation_Fund">
                      Self Managed Superannuation Fund
                    </option>
                  </select>
                </div>
                <div className="organization--field--name"></div>
              </div>
              {superFundType !== "Self_Managed_Superannuation_Fund" && (
                <form>
                  <>
                    <div className="searchCompany">
                      <p>
                        <input
                          type="text"
                          placeholder="Search Super Fund Name"
                          value={searchWord}
                          onChange={(e) => setSearchWord(e.target.value)}
                        />
                        <span className="search-icons">
                          <BiSearch />
                        </span>
                      </p>
                      <p onClick={handleSearchFundName}>Search</p>
                    </div>
                    <div className="showSearchCompany">
                      <div className="showSearchCompany--container">
                        {isLoadingOnLogin && (
                          <>
                            <div className="myLoader">
                              <div class="lds-ripple">
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="company--details">
                          {showSearchResult &&
                            searchSuperFundName.length > 0 &&
                            searchSuperFundName?.map((item) => (
                              <div
                                className="company--details--item"
                                onClick={() => {
                                  setSuperfundAbn(item.abn);
                                  setSuperFundName(item.name);
                                  setSuperFundUSI(item.usi);
                                  setShowSearchResult(false);
                                }}
                              >
                                <p>{item.name}</p>
                                <p>{item.abn}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                </form>
              )}
              {showLoadingFlag && (
                <>
                  <div className="myLoader">
                    <div class="lds-ripple">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </>
              )}
              {superFundType === "Self_Managed_Superannuation_Fund" && (
                <>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Name* : </p>
                      <input
                        type="text"
                        placeholder="Account Name"
                        value={superFundName}
                        onChange={(e) => setSuperFundName(e.target.value)}
                      />
                    </div>
                    <div className="organization--field--para">
                      <p>ABN* : </p>
                      <input
                        type="text"
                        placeholder="ABN"
                        value={superFundAbn}
                        onChange={(e) => setSuperfundAbn(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Electronic Service Address(Opt) : </p>
                      <input
                        type="text"
                        placeholder="Electronic Service Address"
                        value={eca}
                        onChange={(e) => setEca(e.target.value)}
                      />
                    </div>
                    <div className="organization--field--para">
                      <p>Employer Number (Opt) : </p>
                      <input
                        type="text"
                        placeholder="Employee Number"
                        value={employerNumber}
                        onChange={(e) => setEmployeeNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <p className="sub--title">Bank Details :-</p>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Account Name* : </p>
                      <input
                        type="text"
                        placeholder="Account Name"
                        value={superFundAccountName}
                        onChange={(e) =>
                          setSuperFundAccountName(e.target.value)
                        }
                      />
                    </div>
                    <div className="organization--field--para">
                      <p>BSB* : </p>
                      <input
                        type="text"
                        placeholder="BSB"
                        value={superFundBsb}
                        onChange={(e) => setSuperFundBsb(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Account Number* : </p>
                      <input
                        type="number"
                        placeholder="Account Number"
                        value={superFundBankAccNumber}
                        onChange={(e) =>
                          setSuperFundBankAccNumer(e.target.value)
                        }
                      />
                    </div>
                    <div className="organization--field--para"></div>
                  </div>
                </>
              )}
              {superFundType === "Regulated_Superannuation_Fund" && (
                <>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p>Super Fund Name(Opt) : </p>
                      <input
                        type="text"
                        placeholder="Super Fund Name"
                        value={superFundName}
                        onChange={(e) => setSuperFundName(e.target.value)}
                      />
                    </div>
                    <div className="organization--field--para">
                      <p>ABN* : </p>
                      <input
                        type="text"
                        placeholder="ABN"
                        value={superFundAbn}
                        onChange={(e) => setSuperfundAbn(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="organization--field"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="organization--field--name">
                      <p> Member Number(Opt): </p>
                      <input
                        type="text"
                        name="title"
                        value={superFundMemberNumber}
                        placeholder="Member Number"
                        onChange={(e) =>
                          setSuperFundMemberNumber(e.target.value)
                        }
                      />
                    </div>
                    <div className="organization--field--para">
                      <p>USI(Opt) :</p>
                      <input
                        type="text"
                        name="title"
                        value={superFundUSI}
                        placeholder="USI"
                        onChange={(e) => setSuperFundUSI(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}
              <hr />
              <p className="sub--title">Tax Details :-</p>
              <div
                className="organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="organization--field--name">
                  <p>Date Of Birth* : </p>
                  <p className="org--inputDate">
                    <DatePicker
                      selected={dob}
                      onChange={(date) => setDob(date)}
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd / mm / yyyy"
                      dateFormat="dd / MM / yyyy"
                    />
                  </p>
                </div>
                <div className="organization--field--para"></div>
              </div>
              <div
                className="organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="organization--field--name">
                  <p>Status of Residency* : </p>
                  <select onChange={(e) => setResidency(e.target.value)}>
                    <option value="RESIDENCE">Australian Resident</option>
                    <option value="FOREIGN_RESIDENCE">Foreign Resident</option>
                    <option value="WORKING_HOLIDAY_MAKERS">
                      Working Holiday Makers
                    </option>
                    <option value="CHILDREN">Children</option>
                  </select>
                </div>
                <div className="organization--field--para">
                  {haveTfn ? (
                    <>
                      <p>Tax File Number : </p>
                      <input
                        type="number"
                        placeholder="Tax File Number"
                        value={taxFileNumber}
                        onChange={(e) => {
                          setTaxFileNumber(e.target.value);
                          setTfnExemtion("HAS_TFN");
                        }}
                      />
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setHaveTfn(!haveTfn);
                          setTfnExemtion("");
                        }}
                      >
                        No Tax File Number ?{" "}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>TFN Exemption : </p>
                      <select
                        onChange={(e) => {
                          setTaxFileNumber(null);
                          setTfnExemtion(e.target.value);
                        }}
                      >
                        <option>Select</option>
                        <option value="NO_TFN_QUOTED">No TFN Quoted</option>
                        <option value="TFN_PENDING">TFN Pending</option>
                        <option value="UNDER_18">Under 18</option>
                        <option value="PENSIONER">Pensioner</option>
                      </select>
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => setHaveTfn(!haveTfn)}
                      >
                        Yes Tax File Number ?{" "}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {/* <p className="superFund--subForm">Additional Information : </p> */}
              <p className="sub--title">Additional Information :-</p>
              <div
                className="Modal--form--checkers"
                style={{ marginLeft: "50px" }}
              >
                <p>
                  <input
                    type="checkbox"
                    style={{ width: "20px" }}
                    value={claimTaxFreeThreshold}
                    defaultChecked={claimTaxFreeThreshold}
                    onChange={(e) => setTaxFreeThreshold(e.target.checked)}
                  />
                  <label htmlFor="title">Claim tax free threshold :</label>
                </p>
                <p>
                  <input
                    type="checkbox"
                    style={{ width: "20px" }}
                    value={hasTsl}
                    defaultChecked={hasTsl}
                    onChange={(e) => setHasTsl(e.target.checked)}
                  />
                  <label htmlFor="title">Has HELP or TSL debt :</label>
                </p>
                <p>
                  <input
                    type="checkbox"
                    style={{ width: "20px" }}
                    value={hasSSuL}
                    defaultChecked={hasSSuL}
                    onChange={(e) => setHasSSuL(e.target.checked)}
                  />
                  <label htmlFor="title">Has Student Start-up Loan :</label>
                </p>
                <p>
                  <input
                    type="checkbox"
                    style={{ width: "20px" }}
                    value={hasFinancialDebt}
                    defaultChecked={hasFinancialDebt}
                    onChange={(e) => setHasFinancialDept(e.target.checked)}
                  />
                  <label htmlFor="title">Has Financial Supplement debt :</label>
                </p>
              </div>
              <p className="sub--title">Tax File Number Declaration :-</p>
              <div className="InviteEmployee--form--checkers">
                <p>
                  <input
                    type="checkbox"
                    value={tFnDeclation}
                    defaultChecked={tFnDeclation}
                    onChange={(e) => setTfnDeclaration(e.target.checked)}
                  />
                </p>
                <p htmlFor="title">
                  Tick this box if you would like to lodge TFN Declaration. By
                  ticking this, you also declare that the information you have
                  given is true and correct.
                </p>
              </div>
            </div>
            <div className="submit--button">
              <button type="submit" onClick={handleSubmitButton}>
                {myButtonTxt}
              </button>
            </div>
          </form>
        ) : (
          <div style={{ padding: "50px" }}>
            <p style={{ color: "red", textAlign: "center" }}>
              You can not use this link to add your Information. Please Contact
              Organization Admin to get New Link.
            </p>
            <p style={{ fontSize: "24px", textAlign: "center" }}>
              ActivAccount Team !!!
            </p>
          </div>
        )}
      </div>
      <div className="information--logo">
        <p>Manage your employee payment in easy and fast way</p>
        <p>
          <img src={myLogo} alt="Logo" />
        </p>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(InvitationForm);
