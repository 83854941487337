import { mergeMap,catchError,tap, flatMap, switchMap } from "rxjs/operators";
import {ofType} from 'redux-observable'
import {API} from '../BaseUrlProvider';
import { from, of } from "rxjs";


// get email for signup
export const fetchClientEpic = action$ => action$.pipe(
    ofType("FETCH_CLIENT_REQUEST"),
    mergeMap(action=> 
        from(API.get("link/get-linked")).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "FETCH_CLIENT_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "FETCH_CLIENT_FAILURE",
            payload: error.response}))
        ) 
    )
)
// invite organizaiton
export const inviteOrganizationEpic = action$ => action$.pipe(
    ofType("INVITE_ORGANIZATION_REQUEST"),
    mergeMap(action=> 
        from(API.post('/link/accountant-add',action.payload)).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "INVITE_ORGANIZATION_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "INVITE_ORGANIZATION_FAILURE",
            payload: error.response}))
        ) 
    )
)
// invite accountants
export const inviteAccountantEpic = action$ => action$.pipe(
    ofType("INVITE_CLIENTS_REQUEST"),
    mergeMap(action=> 
        from(API.post('accountant/invite',action.payload)).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "INVITE_CLIENTS_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "INVITE_CLIENTS_FAILURE",
            payload: error.response}))
        ) 
    )
)
// fetch accountatns
export const fetchAccountantListEpic = action$ => action$.pipe(
    ofType("FETCH_ACCOUNTANT_LIST_REQUEST"),
    mergeMap(action=> 
        from(API.get('accountant-link')).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "FETCH_ACCOUNTANT_LIST_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "FETCH_ACCOUNTANT_LIST_FAILURE",
            payload: error.response}))
        ) 
    )
)
// delete accountants
export const deleteAccountantEpic = action$ => action$.pipe(
    ofType("DELETE_ACCOUNTANT_REQUEST"),
    mergeMap(action=> 
        from(API.get(`accountant/invitation-delete?${action.payload.type}=${action.payload.id}`)).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "DELETE_ACCOUNTANT_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "DELETE_ACCOUNTANT_FAILURE",
            payload: error.response}))
        ) 
    )
)
// post invite accountant data
export const postInviteAccountantEpic = action$ => action$.pipe(
    ofType("POST_INVITE_ACCOUNTANT_REQUEST"),
    mergeMap(action=> 
        from(API.post(`link/accountant-accept`,action.payload)).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "POST_INVITE_ACCOUNTANT_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "POST_INVITE_ACCOUNTANT_FAILURE",
            payload: error.response}))
        ) 
    )
)
// fetch accountant personal setting
export const fetchAccPersonalDataEpic = action$ => action$.pipe(
    ofType("FETCH_ACC_PERSONALDATA_REQUEST"),
    mergeMap(action=> 
        from(API.get(`accountant/information`,action.payload)).pipe(
            // tap(response=> console.log("The response after posting Reports",response)),
            mergeMap((response)=> of({type: "FETCH_ACC_PERSONALDATA_SUCCESS",
            payload: response.data})),
            catchError(error=> of({type: "FETCH_ACC_PERSONALDATA_FAILURE",
            payload: error.response}))
        ) 
    )
)