import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import myLogo from "../../../Images/ActivAccount_logo_final-01-01.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { getCookie } from "../../../FrontendHelper";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";

const mapStateToProps = (store) => {
  const { isEnabledUsers } = store.mainState;
  return { isEnabledUsers };
};

function NewPassword({ isEnabledUsers }) {
  const dispatch = useDispatch();
  const myToken = getCookie("myToken");
  const { userType } = useParams();
  const [eyeStateOne, setEyeStateOne] = useState(true);
  const [eyeStateTwo, setEyeStateTwo] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [buttonText, setButtonText] = useState("Submit");

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Submitting");
    axios({
      method: "POST",
      url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/forget-password`,
      // url: "http://13.127.139.254:8080/payroller/v1/api/admin/forget-password",
      data: {
        newPassword: newPassword,
        conformPassword: confirmPassword,
      },
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    })
      .then((response) => {
        toast.success("Your Password Changed Successfully.");
        setButtonText("Submit");
        setTimeout(() => {
          window.location.replace("/");
        }, 2000);
      })
      .catch((error) => {
        console.log("The  errror", error);
        toast.error(error.response.data.message);
        setButtonText("Submit");
      });
  };
  return (
    <div className="signup--getEmail">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form>
        <div className="signup--getEmail--container">
          <>
            <div className="signup--heading">
              <div className="signup--heading__para">Forgot Password</div>
              <div className="signup--heading__image">
                <img src={myLogo} alt="mylogo" />
              </div>
            </div>
            <div className="newPassword">
              <div className="newPassword--container">
                <p>New Password : </p>
                <p>
                  <input
                    type={`${eyeStateOne ? "password" : "text"}`}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter New Password"
                  />
                  <span className="eye-icon">
                    {eyeStateOne ? (
                      <AiOutlineEyeInvisible
                        onClick={() => setEyeStateOne(!eyeStateOne)}
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() => setEyeStateOne(!eyeStateOne)}
                      />
                    )}
                  </span>
                </p>
                {/* <p><input type="password" placeholder="Enter New Password" value={newPassword} onChange={(e)=> setNewPassword(e.target.value)}  /></p> */}
              </div>
              <div className="newPassword--container">
                <p>Confirm Password : </p>
                <p>
                  <input
                    type={`${eyeStateTwo ? "password" : "text"}`}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span className="eye-icon">
                    {eyeStateTwo ? (
                      <AiOutlineEyeInvisible
                        onClick={() => setEyeStateTwo(!eyeStateTwo)}
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() => setEyeStateTwo(!eyeStateTwo)}
                      />
                    )}
                  </span>
                </p>
                {/* <p><input type="text"  /></p> */}
              </div>
            </div>
            <button
              style={{ marginTop: "30px" }}
              type="submit"
              onClick={handleSubmit}
            >
              {buttonText}
            </button>
          </>
        </div>
      </form>
    </div>
  );
}

export default connect(mapStateToProps, null)(NewPassword);
