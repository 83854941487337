import React from "react";
import { Document, Image, Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  boxStyle: {
    backgroundColor: "#606683ad",
    padding: "10px",
    width: "160px",
    height: "80px",
  },
  mytext: {
    color: "white",
    padding: "5px 0",
    fontSize: "9px",
  },
  logo: {
    flexDirection: "row",
    justifyContent: "flex-end",
    height: "50px",
    width: "60px",
    objectFit: "cover",
  },
});

function OrganizationDetails({
  companyName,
  abn,
  emState,
  empostCode,
  address,
}) {
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.boxStyle}>
          <Text style={styles.mytext}>{companyName}</Text>
          <Text style={styles.mytext}>ABN : {abn}</Text>
          <Text style={styles.mytext}>{address.one}</Text>
          {address.two !== null && (
            <Text style={styles.mytext}>{address.two}</Text>
          )}
          <Text style={styles.mytext}>
            {emState} {empostCode}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default OrganizationDetails;
