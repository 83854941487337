import { View,Text,StyleSheet } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    container: {
        padding: "40px",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        fontStyle: 'bold',
    },
    text: {
        fontSize: "16"
    }
})

function LeaveBalanceReportTitle({leaveBalanceReportData}) {
    return (
        <View style={styles.container}>
            <View>
            <Text style={styles.text}>{leaveBalanceReportData?.companyLegalName}</Text>
            <Text style={styles.text}>Effective from - {leaveBalanceReportData?.effectiveDate}</Text>
            </View>
            <Text style={styles.text}>Leave Balance</Text>
        </View>
    )
}

export default LeaveBalanceReportTitle

