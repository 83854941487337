import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#90e5fc";
// const styles = StyleSheet.create({
//   heading: {
//     fontSize: "10px",
//     fontStyle: "bold",
//     paddingLeft: 8,
//     textAlign: "left",
//     // backgroundColor: "yellow",
//     marginTop: "20px",
//     // justifyContent: "flex-start"
//   },
//   row: {
//     flexDirection: "row",
//     borderBottomColor: "#bff0fd",
//     borderBottomWidth: 1,
//     alignItems: "center",
//     height: 24,
//     fontSize: "9px",
//   },
//   description: {
//     width: "40%",
//     textAlign: "left",
//     paddingLeft: 8,
//     fontSize: "9px",
//   },
//   type: {
//     width: "30%",
//     textAlign: "center",
//     paddingLeft: 8,
//     fontSize: "9px",
//   },
//   rate: {
//     width: "15%",
//     textAlign: "center",
//     paddingRight: 8,
//     fontSize: "9px",
//   },
//   amount: {
//     width: "15%",
//     textAlign: "center",
//     paddingRight: 8,
//     fontSize: "9px",
//   },
// });

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  heading: {
    fontSize: "10px",
    fontStyle: "bold",
    paddingLeft: 8,
    textAlign: "left",
    // backgroundColor: "yellow",
    // marginTop: "10px",
    // justifyContent: "flex-start"
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: "9px",
    // marginTop:"10px"
  },
  description: {
    width: "50%",
    textAlign: "left",

    paddingLeft: 8,
    fontSize: "9px",
  },
  qty: {
    width: "15%",

    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
  amount: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const WorkerCompensationRow = ({ items, heading, desc }) => {
  const compensationType = items.workerCompensationType.replace(/_/g, "  ");
  const compType= compensationType.slice(0,1) + compensationType.slice(1).toLowerCase()
  return (
    <>
      {/* <View style={styles.row}>
        <Text style={styles.description}>Worker Compensation</Text>
        <Text style={styles.type}>{compensationType}</Text>
        <Text style={styles.rate}>${items.workerCompensation}</Text>
        <Text style={styles.amount}>${items.workerCompensationYTD}</Text>
      </View> */}

      <View>
        <Text style={styles.heading}>{heading}</Text>
      </View>
      <View style={styles.row} key={items.abnNumber}>
        {/* <Text style={styles.description}>{desc}-{items.workerCompensationType}</Text> */}
        <Text style={styles.description}>{compType}</Text>
        <Text style={styles.qty}></Text>
        <Text style={styles.qty}></Text>
        <Text style={styles.rate}>${items.workerCompensation}</Text>
        <Text style={styles.amount}>
          ${items.workerCompensationYTD}
        </Text>
      </View>
    </>
  );
};

export default WorkerCompensationRow;
