import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { isAuth, isAuth2 } from '../../FrontendHelper'

const mapStateToProps = store => {
    const { buttonText, organizationSettingData, isEnabledUsers } = store.mainState
    return { buttonText, organizationSettingData, isEnabledUsers }
}

function AccPersonalSetting({ buttonText, organizationSettingData, isEnabledUsers }) {

    const {accPersonalData} = useSelector(state=> state.accountantState)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [contactNumber, setContactNumber] = useState("")
    const [abnNumber, setAbnNumber] = useState("")
    const [taxAgentNumberId, setTaxAgentNumberId] = useState("")

    const dispatch = useDispatch();

    const handleSubmitButton = (e) => {
        e.preventDefault()
        if(firstName === "") {
            toast.error("Enter first name")
        }
        else if(lastName === ""){
            toast.error("Enter last name")
        }
        else if(contactNumber === ""){
            toast.error("Enter contact number")
        }
        else if(contactNumber.length >10 ){
            toast.error("Enter contact number 9 or 10 digit")
        }
        else if(contactNumber.length <9 ){
            toast.error("Enter contact number 9 or 10 digit")
        }
        else if(abnNumber.length <11 ){
            toast.error("ABN number is not valid. Enter valid ABN")
        }
        else if(abnNumber.length >11 ){
            toast.error("ABN number is not valid. Enter valid ABN")
        }
        else if(taxAgentNumberId === "" ){
            toast.error("taxAgentNumberId number is not valid. Enter valid taxAgentNumberId")
        }
        else {
            dispatch({
                type: "POST_PERSONAL_SETTING_REQUEST",
                payload: {
                    "firstName":firstName,
                    "lastName": lastName,
                    "abnNumber":abnNumber,
                    "taxAgentNumberId":taxAgentNumberId,
                    "contactNumber":contactNumber
                }
                
                
                
            })
        }


    }

    useEffect(() => {
        setFirstName(accPersonalData?.firstName)
        setLastName(accPersonalData?.lastName)
        setContactNumber(accPersonalData?.contactNumber)
        setAbnNumber(accPersonalData?.abnNumber)
        setTaxAgentNumberId(accPersonalData?.taxAgentNumberId)
    }, [accPersonalData])

    useEffect(()=> {
        dispatch({
            type: "FETCH_ACC_PERSONALDATA_REQUEST"
        })

    },[])

    return (
        <div>
            <div className="Modal--heading">
                <p style={{ color: "black" }}>Personal Setting</p>
            </div>
            <form>
                <div className="personal">
                    <div className="personal--container">
                        <div className="personal--container--firstName">
                            <p>First Name :-</p>
                            <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>Last Name :-</p>
                            <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
    
                        <div className="personal--container--firstName">
                            <p>Contact Number :-</p>
                            <input type="text" placeholder="Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>ABN Number :-</p>
                            <input type="text" placeholder="ABN Number" value={abnNumber} onChange={(e) => setAbnNumber(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>Tax Agent Number Id :-</p>
                            <input type="text" placeholder="Tax Agent Number Id" value={taxAgentNumberId} onChange={(e) => setTaxAgentNumberId(e.target.value)} />
                        </div>
                        <div className="modal--container__group--buttons">
                            <button type="submit" onClick={handleSubmitButton} style={{ marginTop: "20px" }}>
                                {buttonText}
                            </button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default connect(mapStateToProps, null)(AccPersonalSetting)
