import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    heading: {
        fontSize: "10px",
        fontStyle: 'bold',
        paddingLeft: 8,
        textAlign: 'left',
        // backgroundColor: "yellow",
        marginTop: "10px"
        // justifyContent: "flex-start"
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3e445ead',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: "9px"
        
    },
    description: {
        width: '50%',
        textAlign: 'left',

        paddingLeft: 8,
        fontSize: "9px"
    },
    qty: {
        width: '15%',

        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
        fontSize: "9px"
    },
    amount: {
        width: '20%',
        textAlign: 'center',
        paddingRight: 8,
        fontSize: "9px"
    },
});

const AllowanceRow = ({ items,heading,desc }) => {

    return (
        <>
            <View>
                <Text style={styles.heading}>{heading}</Text>
            </View>
            {items.allowances !== undefined && items.allowances.map((item)=> <View style={styles.row} key={items.abnNumber}>
                <Text style={styles.description}>{item.allowanceOption} - {item.allowanceType}</Text>
                <Text style={styles.qty}>{item.unit}</Text>
                <Text style={styles.qty}>{item.costPerUnit}</Text>
                <Text style={styles.rate}>${item.allowanceSubTotal}</Text>
                <Text style={styles.amount}>${item.allowanceSubTotal}</Text>
            </View>)}
        </>
    )
}



export default AllowanceRow
