import React, { useState, useEffect, useRef, useCallback } from "react";
import Layout from "../../Layout/Layout";
import Footer from "../Z_Footer/Footer";
import { connect, useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus, AiFillEdit } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { BsX } from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";
import AddPayroller from "./AddPayroller";
import AdjustPayrun from "./AdjustPayrun";
import ShowReviewPayroll from "./ShowReviewPayroll";
import dayjs from "dayjs";
import businessLogo from "../../Images/63964-distance-business-rocket-sitting-vector-education-man.png";
import ShowPayrollDetails from "./ShowPayrollDetails";
import useFetchDashboard from "./useFetchDashboard";
import { getOrganizationId } from "../../FrontendHelper";
import ViewAndAdjustModal from "./ViewAndAdjustModal";
import { ImCross } from "react-icons/im";
import { MdClose } from "react-icons/md";
import { BsArrowReturnLeft } from "react-icons/bs";
import { updateAdjustEmployeeFinaliseStp } from "../../Redux/myEpics";

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    isAddRunPayRoll,
    isToogleSidebar,
    payRunResponse,
    adjustedEmployeeData,
    payRollStatus,
    isEnabledUsers,
    isAdjustPayrunModal,
    buttonText,
    isReviewPayroll,
    //added finalise stp state
    employeeFinaliseStp,
    updatedEmployeeFinaliseStp,
    isLoadingFinaliseStp,
    isFinaliseSTpPayload,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    isAddRunPayRoll,
    isToogleSidebar,
    payRunResponse,
    payRollStatus,
    adjustedEmployeeData,
    isEnabledUsers,
    isAdjustPayrunModal,
    buttonText,
    isReviewPayroll,
    //added finalise stp state
    employeeFinaliseStp,
    updatedEmployeeFinaliseStp,
    isLoadingFinaliseStp,
    isFinaliseSTpPayload,
  };
};

function Dashboard({
  isLoadingOnLogin,
  adjustedEmployeeData,
  isAddRunPayRoll,
  isToogleSidebar,
  payRunResponse,
  payRollStatus,
  isEnabledUsers,
  isAdjustPayrunModal,
  buttonText,
  isReviewPayroll,
  //added finalise stp state
  employeeFinaliseStp,
  updatedEmployeeFinaliseStp,
  isLoadingFinaliseStp,
  isFinaliseSTpPayload,
}) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const [noOfEmployee, setNoOfEmployee] = useState([]);
  const [employeeItem, setEmployeeItem] = useState(null);
  const [reviewShowingPayrollId, setReviewShoingPayrollId] = useState(null);
  const [payStatus, setPayStatus] = useState("");

  // added new features finalise STP
  const [newPayRollStatus, setNewPayRollStatus] = useState(null);
  const [viewAndAdjustModal, setViewAndAdjustModal] = useState(null);
  const date = new Date();
  const year = date.getFullYear();

  const [stpYear, setStpYear] = useState([year - 1, year]);

  useEffect(() => {
    newPayRollStatus == null &&
      window.scrollTo({
        top: 0,
      });
  }, [newPayRollStatus]);

  //function to fetch the finalise stp employee data
  const handleSubmitFinaliseStp = (e) => {
    e.preventDefault();
    const startYear = stpYear[0];
    const endYear = stpYear[1];

    if (stpYear.length > 0) {
      dispatch({
        type: "FETCH_EMPLOYEE_FINALISE_STP_REQUEST",
        payload: { startYear, endYear },
      });
    }
    setNewPayRollStatus(() => "finaliseSTP");
  };
  const [finaliseStpEmployee, setFinaliseStpEmployee] = useState();

  const handleStpYear = (e) => {
    setStpYear([e.target.value - 1, parseInt(e.target.value)]);
    const startYear = e.target.value - 1;
    const endYear = e.target.value;
    if (stpYear.length > 0) {
      dispatch({
        type: "FETCH_EMPLOYEE_FINALISE_STP_REQUEST",
        payload: { startYear, endYear },
      });
    }
  };

  const handleSubmitUpdatedFinaliseStp = () => {
    console.log(
      "you clicked the submit button of finalise stp",
      updatedEmployeeFinaliseStp
    );
    dispatch({
      type: "UPDATE_ADJUST_EMPLOYEE_FINALISESTP_REQUEST",
      payload: updatedEmployeeFinaliseStp,
    });
  };

  useEffect(() => {
    if (employeeFinaliseStp) {
      setFinaliseStpEmployee(employeeFinaliseStp);
    }
  }, [employeeFinaliseStp]);

  const [employeeIndex, setEmployeeIndex] = useState();
  const handleOpenViewAndAdjust = (index) => {
    setEmployeeIndex(index);
    setViewAndAdjustModal(true);
  };

  const [payrollId, setPayrollId] = useState(null);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [mypayPeriod, setMyPayPeriod] = useState("");

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsShoingItem, setDetailsShoingItem] = useState(null);

  const { loading, payroller, hasMore } = useFetchDashboard(currentPage);
  // const { loading, payroller, hasMore } = useFetchDashboard(currentPage,refetch);

  const observer = useRef();
  const organizationId = getOrganizationId();

  const lastPayroller = useCallback((node) => {
    if (isLoadingOnLogin) return;
    if (observer.current) observer.current.disconnect();
    if (hasMore) {
      observer.current = new IntersectionObserver((entries) => {
        // console.log("checking the value of entries", entries[0].isIntersecting);
        if (entries[0].isIntersecting) {
          setCurrentPage(currentPage + 1);
        }
      });
    }
    if (node) observer.current.observe(node);
  });

  useEffect(() => {
    if (isEnabledUsers === "runPayroll") {
      setTimeout(() => {
        dispatch({
          type: "OPEN_ADD_PAY_ROLL_MODAL",
        });
      }, [1000]);
    }
  }, [isEnabledUsers]);

  useEffect(() => {
    if (isEnabledUsers === "deletedPayroll") {
      window.location.reload();
      setTimeout(() => {
        dispatch({
          type: "OPEN_REVIEW_PAYROLL_MODAL",
        });
      }, [1000]);
    }
  }, [isEnabledUsers]);

  useEffect(() => {
    setNoOfEmployee(payRunResponse.employee);
    if (payRunResponse.payrollId !== undefined) {
      setPayrollId(payRunResponse.payrollId);
    } else {
      setPayrollId(null);
    }
    if (payRunResponse.employee !== undefined) {
      setStartingDate(payRunResponse.payPeriodStarting);
      setEndingDate(payRunResponse.payPeriodEnding);
      setPaymentDate(payRunResponse.paymentDate);
      setMyPayPeriod(payRunResponse.period);
    }
  }, [payRunResponse]);

  useEffect(() => {
    if (adjustedEmployeeData.employee !== undefined) {
      setPayrollId(adjustedEmployeeData.payrollId);
      let arr1 = [...noOfEmployee];
      let arr2 = adjustedEmployeeData.employee;
      let newNoOfEmployee = arr1.map(
        (obj) => arr2.find((o) => o.employeeId === obj.employeeId) || obj
      );
      setNoOfEmployee(newNoOfEmployee);
    }
  }, [adjustedEmployeeData]);

  const onHandleSubmit = (e) => {
    e.preventDefault();

    let myEmployeeIdArr = noOfEmployee.map((item) => item.employeeId);

    if (payrollId === null) {
      dispatch({
        type: "POST_ADJUST_PAYROLL2_REQUEST",
        payload: {
          organizationId: organizationId,
          data: {
            payPeriodStarting: startingDate,
            payPeriodEnding: endingDate,
            paymentDate: paymentDate,
            period: mypayPeriod,
            employeeId: myEmployeeIdArr,
            organizationId: organizationId,
          },
        },
      });
    } else {
      dispatch({
        type: "POST_ADJUST_PAYROLL2_REQUEST",
        payload: {
          organizationId: organizationId,
          data: {
            payrollId: payrollId,
            payPeriodStarting: startingDate,
            payPeriodEnding: endingDate,
            paymentDate: paymentDate,
            period: mypayPeriod,
            employeeId: myEmployeeIdArr,
            organizationId: organizationId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (isEnabledUsers === "firstAdjustment") {
      dispatch({ type: "ADJUST_PAYRUN_MODAL" });
    }
  }, [adjustedEmployeeData]);
  useEffect(() => {
    if (isEnabledUsers === "Completed" || isEnabledUsers === "emailSent") {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isEnabledUsers]);
  const handleSaveAsDraft = () => {
    window.location.reload();
  };

  const runPayrollDashboard = () =>
    noOfEmployee !== undefined &&
    noOfEmployee.map((item) => (
      <div key={item.employeeId}>
        <div className="employee--container d-flex gap-4 flex-row">
          <div className="employee--details d-flex flex-row justify-content-between gap-3 shadow flex-wrap">
            <div className="employee--details--heading d-flex flex-row justify-content-between align-items-center">
              {/* <p className="align-center"> */}
              <FcBusinessman className="myEmployee--icon" />
              {/* </p> */}
              <div className="employee--details--heading--para">
                <p>{item.employeeFullName}</p>
                <p>{item.jobTitle}</p>
              </div>
            </div>
            <div
              className="employee--details--footer text-center w-100"
              onClick={() => {
                setShowDetailsModal(true);
                setDetailsShoingItem(item);
              }}
            >
              View Details
            </div>
          </div>
          <div
            className="employee--container__details d-flex flex-row gap-4 pb-4 px-3"
            onClick={() => {
              setEmployeeItem(item);
              dispatch({
                type: "ADJUST_PAYRUN_MODAL",
              });
            }}
          >
            <div className="general--details p-4 shadow">
              <p className="d-flex flex-row justify-content-between">
                Work Hours : <span>{item.workHours} Hours</span>
              </p>
              <p className="d-flex flex-row justify-content-between">
                Work Hours Rate : <span>${item.payRate} </span>
              </p>
              <p className="d-flex flex-row justify-content-between">
                Super Pay : <span>${item.superPay}</span>
              </p>
            </div>
            {/* <div className="major--details"> */}
            {/* <div className="grossEarnings"> */}
            <div className="general--details p-4 shadow">
              <p className="d-flex flex-row justify-content-between">
                Tax Pay : <span>${item.taxPay}</span>
              </p>
              <p className="d-flex flex-row justify-content-between">
                Gross Pay : <span>${item.grossPay}</span>
              </p>
              <p className="d-flex flex-row justify-content-between">
                Net Pay : <span>${item.netPay}</span>
              </p>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <Layout>
      <div className="dashboard">
        <div className="dashboard--heading">
          {/* <p>Run Payroll</p> */}
          {newPayRollStatus === "finaliseSTP" ? (
            <>
              {viewAndAdjustModal ? (
                <div className="d-flex flex-row justify-content-between w-100">
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                    className="pb-4"
                  >
                    Year to Date Summary
                  </span>
                  <span>
                    <MdClose
                      className="crossButton"
                      onClick={() => setViewAndAdjustModal(false)}
                    />
                  </span>
                </div>
              ) : (
                // <p>Finalise STP</p>
                <div className="d-flex flex-row justify-content-between dashboard--heading w-100">
                  <div className="d-flex flex-row gap-4 dashboard--heading align-items-center">
                    <p>Finalise STP</p>
                    <select
                      className="fs-3"
                      style={{ cursor: "pointer" }}
                      onChange={handleStpYear}
                    >
                      <option
                        value={year}
                        selected={
                          updatedEmployeeFinaliseStp.endYear === null
                            ? true
                            : false
                        }
                      >
                        {year - 1}-{year}
                      </option>
                      <option
                        value={year - 1}
                        selected={
                          year - 1 === updatedEmployeeFinaliseStp?.endYear
                            ? true
                            : false
                        }
                      >
                        {year - 2}-{year - 1}
                      </option>
                      <option
                        value={year - 2}
                        selected={
                          year - 2 === updatedEmployeeFinaliseStp?.endYear
                            ? true
                            : false
                        }
                      >
                        {year - 3}-{year - 2}
                      </option>
                      <option
                        value={year - 3}
                        selected={
                          year - 3 === updatedEmployeeFinaliseStp?.endYear
                            ? true
                            : false
                        }
                      >
                        {year - 4}-{year - 3}
                      </option>
                    </select>
                  </div>
                  <div
                    onClick={() => {
                      dispatch({
                        type: "CLEAR_THE_FINALISESTP_EMPLOYEE_DATA",
                      });
                      setStpYear([year - 1, year]);
                      setNewPayRollStatus(null);
                    }}
                    className="p-3"
                    style={{ cursor: "pointer" }}
                  >
                    <BsArrowReturnLeft className="backButton" />
                  </div>
                </div>
              )}
            </>
          ) : (
            <p>Run Payroll</p>
          )}
          {/* changes for finalise */}
          {/* {payRollStatus === "Normal" &&  ( */}
          {payRollStatus === "Normal" && newPayRollStatus === null && (
            <>
              <button
                className="shadow"
                onClick={() => {
                  dispatch({ type: "OPEN_ADD_PAY_ROLL_MODAL" });
                }}
              >
                <AiOutlinePlus style={{ marginRight: "5px" }} /> New Pay Run
              </button>
              <span>
                <button
                  className="btn buttonFinaliseStp shadow"
                  onClick={handleSubmitFinaliseStp}
                >
                  Finalise STP
                </button>
              </span>
            </>
          )}
        </div>
        {/* changes for finalise */}
        {/* {payRollStatus === "Normal" ? ( */}
        {payRollStatus === "Normal" && newPayRollStatus == null ? (
          <div className="dashboard--body" style={{ minHeight: "300px" }}>
            {payroller.length > 0 ? (
              <div className="dashboard__table-container">
                <table>
                  <thead>
                    <tr>
                      <th className="runPayRoll--status">Status</th>
                      <th className="runPayRoll--period">Pay Period</th>
                      <th className="runPayRoll--gross">Gross</th>
                      <th className="runPayRoll--tax">Tax</th>
                      <th className="runPayRoll--super">Super</th>
                      <th className="runPayRoll--netPay">Net Pay</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payroller !== undefined &&
                      payroller.map((item, index) => {
                        if (payroller.length === index + 1) {
                          return (
                            <tr
                              key={index}
                              ref={lastPayroller}
                              onClick={() => {
                                if (item.payrollStatus === "DRAFT") {
                                  let id = item.payrollId;
                                  dispatch({
                                    type: "EDIT_PAYROLL_REQUEST",
                                    payload: { id },
                                  });
                                } else {
                                  setReviewShoingPayrollId(item.payrollId);
                                  setPayStatus(item.payrollStatus);

                                  dispatch({
                                    type: "OPEN_REVIEW_PAYROLL_MODAL",
                                  });
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <label
                                  style={{
                                    height: "7px",
                                    width: "7px",
                                    borderRadius: "50%",
                                    backgroundColor: "red",
                                    fontSize: "0px",
                                    marginBottom: "2px",
                                    marginRight: "10px",
                                  }}
                                ></label>
                                <span style={{ fontWeight: "500" }}>
                                  {item.payrollStatus === "INFOMATION_ADDED"
                                    ? "INFO_ADDED"
                                    : item.payrollStatus}
                                </span>
                              </td>
                              <td>
                                {dayjs(item?.startDate).format("DD MMM YYYY")} -{" "}
                                {dayjs(item?.endDate).format("DD MMM YYYY")}{" "}
                                <span
                                  style={{ color: "peru", fontWeight: "300" }}
                                >
                                  {item.payPeriod}
                                </span>
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.grossPay}
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.taxPay}
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.superPay}
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.netPay}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr
                              onClick={() => {
                                if (item.payrollStatus === "DRAFT") {
                                  let id = item.payrollId;
                                  dispatch({
                                    type: "EDIT_PAYROLL_REQUEST",
                                    payload: { id },
                                  });
                                } else {
                                  setReviewShoingPayrollId(item.payrollId);
                                  setPayStatus(item.payrollStatus);
                                  dispatch({
                                    type: "OPEN_REVIEW_PAYROLL_MODAL",
                                  });
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <label
                                  style={{
                                    height: "7px",
                                    width: "7px",
                                    borderRadius: "50%",
                                    backgroundColor: "red",
                                    fontSize: "0px",
                                    marginBottom: "2px",
                                    marginRight: "10px",
                                  }}
                                ></label>
                                <span style={{ fontWeight: "500" }}>
                                  {item.payrollStatus === "INFOMATION_ADDED"
                                    ? "INFO_ADDED"
                                    : item.payrollStatus}
                                </span>
                              </td>
                              <td>
                                {dayjs(item?.startDate).format("DD MMM YYYY")} -{" "}
                                {dayjs(item?.endDate).format("DD MMM YYYY")}{" "}
                                <span
                                  style={{ color: "peru", fontWeight: "300" }}
                                >
                                  {item.payPeriod}
                                </span>
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.grossPay}
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.taxPay}
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.superPay}
                              </td>
                              <td
                                style={{
                                  color: "rgb(125, 156, 125);",
                                  fontWeight: "500",
                                }}
                              >
                                ${item.netPay}
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
                {loading && (
                  <div className="dashboard--loading">
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>6</p>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="empty--employee">
                  <div className="empty--employee_container">
                    <div
                      className="businessLogo--container"
                      style={{ marginTop: "40px" }}
                    >
                      <img src={businessLogo} />
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {" "}
                      Welcome to ActivAccount
                    </div>
                    <div> Add your all employees before New Pay Run</div>
                    <div> So you can pay them with ActivAccount</div>
                    <Link to="employee">
                      <button>Click Here to Add Employee</button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : // plotting the idea of the finalise STP
        newPayRollStatus === "finaliseSTP" ? (
          <>
            {viewAndAdjustModal ? (
              <div>
                <ViewAndAdjustModal
                  setViewAndAdjustModal={setViewAndAdjustModal}
                  employeeIndex={employeeIndex}
                  stpYear={stpYear}
                />
                {/* {employeeIndex &&  <ViewAndAdjustModal employeeIndex={employeeIndex} stpYear={stpYear} />} */}
              </div>
            ) : (
              <div height="50vh">
                <p
                  style={{ backgroundColor: "#FFFBAC", margin: "3rem 0" }}
                  className="p-4"
                >
                  You need to make a finalization declaration by 14 July each
                  year. If you have 19 or fewer employees, you have until 31
                  July {stpYear[1]} to make the finalisation declaration. This
                  is explained in detail in{" "}
                  <a href="/dashboard" style={{ textDecorationLine: "none" }}>
                    Finalising your Single Touch Payroll data
                  </a>
                </p>

                <div
                  className="dashboard__table-container"
                  style={{ marginTop: "-2rem", minHeight: "50vh" }}
                >
                  {!isLoadingFinaliseStp &&
                    employeeFinaliseStp.finalizedSTPDataEntityList == 0 && (
                      <p className="text-danger fw-1">
                        Finalise_STP Employee list is Empty
                      </p>
                    )}
                  <table className="col-12">
                    <thead>
                      <tr className="d-flex flex-row-start">
                        {isFinaliseSTpPayload && <th>Employee</th>}
                      </tr>
                    </thead>
                    <tbody className="gap-1">
                      {employeeFinaliseStp.finalizedSTPDataEntityList ? (
                        employeeFinaliseStp.finalizedSTPDataEntityList.map(
                          (employee, index) => {
                            return (
                              <tr key={index} className="col-12 text-start">
                                <td
                                  className="text-start border-end fs-3 px-3"
                                  style={{
                                    color: "rgb(125, 156, 125);",
                                    fontWeight: "500",
                                  }}
                                >
                                  {employee.employeeDetailResponse.personal
                                    .firstName +
                                    " " +
                                    employee.employeeDetailResponse.personal
                                      .lastName}
                                </td>
                                <td
                                  className="col-2"
                                  style={{
                                    color: "rgb(125, 156, 125);",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    alignItems: "center",
                                    color: "blue",
                                  }}
                                  onClick={() => handleOpenViewAndAdjust(index)}
                                >
                                  View & adjust
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <>
                          <div className="finaliseStp--data--loading">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>6</p>
                          </div>
                          <div className="finaliseStp--data--loading">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>6</p>
                          </div>
                          <div className="finaliseStp--data--loading">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>6</p>
                          </div>
                          <div className="finaliseStp--data--loading">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>6</p>
                          </div>
                          <div className="finaliseStp--data--loading">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>6</p>
                          </div>
                          <div className="finaliseStp--data--loading">
                            <p>1</p>
                            <p>2</p>
                            <p>3</p>
                            <p>4</p>
                            <p>5</p>
                            <p>6</p>
                            <p>6</p>
                          </div>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                {isFinaliseSTpPayload && (
                  <div className="d-flex flex-row-reverse mt-5 mb-5 me-4">
                    <div className="dropdown mx-4 ">
                      <button
                        type="button"
                        aria-expanded="false"
                        className="btn btn-primary btn-lg fs-4"
                        data-bs-target="#exampleModalToggle"
                        data-bs-toggle="modal"
                      >
                        Finalise STP
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModalToggle"
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabindex="-1"
                      >
                        <div
                          class="modal-dialog modal-dialog-centered"
                          style={{ width: "30rem" }}
                        >
                          <div class="modal-content">
                            <div class="modal-body d-flex flex-column">
                              <p className="text-center">
                                <strong>Are you sure wants to proceed?</strong>
                              </p>

                              <div className="d-flex flex-row justify-content-between p-4">
                                <button
                                  className="btn btn-lg btn-danger px-4"
                                  data-bs-dismiss="modal"
                                >
                                  No
                                </button>
                                <button
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    handleSubmitUpdatedFinaliseStp();
                                    setTimeout(() => {
                                      setNewPayRollStatus(null);
                                    }, 500);
                                  }}
                                  className="btn btn-lg btn-success px-4"
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        {
                          dispatch({
                            type: "SAVE_EMPLOYEE_FINALISE_STP_REQUEST",
                            payload: updatedEmployeeFinaliseStp,
                          });
                        }
                      }}
                      type="button"
                      className="btn btn-warning btn-lg "
                    >
                      <strong className="fs-4"> Save </strong>
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="adjust--payroll">
              <div className="adjust--payroll--heading">
                <div className="adjust--payroll--heading__button">
                  <p className="adjust">Adjust</p>
                  <div className="underLine"></div>
                </div>
                <div className="adjust--payroll--heading__dates">
                  <p>
                    Payment Date :{" "}
                    {dayjs(payRunResponse?.paymentDate).format("DD MMM YYYY")}
                  </p>
                  <p>
                    {dayjs(payRunResponse?.payPeriodStarting).format(
                      "DD MMM YYYY"
                    )}{" "}
                    to{" "}
                    {dayjs(payRunResponse?.payPeriodEnding).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
              <hr />
              {runPayrollDashboard()}

              <div>
                <div className="modal--container__group--buttons">
                  <button
                    type="submit"
                    style={{ marginBottom: "50px" }}
                    onClick={onHandleSubmit}
                  >
                    {buttonText}
                  </button>
                  {payrollId !== null && payStatus === "" && (
                    <Link to="/dashboard">
                      <button
                        type="submit"
                        style={{ marginBottom: "50px" }}
                        onClick={handleSaveAsDraft}
                      >
                        Save As Draft
                      </button>
                    </Link>
                  )}
                  {payrollId !== null && payStatus === "" && (
                    <Link to="/dashboard">
                      <button
                        type="submit"
                        style={{ marginBottom: "50px" }}
                        onClick={() => {
                          let id = payrollId;
                          dispatch({
                            type: "DELETE_PAYROLL_REQUEST",
                            payload: { id },
                          });
                        }}
                      >
                        Delete
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
      {/* add run pay roll */}
      <div
        className={`${isAddRunPayRoll ? "mymodal modal_activated" : "mymodal"}`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddPayroller />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_ADD_PAY_ROLL_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* review payroller modal */}
      <div
        className={`${isReviewPayroll ? "mymodal modal_activated" : "mymodal"}`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          {reviewShowingPayrollId !== null && (
            <ShowReviewPayroll
              myStatus={payStatus}
              id={reviewShowingPayrollId}
            />
          )}
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_REVIEW_PAYROLL_MODAL" });
                  window.location.reload();
                }}
              >
                <BsX />
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      {/* adjust payrun */}
      <div
        className={`${
          isAdjustPayrunModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          {/* {console.log(
              "from dashboard checking the employee details",
              employeeItem
            )} */}
          {employeeItem !== null && (
            <AdjustPayrun
              item={employeeItem}
              payrollId={payrollId}
              myPayRunResponse={payRunResponse}
            />
          )}
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADJUST_PAYRUN_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Edit</p>
            </div>
          </div>
        </div>
      </div>
      {/* Show payroll details */}
      <div
        className={`${
          showDetailsModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          {detailsShoingItem !== null && (
            <ShowPayrollDetails item={detailsShoingItem} />
          )}
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span onClick={() => setShowDetailsModal(!showDetailsModal)}>
                <BsX />
              </span>
              <p>Edit</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Dashboard);
