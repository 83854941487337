import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {useSelector} from 'react-redux'
import EmployeeSummaryHeader from './EmployeeSummaryHeader';
import EmployeeSummaryRowData from './EmployeeSummaryRowData';

const tableRowsCount = 1;

const styles = StyleSheet.create({
    tableMargin: {
        marginTop: "30px"
    }
})

function EmployeeSummaryTable({employeeSummaryReportData}) {


    return (
        <View style={styles.tableMargin}>
            <EmployeeSummaryHeader />
            <EmployeeSummaryRowData employeeSummaryReportData={employeeSummaryReportData} />
        </View>
    )
}

export default EmployeeSummaryTable

