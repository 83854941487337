import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { authenticateAdminFromSkip, getCookie } from "../../FrontendHelper";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

function TwoStepVerification() {
  const tsvtoken = getCookie("tsvtoken");
  const dispatch = useDispatch();
  const { userType } = useParams();
  const [myVerificationCode, setMyVerificationCode] = useState(null);
  const { loginResponse } = useSelector((state) => state.mainState);

  const handleVerify = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/add-second-step-login`,
      data: {
        code: myVerificationCode,
      },
      headers: {
        Authorization: `Bearer ${tsvtoken}`,
      },
    })
      .then((response) => {
        toast.success("Two step verification successfully completed.");
        authenticateAdminFromSkip(response);
        window.location.replace("/dashboard");
      })
      .catch((error) => {
        if (error !== undefined) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something wrong happened. Try Again Later !!!");
        }
      });
  };
  const handleSkipButton = () => {
    authenticateAdminFromSkip(loginResponse);
    setTimeout(() => {
      window.location.replace("/dashboard");
    }, 100);
  };

  const handleForgotTSV = (e) => {
    e.preventDefault();
    axios({
      method: "GET",
      url: `http://3.109.137.244:8080/payroller/v1/api/${userType}/forgot-second-auth`,
      headers: {
        Authorization: `Bearer ${tsvtoken}`,
      },
    })
      .then((response) => {
        toast.success(
          "Email has been sent for two step verification reset functionality."
        );
        // window.location.replace('/dashboard');
      })
      .catch((error) => {
        if (error !== undefined) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something wrong happened. Try Again Later !!!");
        }
      });
    // dispatch({
    //   type: "FORGOT_AUTHENTICATION_TSV_REQUEST",
    //   payload: userType
    // })
  };

  return (
    <div>
      <ToastContainer />
      <div className="adminlogin--container">
        <div className="circle--container">
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">6</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">4</div>
          <div className="circle">5</div>
          <div className="circle">6</div>
          <div className="circle">7</div>
          <div className="circle">8</div>
          <div className="circle">9</div>
          <div className="circle">1</div>
          <div className="circle">2</div>
          <div className="circle">3</div>
        </div>
        <div className="mainPage--container">
          {loginResponse.data ? (
            <form>
              <div className="twoStep--verification">
                <div
                  className={
                    loginResponse?.data?.qrString !== null
                      ? "login__twoStep--verification--container"
                      : "login__twoStep--verification--container-sm"
                  }
                >
                  <div className="login__twostep--verification--heading">
                    <p>Two Step Verification</p>
                    <hr />
                  </div>
                  {loginResponse?.data?.qrString !== null ? (
                    <>
                      <div className="2step--verification--body">
                        <p>
                          Two-Factor Authentication provides an extra level of
                          security to your Payroller login.
                        </p>
                        <p>
                          1. Download Google Authenticator from{" "}
                          <a
                            style={{ textDecoration: "none" }}
                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                          >
                            App Store
                          </a>{" "}
                          or{" "}
                          <a
                            style={{ textDecoration: "none" }}
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ne&gl=US"
                          >
                            Google Play
                          </a>
                        </p>
                        <p>
                          2. Scan the QR Code, or enter{" "}
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            {loginResponse?.data?.authenticaitonCode}
                          </span>{" "}
                          into your Google Authenticator app
                        </p>
                      </div>
                      <div className="qr--container">
                        <QRCode
                          style={{ transform: "scale(0.5)" }}
                          value={loginResponse?.data?.qrString}
                        />
                      </div>
                      <p>
                        After scanning the QR Code, you will receive 6 digit
                        code. You have to enter that code below.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Provide your authentication code in the field below to
                        complete sign in.
                      </p>
                    </>
                  )}
                  <div className="verification--input">
                    <p>
                      <input
                        type="text"
                        placeholder="Verification Code"
                        value={myVerificationCode}
                        onChange={(e) => setMyVerificationCode(e.target.value)}
                      />
                    </p>
                  </div>
                  <p>
                    <button
                      className="vefify--code--button"
                      type="submit"
                      onClick={handleVerify}
                    >
                      Verify
                    </button>
                  </p>
                  {loginResponse?.data?.qrString !== null ? (
                    <div className="verify-via-email">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(43, 43, 133)",
                          cursor: "pointer",
                        }}
                        onClick={handleSkipButton}
                      >
                        Skip Now
                      </p>
                    </div>
                  ) : (
                    <div className="verify-via-email">
                      <Link
                        to={`/${userType}/verify-via-email/`}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(43, 43, 133)",
                          }}
                        >
                          Verify Via Email
                        </p>
                      </Link>
                    </div>
                  )}
                  {loginResponse?.data?.qrString === null && (
                    <div className="verify-via-email">
                      <p
                        style={{
                          marginTop: "-20px",
                          color: "rgb(43, 43, 133)",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={handleForgotTSV}
                      >
                        Lost your authenticator?
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TwoStepVerification;
