import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    heading: {
        fontSize: "10px",
        fontStyle: 'bold',
        paddingLeft: 8,
        textAlign: 'left',
        marginTop: "10px"
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: "9px"
        
    },
    container: {
        paddingLeft: "20",
        paddingRight: "20",
        paddingLeft: "20"
    },
    description: {
        width: '70%',
        textAlign: 'left',

        paddingLeft: 8,
        fontSize: "9px"
    },
    qty: {
        width: '15%',

        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
        fontSize: "9px"
    },
    amount: {
        width: '20%',
        textAlign: 'center',
        paddingRight: 8,
        fontSize: "9px"
    },
});

const ActivitySummarySickLeave = ({ items,heading,desc }) => {
    return (
        <>
        <View style={styles.container}>
        <View >
                <Text style={styles.heading}>{heading}</Text>
            </View>
            <View style={styles.row} key={items.abnNumber}>
                <Text style={styles.description}>{desc}</Text>
                <Text style={styles.qty}>{items.sickLeaveHours ||0}</Text>
                <Text style={styles.qty}>${items.sickLeaveHoursAmount||0}</Text>
            </View>
        </View>
        </>
    )
}

export default ActivitySummarySickLeave

