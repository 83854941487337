import React,{useEffect,useState} from 'react'
// import axios from 'axios'
import {useDispatch,useSelector} from "react-redux"
import { getCookie, getOrganizationId } from '../../FrontendHelper'

export default function useFetchDashboard(currentPage) {
    // export default function useFetchDashboard(currentPage,refetch) {
    let postsPerPage = 5
    const dispatch = useDispatch()
    // dashboardData
    const myData = useSelector((state) => state.mainState.dashboardData)

    const loading = useSelector((state)=> state.mainState.isLoadingOnLogin)
    const [payroller,setPayrollers] = useState([])
    const [hasMore,setHasMore] = useState(true)
    const organizationId = getOrganizationId()
    const ss = getCookie("token")
    // useEffect(()=> {
    //     if(!ss) {
    //         window.location.reload()
    //     }
    // },[])

    useEffect(()=> {
        if(hasMore) {
            
            dispatch({
                type: "FETCH_DASHBOARD_DATA_REQUEST",
                payload: { currentPage, postsPerPage,organizationId },
              });

            }
        },[currentPage])
    // },[currentPage,refetch])
    useEffect(()=> {
        if(myData.data !== undefined) {
            setPayrollers(prevPayroller=> {
                return [...new Set([...prevPayroller,...myData.data])]
                // return [...prevPayroller,...myData.data]
            })
            setHasMore(myData.data.length > 0 ? true:false)
        }
    },[currentPage,myData])
    return {loading,payroller,hasMore}
}
