import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { connect, useDispatch } from "react-redux";
import Footer from "../Z_Footer/Footer";
import dayjs from "dayjs";
import MyChart from "./MyChart";
import { BiX } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import EmployeeContactsReport from "./EmployeeContactsReports/EmployeeContactsReport";
import { BsX } from "react-icons/bs";
import LeaveBalanceReport from "./LeaveBalanceReport/LeaveBalanceReport";
import DatePicker from "react-datepicker";
import ActivitySummaryReport from "./ActivitySummary/ActivitySummaryReport";
import EmployeeSummaryReport from "./EmployeeSummaryReport/EmployeeSummaryReport";
import { getOrganizationId } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    reportData,
    isEmployeeContactsReportModal,
    isToogleSidebar,
    isLeaveBalanceReportModal,
    employeeDataList,
    isActivePayrollReportModal,
    isEmployeeSummaryReportModal,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    reportData,
    isEmployeeContactsReportModal,
    isToogleSidebar,
    isLeaveBalanceReportModal,
    employeeDataList,
    isActivePayrollReportModal,
    isEmployeeSummaryReportModal,
  };
};

function Reports({
  isLoadingOnLogin,
  reportData,
  isEmployeeContactsReportModal,
  isToogleSidebar,
  isLeaveBalanceReportModal,
  employeeDataList,
  isActivePayrollReportModal,
  isEmployeeSummaryReportModal,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [containerClassName, setContainerClassName] = useState(
    "leaveBalance_SM--containerXX"
  );
  // leave balance report
  const [showLeaveBalanceSM, setShowLeaveBalanceSM] = useState(false);
  const [lBEffecdtiveDate, setLBEffectiveDate] = useState(new Date());
  const [employeeId, setEmployeeid] = useState("");
  const [leaveType, setLeaveType] = useState("SICK_LEAVE");
  // Payroll activity report
  const [showPayrollActivitySM, setShowPayrollActivitySM] = useState(false);
  const [aSEmployeeId, setASEmployeeId] = useState("");
  const [aSPeriod, setASPeriod] = useState("THIS_MONTH");
  const [aSStartDate, SetASStartDate] = useState("");
  const [aSEndDate, SetASEndDate] = useState("");
  // Employee Summary Report
  const [showEmployeeSummarySM, setShowEmployeeSummarySM] = useState(false);
  const [eSEmployeeId, setESEmployeeId] = useState("");
  const [eSPeriod, setESPeriod] = useState("THIS_MONTH");
  const [eSStartDate, setESStartDate] = useState("");
  const [eSEndDate, setESEndDate] = useState("");

  const dispatch = useDispatch();
  const organizationId = getOrganizationId();

  useEffect(() => {
    dispatch({
      type: "FETCH_REPORT_DATA_REQUEST",
      payload: {
        currentPage,
        postsPerPage,
        organizationId,
      },
    });
  }, []);

  useEffect(() => {
    let currentPage = 1;
    let postsPerPage = 30;
    dispatch({
      type: "FETCH_EMPLOYEE_DATA_REQUEST",
      payload: { currentPage, postsPerPage },
    });
  }, []);

  return (
    <Layout>
      <div className="report">
        <div className="report--reports">
          <h2>
            Reports{" "}
            <span>
              ({reportData.startYear} - {reportData.endYear})
            </span>
          </h2>
          <hr />
          <div className="report--reports__summary">
            <div>
              <p>Gross Earning</p>
              <p>${reportData.grossEarning}</p>
            </div>
            <div>
              <p>Tax</p>
              <p>${reportData.tax}</p>
            </div>
            <div>
              <p>Super</p>
              <p>${reportData.superfund}</p>
            </div>
            <div>
              <p>NetPay</p>
              <p>${reportData.netPay}</p>
            </div>
          </div>
          <div className="report--reports__monthlyReview">
            <div className="monthly--review">
              <h2 style={{ fontSize: "20px" }}>Monthly Overview</h2>
              <hr />
              <div className="monthly--review--table">
                <p>
                  Tip : the payment date determines which month a pay run is
                  recorded in.
                </p>
                <div
                  className="dashboard__table-container"
                  style={{ marginTop: "1ß0px" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th className="runPayRoll--status">Date</th>
                        <th className="runPayRoll--gross">Gross</th>
                        <th className="runPayRoll--tax">Tax</th>
                        <th className="runPayRoll--super">Super</th>
                        <th className="runPayRoll--netPay">Net Pay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.payroll?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{dayjs(item.paymentDate).format("MMM-YYYY")}</td>
                          <td>${item.grossPay || 2323}</td>
                          <td>${item.taxPay}</td>
                          <td>${item.superPay}</td>
                          <td>${item.netPay}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="monthly--graphPortion">
              <h2 style={{ fontSize: "20px" }}>Graph Portion</h2>
              <hr />
              <div className="monthly--graphPortion--graph">
                <MyChart
                  netPay={reportData.netPay}
                  taxPay={reportData.tax}
                  superPay={reportData.superfund}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="report--documents">
          <h2>Documents</h2>
          <hr />
          <div className="report--documents--para">
            <div>
              <p>Employee Contacts</p>
              <p
                className="my-report__icon"
                onClick={() =>
                  dispatch({ type: "OPEN_EMPLOYEE_CONTACTS_REPORT_MODAL" })
                }
              >
                <HiOutlineDocumentReport />
              </p>
            </div>
            <div>
              <p>Leave Balances</p>
              <p
                className="my-report__icon"
                onClick={() => {
                  setShowLeaveBalanceSM(true);
                  setTimeout(() => {
                    setContainerClassName("leaveBalance_SM--container");
                  }, 2);
                }}
              >
                <HiOutlineDocumentReport />
              </p>
            </div>
            <div>
              <p>Payroll Activity Summary</p>
              <p
                className="my-report__icon"
                onClick={() => {
                  setShowPayrollActivitySM(true);
                  setTimeout(() => {
                    setContainerClassName("leaveBalance_SM--container");
                  }, 2);
                }}
              >
                <HiOutlineDocumentReport />
              </p>
            </div>
            <div>
              <p>Payroll Employee Summary</p>
              <p
                className="my-report__icon"
                onClick={() => {
                  setShowEmployeeSummarySM(true);
                  setTimeout(() => {
                    setContainerClassName("leaveBalance_SM--container");
                  }, 2);
                }}
              >
                <HiOutlineDocumentReport />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* show Employee Contact Report */}
      <div
        className={`${
          isEmployeeContactsReportModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <EmployeeContactsReport />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_EMPLOYEE_CONTACTS_REPORT_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      {/* show Leave Balance Report */}
      <div
        className={`${
          isLeaveBalanceReportModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <LeaveBalanceReport />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_LEAVE_BALANCE_REPORT_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      {/* show Activity Payroll summary Report */}
      <div
        className={`${
          isActivePayrollReportModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <ActivitySummaryReport />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_ACTIVITY_SUMMARY_REPORT_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      {/* show Payroll Employee summary Report */}
      <div
        className={`${
          isEmployeeSummaryReportModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <EmployeeSummaryReport />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_EMPLOYEE_SUMMARY_REPORT_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      {showLeaveBalanceSM && (
        <div className="leaveBalance_SM">
          <div className={containerClassName}>
            <div className="leavBalance_sm--heading">
              <p>Leave Balance Summary</p>
              <p
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowLeaveBalanceSM(false);
                  }, 300);
                }}
              >
                <BiX />
              </p>
            </div>
            <div className="leavBalance_sm--body">
              <div className="leaveBalance--inputs">
                <p>Select Employee</p>
                <select
                  name=""
                  id=""
                  value={employeeId}
                  onChange={(e) => setEmployeeid(e.target.value)}
                >
                  <option value="">All</option>
                  {employeeDataList.data?.map((item, index) => (
                    <option key={index} value={item.employeeId}>
                      {item.personal.firstName} {item.personal.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="leaveBalance--customInput--title">
                <p>Effective Date :</p>
                <p>
                  <DatePicker
                    selected={lBEffecdtiveDate}
                    onChange={(date) => setLBEffectiveDate(date)}
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd / mm / yyyy"
                    dateFormat="dd / MM / yyyy"
                  />
                </p>
              </div>
              <div className="leaveBalance--inputs">
                <p>Leave Type</p>
                <select
                  name=""
                  id=""
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                >
                  <option value="SICK_LEAVE">Sick Leave</option>
                  <option value="ANNUAL_LEAVE">Annual Leave</option>
                </select>
              </div>
            </div>
            <div className="leaveBalance--buttons">
              <button
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowLeaveBalanceSM(false);
                  }, 300);
                }}
              >
                Close
              </button>
              <button
                onClick={() => {
                  let efectiveDate =
                    dayjs(lBEffecdtiveDate).format("YYYY-MM-DD");
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowLeaveBalanceSM(false);
                    dispatch({ type: "OPEN_LEAVE_BALANCE_REPORT_MODAL" });
                  }, 300);
                  dispatch({
                    type: "FETCH_LEAVE_BALANCE_REPORT_REQUEST",
                    payload: {
                      efectiveDate,
                      leaveType,
                      employeeId,
                      organizationId,
                    },
                  });
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
      )}
      {showPayrollActivitySM && (
        <div className="leaveBalance_SM">
          <div className={containerClassName}>
            <div className="leavBalance_sm--heading">
              <p>Payroll Activity Summary</p>
              <p
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowPayrollActivitySM(false);
                  }, 300);
                }}
              >
                <BiX />
              </p>
            </div>
            <div className="leavBalance_sm--body">
              <div className="leaveBalance--inputs">
                <p>Select Employee</p>
                <select
                  name=""
                  id=""
                  value={employeeId}
                  onChange={(e) => setEmployeeid(e.target.value)}
                >
                  <option value="All">All</option>
                  {employeeDataList.data?.map((item, index) => (
                    <option key={index} value={item.employeeId}>
                      {item.personal.firstName} {item.personal.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="leaveBalance--inputs">
                <p>Period</p>
                <select
                  value={aSPeriod}
                  onChange={(e) => setASPeriod(e.target.value)}
                >
                  <option value="THIS_MONTH">This Month</option>
                  <option value="LAST_MONTH">Last Month</option>
                  <option value="THIS_QUATER">This Quarter</option>
                  <option value="LAST_QUATER">Last Quarter</option>
                  <option value="THIS_FINANCIAL_YEAR">
                    This Financial Year
                  </option>
                  <option value="LAST_FINANCIAL_YEAR">
                    Last Financial Year
                  </option>
                  <option value="CUSTOM">Custom</option>
                </select>
              </div>
              {aSPeriod === "CUSTOM" && (
                <>
                  <div className="leaveBalance--customInput--title">
                    <p>Start Date :</p>
                    <p>
                      <DatePicker
                        selected={aSStartDate}
                        onChange={(date) => SetASStartDate(date)}
                        disabledKeyboardNavigation
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd / mm / yyyy"
                        dateFormat="dd / MM / yyyy"
                      />
                    </p>
                  </div>
                  <div className="leaveBalance--customInput--title">
                    <p>End Date :</p>
                    <p>
                      <DatePicker
                        selected={aSEndDate}
                        onChange={(date) => SetASEndDate(date)}
                        disabledKeyboardNavigation
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd / mm / yyyy"
                        dateFormat="dd / MM / yyyy"
                      />
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="leaveBalance--buttons">
              <button
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowPayrollActivitySM(false);
                  }, 300);
                }}
              >
                Close
              </button>
              <button
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowPayrollActivitySM(false);
                    dispatch({
                      type: "OPEN_ACTIVITY_SUMMARY_REPORT_MODAL",
                    });
                  }, 300);

                  let esStartDateX =
                    aSStartDate !== ""
                      ? dayjs(aSStartDate).format("YYYY-MM-DD")
                      : "";
                  let eSEndDateX =
                    aSEndDate !== ""
                      ? dayjs(aSEndDate).format("YYYY-MM-DD")
                      : "";

                  dispatch({
                    type: "FETCH_ACTIVITY_SUMMARY_REQUEST",
                    payload: {
                      aSPeriod,
                      esStartDateX,
                      eSEndDateX,
                      aSEmployeeId,
                      organizationId,
                    },
                  });
                  dispatch({
                    type: "FETCH_ORGANIZATION_REQUEST",
                  });
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
      )}
      {showEmployeeSummarySM && (
        <div className="leaveBalance_SM">
          <div className={containerClassName}>
            <div className="leavBalance_sm--heading">
              <p>Payroll Employee Summary</p>
              <p
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowEmployeeSummarySM(false);
                  }, 300);
                }}
              >
                <BiX />
              </p>
            </div>
            <div className="leavBalance_sm--body">
              <div className="leaveBalance--inputs">
                <p>Select Employee</p>
                <select
                  name=""
                  id=""
                  value={eSEmployeeId}
                  onChange={(e) => setESEmployeeId(e.target.value)}
                >
                  <option value="">All</option>
                  {employeeDataList.data?.map((item, index) => (
                    <option key={index} value={item.employeeId}>
                      {item.personal.firstName} {item.personal.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="leaveBalance--inputs">
                <p>Period</p>
                <select
                  value={eSPeriod}
                  onChange={(e) => setESPeriod(e.target.value)}
                >
                  <option value="THIS_MONTH">This Month</option>
                  <option value="LAST_MONTH">Last Month</option>
                  <option value="THIS_QUATER">This Quarter</option>
                  <option value="LAST_QUATER">Last Quarter</option>
                  <option value="THIS_FINANCIAL_YEAR">
                    This Financial Year
                  </option>
                  <option value="LAST_FINANCIAL_YEAR">
                    Last Financial Year
                  </option>
                  <option value="CUSTOM">Custom</option>
                </select>
              </div>
            </div>
            {eSPeriod === "CUSTOM" && (
              <>
                <div className="leaveBalance--customInput--title">
                  <p>Start Date :</p>
                  <p>
                    <DatePicker
                      selected={eSStartDate}
                      onChange={(date) => setESStartDate(date)}
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd / mm / yyyy"
                      dateFormat="dd / MM / yyyy"
                    />
                  </p>
                </div>
                <div className="leaveBalance--customInput--title">
                  <p>End Date :</p>
                  <p>
                    <DatePicker
                      selected={eSEndDate}
                      onChange={(date) => setESEndDate(date)}
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd / mm / yyyy"
                      dateFormat="dd / MM / yyyy"
                    />
                  </p>
                </div>
              </>
            )}
            <div className="leaveBalance--buttons">
              <button
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowEmployeeSummarySM(false);
                  }, 300);
                }}
              >
                Close
              </button>
              <button
                onClick={() => {
                  setContainerClassName("leaveBalance_SM--containerXX");
                  setTimeout(() => {
                    setShowEmployeeSummarySM(false);
                    dispatch({
                      type: "OPEN_EMPLOYEE_SUMMARY_REPORT_MODAL",
                    });
                  }, 300);

                  let esStartDateX =
                    eSStartDate !== ""
                      ? dayjs(eSStartDate).format("YYYY-MM-DD")
                      : "";
                  let eSEndDateX =
                    eSEndDate !== ""
                      ? dayjs(eSEndDate).format("YYYY-MM-DD")
                      : "";
                  dispatch({
                    type: "FETCH_EMPLOYEE_SUMMARY_REPORT_REQUEST",
                    payload: {
                      eSPeriod,
                      esStartDateX,
                      eSEndDateX,
                      eSEmployeeId,
                      organizationId,
                    },
                  });
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Reports);
