import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { BiSearch } from "react-icons/bi";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getOrganizationId } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers, searchSuperFundName, isLoadingOnLogin } =
    store.mainState;
  return { buttonText, isEnabledUsers, searchSuperFundName, isLoadingOnLogin };
};

function AddEmployee({
  buttonText,
  isEnabledUsers,
  searchSuperFundName,
  isLoadingOnLogin,
}) {
  const [searchWord, setSearchWord] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [suburb, setSuburb] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [myState, setMyState] = useState(null);
  // Bank & Super Details :
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [paymentMethodAccountName, setPaymentMethodAccountName] =
    useState(null);
  const [paymentMethodBsb, setPaymentMethodBsb] = useState(null);
  const [paymentMethodAccNumber, setPaymentMethodAccNumber] = useState(null);
  const [contributionRate, setContributionRate] = useState(10.5);
  const [superFundAbn, setSuperFundAbn] = useState(null);
  const [
    bonusSalarySacrificeToSuperPercentage,
    setBonusSalarySacrificeToSuperPercentage,
  ] = useState(0);
  // const [regulatedAbn, setRegulatedAbn] = useState(null)

  const [superFundType, setSuperFundType] = useState(
    "REGULATED_SUPERANNUATION_FUND"
  );
  const [superFundName, setSuperFundName] = useState(null);
  const [bankDetailAccountName, setBankDetailsAccName] = useState(null);
  const [employerNumber, setEmployeeNumber] = useState(null);
  const [eca, setEca] = useState(null);
  const [bankAccNumber, setBankAccNumber] = useState(null);
  const [bankDetailBsb, setBankDetailBsb] = useState(null);
  // const [companyName, setCompanyName] = useState(null)

  // const [mySuperFundName,setMySuperFundName] = useState(null)
  const [superFundUSI, setSuperFundUSI] = useState(null);
  const [superFundMemberNumber, setSuperFundMemberNumber] = useState(null);

  // Tax detail section
  const [dob, setDob] = useState(null);
  const [residency, setResidency] = useState("RESIDENCE");
  const [taxFileNumber, setTaxFileNumber] = useState(null);
  const [tFexemtion, setTfnExemtion] = useState(null);
  const [claimTaxFreeThreshold, setClaimTaxFreeThreshold] = useState(false);
  const [hasTsl, setHasTsl] = useState(false);
  const [hasFinancialDebt, setHasFinancialDept] = useState(false);
  const [hasHECS, setHasHECS] = useState(false);
  const [lodgeTFN, setLodeTFN] = useState(false);
  const [hasClaimedMedicareLevy, setHasClaimedMedicareLevy] = useState(false);
  const [hasSpouse, setHasSpouse] = useState(false);
  const [hasCombinedWeeklyIncome, setHasCombinedWeeklyIncome] = useState(false);
  const [numberOfDependants, setNumberOfDependants] = useState(0);

  // Employment Details
  const [jobTittle, setJobTitle] = useState("");
  const [employeeStartDate, setEmployeeStartDate] = useState(null);
  const [employeType, setEmployeeType] = useState("FULL_TIME");
  const [weekyWHorus, setWeeklyWHours] = useState(null);
  const [leaveLoading, setLeaveLoading] = useState(0);
  const [payRate, setPayRate] = useState(null);
  const [ratePer, setRatePer] = useState("PER_HOUR");
  const [payPeriod, setPayPeriod] = useState("MONTHLY");

  const [haveTfn, setHaveTfn] = useState(true);

  const dispatch = useDispatch();
  const organizationId = getOrganizationId();

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (firstName.length <= 1) {
      toast.error("Enter your first name.");
    } else if (lastName.length <= 1) {
      toast.error("Enter your last name.");
    } else if (gender === "") {
      toast.error("Select your gender.");
    } else if (email === null) {
      toast.error("Enter valid email address");
    } else if (!email.match(regexEmail)) {
      toast.error("Your email must contain special charecters");
    } else if (contactNumber === null) {
      toast.error("Enter Valid Contact Number");
    } else if (contactNumber.length <= 8) {
      toast.error("Contact number should be 9 or 10");
    } else if (contactNumber.length >= 11) {
      toast.error("Contact number shouldn't greater than 10");
    } else if (address1 === null) {
      toast.error("Enter Valid Address");
    } else if (suburb === null) {
      toast.error("Enter Valid Suburb");
    } else if (postCode === null) {
      toast.error("Enter Valid Postcode");
    } else if (postCode.length < 4) {
      toast.error("Postcode should be exactly 4 digit");
    } else if (postCode.length > 4) {
      toast.error("Postcode should be exactly 4 digit");
    } else if (myState === null) {
      toast.error("Select your state");
    }
    // Bank and super
    else if (
      superFundType === "REGULATED_SUPERANNUATION_FUND" &&
      superFundName !== null &&
      superFundAbn === null
    ) {
      toast.error("Enter valid ABN number of valid Super Fund");
    } else if (paymentMethod === "BANK" && paymentMethodAccountName === null) {
      toast.error("Enter payment method account name");
    } else if (paymentMethod === "BANK" && paymentMethodBsb === null) {
      toast.error("Enter payment method BSB");
    } else if (paymentMethod === "BANK" && paymentMethodBsb <= 0) {
      toast.error("Payment method BSB can not be negative");
    } else if (paymentMethod === "BANK" && paymentMethodBsb <= 0) {
      toast.error("Payment method BSB can not be negative");
    } else if (paymentMethod === "BANK" && paymentMethodAccNumber === null) {
      toast.error("Enter valid payment method bank account number");
    } else if (paymentMethod === "BANK" && paymentMethodAccNumber <= 0) {
      toast.error("Payment method account number can not be negative");
    } else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      superFundName === null
    ) {
      toast.error("Enter super fund name");
    } else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      superFundAbn === null
    ) {
      toast.error("Enter valid abn number");
    } else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      superFundAbn.length !== 11
    ) {
      toast.error("ABN number should be 11 digit");
    }
    // else if(superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" && companyName===null) {
    //     toast.error("Enter company name")
    // }
    else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      bankDetailAccountName === null
    ) {
      toast.error("Enter bank account name");
    } else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      bankDetailBsb === null
    ) {
      toast.error("Enter BSB");
    } else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      bankDetailBsb.length !== 6
    ) {
      toast.error("Super fund BSB must be 6 digit");
    } else if (
      superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" &&
      bankAccNumber === null
    ) {
      toast.error("Enter bank account number");
    } else if (contributionRate <= 0) {
      toast.error("Enter valid contribution rate");
    } else if (contributionRate >= 100) {
      toast.error("Contribution rate can not greater than 100 %");
    } else if (dob === null) {
      toast.error("DOB is empty. Fill DOB First !!!");
    } else if (haveTfn == true && taxFileNumber === null) {
      toast.error("Tax file number is empty");
    } else if (haveTfn == true && taxFileNumber.length >= 10) {
      toast.error("Tax file number shouldn't greater than 9");
    } else if (haveTfn == true && taxFileNumber.length <= 8) {
      toast.error(
        "Your TFN is less than 9 digit. It should be exactly 9 digit"
      );
    } else if (haveTfn !== true && tFexemtion == null) {
      toast.error("Select at least one tax file number exemption");
    } else if (haveTfn !== true && tFexemtion === "") {
      toast.error("Select at least one tax file number exemption");
    } else if (employeeStartDate === null) {
      toast.error("Employee start date is empty");
    } else if (jobTittle === "") {
      toast.error("Enter employee job title");
    } else if (weekyWHorus === null) {
      toast.error("Enter weekly work hours");
    } else if (weekyWHorus <= 0) {
      toast.error("Weekly work hours should be greater than 1");
    } else if (leaveLoading === null) {
      toast.error("Leave Loading is Mandatory");
    } else if (leaveLoading < 0) {
      toast.error("Leave Loading should be greater than 0");
    } else if (payRate === null) {
      toast.error("Enter payRate");
    } else if (payRate <= 0) {
      toast.error("Pay rate should be greater than 0");
    } else if (
      bonusSalarySacrificeToSuperPercentage < 0 &&
      bonusSalarySacrificeToSuperPercentage > 100
    ) {
      toast.error("Bonous salary sacrificeto super must be in range [0,100]");
    } else {
      dispatch({
        type: "POST_EMPLOYEE_REQUEST",
        payload: {
          organizationId: organizationId,
          personal: {
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            email: email,
            contactNumber: contactNumber,
            addressOne: address1,
            addressTwo: address2,
            suburb: suburb,
            postCode: postCode,
            state: myState,
            position: "a",
          },
          bankAndSuper: {
            paymentMethod: paymentMethod,
            paymentMethodAccountName: paymentMethodAccountName,
            paymentMethodBsd: paymentMethodBsb,
            paymentMethodAccountNumber: paymentMethodAccNumber,
            superFundType: superFundType,
            superFundUSI: superFundUSI,
            superFundMemberNumber: superFundMemberNumber,
            contributionPercentage: Number(contributionRate),
            bonusSalarySacrificeToSuperPercentage: Number(
              bonusSalarySacrificeToSuperPercentage
            ),
            superFundName: superFundName,

            superFundAbn: superFundAbn,
            electronicServiceAddress: eca,
            employerNumber: employerNumber,
            bankDetailAccountName: bankDetailAccountName,
            bankDetailBsd: bankDetailBsb,
            bankDetailAccountNumber: bankAccNumber,
            removeMonthly: "true",
            // "companyName": companyName
          },
          employment: {
            jobTitle: jobTittle,
            startDate: dayjs(employeeStartDate).format("YYYY-MM-DD"),
            employmentType: employeType,
            weeklyHours: parseFloat(weekyWHorus),
            leaveLoading: leaveLoading,
            payPeriod: payPeriod,
            payRate: payRate,
            selectAnOption: ratePer,
          },
          tax: {
            dateOfBirth: dob !== null ? dayjs(dob).format("YYYY-MM-DD") : null,
            statusOfResidency: residency,
            tfnNumber: taxFileNumber,
            tfnExemption: tFexemtion,
            hasTSL: hasTsl,
            hasFinancialDebt: hasFinancialDebt,
            hasHECS: hasHECS,
            lodgeTFN: lodgeTFN,
            claimTaxFreeThreshold: claimTaxFreeThreshold,

            hasClaimedMedicareLevy: hasClaimedMedicareLevy,
            hasSpouse: hasSpouse ? "Yes" : "No",
            hasCombinedWeeklyIncome: hasCombinedWeeklyIncome ? "Yes" : "No",
            numberOfDependants: numberOfDependants,
          },
          openingBalance: {
            annualLeaveBalance: 0,
            sickLeaveBalance: 0,
            employerContribution: 0,
            grossEarning: 0,
            tax: 0,
            superBalance: 0,
            employerContributionRESC: 0,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (isEnabledUsers === "employeeAdded") {
      let currentPage = 1;
      let postsPerPage = 10;

      setFirstName("");
      setLastName("");
      setGender("");
      setEmail("");
      setContactNumber("");
      setAddress1("");
      setAddress2("");
      setSuburb("");
      setPostCode("");
      setMyState(null);

      setPaymentMethod("CASH");
      setPaymentMethodAccountName(null);
      setPaymentMethodBsb(null);
      setPaymentMethodAccNumber(null);
      setSuperFundType("REGULATED_SUPERANNUATION_FUND");
      setContributionRate(10);
      setSuperFundAbn(null);
      setSuperFundName(null);
      setSuperFundUSI(null);
      setSuperFundMemberNumber(null);
      setBankDetailsAccName(null);
      setEmployeeNumber(null);
      setBankAccNumber(null);
      setBankDetailBsb(null);
      // setCompanyName(null)
      setTaxFileNumber(null);

      setDob(null);
      setResidency("RESIDENCE");
      setTfnExemtion(null);
      setHaveTfn(true);

      setJobTitle("");
      setEmployeeStartDate("");
      setEmployeeType("FULL_TIME");
      setWeeklyWHours("");
      setLeaveLoading("");
      setPayRate("");
      setRatePer("PER_HOUR");
      setPayPeriod("PER_HOUR");
      dispatch({ type: "ADD_CATEGORY_MODAL" });

      dispatch({
        type: "FETCH_EMPLOYEE_DATA_REQUEST",
        payload: { currentPage, postsPerPage },
      });
    }
  }, [isEnabledUsers]);
  useEffect(() => {
    if (isEnabledUsers === "searched") {
      setShowSearchResult(true);
    }
  }, [isEnabledUsers]);
  useEffect(() => {
    setSuperFundName(null);
    setSuperFundAbn(null);
  }, [superFundType]);
  return (
    <div>
      <div className="Modal--heading">
        <p>Add Employee</p>
      </div>
      <div className="Modal--form">
        <form>
          <div className="employee--title">
            <p>Personal Details : </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">First Name*</label>
              <input
                type="text"
                name="title"
                value={firstName}
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Last Name*</label>
              <input
                type="text"
                name="title"
                value={lastName}
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Gender*</label>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Others</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Email*</label>
              <input
                type="email"
                name="title"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contact No.*</label>
              <input
                type="number"
                name="title"
                value={contactNumber}
                placeholder="Contact Number"
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Address One*</label>
              <input
                type="text"
                name="title"
                value={address1}
                placeholder="Address One"
                onChange={(e) => setAddress1(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Address Two(Opt)</label>
              <input
                type="text"
                name="title"
                value={address2}
                placeholder="Address Two"
                onChange={(e) => setAddress2(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Suburb*</label>
              <input
                type="text"
                name="title"
                value={suburb}
                placeholder="Suburb"
                onChange={(e) => setSuburb(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Postcode*</label>
              <input
                type="number"
                name="title"
                value={postCode}
                placeholder="Post Code"
                onChange={(e) => setPostCode(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">State*</label>
              <select
                value={myState}
                onChange={(e) => setMyState(e.target.value)}
              >
                <option>Select</option>
                <option value="ACT">ACT</option>
                <option value="NSW">NSW</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="TAS">TAS</option>
                <option value="VIC">VIC</option>
                <option value="WA">WA</option>
              </select>
            </p>
          </div>
          {/* <hr /> */}
          <div className="employee--title">
            <p>Bank & Super Details : </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Payment Method* : </label>
              <select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="CASH">Cash</option>
                <option value="BANK">Bank</option>
              </select>
            </p>
          </div>
          {paymentMethod === "BANK" && (
            <>
              {" "}
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Account Name* : </label>
                  <input
                    type="text"
                    name="title"
                    value={paymentMethodAccountName}
                    placeholder="Account Name"
                    onChange={(e) =>
                      setPaymentMethodAccountName(e.target.value)
                    }
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">BSB* : </label>
                  <input
                    type="number"
                    name="title"
                    value={paymentMethodBsb}
                    placeholder="BSB"
                    onChange={(e) => setPaymentMethodBsb(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Account Number* :</label>
                  <input
                    type="number"
                    name="title"
                    value={paymentMethodAccNumber}
                    placeholder="Account Number"
                    onChange={(e) => setPaymentMethodAccNumber(e.target.value)}
                  />
                </p>
              </div>
            </>
          )}
          <p className="superFund--subForm">Super Fund : </p>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Type* : </label>
              <select
                value={superFundType}
                onChange={(e) => setSuperFundType(e.target.value)}
              >
                <option value="REGULATED_SUPERANNUATION_FUND">
                  REGULATED SUPERANNUATION FUND
                </option>
                <option value="SELF_MANAGED_SUPERANNUATION_FUND">
                  SELF MANAGED SUPERANNUATION FUND
                </option>
              </select>
            </p>
          </div>
          {superFundType !== "SELF_MANAGED_SUPERANNUATION_FUND" && (
            <>
              <div className="searchSuperFund">
                <p>
                  <input
                    type="text"
                    placeholder="Search Super Fund Name"
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                  />
                  <span className="search-icons">
                    <BiSearch />
                  </span>
                </p>
                <p
                  onClick={() => {
                    // setShowSearchResult(true)
                    if (searchWord.length < 1) {
                      toast.error("Enter at least one character");
                    } else {
                      dispatch({
                        type: "SEARCH_SUPERFUND_NAME_REQUEST",
                        payload: {
                          searchWord,
                        },
                      });
                    }
                  }}
                >
                  Search
                </p>
              </div>
              <div className="showSearchCompany">
                <div className="showSearchCompany--container">
                  {isLoadingOnLogin && (
                    <>
                      <div className="myLoader">
                        <div class="lds-ripple">
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="company--details">
                    {showSearchResult &&
                      searchSuperFundName.length > 0 &&
                      searchSuperFundName?.map((item) => (
                        <div
                          className="company--details--item"
                          onClick={() => {
                            setSuperFundAbn(item.abn);
                            setSuperFundName(item.name);
                            setSuperFundUSI(item.usi);
                            setShowSearchResult(false);
                          }}
                        >
                          <p>{item.name}</p>
                          <p>{item.abn}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {superFundType === "SELF_MANAGED_SUPERANNUATION_FUND" && (
            <>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Name* :</label>
                  <input
                    type="text"
                    name="title"
                    value={superFundName}
                    placeholder="Name"
                    onChange={(e) => setSuperFundName(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">ABN* :</label>
                  <input
                    type="number"
                    name="title"
                    value={superFundAbn}
                    placeholder="ABN"
                    onChange={(e) => setSuperFundAbn(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">
                    Electronic Service Address(Opt) :
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={eca}
                    placeholder="Electronic Service Address"
                    onChange={(e) => setEca(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Employer Number(Opt) :</label>
                  <input
                    type="text"
                    name="title"
                    value={employerNumber}
                    placeholder="Employer Number"
                    onChange={(e) => setEmployeeNumber(e.target.value)}
                  />
                </p>
              </div>
              {/* <div className="Modal--form--title">
                            <p><label htmlFor="title">Company Name* :</label><input type="text" name="title" value={companyName} placeholder="Company Name" onChange={(e) => setCompanyName(e.target.value)} /></p>
                        </div> */}
              <p className="superFund--subForm">Bank details : </p>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Account Name* :</label>
                  <input
                    type="text"
                    name="title"
                    value={bankDetailAccountName}
                    placeholder="Account Name"
                    onChange={(e) => setBankDetailsAccName(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">BSB* :</label>
                  <input
                    type="number"
                    name="title"
                    value={bankDetailBsb}
                    placeholder="BSB"
                    onChange={(e) => setBankDetailBsb(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Account Number* :</label>
                  <input
                    type="number"
                    name="title"
                    value={bankAccNumber}
                    placeholder="Account Number"
                    onChange={(e) => setBankAccNumber(e.target.value)}
                  />
                </p>
              </div>
            </>
          )}
          {superFundType === "REGULATED_SUPERANNUATION_FUND" && (
            <>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Super Fund Name(Opt) :</label>
                  <input
                    disabled
                    type="text"
                    name="title"
                    value={superFundName}
                    placeholder="Super Fund Name"
                    onChange={(e) => setSuperFundName(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">ABN(Opt) :</label>
                  <input
                    type="number"
                    disabled
                    name="title"
                    value={superFundAbn}
                    placeholder="ABN"
                    onChange={(e) => setSuperFundAbn(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">USI(Opt) :</label>
                  <input
                    type="text"
                    name="title"
                    value={superFundUSI}
                    placeholder="USI"
                    onChange={(e) => setSuperFundUSI(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Member Number(Opt) :</label>
                  <input
                    type="number"
                    name="title"
                    value={superFundMemberNumber}
                    placeholder="Member Number"
                    onChange={(e) => setSuperFundMemberNumber(e.target.value)}
                  />
                </p>
              </div>
            </>
          )}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contribution Rate* :</label>
              <input
                type="text"
                name="title"
                value={contributionRate}
                placeholder="10.50"
                onChange={(e) => setContributionRate(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="bonousSacrifice">
                Bonus salary sacrifice to super
              </label>
              <input
                type="number"
                min={0}
                max={100}
                name="bonousSacrifice"
                value={bonusSalarySacrificeToSuperPercentage}
                placeholder=" Bonus salary sacrifice to super"
                onChange={(e) =>
                  setBonusSalarySacrificeToSuperPercentage(e.target.value)
                }
              />
            </p>
          </div>
          {/* <hr /> */}
          <div className="employee--title">
            <p>Tax Details : </p>
          </div>
          <div className="customInput--title">
            <p>Date Of Birth * :</p>
            <p>
              <DatePicker
                selected={dob}
                onChange={(date) => setDob(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
                maxDate={new Date()}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Status of Residency* :</label>
              <select
                value={residency}
                onChange={(e) => setResidency(e.target.value)}
              >
                <option value="RESIDENCE">Australian Resident</option>
                <option value="FOREIGN_RESIDENCE">Foreign Resident</option>
                <option value="WORKING_HOLIDAY_MAKERS">
                  Working Holiday Makers
                </option>
                <option value="CHILDREN">Children</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            {haveTfn ? (
              <>
                <p>
                  <label htmlFor="title">Tax File Number* :</label>
                  <input
                    type="number"
                    placeholder="Tax File Number"
                    value={taxFileNumber}
                    onChange={(e) => {
                      setTaxFileNumber(e.target.value);
                      setTfnExemtion("HAS_TFN");
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      marginLeft: "240px",
                      width: "200px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setHaveTfn(!haveTfn);
                      setTfnExemtion("");
                    }}
                  >
                    No Tax File Number ?{" "}
                  </p>
                </p>
              </>
            ) : (
              <>
                <p>
                  <label htmlFor="title">TFN Exemption* :</label>
                  <select
                    value={tFexemtion}
                    onChange={(e) => {
                      setTaxFileNumber(null);
                      setTfnExemtion(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="NO_TFN_QUOTED">No TFN Quoted</option>
                    <option value="TFN_PENDING">TFN Pending</option>
                    <option value="UNDER_18">Under 18</option>
                    <option value="PENSIONER">Pensioner</option>
                  </select>
                </p>
                <p
                  style={{
                    color: "red",
                    marginLeft: "240px",
                    width: "200px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setHaveTfn(!haveTfn);
                    setTaxFileNumber(null);
                  }}
                >
                  Yes Tax File Number ?{" "}
                </p>
              </>
            )}
          </div>
          <p className="superFund--subForm">Additional Information : </p>
          <div className="Modal--form--checkers">
            <p>
              <input
                type="checkbox"
                style={{ width: "20px" }}
                checked={claimTaxFreeThreshold}
                onChange={(e) => setClaimTaxFreeThreshold(e.target.checked)}
              />
              <label htmlFor="title">Claim tax free threshold</label>
            </p>
            <div className="levy--container">
              <p>
                <input
                  type="checkbox"
                  style={{ width: "20px" }}
                  checked={hasClaimedMedicareLevy}
                  onChange={(e) => setHasClaimedMedicareLevy(e.target.checked)}
                />
                <label htmlFor="title">Has Medicare Levy Reduction</label>
              </p>
              {hasClaimedMedicareLevy && (
                <div className="levy--container--body">
                  <div>
                    <span>Has Spouse</span>

                    <input
                      type="checkbox"
                      checked={hasSpouse}
                      onChange={(e) => setHasSpouse(e.target.checked)}
                    />
                  </div>
                  <div>
                    <span>Has Combine Weekly Income</span>

                    <input
                      type="checkbox"
                      checked={hasCombinedWeeklyIncome}
                      onChange={(e) =>
                        setHasCombinedWeeklyIncome(e.target.checked)
                      }
                    />
                  </div>
                  <div>
                    <span>Number of Dependants</span>
                    <input
                      type="number"
                      min={0}
                      style={{
                        width: "7rem",
                        padding: "0.2rem 0.5rem",
                        textAlign: "center",
                      }}
                      value={numberOfDependants}
                      onChange={(e) => setNumberOfDependants(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <p>
              <input
                type="checkbox"
                style={{ width: "20px" }}
                checked={hasTsl}
                onChange={(e) => setHasTsl(e.target.checked)}
              />
              <label htmlFor="title">Has HELP or TSL debt </label>
            </p>
            <p>
              <input
                type="checkbox"
                style={{ width: "20px" }}
                checked={hasHECS}
                onChange={(e) => setHasHECS(e.target.checked)}
              />
              <label htmlFor="title">Has Student Start-up Loan </label>
            </p>
            <p>
              <input
                type="checkbox"
                style={{ width: "20px" }}
                checked={hasFinancialDebt}
                onChange={(e) => setHasFinancialDept(e.target.checked)}
              />
              <label htmlFor="title">Has Financial Supplement debt </label>
            </p>
            <p>
              <input
                type="checkbox"
                style={{ width: "20px" }}
                checked={lodgeTFN}
                onChange={(e) => setLodeTFN(e.target.checked)}
              />
              <label
                htmlFor="title"
                style={{
                  width: "80%",
                  marginTop: "40px",
                  marginBottom: "10px",
                  color: "red",
                }}
              >
                Tick this box if you would like to lodge TFN Declaration in the
                next STP lodgement. By ticking this, you also declare that the
                information you have given is true and correct{" "}
              </label>
            </p>
          </div>
          <div className="employee--title" style={{ marginTop: "40px" }}>
            <p>Employment Details : </p>
          </div>
          <div className="customInput--title">
            <p>Start Date * :</p>
            <p>
              <DatePicker
                selected={employeeStartDate}
                onChange={(date) => setEmployeeStartDate(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Job Title* :</label>
              <input
                type="text"
                name="title"
                value={jobTittle}
                placeholder="Job Title"
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Employee Type* :</label>
              <select
                value={employeType}
                onChange={(e) => setEmployeeType(e.target.value)}
              >
                <option value="FULL_TIME">Full Time</option>
                <option value="PART_TIME">Part Time</option>
                <option value="CASUAL">Casual</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Weekly Work Hours* :</label>
              <input
                type="number"
                name="title"
                value={weekyWHorus}
                placeholder="Weekly Work Hours"
                onChange={(e) => setWeeklyWHours(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Leave Loading* :</label>
              <input
                type="number"
                name="title"
                value={leaveLoading}
                placeholder="Leave Loading"
                onChange={(e) => setLeaveLoading(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Pay Rate* :</label>
              <input
                type="number"
                name="title"
                value={payRate}
                placeholder="Pay Rate"
                onChange={(e) => setPayRate(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Pay Rate Per* : </label>
              <select
                value={ratePer}
                onChange={(e) => setRatePer(e.target.value)}
              >
                <option value="PER_HOUR">Hour</option>
                <option value="PER_DAY">Day</option>
                <option value="PER_ANNUM">Annum</option>
              </select>
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              onClick={handleAddCategorySubmit}
              style={{ marginBottom: "50px" }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddEmployee);
