import axios from "axios";
import { getCookie } from "./FrontendHelper";

const tsvtoken = getCookie("tsvtoken");

export const APITSV = axios.create({
  // baseURL: "https://api.activaccount.com.au:8443/payroller/v1/api",
  baseURL: "http://3.109.137.244:8080/payroller/v1/api",
  // baseURL: "http://localhost:9195/v1/api",
  headers: {
    Authorization: `Bearer ${tsvtoken}`,
  },
});
