import React,{useState,useEffect} from 'react'
import Layout from '../../../Layout/Layout'
import {AiFillCloseCircle} from 'react-icons/ai'

import { useHistory } from 'react-router'

const SubscriptionErrorMessage = () => {
  const history = useHistory()
  const [containerClassName,setContainerClassName] = useState("leaveBalance_SM--containerXX")
  const [showSuccessPopUp,setShowSuccessPopUp] = useState(false)
  useEffect(()=> {
    setTimeout(()=> {
      setShowSuccessPopUp(true)
      setShowSuccessPopUp(true)
        setTimeout(()=> {
          setContainerClassName("leaveBalance_SM--container")
        },300)
    },1000)
  }, [])
  return (
    <Layout>
      <div className='successPopUP'>
      {showSuccessPopUp && <div className="leaveBalance_SM">
        <div className={containerClassName}>
            <div className="subscription--success">
              <div className="subscription--success__heading">
                <AiFillCloseCircle className='AiFillCheckCircle--icon' />
              </div>
              <div className="subscription--success__body">
                <p>Error !!!</p>
                <p>Your Subscription plan couldn't granted.</p>
              </div>
              <hr />
              <div className="subscription--success__footer">
                <button onClick={()=> {
                  setContainerClassName("leaveBalance_SM--containerXX")
                  setTimeout(()=> {
                    history.push("/dashboard")
                  },300)
                  
                }}>Go To PayRun</button>
              </div>
            </div>
        </div>
      </div>}

      </div>

    </Layout>
  )
}

export default SubscriptionErrorMessage