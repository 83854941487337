import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsList } from 'react-icons/bs'
import { IoMdNotifications } from 'react-icons/io'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { BsChevronDown, BsLock } from 'react-icons/bs'
import { AiOutlineUser, AiOutlineLogout } from 'react-icons/ai'

import LogoImg from '../../Images/active account_white-02.png';
import SmLogoImg from '../../Images/logo_hr-03.png';

import { connect, useDispatch } from 'react-redux';
import { toogleSidebar } from '../../Redux/actions'
import { getAdminType, isAuth, isAuth2 } from '../../FrontendHelper'
import { removeCookie, removeLocalStorage } from '../../FrontendHelper'
// import { ToastContainer } from 'react-toastify'

const mapStateToProps = store => {
    const { isToogleSidebar ,activityLogList} = store.mainState;
    return { isToogleSidebar,activityLogList }
}

function AccNavbar({ isToogleSidebar, toogleSidebar ,activityLogList}) {

    const [showAdminOption, setShowAdminOption] = useState(false);
    const [showActivityLog,setShowActivityLog] = useState(false)
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const showActtivityRef = useRef(null);
    

    useEffect(() => {
        if (showAdminOption) {

            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setShowAdminOption(!showAdminOption)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };

        }
    }, [showAdminOption]);
    useEffect(() => {
        if (showActivityLog) {

            function handleClickOutside(event) {
                if (showActtivityRef.current && !showActtivityRef.current.contains(event.target)) {
                    setShowActivityLog(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };

        }
    }, [showActivityLog]);

    const handleLogOut = (e) => {
        e.preventDefault()
        removeLocalStorage("adminProfilefirstName");
        removeLocalStorage("adminProfilelastName");
        removeLocalStorage("accountantType");
        removeLocalStorage("loginType");
        removeCookie("token");
        removeCookie("tSVtoken");
        window.location.replace("/")
        dispatch({
            type: "LOG_OUT_REQUEST"
        })

    }
    return (
        <div className="_navbar" >
            <div className={`${isToogleSidebar ? "_navbar--logo _navbar--logo_sm" : "_navbar--logo"}`}>
                {isToogleSidebar ? <img src={SmLogoImg} alt="Logo" /> : <Link to="/dashboard"> <img src={LogoImg} alt="Logo" /></Link>}
            </div>
            <div className="_navbar--searcn_n_icon">
                <div className='nav_icon' onClick={() => toogleSidebar()}>
                    <BsList />
                </div>
                {/* <div className='nav_search'>
                    <input type="text" placeholder="Search..." /> <span><BiSearch /></span>
                </div> */}
            </div>
            <div className="_navbar--user_n_notification">
                <div className="vertical_line"><p></p></div>
                <div className="_nav_profile">
                    <div className="_nav_profile--orgName">
                        <div className="orgFirstLetter">M</div>
                        <div style={{ textTransform: "uppercase", marginLeft: "10px" }} >{isAuth()}</div> <span style={{marginLeft:"10px"}}><BsChevronDown onClick={() => setShowAdminOption(!showAdminOption)} className="down-arow--icon" /></span>
                    </div>

                    <div className={`${showAdminOption ? "show-admin--option" : "admin_options"}`} ref={wrapperRef}>
                        <div className="admin_options--container">
                            {/* <Link to="#" style={{ textDecoration: "none" }}><p><AiOutlineUser className="admin--option_icons" /> Admin Control Room</p></Link>
                            <Link to="#" style={{ textDecoration: "none" }}><p><AiOutlineUser className="admin--option_icons" /> Admin Profile</p></Link>
                            <Link to="#" style={{ textDecoration: "none" }}><p><BsLock className="admin--option_icons" /> Change Password</p></Link> */}
                            <form>
                                <p onClick={handleLogOut}><AiOutlineLogout className="admin--option_icons" /> Log Out</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        toogleSidebar: () => dispatch(toogleSidebar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccNavbar)
