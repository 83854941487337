import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { connect,useDispatch } from "react-redux";
import { handleAdminCre_Input, loginRequest } from "../../../Redux/actions";
import OrkmateLogo from "../../../Images/ActivAccount_logo_final-01-01.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const mapStateToProps = (store) => {
  const { adminCredencial, isLoadingOnLogin,isSuccessOnLogin,verificationState ,forgotTSVAuth} =
    store.mainState;
  return { adminCredencial, isLoadingOnLogin,verificationState,isSuccessOnLogin ,forgotTSVAuth};
};


const LogIn = ({
  handleAdminCre_Input,
  loginRequest,
  adminCredencial,
  isLoadingOnLogin,
  isSuccessOnLogin

}) => {
  const history = useHistory()
  const [eyeState, setEyeState] = useState(false);

  const [loginType,setLoginType] = useState("admin")

  const { userName, password } = adminCredencial;
  const handleInput = (name) => (event) => {
    handleAdminCre_Input({ ...adminCredencial, [name]: event.target.value });
  };


  useEffect(()=> {
    if(isSuccessOnLogin) {
      history.push(`/${loginType}/two-step-verification`)
    }
  },[isSuccessOnLogin])



  return (
    <>
    <ToastContainer />
    <div className="adminlogin--container">
      <div className="circle--container">
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">6</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">6</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">6</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">4</div>
        <div className="circle">5</div>
        <div className="circle">6</div>
        <div className="circle">7</div>
        <div className="circle">8</div>
        <div className="circle">9</div>
        <div className="circle">1</div>
        <div className="circle">2</div>
        <div className="circle">3</div>
      </div>
        <div className="mainPage--container">

          <div className="admin-login">
          <div className="login_navigator">
            <div>
              <p className={`${loginType === "admin" ? "login_active" : ""}`} onClick={()=> setLoginType("admin")}>Organisation</p>
              <p className={`${loginType === "accountant" ? "login_active" : ""}`} onClick={()=> setLoginType("accountant")}>Accountant</p>
            </div>
          </div>
            <form>
              <div className="admin-login--heading">
                <p>Login</p>
                <div className="admin-login--heading__imgContainer">
                  <img src={OrkmateLogo} alt="Gurug Logo" />
                </div>
              </div>
              <div className="login_loading--text">
                {isLoadingOnLogin && "Loading..."}
                {isSuccessOnLogin && "Succed!!"}
              </div>
              <div className="admin-login--body">
                <div className="email--field">
                  <p>Email </p>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    value={userName}
                    onChange={handleInput("userName")}
                  />
                </div>
                <div className="password--field">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Password </p>
                    <Link
                      to={`${loginType === "admin" ? "/forgot-password/admin": "/forgot-password/accountant"}`}
                      style={{ textDecoration: "none" }}
                    >
                      <p className="signup--text">Forgot Password ?</p>
                    </Link>
                  </div>
                  <input
                    type={`${eyeState ? "text" : "password"}`}
                    placeholder="Enter Password"
                    value={password}
                    onChange={handleInput("password")}
                  />
                  <span className="eye-icon">
                    {eyeState ? (
                      <AiOutlineEyeInvisible
                        onClick={() => setEyeState(!eyeState)}
                      />
                    ) : (
                      <AiOutlineEye onClick={() => setEyeState(!eyeState)} />
                    )}
                  </span>
                </div>
              </div>
              <div className="admin-login--footer">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    loginRequest({
                      userType:loginType,
                      adminCredencial
                    });
                  }}
                >
                  Log In
                </button>
              </div>
              <div className="signup--para">
                <p>Don't have an account yet?</p>
                <Link to="user-type" style={{ textDecoration: "none" }}>
                  <p className="signup--text">Sign Up</p>
                </Link>
              </div>
            </form>
          </div>
        </div>
    </div>
    
    </>
    
    
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAdminCre_Input: (values) => dispatch(handleAdminCre_Input(values)),
    loginRequest: (Credencial) => dispatch(loginRequest(Credencial)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);

