import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import dayjs from "dayjs";
// import DatePicker from "react-datepicker";

const mapStateToProps = (store) => {
  const { buttonText } = store.mainState;
  return { buttonText };
};

function ShowPayrollDetails({ buttonText, item }) {
  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Payroll Employee Details</p>
      </div>
      <div className="payroll--details">
        <div className="payroll--details--header">
          <div>
            <div className="payroll__employee--details">
              <p>{item.employeeFullName[0]}</p>
              <p>{item.employeeFullName}</p>
              <p>{item.jobTitle}</p>
            </div>
            <div className="adjusted--details">
              <div className="adjusted--details--para">
                <p>
                  <label htmlFor="">Work Hours :</label> {item.workHours} Hours
                </p>
                <p>
                  <label htmlFor="">Pay Rate :</label>{" "}
                  {Number(item.payRate).toFixed(2)}{" "}
                  {item.selectAnOption === "PER_HOUR" && "Per Hour"}{" "}
                  {item.selectAnOption === "PER_DAY" && "Per Day"}{" "}
                  {item.selectAnOption === "PER_MONTH" && "Per Month"}
                </p>
              </div>
              <div className="adjusted--details--para">
                <p>
                  <label htmlFor="">Gross Pay :</label> ${item.grossPay}
                </p>
                <p>
                  <label htmlFor="">Tax Pay :</label> ${item.taxPay}
                </p>
              </div>
              <div className="adjusted--details--para">
                <p>
                  <label htmlFor="">Super Pay :</label> ${item.superPay}
                </p>
                <p>
                  <label htmlFor="">Net Pay :</label> ${item.netPay}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="payroll--details--body">
          <div className="leaveTable">
            <hr />
            <h2>Leave Details :-</h2>
            <table>
              <thead>
                <tr>
                  <th className="runPayRoll--status">Sick Leave</th>
                  <th className="runPayRoll--period">Annual Leave</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Sick Leave Acquired : {item.sickLeaveAcquired || 0} hours
                  </td>
                  <td>
                    Annual Leave Acquired : {item.annualLeaveAcquired || 0}{" "}
                    hours
                  </td>
                </tr>
                <tr>
                  <td>
                    Overal Sick Leave Taken : {item.sickLeaveTakenHour || 0}{" "}
                    hours
                  </td>
                  <td>
                    Overal Annual Leave Taken : {item.annualLeaveTakenHour || 0}{" "}
                    hours
                  </td>
                </tr>
                <tr>
                  <td>
                    Annual Leave Remaining :{" "}
                    {Number(item.annualLeaveRemainingHour).toFixed(2)} hours
                  </td>
                  <td>
                    Sick Leave Remaining :{" "}
                    {Number(item.sickLeaveRemainingHour).toFixed(2)} hours
                  </td>
                </tr>
                <tr>
                  <td>Sick Leave Pay : ${item.sickLeavePay}</td>
                  <td>Annual Leave Pay : ${item.annualLeavePay}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {item.extraHours !== undefined && item.extraHours.length > 0 && (
            <div className="leaveTable">
              <hr />
              <h2>Extra Hours Details :-</h2>
              <table>
                <thead>
                  <tr>
                    <th className="runPayRoll--status">Option</th>
                    <th className="runPayRoll--status">Note</th>
                    <th className="runPayRoll--status">Hour</th>
                    <th className="runPayRoll--status">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {item.extraHours.map((item) => (
                    <tr>
                      <td>{item.option}</td>
                      <td>{item.note}</td>
                      <td>{item.hours}</td>
                      <td>{item.rate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {item.bonusCommissionAmount !== undefined &&
            item.bonusCommissionAmount > 0 && (
              <div className="leaveTable">
                <hr />
                <h2>Bonus Commission Details :-</h2>
                <table>
                  <thead>
                    <tr>
                      <th className="runPayRoll--status">Bonus Option</th>
                      <th className="runPayRoll--status"> Bonus Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{item.bonusOption}</td>
                      <td>{item.bonusCommissionAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

          {item.allowances !== undefined && item.allowances.length > 0 && (
            <div className="leaveTable">
              <hr />
              <h2>Allowance Details :-</h2>
              <table>
                <thead>
                  <tr>
                    <th className="runPayRoll--status">Allowance Option</th>
                    <th className="runPayRoll--status">Allowance Type</th>
                    <th className="runPayRoll--status">CostPerUnit</th>
                    <th className="runPayRoll--status">Unit</th>
                  </tr>
                </thead>
                <tbody>
                  {item.allowances.map((item) => (
                    <tr>
                      <td>{item.allowanceOption}</td>
                      <td>{item.allowanceType}</td>
                      <td>{item.costPerUnit}</td>
                      <td>{item.unit}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {item.deductions !== undefined && item.deductions.length > 0 && (
            <div className="leaveTable">
              <hr />
              <h2>Deduction Details :-</h2>
              <table>
                <thead>
                  <tr>
                    <th className="runPayRoll--status">Option</th>
                    <th className="runPayRoll--status">Type</th>
                    <th className="runPayRoll--status">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {item.deductions.map((item) => (
                    <tr>
                      <td>{item.option}</td>
                      <td>{item.type}</td>
                      <td>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="payroll--details--footer"></div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(ShowPayrollDetails);
