import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  heading: {
    fontSize: "10px",
    fontStyle: "bold",
    paddingLeft: 8,
    textAlign: "left",
    marginBottom:"-10px"
    // backgroundColor: "yellow",
    // marginTop: "10px",
    // justifyContent: "flex-start"
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: "9px",
    marginTop:"10px"
  },
  description: {
    width: "50%",
    textAlign: "left",

    paddingLeft: 8,
    fontSize: "9px",
  },
  qty: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
  amount: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const ChildSupportsRow = ({ childSupports, heading }) => {

  return (
    <>
      <View>
        <Text style={styles.heading}>{heading}</Text>
      </View>
      {childSupports.map((support,index) => {
        return (
          <View style={styles.row} key={index}>
            <Text style={styles.description}>Child Support-{support.supportType}</Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.rate}></Text>
            <Text style={styles.amount}>
              ${support.supportRate}
            </Text>
            <Text style={styles.qty}></Text>
          </View>
        );
      })}
    </>
  );
};

export default ChildSupportsRow;
