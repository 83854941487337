import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import ClientPage from "./ClientPage";
import PaymentMethod from "./PaymentMethod";
import TeamMember from "./TeamMember";
import WhiteLabel from "./WhiteLabel";

function AccSetting() {
  const [myActivClass,setMyActivClass] = useState("team_member")
  return (
    <Layout>
      <div className="dashboard">
        <div className="dashboard--heading">
          <p>Settings</p>
        </div>
      </div>
      <div className="setting--navigator">
        <div className="setting--navigator__container">
          <ul>
            {/* <li className={`${myActivClass === "white_label" ? "setting--navigator__li_active" : "setting--navigator__li"}`} onClick={()=> setMyActivClass("white_label")}>White Label <span>a</span></li> */}
            <li className={`${myActivClass === "team_member" ? "setting--navigator__li_active" : "setting--navigator__li"}`} onClick={()=> setMyActivClass("team_member")}>Team Member <span>a</span></li>
            {/* <li className={`${myActivClass === "client_page" ? "setting--navigator__li_active" : "setting--navigator__li"}`} onClick={()=> setMyActivClass("client_page")}>Client Page <span>a</span></li> */}
            {/* <li className={`${myActivClass === "payment_method" ? "setting--navigator__li_active" :"setting--navigator__li"}`} onClick={()=> setMyActivClass("payment_method")}>Payment Method <span>a</span></li> */}
          </ul>
        </div>
      </div>
      <div>
        {/* {myActivClass === "white_label" && <WhiteLabel />} */}
        {myActivClass === "team_member" && <TeamMember />}
        {/* {myActivClass === "client_page" && <ClientPage />}
        {myActivClass === "payment_method" && <PaymentMethod />} */}
      </div>
    </Layout>
  );
}

export default AccSetting;
