import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Layout from '../../Layout/Layout'
import { Hero } from '../../Utils'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import {IoTrashOutline} from 'react-icons/io5'
import {BiX} from 'react-icons/bi'

function AccountantSetting() {
    const dispatch = useDispatch()

    const [containerClassName,setContainerClassName] = useState("leaveBalance_SM--containerXX")
    const {orgAccountantInfo,isLoadingOnLogin,isPostUpdateCompleted} = useSelector(state=> state.mainState)
    const [accEmail,setAccEmail] = useState("");
    const [showDeleteAccountantModal,setShowDeleteAccountantModal] = useState(false)
    
    useEffect(()=> {
        dispatch({
            type: "FETCH_ORG_ACCOUNTANT_REQUEST"
        })
    },[])
    
  return (
    <Layout>
            <div>
                <div className="group_list">
                    <h4>Accountant Setting</h4>
                </div>
                {isPostUpdateCompleted && <Hero />}
                <p className="myloading--paragraph">{isLoadingOnLogin && <LoadingComponent />}</p>
                <div className="accountant--card" style={{minHeight: "60vh"}}>
                    <div className="accountant--card__container">
                        <div className="accountant--card__container--heading">
                            <p>Accountant Details :-</p>
                        </div>
                        <hr />
                        <div className="accountant--card__container--body">
                            {(orgAccountantInfo.id==null || orgAccountantInfo.name == null) ? 
                            <p style={{color: "red"}}>*No accountant linked. Invite your accountant below.</p> : 
                            <>
                            <p><label htmlFor="">Name : </label> {orgAccountantInfo.name}</p>
                            <p><label htmlFor="">Email : </label> {orgAccountantInfo.email}</p></>}
                              {(orgAccountantInfo.id !== null & orgAccountantInfo.name !== null) ?
                               <p className='remove--accountant'><IoTrashOutline onClick={()=> {
                                setShowDeleteAccountantModal(true)
                                setTimeout(()=> {
                                  setContainerClassName("leaveBalance_SM--container")
                                },2)
                              }} /></p> : null}
                        </div>
                        <hr />
                        <div className="accountant--card__container--footer">
                            <div className='footer--input'>
                                <input type='email' placeholder='Email Address' value={accEmail} onChange={(e)=> setAccEmail(e.target.value)} />
                                <p>*Email Address : </p>    
                            </div>
                            <div className='footer--button'>
                                <button onClick={()=> {
                                    if(accEmail === "") {
                                        toast.error("Enter your accountant email address first")
                                    } else {
                                        dispatch({
                                            type: "INVITE_ORG_ACCOUNTANT_REQUEST",
                                            payload: {
                                                "accountantEmail": accEmail
                                            }
                                        })
                                    }
                                }}>Invite</button>
                            </div>
                        </div>
                    </div>
                </div>
                {showDeleteAccountantModal && <div className="leaveBalance_SM">
        <div className={containerClassName}>
          <div className="leavBalance_sm--heading">
            <p>Remove current accountant</p>
            <p onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowDeleteAccountantModal(false)
              },300)
            }}><BiX /></p>
          </div>
            <div>
                <p>Are you sure to remove current accountant ?</p>
            </div>
         
          <div className="leaveBalance--buttons">
            <button onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowDeleteAccountantModal(false)
              },300)
              }}>Close</button>
            <button onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowDeleteAccountantModal(false)
                dispatch({
                  type: "REMOVE_ACCOUNTANT_REQUEST"
                })
              },300)

            }}>Ok</button>
          </div>
        </div>
      </div>}
                
                <Footer />
            </div>

        </Layout>
  )
}

export default AccountantSetting