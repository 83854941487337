import React, {useEffect,useState} from 'react'
import Layout from '../../../Layout/Layout'
import { useParams } from 'react-router-dom'
import {useDispatch,useSelector} from "react-redux"
import { useHistory } from "react-router";
import { getOrganizationId } from '../../../FrontendHelper';


function PricingInfo() {
    const dispatch = useDispatch();
    const history = useHistory()
    const billingPriceData = useSelector((state)=> state.mainState.billingPriceData)
    const billingGeneratedUrl = useSelector((state)=> state.mainState.billingGeneratedUrl)
    const {employeeCount,duration} = useParams()
    const organizationId = getOrganizationId()
    useEffect(()=> {
        dispatch({
            type: "CALCULATE_BILLING_PRICE_REQUEST",
            payload: {
            employeeCount,duration,
            
            }
        })
    dispatch({
            type: "FETCH_CHECKOUT_URL_REQUEST",
            payload: {
            employeeCount,duration,
            organizationId
            }
        })
    },[duration,employeeCount])

  return (
    <Layout>
        <div>
            <button className='pricing-info__buttonFirst' onClick={()=> {
                history.goBack()
            }}>Back</button>
        <div className="group_list">
                    <h4>Pricing Information</h4>
                </div>
        </div>
        <div className="pricing-info">
            <p>Your total employee is : <span>{billingPriceData?.employeeCount}</span></p>
            <p>Pricing per employee is : <span>AU${billingPriceData?.pricePerEmployee}</span></p>
            <p>Your total price is : <span> AU${billingPriceData?.totalCharge}</span></p>
        </div>
        <div className='pricing-info__button'>
            <button ><a href={billingGeneratedUrl?.redirectURL}>Go To Checkout</a></button>
        </div>
    </Layout>
  )
}

export default PricingInfo