import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { CgOrganisation } from 'react-icons/cg'
import { connect, useDispatch } from 'react-redux'
import Footer from '../Z_Footer/Footer'
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import { FcBusinessman } from 'react-icons/fc'
import { ImCreditCard } from 'react-icons/im'
import LoadingComponent from '../LoadingComponent'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { BsX } from 'react-icons/bs'
import { BiDollar } from 'react-icons/bi'
import OrganizationSetting from './OrganizationSetting'
import PersonalSetting from './PersonalSetting'
import PaymentSetting from './PaymentSetting'
import { Link } from 'react-router-dom'
import { getLoginType, getOrganizationId } from '../../FrontendHelper'
import AccPersonalSetting from '../../AccComponent/Settings/AccPersonalSetting'



const mapStateToProps = store => {
    const { isAddOrganization, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully, isAddPersonalSetting,isPaymentSetting,organizationSettingData } = store.mainState
    return { isAddOrganization, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully, isAddPersonalSetting ,isPaymentSetting,organizationSettingData}
}

function Setting({ isAddOrganization, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully, isAddPersonalSetting,isPaymentSetting,organizationSettingData }) {
    const dispatch = useDispatch()
    const userType = getLoginType()
    const [isSubscriptionActive,setIsSubscriptionActive] = useState(false)
    const organizationId = getOrganizationId()
    useEffect(()=> {
        if(organizationSettingData) {
            setIsSubscriptionActive(organizationSettingData.isSubscriptionActive)
        }
    },[organizationSettingData])

    useEffect(()=> {
        let currentPage =1
        let postsPerPage = 10
        dispatch({
            type: "FETCH_PAYMENT_HISTORY_REQUEST",
            payload: { currentPage, postsPerPage,organizationId },
          });
    },[])
    console.log("The login type",userType)

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Settings</h4>
                </div>
                <div className="setting--container" style={{minHeight: "60vh"}}>
                    <div className="setting--container__items">
                        <div className="setting--container__items--organization" onClick={() => {
                            dispatch({
                                type: "OPEN_ADD_ORGANIZATION_MODAL",

                            })
                        }}>
                            <p><CgOrganisation /></p>
                            <p>Organization Settings</p>
                            <label htmlFor="">Edit company, payroll and payslip details</label>
                        </div>
                        <div className="setting--container__items--personal" onClick={() => dispatch({
                            type: "OPEN_ADD_PERSONAL_MODAL"
                        })}>
                            <p><FcBusinessman /></p>
                            <p>Personal Settings</p>
                            <label htmlFor="">Edit name & password</label>
                        </div>
                        <div className="setting--container__items--employeePayment" onClick={() => dispatch({
                            type: "OPEN_ADD_EMPLOYEE_PAYMENT_MODAL"
                        })}>
                            <p><ImCreditCard /></p>
                            <p>Employee Payment Settings</p>
                            <label htmlFor="">Payment method to pay your employees</label>
                        </div>
                        <Link to= {`${isSubscriptionActive ? "payment-history" : "billing-subscription"}`} style={{textDecoration: "none"}}>
                        <div className="setting--container__items--billing" onClick={() => dispatch({
                            type: "OPEN_ADD_EMPLOYEE_PAYMENT_MODAL"
                        })}>
                            <p><BiDollar /></p>
                            <p>Billing Section</p>
                            <label htmlFor="">Edit your subscription details</label>
                        </div>
                        </Link>
                        {userType === "admin" && <Link to="accountant-setting" style={{textDecoration: "none"}}>
                        <div className="setting--container__items--employeePayment" >
                            <p><ImCreditCard /></p>
                            <p>Accountant Settings</p>
                            <label htmlFor="">Invite, update, delete your accountant</label>
                        </div>
                        </Link>}
                        
                    </div>
                </div>
                <div className={`${isAddOrganization ? "mymodal modal_activated" : "mymodal"}`}>
                    <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                        <OrganizationSetting />
                    </div>
                    <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                        <div className="task--rectangle__circle">
                            <div className='circle'> <span onClick={() => {

                                dispatch({ type: "OPEN_ADD_ORGANIZATION_MODAL" });

                            }}><BsX /></span><p>Edit</p></div>
                        </div>
                    </div>
                </div>
                <div className={`${isAddPersonalSetting ? "mymodal modal_activated" : "mymodal"}`}>
                    <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                       {userType === "accountant" ? <AccPersonalSetting /> :  <PersonalSetting />}
                    </div>
                    <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                        <div className="task--rectangle__circle">
                            <div className='circle'> <span onClick={() => {

                                dispatch({ type: "OPEN_ADD_PERSONAL_MODAL" });

                            }}><BsX /></span><p>Edit</p></div>
                        </div>
                    </div>
                </div>
                <div className={`${isPaymentSetting ? "mymodal modal_activated" : "mymodal"}`}>
                    <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                        <PaymentSetting />
                    </div>
                    <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                        <div className="task--rectangle__circle">
                            <div className='circle'> <span onClick={() => {

                                dispatch({ type: "OPEN_ADD_EMPLOYEE_PAYMENT_MODAL" });

                            }}><BsX /></span><p>Edit</p></div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}


export default connect(mapStateToProps, null)(Setting)

