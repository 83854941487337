import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { BsImage } from "react-icons/bs";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getOrganizationId } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { buttonText, organizationSettingData, isEnabledUsers } =
    store.mainState;
  return { buttonText, organizationSettingData, isEnabledUsers };
};

function OrganizationSetting({
  buttonText,
  organizationSettingData,
  isEnabledUsers,
}) {
  const organizationId = getOrganizationId();

  const { organizationDetail, payRollDetail, leaveSettings } =
    organizationSettingData;

  const [businessName, setBusinessName] = useState("");
  const [abn, setAbn] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState(null);
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostcode] = useState("");
  const [myState, setMyState] = useState("");

  const [payrolStartDate, setPayrolStartDate] = useState("");
  const [hoursPerDay, setHoursPerDay] = useState("");
  const [payPeriod, setPayPeriod] = useState("");

  const [alsl, setAlsl] = useState(false);
  const [albl, setAlbl] = useState(false);
  const [pclb, setPclb] = useState(false);
  const [enalbleStp, setEnableStp] = useState(false);

  const [orgLogo, setOrgLogo] = useState(null);

  const dispatch = useDispatch();

  const handleSubmitButton = (e) => {
    e.preventDefault();
    if (businessName.length <= 0) {
      toast.error("Enter your business name");
    } else if (abn === "") {
      toast.error("Enter your ABN");
    } else if (abn.length < 11 || abn.length > 11) {
      toast.error("ABN length should be exactly 11 digit");
    } else if (companyName === "") {
      toast.error("Company name shouldn't empty");
    } else if (contactNo === "") {
      toast.error("Contact number shouldn't empty");
    } else if (contactNo.length < 9) {
      toast.error(
        "Contact number is less than 9 digit. It should be 9 or 10 digit"
      );
    } else if (contactNo.length >= 11) {
      toast.error(
        "Contact number is greater than 10 digit. It should be 9 or 10 digit"
      );
    } else if (addressOne === "") {
      toast.error("Address one shouldn't empty");
    } else if (suburb === "") {
      toast.error("Suburb shouldn't empty");
    } else if (postCode === "") {
      toast.error("Postcode shouldn't empty");
    } else if (postCode.length < 4 || postCode.length > 4) {
      toast.error("Postcode length should be exactly 4 digit");
    } else if (payrolStartDate === "") {
      toast.error("Payroll start date shouldn't emptty");
    } else if (hoursPerDay === "") {
      toast.error("Hours per day shouldn't emptty");
    } else if (hoursPerDay > 24) {
      toast.error("Hours per day cann't exceed 24");
    } else if (hoursPerDay <= 0) {
      toast.error("Hours per day should greater than 0");
    } else {
      dispatch({
        type: "POST_ORGANIZATION_REQUEST",
        payload: {
          organizationDetail: {
            businessName: businessName,
            abnNumber: abn,
            companyLegalName: companyName,
            addressOne: addressOne,
            addressTwo: addressTwo,
            suburb: suburb,
            postCode: postCode,
            state: myState,
            contactNumber: contactNo,
          },
          payRollDetail: {
            hoursPerDay: Number(hoursPerDay),
            payrollStartDate: dayjs(payrolStartDate).format("YYYY-MM-DD"),
            payPeriod: payPeriod,
            enableSTP: enalbleStp,
          },
          leaveSettings: {
            allowLongServiceLeave: alsl,
            annualLeaveBalance: albl,
            personalCarerLeaveBalance: pclb,
          },
          organizationId: organizationId,
        },
      });
    }
  };
  useEffect(() => {
    const dataForm = new FormData();
    dataForm.append("logo", orgLogo);
    if (orgLogo !== null) {
      dispatch({
        type: "UPLOAD_ORG_LOGO_REQUEST",
        payload: dataForm,
      });
      dispatch({
        type: "FETCH_ORGANIZATION_REQUEST",
      });
    }
  }, [orgLogo]);
  useEffect(() => {
    dispatch({
      type: "FETCH_ORGANIZATION_REQUEST",
    });
  }, []);
  useEffect(() => {
    if (organizationSettingData.organizationDetail !== undefined) {
      setBusinessName(organizationDetail.businessName);
      setAbn(organizationDetail.abnNumber);
      setCompanyName(organizationDetail.companyLegalName);
      setContactNo(organizationDetail.contactNumber);
      setAddressOne(organizationDetail.addressOne);
      setAddressTwo(organizationDetail.addressTwo);
      setSuburb(organizationDetail.suburb);
      setPostcode(organizationDetail.postCode);
      setMyState(organizationDetail.state);
    }
    if (organizationSettingData.payRollDetail !== undefined) {
      setPayrolStartDate(new Date(payRollDetail.payrollStartDate));
      setHoursPerDay(payRollDetail.hoursPerDay);
      setPayPeriod(payRollDetail.payPeriod);
      setEnableStp(payRollDetail.enableSTP);
    }
    if (organizationSettingData.leaveSettings !== undefined) {
      setAlsl(leaveSettings.allowLongServiceLeave);
      setAlbl(leaveSettings.annualLeaveBalance);
      setPclb(leaveSettings.personalCarerLeaveBalance);
    }
  }, [organizationSettingData]);
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        dispatch({ type: "OPEN_ADD_ORGANIZATION_MODAL" });
        dispatch({ type: "FETCH_ORGANIZATION_REQUEST" });
      }, 1000);
    }
    if (isEnabledUsers === "c") {
      dispatch({ type: "FETCH_ORGANIZATION_REQUEST" });
    }
  }, [isEnabledUsers]);

  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Organization Settings</p>
      </div>
      <div className="organization--logo">
        <div className="organization--logo__container">
          {organizationSettingData.logo !== undefined ? (
            <img
              src={organizationSettingData.logo}
              alt="Organization Logo Here"
            />
          ) : (
            <>
              <p>
                <BsImage />
              </p>
              <p>Upload Organization Logo Here</p>
            </>
          )}
          <input type="file" onChange={(e) => setOrgLogo(e.target.files[0])} />
        </div>
      </div>
      {/* <img src="https://res.cloudinary.com/dees63q5v/image/upload/v1621166702/img-3_h6cfl8.png" alt="" /> */}
      <div className="organization">
        <div className="organixation--information--container">
          <form>
            <div className="form--container">
              <p className="sub--title">Organization Details :-</p>
              <div className="setting--organization--field">
                <div className="setting--organization--field--name">
                  <p>Business Name* : </p>
                  <input
                    type="text"
                    placeholder="Business Name"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </div>
                <div className="setting--organization--field--para">
                  <p>ABN* : </p>
                  <input
                    type="text"
                    placeholder="ABN"
                    value={abn}
                    onChange={(e) => setAbn(e.target.value)}
                  />
                </div>
              </div>
              <div className="setting--organization--field">
                <div className="setting--organization--field--name">
                  <p>Company Name* : </p>
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="setting--organization--field--para">
                  <p>Contact Number* : </p>
                  <input
                    type="text"
                    placeholder="Contact Number"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="setting--organization--field">
                <div className="setting--organization--field--name">
                  <p>Address One* : </p>
                  <input
                    type="text"
                    placeholder="Address One"
                    value={addressOne}
                    onChange={(e) => setAddressOne(e.target.value)}
                  />
                </div>
                <div className="setting--organization--field--para">
                  <p>Address Two(Opt) : </p>
                  <input
                    type="text"
                    placeholder="Address Two"
                    value={addressTwo}
                    onChange={(e) => setAddressTwo(e.target.value)}
                  />
                </div>
              </div>
              <div className="setting--organization--field">
                <div className="setting--organization--field--name">
                  <p>Suburb* : </p>
                  <input
                    type="text"
                    placeholder="Suburb"
                    value={suburb}
                    onChange={(e) => setSuburb(e.target.value)}
                  />
                </div>
                <div className="setting--organization--field--para">
                  <p>Post Code* : </p>
                  <input
                    type="text"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={(e) => setPostcode(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="setting--organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="setting--organization--field--name">
                  <p>State* : </p>
                  <select
                    onChange={(e) => setMyState(e.target.value)}
                    value={myState}
                  >
                    <option value="ACT">ACT</option>
                    <option value="NSW">NSW</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                  </select>
                </div>
                <div className="setting--organization--field--para"></div>
              </div>
              <hr />
              <p className="sub--title">Payroll Details :-</p>
              <div className="setting--organization--field">
                <div className="setting--organization--field--name">
                  <p>Payroll Start Date* : </p>
                  <p className="org--inputDate">
                    <DatePicker
                      selected={payrolStartDate}
                      onChange={(date) => setPayrolStartDate(date)}
                      disabledKeyboardNavigation
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd / mm / yyyy"
                      dateFormat="dd / MM / yyyy"
                    />
                  </p>

                  {/* <input type="date" placeholder="Suburb" value={payrolStartDate} onChange={(e) => setPayrolStartDate(e.target.value)} /> */}
                </div>
                <div className="setting--organization--field--para">
                  <p>Hours Per Day* : </p>
                  <input
                    type="number"
                    placeholder="Hours Per Day"
                    value={hoursPerDay}
                    onChange={(e) => setHoursPerDay(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="setting--organization--field"
                style={{ marginBottom: "30px" }}
              >
                <div className="setting--organization--field--name">
                  <p>Pay Period* : </p>
                  <select
                    value={payPeriod}
                    onChange={(e) => setPayPeriod(e.target.value)}
                  >
                    <option value="WEEKLY">WEEKLY</option>
                    <option value="FORTNIGHTLY">FORTNIGHTLY</option>
                    <option value="MONTHLY">MONTHLY</option>
                  </select>
                </div>
                <div className="setting--organization--field--para"></div>
              </div>
              <hr />
              <div
                className="EnableStp--organization"
                style={{ marginBottom: "30px" }}
              >
                <p>
                  <input
                    type="checkbox"
                    value={enalbleStp}
                    checked={enalbleStp}
                    onChange={(e) => setEnableStp(e.target.checked)}
                  />{" "}
                  <label style={{ marginLeft: "10px" }}>Enable STP*</label>
                </p>
                <p>
                  Check Enable STP if you like to report STP using
                  ActiveAccount.
                </p>
                <div className="setting--organization--field--para"></div>
              </div>
              <hr />
              <p className="sub--title">Leave Setting* :-</p>
              <div className="organization--checkbox">
                <p>
                  <input
                    type="checkbox"
                    value={alsl}
                    checked={alsl}
                    onChange={(e) => setAlsl(e.target.checked)}
                  />{" "}
                  <label>Allow Long Service Leave</label>
                </p>
                <p>
                  <input
                    type="checkbox"
                    value={albl}
                    checked={albl}
                    onChange={(e) => setAlbl(e.target.checked)}
                  />{" "}
                  <label>Annual Leave Balance</label>
                </p>
                <p>
                  <input
                    type="checkbox"
                    value={pclb}
                    checked={pclb}
                    onChange={(e) => setPclb(e.target.checked)}
                  />{" "}
                  <label>Personal Carrier Leave Balance</label>
                </p>
              </div>
              <hr />
            </div>
            <div className="modal--container__group--buttons">
              <button
                type="submit"
                onClick={handleSubmitButton}
                style={{ marginBottom: "50px" }}
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(OrganizationSetting);
