import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Document, Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import LeaveBalanceReportTitle from './LeaveBalanceReportTitle';
import LeaveBalanceTable from './LeaveBalanceTable';

const mapStateToProps = store => {
    const { buttonText,leaveBalanceReportData, employeeDropdownList, isEnabledUsers,isEmployeeContactsReportModal } = store.mainState
    return { buttonText,leaveBalanceReportData, employeeDropdownList, isEnabledUsers,isEmployeeContactsReportModal }
}


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        fontSize: "10px",
        textAlign: "right",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    text: {
        fontSize: "10px",
        color: "yellow"
    },

});

function LeaveBalanceReport({ buttonText, employeeDropdownList, isEnabledUsers,isEmployeeContactsReportModal,reportData,leaveBalanceReportData }) {
    console.log("the leave Balance data in xx",leaveBalanceReportData)

        // Create Document Component
        const MyDocument = () => (
            <Document className="document">
                <Page size="A4" style={styles.page} wrap>
                    <LeaveBalanceReportTitle leaveBalanceReportData={leaveBalanceReportData} />
                    <LeaveBalanceTable leaveBalanceReportData={leaveBalanceReportData} /> 
                </Page>
            </Document>
        );

    return (
        <div>
            <div className="Modal--heading">
                <p style={{ color: "black" }}>Leave Balance Report</p>
            </div>
            <div className="pdf--container">
                <PDFViewer className="pdf--container__pdf">
                    <MyDocument />
                </PDFViewer>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(LeaveBalanceReport)

