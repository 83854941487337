import { View,Text,StyleSheet } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import React from 'react'

const styles = StyleSheet.create({
    container: {
        padding: "20",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        fontStyle: 'bold',
    },
    text: {
        fontSize: "14"
    },
    textDate: {
        marginTop: "5",
        fontSize: "12",
        color:"#975466"
    }
})

function EmployeeSummaryTitle({employeeSummaryReportData}) {
    return (
        <View style={styles.container}>
            <View>
            <Text style={styles.text}>{employeeSummaryReportData.companyLegalName}</Text>
            <Text style={styles.textDate}>{dayjs(employeeSummaryReportData.startDate).format("DD-MMM-YYYY")} - {dayjs(employeeSummaryReportData.endDate).format("DD-MMM-YYYY")}</Text>
            </View>
            <Text style={styles.text}>Payroll Employee Summary</Text>
        </View>
    )
}

export default EmployeeSummaryTitle

