import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { AiFillEye, AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import { RiSendPlaneFill, RiBroadcastFill } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { BsX, BsChevronDoubleRight } from "react-icons/bs";
import ShowPaySlip from "./ShowPaySlip";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import dayjs from "dayjs";
import useFetchDashboard from "./useFetchDashboard";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isToogleSidebar,
    payrollInfo,
    reportButtonText,
    sendButtonText,
    payButtonText,
    isEnabledUsers,
    removeButton,
    editButton,
    organizationSettingData,
  } = store.mainState;
  return {
    buttonText,
    isToogleSidebar,
    payrollInfo,
    reportButtonText,
    sendButtonText,
    payButtonText,
    isEnabledUsers,
    removeButton,
    editButton,
    organizationSettingData,
  };
};

function ShowReviewPayroll({
  buttonText,
  myStatus,
  id,
  isToogleSidebar,
  payrollInfo,
  reportButtonText,
  sendButtonText,
  payButtonText,
  isEnabledUsers,
  removeButton,
  editButton,
  organizationSettingData,
}) {
  console.warn("checking the data of the payrollInfo ", payrollInfo);
  const [editStp, setEditStp] = useState(true);

  useEffect(() => {
    const startYear = new Date(payrollInfo.startDate);
    const dd = ("0" + startYear.getDate()).slice(-2);
    const mm = ("0" + (startYear.getMonth() + 1)).slice(-2);
    const yyyy = startYear.getFullYear();
    let year;

    if (mm > 6) {
      year = `${yyyy}-07-01`;
    } else {
      year = `${yyyy - 1}-07-01`;
    }
    const data1 = new Date();
    const data2 = new Date(year);
    const diffInMilliseconds = data1.getTime() - data2.getTime();
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    const diffInYear = diffInDays / 365;
    setEditStp(diffInYear < 1 ? true : false);
  }, [payrollInfo.startDate]);

  const [currentPage, setCurrentPage] = useState(1);
  const [showPaySlipModal, setShowPaySlipModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showRemovePayrollModal, setShowRemovePayrollModal] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const dispatch = useDispatch();

  const wrapperRef = useRef(null);
  const { loading, payroller, hasMore } = useFetchDashboard(currentPage);
  useEffect(() => {
    if (showSignatureModal) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShowSignatureModal(!showSignatureModal);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showSignatureModal]);

  useEffect(() => {
    dispatch({
      type: "FETCH_PAYROLL_INFO_REQUEST",
      payload: {
        id,
      },
    });
  }, [id]);
  useEffect(() => {
    if (isEnabledUsers === "reported") {
      setShowSignatureModal(false);
      setSignatureName("");
      setTimeout(() => {
        window.location.reload();

        dispatch({ type: "OPEN_REVIEW_PAYROLL_MODAL" });
        setCurrentPage(1);
      }, 1500);
    }
  }, [isEnabledUsers]);

  return (
    <div>
      <div className="Modal--heading">
        <div className="review--section--topHeading">
          <p style={{ color: "black" }}>Pay Run Details</p>
          <div className="topHeading--buttons">
            {myStatus !== "STP_PENDING" && (
              <p
                onClick={() => {
                  dispatch({ type: "OPEN_REVIEW_PAYROLL_MODAL" });
                  setTimeout(() => {
                    dispatch({
                      type: "EDIT_PAYROLL_REQUEST",
                      payload: { id },
                    });
                  }, 500);
                }}
              >
                {/* <AiTwotoneEdit /> Edit */}
                {editStp ? (
                  <>
                    <AiTwotoneEdit /> Edit
                  </>
                ) : (
                  ""
                )}
              </p>
            )}
            <p
              onClick={() => {
                setShowRemovePayrollModal(true);
              }}
            >
              {" "}
              <AiTwotoneDelete style={{ marginRight: "5px" }} /> {removeButton}
            </p>
          </div>
        </div>
      </div>
      <div className="review--section">
        <div className="review--section__heading">
          <div className="review--section__heading--dates">
            <p>
              {dayjs(payrollInfo.startDate).format("DD MMM YYYY")} -{" "}
              {dayjs(payrollInfo.endDate).format("DD MMM YYYY")}
            </p>
            <p>
              Pay Date : {dayjs(payrollInfo.paymentDate).format("DD MMM YYYY")}
            </p>
          </div>
        </div>
        {payrollInfo.payrollStatus === "STP_FAILED" &&
          payrollInfo.stpFailedReason !== null && (
            <div style={{ color: "red" }}>
              <h3>Reason To Fail STP !!!</h3>
              <p style={{ padding: "10px", color: "coral" }}>
                {payrollInfo.stpFailedReason}
              </p>
            </div>
          )}
        <div className="review--section__body">
          <div className="dashboard__table-container">
            <table>
              <thead>
                <tr>
                  <th className="payrollDetails--employee">Employee</th>
                  <th className="payrollDetails--annualLeave">
                    Annual leave Accrued{" "}
                  </th>
                  <th className="payrollDetails--gross">Sick leave Accrued</th>
                  <th className="payrollDetails--gross">Toil leave Accrued</th>
                  <th className="payrollDetails--tax">Earnings</th>
                  <th className="payrollDetails--tax">Tax</th>
                  <th className="payrollDetails--tax">Super</th>
                  <th className="payrollDetails--tax">Net Pay</th>
                  <th className="payrollDetails--tax">Total Paid</th>
                </tr>
              </thead>
              <tbody>
                {payrollInfo.employeePayroll !== undefined &&
                  payrollInfo.employeePayroll.map((item, index) => (
                    <tr key={index}>
                      <td>{item.employeeFullName}</td>
                      <td>{item.annualLeaveAcquired}</td>
                      <td>{item.sickLeaveAcquired}</td>
                      <td>{item.toilLeaveAcquired}</td>
                      <td
                        style={{
                          color: "rgb(125, 156, 125);",
                          fontWeight: "500",
                        }}
                      >
                        ${item.grossPay}
                      </td>
                      <td
                        style={{
                          color: "rgb(125, 156, 125);",
                          fontWeight: "500",
                        }}
                      >
                        ${item.taxPay}
                      </td>
                      <td
                        style={{
                          color: "rgb(125, 156, 125);",
                          fontWeight: "500",
                        }}
                      >
                        ${item.superPay}
                      </td>
                      <td
                        style={{
                          color: "rgb(125, 156, 125);",
                          fontWeight: "500",
                        }}
                      >
                        ${item.netPay}
                      </td>
                      <td
                        style={{
                          color: "rgb(125, 156, 125);",
                          fontWeight: "500",
                        }}
                      >
                        ${item.netPay}
                      </td>
                    </tr>
                  ))}
                <tr
                  style={{ borderBottom: "1px solid white", boxShadow: "none" }}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bold" }}>
                    ${payrollInfo.grossPay}
                  </td>
                  <td style={{ fontWeight: "bold" }}>${payrollInfo.taxPay}</td>
                  <td style={{ fontWeight: "bold" }}>
                    ${payrollInfo.superPay}
                  </td>
                  <td style={{ fontWeight: "bold" }}>${payrollInfo.netPay}</td>
                  <td style={{ fontWeight: "bold" }}>${payrollInfo.netPay}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Delete payroll */}
        <div
          className={`${
            showRemovePayrollModal ? "delete--admin" : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading"></div>
            <>
              <div className="delete--admin--container__body">
                <h1>Are you sure to remove this payroll ?</h1>
                <div>
                  {isEnabledUsers === "a" && (
                    <div className="deleting--something">
                      {" "}
                      <p>Processing...</p>
                    </div>
                  )}
                </div>
                <div>
                  {" "}
                  {isEnabledUsers === "b" && (
                    <div className="deleting--something">
                      {" "}
                      <TiTick className="success--icon" /> <p>Successful</p>
                    </div>
                  )}
                </div>
                <div>
                  {" "}
                  {isEnabledUsers === "c" && (
                    <div className="deleting--something">
                      {" "}
                      <GiSkullCrossedBones
                        className="success--icon"
                        style={{ fontSize: "30px" }}
                      />{" "}
                      <p>Try Again</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="delete--admin--container__footer">
                <button onClick={() => setShowRemovePayrollModal(false)}>
                  Cancel
                </button>
                <button
                  onClick={() => {
                    dispatch({
                      type: "DELETE_PAYROLL_REQUEST",
                      payload: { id },
                    });
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
        <div className="review--section__footer">
          <div className="review--section__footer--buttons">
            <button
              onClick={() => {
                setShowPaySlipModal(true);
              }}
            >
              {" "}
              <AiFillEye /> View Payslip
            </button>

            <button
              onClick={() => {
                dispatch({
                  type: "SEND_PAYSLIP_REQUEST",
                  payload: {
                    id,
                  },
                });
              }}
            >
              {" "}
              <RiSendPlaneFill /> {sendButtonText}
            </button>

            {payrollInfo.payrollStatus === "STP_PENDING" && (
              <button disabled>
                {" "}
                <RiBroadcastFill /> STP Pending
              </button>
            )}

            {(payrollInfo.payrollStatus === "COMPLETED" ||
              payrollInfo.payrollStatus === "STP_FAILED") && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowSignatureModal(true);
                  dispatch({
                    type: "FETCH_ORGANIZATION_REQUEST",
                  });
                }}
              >
                {" "}
                <RiBroadcastFill /> {reportButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* show signature module */}
      <div
        className={`${
          showSignatureModal
            ? "small--modal--container"
            : "small--modal--container--colosed"
        }`}
      >
        <div className="signature" ref={wrapperRef}>
          {organizationSettingData.payRollDetail !== undefined &&
          organizationSettingData.payRollDetail.enableSTP ? (
            <div className="signature--container">
              <div className="signature--container--terms">
                <p className="signature-para">
                  Before submitting the payroll report, please review the
                  following declaration:
                </p>
                <div className="abdf">
                  <p>
                    This report aligns with the information supplied by the
                    entity.
                  </p>
                  <p>
                    The entity has declared that the information provided is
                    true and correct.
                  </p>
                  <p>
                    I am authorised by the entity to give submit this report to
                    the ATO.
                  </p>
                </div>
              </div>
              <div className="signature--container--terms">
                <p className="signature-para">I am also aware that:</p>
                <div className="abdf">
                  <p>
                    ActivAccount will not be held responsible for the accuracy
                    of my file.
                  </p>
                  <p>
                    By clicking ‘Submit STP’ I am signing this declaration and
                    ActivAccount will submit this report to the ATO with my
                    credentials using ActivAccount's AUSkey.
                  </p>
                </div>
              </div>

              <p>
                Signature Name :{" "}
                <input
                  autoFocus="true"
                  type="text"
                  placeholder="Name"
                  value={signatureName}
                  onChange={(e) => setSignatureName(e.target.value)}
                />{" "}
                <button
                  onClick={() => {
                    let signatoryIdentifier = signatureName;
                    if (signatoryIdentifier.length <= 5) {
                      toast.error(
                        "Signature Name Should be more than 5 charecters !!!"
                      );
                    } else {
                      dispatch({
                        type: "REPORT_STP_REQUEST",
                        payload: {
                          id,
                          signatoryIdentifier,
                        },
                      });
                    }
                  }}
                >
                  {buttonText}
                </button>
                <button
                  className="cancel-ReportStp"
                  onClick={() => setShowSignatureModal(false)}
                >
                  Cancel
                </button>
              </p>
            </div>
          ) : (
            <div>
              <div className="enableStp--error">
                <h2>STP Validation errors</h2>
                <p>
                  You need to enable STP in Settings to lodge the STP report to
                  ATO.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* View paySlip */}
      <div
        className={`${
          showPaySlipModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <ShowPaySlip id={id} />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  setShowPaySlipModal(!showPaySlipModal);
                }}
              >
                <BsX />
              </span>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(ShowReviewPayroll);
