import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import {useDispatch,useSelector} from 'react-redux'

import {AiFillDelete} from 'react-icons/ai'
import {TiTick} from 'react-icons/ti'
import {GiSkullCrossedBones} from 'react-icons/gi'
import { Hero } from '../../Utils'
import { getAccountantType } from '../../FrontendHelper'

function TeamMember() {
    const dispatch = useDispatch()
    const accountantType = getAccountantType()
    const {accountantList,isProgressShow,isPostUpdateCompleted,isEnabledUsers} = useSelector(state=> state.accountantState);
    const [inviteAccountantState,setInviteAccountantState] = useState({
        firstName: "",
        lastName: "",
        accountantEmail: "",
    })
    const [showDeleteModal,setShowDeleteModal] = useState(false);
    const [deletingItem,setDeletingItem] = useState(null)
    const handleStateChange = (e)=> {
        const {name,value} = e.target
        setInviteAccountantState({
            ...inviteAccountantState,
            [name]: value
        })
    }
    console.log("the delting item",deletingItem)
    const handleSendButton = (e)=> {
        e.preventDefault();
        console.log("the values",inviteAccountantState)
        dispatch({
            type: "INVITE_CLIENTS_REQUEST",
            payload: inviteAccountantState
        })
    }
    useEffect(()=> {
        dispatch({
            type: "FETCH_ACCOUNTANT_LIST_REQUEST",
        })
    },[])
    useEffect(()=> {
        if(isPostUpdateCompleted) {
            setInviteAccountantState({
                firstName: "",
                lastName: "",
                accountantEmail: "",
            })
            setShowDeleteModal(false)
            dispatch({
                type: "FETCH_ACCOUNTANT_LIST_REQUEST",
            })

        }
    },[isPostUpdateCompleted])
  return (
   <div>
       {isProgressShow && Hero()}
    <div className="team_member">
    
        <div className="team_member--container sscroll">
        <p>Accountant List :-</p>
            <table >
                <thead>
                    <tr>
                        <td className='team_member--status'>Status</td>
                        <td className='team_member--name'>Name</td>
                        <td className='team_member--email'>Email</td>
                        {/* <td className='team_member--accountantType'>AccountType</td> */}
                        
                        <td className='team_member--action'>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {accountantList?.map((item,index)=> <tr key={index}>
                        <td style={{display: "flex",alignItems: "center",flexWrap: "nowrap"}}><label className={`${item.accountantType === "REQUESTED" ? "status--label_pending" : "status--label"}`}>a</label>{item.accountantType === "REQUESTED" ? "Pending": "Active"}</td>
                        <td>{item.accountantName}</td>
                        <td>{item.accountantEmail}</td>
                        {/* <td style={{textAlign: "center"}}>{item.accountantType}</td> */}
                        
                        {(index === 0 || accountantType !== "MAIN_ACCOUNTANT") ? <td style={{textAlign: "center"}}></td> : <td style={{textAlign: "center"}}><AiFillDelete style={{color: "#3e445e",cursor: "pointer"}} onClick={()=> {
                            setShowDeleteModal(true);
                            setDeletingItem(item)
                        }} /></td>}
                    </tr>)}
                </tbody>
            </table>
            <p><hr /></p>
        </div>
    </div>
    {accountantType === "MAIN_ACCOUNTANT" &&  <div className='invite--accountant'>
        <div className="invite--accountant__container">
            <p>Invite Accountant :-</p>
            <form>
            <div className="invite--accountant__container--input">
                <p><label htmlFor="">First Name* : </label> <input type="text" name='firstName' placeholder='First Name' value={inviteAccountantState.firstName} onChange = {handleStateChange} /></p>
                <p><label htmlFor="">Last Name* : </label> <input type="text" name="lastName" placeholder='Last Name' value={inviteAccountantState.lastName} onChange = {handleStateChange}  /></p>
                <p><label htmlFor="">Accountant Email* : </label> <input type="email" name="accountantEmail" placeholder='Accountant Email' value={inviteAccountantState.accountantEmail} onChange = {handleStateChange}  /></p>
                <button type='submit' onClick={handleSendButton}>Send</button>
            </div>
            </form>
        </div>
        
    </div>}
   
    {/* delete employee */}
    <div className={`${showDeleteModal ? "delete--admin" : "delete--admin__hidden"}`} >
                        <div className="delete--admin--container" >
                            <div className="delete--admin--container__heading"></div>
                            <><div className="delete--admin--container__body">
                                <h1>Are you sure to delete this accountant ?</h1>
                                <div>{isEnabledUsers === "a" && <div className="deleting--something">  <p>Processing...</p></div>}</div>
                                <div> {isEnabledUsers === "b" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div>}</div>
                                <div> {isEnabledUsers === "c" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p>

                                </div>}</div>
                            </div>
                                <div className="delete--admin--container__footer">
                                    <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
                                    <button onClick={()=> {
                                        if(deletingItem.active) {
                                            dispatch({
                                                type: "DELETE_ACCOUNTANT_REQUEST",
                                                payload: {
                                                    type: "accountantId",
                                                    id: deletingItem.accountantId
                                                }
                                            })
                                        } else {
                                            dispatch({
                                                type: "DELETE_ACCOUNTANT_REQUEST",
                                                payload: {
                                                    type: "linkId",
                                                    id: deletingItem.linkRequest
                                                }
                                            })
                                        }
                                        
                                    }}>Ok</button>
                                </div></>

                        </div>
                    </div>

   </div>
  )
}

export default TeamMember