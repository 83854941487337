import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import PaymentTableHeader from "./PaymentTableHeader";
import PaymentTableRowData from "./PaymentTableRowData";

const tableRowsCount = 1;
const styles = StyleSheet.create({
  tableMargin: {
    marginTop: "10px",
  },
});

function PaymentTable({ item }) {
  return (
    <View style={styles.tableMargin}>
      <PaymentTableHeader />
      <PaymentTableRowData desc="Cash" items={item} />
    </View>
  );
}

export default PaymentTable;
