import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

const mapStateToProps = (store) => {
  const { employeeFinaliseStp, updatedEmployeeFinaliseStp } = store.mainState;
  return { employeeFinaliseStp, updatedEmployeeFinaliseStp };
};

const ViewAndAdjustModal = ({
  stpYear,
  employeeFinaliseStp,
  employeeIndex,
  setViewAndAdjustModal,
  updatedEmployeeFinaliseStp,
}) => {
  // console.warn(
  //   "checking the value of the updatedEmployee Finalise_STP",
  //   updatedEmployeeFinaliseStp
  // );

  const dispatch = useDispatch();
  const [FinaliseStpEmployee, setFinaliseStpEmployee] = useState();
  const [entityList, setEntityList] = useState();
  const [allowanceEntityList, setAllowanceEntityList] = useState();
  const [deductionEntityList, setDeducationEntityList] = useState();
  const [lumpSumPaymentsEntityList, setLumpSumPaymentsEntityList] = useState();
  const [
    reportableFringeBenefitsEntityList,
    setReportableFringeBenefitsEntityList,
  ] = useState();
  const [superannuationEntityList, setSuperannuationEntityList] = useState();
  const [paidLeaveEntityList, setPaidLeaveEntityList] = useState();
  const [terminationEntityList, setTerminationEntityList] = useState();

  const [employeeId, setEmployeeId] = useState();
  const [finalizeSTPAdjustmentData, setFinalizeSTPAdjustmentData] = useState(
    []
  );

  // if (allowanceEntityList) {
  //   console.log(
  //     "checking the value of the emplyeee allowance entity list",
  //     allowanceEntityList
  //   );
  // }

  useEffect(() => {
    setFinaliseStpEmployee(updatedEmployeeFinaliseStp);
    setEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedEntityList
    );
    setEmployeeId(
      employeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .employeeDetailResponse.employeeId
    );

    setAllowanceEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedAllowanceEntityList
    );

    setDeducationEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedDeductionsEntityList
    );

    setLumpSumPaymentsEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedLumpSumPaymentsEntityList
    );

    setReportableFringeBenefitsEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedReportableFringeBenefitsEntityList
    );

    setSuperannuationEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedSuperannuationEntityList
    );

    setPaidLeaveEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedPaidLeaveEntityList
    );

    setTerminationEntityList(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
        .finalizedAdjustedTerminationPaymentEntityList
    );
  }, [employeeFinaliseStp]);

  useEffect(() => {
    setFinalizeSTPAdjustmentData(
      updatedEmployeeFinaliseStp.finalizedSTPDataEntityList
    );
  });

  const handleChangeAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...entityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setEntityList(newData);
    } else {
      const newData = [...entityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setEntityList(newData);
    }
  };

  const handleChangeAllowanceAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...allowanceEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setAllowanceEntityList(newData);
    } else {
      const newData = [...allowanceEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setAllowanceEntityList(newData);
    }
  };

  const handleChangeDeductionAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...deductionEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setDeducationEntityList(newData);
    } else {
      const newData = [...deductionEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setDeducationEntityList(newData);
    }
  };

  const handleChangeLumpSumAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...lumpSumPaymentsEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setLumpSumPaymentsEntityList(newData);
    } else {
      const newData = [...lumpSumPaymentsEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setLumpSumPaymentsEntityList(newData);
    }
  };

  const handleChangeReportableAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...reportableFringeBenefitsEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setReportableFringeBenefitsEntityList(newData);
    } else {
      const newData = [...reportableFringeBenefitsEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setReportableFringeBenefitsEntityList(newData);
    }
  };

  const handleChangeSuperAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...superannuationEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setSuperannuationEntityList(newData);
    } else {
      const newData = [...superannuationEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setSuperannuationEntityList(newData);
    }
  };

  const handleChangePaidLeaveAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...paidLeaveEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setPaidLeaveEntityList(newData);
    } else {
      const newData = [...paidLeaveEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setPaidLeaveEntityList(newData);
    }
  };

  const handleChangeTerminationAdjustment = (e, index) => {
    if (e.target.value !== "") {
      const newData = [...terminationEntityList];
      const updatedAdjustment = parseFloat(e.target.value);
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setTerminationEntityList(newData);
    } else {
      const newData = [...terminationEntityList];
      const updatedAdjustment = 0;
      newData[index].adjustment = updatedAdjustment;
      newData[index].finalReport = updatedAdjustment + newData[index].ytd;
      setTerminationEntityList(newData);
    }
  };

  const submitAdjustEmployeeStp = () => {
    const tempFinaliseStpEmployee = { ...FinaliseStpEmployee };
    tempFinaliseStpEmployee.finalizedSTPDataEntityList[
      employeeIndex
    ].finalizedAdjustedEntityList = entityList;

    const isContentEmployee = finalizeSTPAdjustmentData.some((record) =>
      Object.values(record.employeeDetailResponse).includes(employeeId)
    );

    const tempFinalizeSTPAdjustmentData = [...finalizeSTPAdjustmentData];

    if (isContentEmployee) {
      // console.log("we are inside");
      let indexValue;
      finalizeSTPAdjustmentData.map((record, index) => {
        if (record.employeeDetailResponse.employeeId === employeeId) {
          indexValue = index;
        }
      });
      tempFinalizeSTPAdjustmentData[indexValue].finalizedAdjustedEntityList =
        entityList;
    } else {
      // console.log("we are in the else statement");
      tempFinalizeSTPAdjustmentData.push({
        finalizedAdjustedEntityList: entityList,
        finalizedAdjustedAllowanceEntityList: allowanceEntityList,
        finalizedAdjustedDeductionEntityList: deductionEntityList,
        finalizedAdjustedLumpSumPaymentsEntityList: lumpSumPaymentsEntityList,
        finalizedAdjustedReportableFringeBenefitsEntityList:
          reportableFringeBenefitsEntityList,
        finalizedAdjustedSuperannuationEntityList: superannuationEntityList,
        finalizedAdjustedPaidLeaveEntityList: paidLeaveEntityList,
        finalizedAdjustedTerminationPaymentEntityList: terminationEntityList,
        employeeDetailResponse: {
          employeeId: employeeId, 
        },
      });
    }

    dispatch({
      type: "UPDATE_ADJUST_STP_OF_EMPLOYEE",
      payload: {
        // tempFinaliseStpEmployee,
        updatedEmployeeFinaliseStp: {
          finalizedSTPDataEntityList: tempFinalizeSTPAdjustmentData,
          startYear: stpYear[0],
          endYear: stpYear[1],
        },
      },
    });
    console.log(
      "checking the value of the temp finalize stp adhustment data",
      tempFinaliseStpEmployee
    );

    setViewAndAdjustModal(false);
  };

  return (
    <div className="view--adjust--container">
      <p
        style={{ backgroundColor: "#FFE9A0" }}
        className="p-4 mt-4 d-flex flex-row justify-content-between"
      >
        <strong>
          {employeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
            .employeeDetailResponse.personal.firstName +
            " " +
            employeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
              .employeeDetailResponse.personal.lastName}
        </strong>
        <span>
          TFN:{" "}
          {
            employeeFinaliseStp.finalizedSTPDataEntityList[employeeIndex]
              .employeeDetailResponse.tax.tfnNumber
          }
        </span>
      </p>
      <div className="d-flex flex-row-reverse me-4">
        <button
          onClick={submitAdjustEmployeeStp}
          className="btn btn-success btn-lg px-4"
        >
          Done
        </button>
      </div>
      <div className="table-responsive mt-4">
        <table className="table table-hover caption-top">
          <thead>
            <tr style={{ padding: "1rem" }}>
              <th className="px-3">ITEM</th>
              <th className="text-center">YTD</th>
              <th>+</th>
              <th className="text-center">ADJUSTMENT</th>
              <th>=</th>
              <th className="text-center">FINAL REPORT</th>
            </tr>
          </thead>
          <tbody className="table-hover">
            {entityList ? (
              entityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      $ {record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="dashboard--loading">
                <p>1</p>
                <p>2</p>
                <p>3</p>
                <p>4</p>
                <p>5</p>
                <p>6</p>
                <p>6</p>
              </div>
            )}
          </tbody>
          <tbody className="table-hover">
            <caption className="fw-bold text-dark">Allowance</caption>
            {allowanceEntityList &&
              allowanceEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeAllowanceAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tbody className="table-hover">
            <caption className="fw-bold text-dark">Deduction</caption>
            {deductionEntityList &&
              deductionEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeDeductionAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tbody className="table-hover">
            {/* <caption className="fw-bold text-dark">Lump sum payment</caption> */}
            <p className="fw-bold text-dark">Lump sum payment</p>
            {lumpSumPaymentsEntityList &&
              lumpSumPaymentsEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeLumpSumAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tbody className="table-hover">
            {/* <caption className="fw-bold text-dark">
              Reportable Fringe Benefits
            </caption> */}
            <p className="fw-bold text-dark"> Reportable Fringe Benefits</p>
            {reportableFringeBenefitsEntityList &&
              reportableFringeBenefitsEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeReportableAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tbody className="table-hover">
            {/* <caption className="fw-bold text-dark">
              Super Annuation
            </caption> */}
            <p className="fw-bold text-dark">Super Annuation</p>
            {superannuationEntityList &&
              superannuationEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeSuperAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tbody className="table-hover">
            {/* <caption className="fw-bold text-dark">
              Super Annuation
            </caption> */}
            <p className="fw-bold text-dark">Paid Leave</p>
            {paidLeaveEntityList &&
              paidLeaveEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangePaidLeaveAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tbody className="table-hover">
            {/* <caption className="fw-bold text-dark">
              Super Annuation
            </caption> */}
            <p className="fw-bold text-dark">Termination</p>
            {terminationEntityList &&
              terminationEntityList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="col-6 p-4">
                      {record.dataType.charAt(0) +
                        record.dataType
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .slice(1)}
                    </td>
                    <td className="col-2 text-center p-4">
                      ${" "}
                      {record.ytd == null || record.ytd == 0
                        ? 0
                        : record.ytd.toFixed(2)}
                    </td>
                    <td></td>
                    <td className="col-2 p-4">
                      <input
                        style={{
                          borderBottom: "1px dashed blue",
                          backgroundColor: "#F7F7F7",
                          textAlign: "center",
                        }}
                        type="number"
                        min={0}
                        Value={record.adjustment}
                        onChange={(e) => {
                          handleChangeTerminationAdjustment(e, index);
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="col-2 text-center p-4">
                      $ {record.finalReport.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end my-4">
        <button
          onClick={() => setViewAndAdjustModal(false)}
          class="btn me-md-2 btn-lg border px-4"
          type="button"
        >
          Close
        </button>
        <button
          onClick={submitAdjustEmployeeStp}
          class="btn btn-success btn-lg px-4"
          type="button"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(ViewAndAdjustModal);
