import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import TableHeader from "./TableHeader";
import TableBlankSpaces from "./TableBlankSpaces";
import TableFirstRowData from "./TableFirstRowData";
import FirstTableRowThisPayYdt from "./FirstTableRowThisPayYdt";
import CommissionBonousRow from "./CommissionBonousRow";
import TaxRow from "./TaxRow";
import SuperContribution from "./SuperContribution";
import SuperContributionYdt from "./SuperContributionYdt";
import CommissionBonusYdt from "./CommissionBonusYdt";
import TaxRowYdt from "./TaxRowYdt";
import ExtrahoursRow from "./ExtraHoursTable/ExtrahoursRow";
import ExtraHourYdt from "./ExtraHoursTable/ExtraHourYdt";
import AllowanceRow from "./AllowanceTable/AllowanceRow";
import AllowanceYdt from "./AllowanceTable/AllowanceYdt";
import DeductionRow from "./DeductionTable/DeductionRow";
import DeductionYdt from "./DeductionTable/DeductionYdt";

import DefenceLeave from "../Payslip_component/DefenceLeave";
import DefenceLeaveYtd from "../Payslip_component/DefenceLeaveYtd";
import ParentalLeaveRow from "../Payslip_component/ParentalLeave";
import ParentalLeaveYtd from "../Payslip_component/ParentalLeaveYtd";
import DirectorFeeRow from "../Payslip_component/DirectorFee";
import DirectorFeeYtd from "../Payslip_component/DirectorFeeYtd";
import AnnualLeave from "../Payslip_component/AnnualLeave";
import SickLeave from "../Payslip_component/SickLeave";
import WorkerCompensationRow from "../Payslip_component/WorkerCompensationRow";
import ReimbursementRow from "../Payslip_component/ReimbursementRow";
import ChildSupportsRow from "../Payslip_component/ChildSupportRow";

const tableRowsCount = 1;

function FirstTable({ item }) {
  return (
    <View>
      <TableHeader />
      <TableFirstRowData
        heading="SALARY & WAGE"
        desc="Ordinary Hours"
        items={item}
      />

      {item.reimbursement > 0 && (
        <ReimbursementRow items={item} desc="Reimbursement" />
      )}

      {item.annualLeaveTaken && (
        <AnnualLeave heading="LEAVE" desc="Annual Leave" items={item} />
      )}

      {item.sickLeaveTaken && <SickLeave desc="Sick Leave" items={item} />}

      {item.defenceLeave > 0 && (
        <>
          <DefenceLeave desc="Defence Leave" items={item} />
        </>
      )}

      {item.parentalLeave && (
        <>
          <ParentalLeaveRow desc="Parental Leave" items={item} />
        </>
      )}

      {item.directorFee > 0 && (
        <>
          <DirectorFeeRow items={item} heading="FEES" desc="Director Fee" />
        </>
      )}

      {item.allowances !== undefined && item.allowances.length > 0 && (
        <>
          <AllowanceRow heading="Allowance" items={item} />
          <AllowanceYdt items={item} />
        </>
      )}

      <CommissionBonousRow
        heading="BONUS & COMMISSION"
        desc="PAYG"
        items={item}
      />
      <CommissionBonusYdt items={item} />

      {item.childSupports.length > 0 && (
        <ChildSupportsRow
          heading="CHILD SUPPORT"
          childSupports={item.childSupports}
        />
      )}

      {item.deductions !== undefined && item.deductions.length > 0 && (
        <>
          <DeductionRow heading="Deductions" items={item} />
          <DeductionYdt items={item} />
        </>
      )}

      {item.extraHours !== undefined && item.extraHours.length > 0 && (
        <>
          <ExtrahoursRow items={item} heading="Extra Hours" />
          <ExtraHourYdt items={item} />
        </>
      )}

      <SuperContribution
        heading="SUPER CONTRIBUTIONS"
        desc="Super Guarantee"
        items={item}
      />
      <SuperContributionYdt items={item} />

      <TaxRow heading="TAX" desc="PAYG" items={item} />
      <TaxRowYdt items={item} />

      {item.workerCompensation > 0 && (
        <WorkerCompensationRow heading="WORKER COMPENSATION" items={item} />
      )}
    </View>
  );
}

export default FirstTable;
