import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { toast, ToastContainer } from 'react-toastify'
import { useParams } from 'react-router-dom';
import myLogo from '../../../Images/ActivAccount_logo_final-01-01.png'

const mapStateToProps = store => {
    const { buttonText, responseOnPostingEmail, responseAfterValidateOtp, resendButtonText,responseValidateForgotPassOtp, forgotPassportResData } = store.mainState
    return { buttonText, responseOnPostingEmail, responseAfterValidateOtp, resendButtonText,responseValidateForgotPassOtp, forgotPassportResData }
}

function ForgotPassword({ buttonText, responseOnPostingEmail, responseAfterValidateOtp, resendButtonText,responseValidateForgotPassOtp, forgotPassportResData }) {
    const {userType} = useParams()
    const dispatch = useDispatch()
    const [getEmail, setGetEmail] = useState("")
    const [getOtp, setGetOtp] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(getEmail==="") {
            toast.error("Enter your email first...")
        }
        else if(!getEmail.match(regexEmail)){
            toast.error("Enter valid email address")
        } 
        else {
            dispatch({
                type: "GET_EMAIL_FOR_FORGOT_PASSWORD_REQUEST",
                payload: {
                    userType: userType,
                    data: {
                        "email": getEmail,
                    "otpFor":"FORGOT_PASSWORD"
                    }
                }
    
            })
        }
    }

    const handleValidateOtp = (e) => {
        e.preventDefault();
        if(getOtp=== "") {
            toast.error("Enter your OTP.")
        }
         else {
            dispatch({
                type: "VALIDATE_OTP_FORGOT_PASSWORD_REQUEST",
                payload: {
                    userType: userType,
                    data: {
                        "adminId": forgotPassportResData.adminId,
                    "otp": getOtp,
                    "otpFor": "FORGOT_PASSWORD"
                    }
                }
    
            })
        }

    }
        const handleResendOtp = (e) => {
            e.preventDefault();
            dispatch({
                type: "RESEND_OTP_FORPASS_REQUEST",
                payload:{
                    userType: userType,
                    data: {"email": getEmail,
                    "otpFor": "FORGOT_PASSWORD"}
                }

            })

    }

    const validateOtp = () => {
        if (forgotPassportResData.adminId !== undefined) {
            return (
                <form>
                    <div className="signup--getEmail--container">
                        <>
                            <div className="signup--heading">
                                <div className="signup--heading__para">Validate OTP</div>
                                <div className="signup--heading__image">
                                    <img src={myLogo} alt="mylogo" />
                                </div>
                            </div>
                            <p><input type="text" placeholder="Enter Your OTP Pin" value={getOtp} onChange={(e) => setGetOtp(e.target.value)} /></p>
                            <button type="submit" onClick={handleValidateOtp}>{buttonText}</button>
                            <button type="submit" style={{ marginLeft: "20px" }} onClick={handleResendOtp}>{resendButtonText}</button>
                        </>

                    </div>

                </form>
            )
        }
    }
    useEffect(() => {
        if (responseValidateForgotPassOtp.token !== undefined) {
            setTimeout(() => {
                // history.push("/add-information");
                window.location.replace(`/get-newPassword/${userType}`);
            }, 1000)
        }

    }, [responseValidateForgotPassOtp])
    return (
        <div className="signup--getEmail">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
           {forgotPassportResData.adminId === undefined ? <form>
                <div className="signup--getEmail--container">
                    <>
                        <div className="signup--heading">
                            <div className="signup--heading__para">Forgot Password</div>
                            <div className="signup--heading__image">
                                <img src={myLogo} alt="mylogo" />
                            </div>
                        </div>
                        <p><input type="email" placeholder="Enter Your Email" value={getEmail} onChange={(e) => setGetEmail(e.target.value)} /></p>
                        <button type="submit" onClick={handleSubmit}>{buttonText}</button>
                    </>
                </div>

            </form> : validateOtp()}

        </div>
    )
}

export default connect(mapStateToProps, null)(ForgotPassword)

