import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import LeaveTableHeader from "./LeaveTableHeader";
import LeaveTableRowData from "./LeaveTableRowData";

const tableRowsCount = 1;

const styles = StyleSheet.create({
  tableMargin: {
    marginTop: "10px",
  },
});

function LeaveTable({ item }) {
  return (
    <View style={styles.tableMargin}>
      <LeaveTableHeader />
      <LeaveTableRowData
        desc="Sick Leave"
        acured={item.sickLeaveAcquired}
        taken={item.sickLeaveTaken}
        remaining={item.sickLeaveRemaning}
      />
      <LeaveTableRowData
        desc="Annual Leave"
        acured={item.annualLeaveAcquired}
        taken={item.annualLeaveTaken}
        remaining={item.annualLeaveRemaning}
      />
    </View>
  );
}

export default LeaveTable;
