import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {
  authenticateAdminEpic,
  verifyTwoSVEpic,
  getEmailSignUpEpic,
  updateAdminDataEpic,
  fetchEmployeeDataEpic,
  updatingEmployeeEpic,
  fetchLeaveEpic,
  postLeaveEpic,
  updateLeaveEpic,
  deleteLeaveEpic,
  fetchEmployeDropDowneEpic,
  validateOtpEpic,
  additionalInfoEpic,
  postPayRunEpic,
  fetchPayRollEpic,
  postAdjustPayrollEpic,
  fetchOrganizationEpic,
  postOrganizationEpic,
  postPersonalEpic,
  fetchPaymentSettingEpic,
  fetchDashboardDataEpic,
  postEmployeePaymentSettingEpic,
  postingEmployeeEpic,
  fetchAddPayrunDataEpic,
  postAdjustPayroll2Epic,
  fetchPayrollInfoEpic,
  inviteEmployeeEpic,
  submitInvitedEmployeeDataEpic,
  fetchPrivacyEpic,
  fetchPayslipDateEpic,
  UploadOrgLogoEpic,
  resendOtppic,
  reportStpEpic,
  payToEmployeeEpic,
  getEmailForForgotPasswordEpic,
  validateOtpforForgotPassowordEpic,
  enableEmployeeEpic,
  diableEmployeeEpic,
  deleteEmployeeEpic,
  approveEmployeeEpic,
  resendInvitationEpic,
  updateAdjustPayrollEpic,
  editPayRunEpic,
  deletePayrollEpic,
  sendPayslipEpic,
  resendOtpForgotPasspic,
  validateOtpTSVEpic,
  forgotOtpTSVEpic,
  fetchReportEpic,
  fetchLeaveBalanceReportEpic,
  fetchEmployeeSummaryReportEpic,
  fetchActivitySummaryReportEpic,
  searchCompanyNameEpic,
  searchSuperFundNameEpic,
  fetchActivityLogEpic,
  fetchProductInfoEpic,
  fetchCheckOutUrlEpic,
  fetchBillingPriceEpic,
  postPaymentSuccessfullEpic,
  fetchPaymentHistoryEpic,
  cancelSubscriptionEpic,
  inviteOrgAccountantEpic,
  fetchOrgAccountantEpic,
  removeAccountantEpic,

  //update features
  fetchEmployeeFinaliseStp,
  updateAdjustEmployeeFinaliseStp,
  saveEmployeeFinaliseStp,
} from "./myEpics";

import {
  fetchClientEpic,
  inviteOrganizationEpic,
  fetchAccountantListEpic,
  inviteAccountantEpic,
  deleteAccountantEpic,
  postInviteAccountantEpic,
  fetchAccPersonalDataEpic,
} from "./accountantEpics";
import orkReducer from "./orkReducer";
import accountReducer from "./accountantReducer";

export const rootReducer = combineReducers({
  mainState: orkReducer,
  accountantState: accountReducer,
});
export const rootEpic = combineEpics(
  getEmailSignUpEpic,
  validateOtpEpic,
  resendOtppic,
  resendOtpForgotPasspic,
  additionalInfoEpic,
  verifyTwoSVEpic,
  forgotOtpTSVEpic,

  updateAdminDataEpic,

  authenticateAdminEpic,

  fetchDashboardDataEpic,

  fetchEmployeeDataEpic,
  updatingEmployeeEpic,

  fetchLeaveEpic,
  postLeaveEpic,
  updateLeaveEpic,
  deleteLeaveEpic,

  postingEmployeeEpic,
  fetchEmployeDropDowneEpic,

  fetchPayRollEpic,
  fetchAddPayrunDataEpic,
  postPayRunEpic,
  editPayRunEpic,

  postAdjustPayrollEpic,
  updateAdjustPayrollEpic,
  postAdjustPayroll2Epic,
  fetchPayrollInfoEpic,

  // setting
  fetchOrganizationEpic,
  postOrganizationEpic,
  postPersonalEpic,
  fetchPaymentSettingEpic,
  postEmployeePaymentSettingEpic,

  inviteEmployeeEpic,
  submitInvitedEmployeeDataEpic,
  // privacy
  fetchPrivacyEpic,
  // payslip data
  fetchPayslipDateEpic,

  // upload organization logo
  UploadOrgLogoEpic,
  // report stp
  reportStpEpic,
  sendPayslipEpic,
  payToEmployeeEpic,
  getEmailForForgotPasswordEpic,
  validateOtpforForgotPassowordEpic,

  enableEmployeeEpic,
  diableEmployeeEpic,
  deleteEmployeeEpic,
  approveEmployeeEpic,
  resendInvitationEpic,
  deletePayrollEpic,

  // TWO STEP VERIFICATION
  validateOtpTSVEpic,

  // retpors
  fetchReportEpic,
  fetchLeaveBalanceReportEpic,
  fetchEmployeeSummaryReportEpic,
  fetchActivitySummaryReportEpic,
  searchCompanyNameEpic,
  searchSuperFundNameEpic,

  fetchActivityLogEpic,
  fetchOrgAccountantEpic,
  inviteOrgAccountantEpic,

  // billing
  fetchProductInfoEpic,
  fetchBillingPriceEpic,
  fetchCheckOutUrlEpic,
  postPaymentSuccessfullEpic,
  fetchPaymentHistoryEpic,
  cancelSubscriptionEpic,
  removeAccountantEpic,

  // accountant section
  fetchClientEpic,
  inviteOrganizationEpic,
  fetchAccountantListEpic,
  inviteAccountantEpic,
  deleteAccountantEpic,
  postInviteAccountantEpic,
  fetchAccPersonalDataEpic,

  //finalise stp feature
  fetchEmployeeFinaliseStp,
  updateAdjustEmployeeFinaliseStp,
  saveEmployeeFinaliseStp
);
