import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {useSelector} from 'react-redux'
import ActivitySummaryReportHeader from './ActivitySummaryReportHeader';
import ActivitySummaryReportRowData from './ActivitySummaryReportRowData';

const tableRowsCount = 1;

const styles = StyleSheet.create({
    tableMargin: {
        marginTop: "30px"
    }
})

function ActivitySummaryTable({activitySummaryReportData}) {
    // const {activitySummaryReportData} = useSelector(state=> state.mainState)
    // console.log("activitySummaryReportData jpt",activitySummaryReportData)

    return (
        <View style={styles.tableMargin}>
            <ActivitySummaryReportHeader />
            <ActivitySummaryReportRowData activitySummaryReportData={activitySummaryReportData} />
            {/* <EmployeeContactsRowData reportData={reportData} /> */}
            {/* <LeaveTableRowData  desc="Sick Leave"  acured={item.sickLeaveAcquired} taken={item.sickLeaveTaken} remaining={item.sickLeaveRemaning} />
            <LeaveTableRowData  desc="Annual Leave" acured={item.annualLeaveAcquired} taken={item.annualLeaveTaken} remaining={item.annualLeaveRemaning} /> */}
        </View>
    )
}

export default ActivitySummaryTable

