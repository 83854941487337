import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import { ImUsers } from "react-icons/im";
import { MdReportProblem } from "react-icons/md";
import { AiTwotoneApi } from "react-icons/ai";
import { IoSettings } from "react-icons/io5";
import { FaRunning } from "react-icons/fa";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import {
  HiOutlineDocumentDuplicate,
  HiOutlinePlusCircle,
  HiTrendingUp,
} from "react-icons/hi";
import { BsQuestionSquare, BsLock } from "react-icons/bs";
import { toogleSidebar } from "../../Redux/actions";
import { BsX, BsChevronDoubleRight } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import AddAdmin from "../Admin Controller/AddAdmin";

import AddLeave from "../Leave/AddLeave";
import AddEmployee from "../Employee/AddEmployee";
import {
  getLoginType,
  getOrganizationId,
  getOrginalAccFirstName,
  getOrginalAccLastName,
  pushToAccountantPage,
} from "../../FrontendHelper";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    isAddCouponModal,
    isAddReataurantModal,
    isAddAdminRegisterModal,
    isAddCategoryModal,
    isAddMenuItemModal,
    isServiceRateModal,
    adminProfileData,
    payRollStatus,
  } = store.mainState;
  return {
    isToogleSidebar,
    isAddCouponModal,
    isAddReataurantModal,
    isAddAdminRegisterModal,
    isAddCategoryModal,
    isAddMenuItemModal,
    isServiceRateModal,
    adminProfileData,
    payRollStatus,
  };
};

function Sidebar({
  isToogleSidebar,
  isAddCouponModal,
  isAddReataurantModal,
  isAddAdminRegisterModal,
  isAddCategoryModal,
  isAddMenuItemModal,
  isServiceRateModal,
  adminProfileData,
  payRollStatus,
}) {
  const [people, setPeople] = useState(false);
  const [leave, setLeave] = useState(false);
  const [reports, setReports] = useState(false);
  const [setting, setSetting] = useState(false);
  const organizationId = getOrganizationId();
  const dispatch = useDispatch();
  const userType = getLoginType();
  const firstName = getOrginalAccFirstName();
  const lastName = getOrginalAccLastName();

  // console.log("The ORIGNAL name",{
  //     firstName,lastName
  // })
  const handleFetchDashboard = () => {
    if (payRollStatus === "Draft") {
      dispatch({
        type: "FETCH_PAYROLL_DATA_REQUEST",
        payload: {
          organizationId,
        },
      });
    }
  };

  return (
    <>
      {" "}
      <section id={`${isToogleSidebar ? "sidebar_sm" : "sidebar"}`}>
        <div className={`${isToogleSidebar ? "_sidebar_sm" : "_sidebar"}`}>
          <div className="_sidebar--main__para">Main</div>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <div
              className="_sidebar--main__dashboard"
              onClick={handleFetchDashboard}
            >
              <ul style={{ color: "white" }}>
                <span>
                  <FaRunning />
                </span>
                Run Payroll
              </ul>
            </div>
          </Link>

          {/* <div className="_sidebar--main__para">Components</div> */}
          <div
            className={`${
              people
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setPeople(!people)}
          >
            <ul>
              <span>
                <ImUsers />
              </span>{" "}
              Employee{" "}
              {people ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              people ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li style={{ color: "white" }}>
              {" "}
              <Link
                to="employee"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Manage Employee{" "}
              </Link>
              <span>
                <HiOutlinePlusCircle
                  style={{ color: "white" }}
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_CATEGORY_MODAL" })}
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              leave
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setLeave(!leave)}
          >
            <ul>
              <span>
                <AiTwotoneApi />
              </span>{" "}
              Leave{" "}
              {leave ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              leave ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              {" "}
              <Link
                to="leave"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Manage Leave
              </Link>
              <span>
                <HiOutlinePlusCircle
                  style={{ color: "white" }}
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_MENU_ITEM_MODAL" })}
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              reports
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setReports(!reports)}
          >
            <ul>
              <span>
                <TiDocumentText />
              </span>{" "}
              Reports{" "}
              {reports ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              reports ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              {" "}
              <Link
                to="/reports"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Reports
              </Link>
            </li>
          </div>
          <div
            className={`${
              setting
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setSetting(!setting)}
          >
            <ul>
              <span>
                <IoSettings />
              </span>{" "}
              Settings{" "}
              {setting ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              setting ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link
                to="setting"
                style={{ textDecoration: "none", color: "white" }}
              >
                Settings
              </Link>
            </li>
          </div>
          {userType !== "admin" && (
            <div className="myBackbotton">
              <button
                onClick={() => {
                  pushToAccountantPage({
                    firstName: firstName,
                    lastName: lastName,
                  });
                  toast.info("Redirecting to the accountant home page....");
                  setTimeout(() => {
                    window.location.replace("dashboard");
                  }, 1000);
                }}
              >
                Go to Accountant
              </button>
            </div>
          )}
        </div>
      </section>
      {/* add employee */}
      <div
        className={`${
          isAddCategoryModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddEmployee />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_CATEGORY_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add leave modal */}
      <div
        className={`${
          isAddMenuItemModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddLeave />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_MENU_ITEM_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toogleSidebar: () => dispatch(toogleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
