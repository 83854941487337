import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const { buttonText, addPayrunDatePeriod } = store.mainState;
  return { buttonText, addPayrunDatePeriod };
};

function RunPayroll({ buttonText, addPayrunDatePeriod, item }) {
  const [payRunPeriod, setPayRunPeriod] = useState("WEEKLY");
  const [payPeriodEndDate, setPayPeriodEndDate] = useState(new Date());
  const [paymentDate, setPaymentDate] = useState(new Date());
  const dispatch = useDispatch();
  const organizationId = item?.linkedOrganizationId;

  useEffect(() => {
    if (addPayrunDatePeriod.payrollEndPeriod) {
      setPayRunPeriod(addPayrunDatePeriod?.payPeriod);
      setPayPeriodEndDate(new Date(addPayrunDatePeriod?.payrollEndPeriod));
    }
  }, [addPayrunDatePeriod]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: "FETCH_ADD_PAYRUN_DATA_REQUEST",
        payload: {
          organizationId,
        },
      });
    }
  }, [organizationId]);

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    if (payPeriodEndDate === null) {
      toast.error("Pay period end date can not be empty");
    } else if (paymentDate === null) {
      toast.error("Payment date can not be empty");
    } else {
      dispatch({
        type: "POST_PAY_RUN_REQUEST",
        payload: {
          period: payRunPeriod,
          payPeriodEnding: dayjs(payPeriodEndDate).format("YYYY-MM-DD"),
          paymentDate: dayjs(paymentDate).format("YYYY-MM-DD"),
          organizationId: organizationId,
        },
      });
    }
  };

  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Add New Pay Run</p>
      </div>
      <div className="payPeriod--container">
        <div className="payPeriod--container--para">
          <p>Let's pay your Employees !!!</p>
          <p>Pay Period</p>
          <p>
            {dayjs(addPayrunDatePeriod.startDate).format("DD-MMM-YYYY")} ~{" "}
            {dayjs(addPayrunDatePeriod.payrollEndPeriod).format("DD-MMM-YYYY")}
          </p>
        </div>
      </div>
      <div className="Modal--form" style={{ marginTop: "-20px" }}>
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Pay Run Period * : </label>
              <select
                onChange={(e) => setPayRunPeriod(e.target.value)}
                value={payRunPeriod}
              >
                <option value="WEEKLY">Weekly</option>
                <option value="FORTNIGHTLY">Fortnightly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </p>
          </div>
          {payPeriodEndDate && (
            <div className="customInput--title">
              <p>Pay Period Ending * :</p>
              <p>
                <DatePicker
                  selected={payPeriodEndDate}
                  onChange={(date) => setPayPeriodEndDate(date)}
                  disabledKeyboardNavigation
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd / mm / yyyy"
                  dateFormat="dd/MM/yyyy"
                />
              </p>
            </div>
          )}

          {paymentDate && (
            <div className="customInput--title">
              <p>Payment Date * :</p>
              <p>
                <DatePicker
                  selected={paymentDate}
                  onChange={(date) => setPaymentDate(date)}
                  disabledKeyboardNavigation
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd / mm / yyyy"
                  dateFormat="dd / MM / yyyy"
                />
              </p>
            </div>
          )}
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              onClick={handleAddCategorySubmit}
              style={{ marginBottom: "50px" }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(RunPayroll);
