import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function MyChart({netPay,taxPay,superPay}) {
  let sum = Number(netPay) + Number(taxPay)+ Number(superPay)
  console.log("The sum",sum)
    const data = {
        datasets: [
          {
            data: [Number(netPay)/sum*100, Number(taxPay)/sum*100, Number(superPay)/sum*100],
            backgroundColor: [
              '#d3a518',
              '#6f7490',
              '#e8efff',
            ],
            borderColor: [
                '#d3a518',
                '#6f7490',
                '#e8efff'
            ],
            borderWidth: 1,
          },
        ],
      };
    return (
        <div className='my--pyChart'>
            <Doughnut data={data} />
            <hr />
            <div className="my--pyChart--para">
            <p><span>co</span>Net Pay</p>
            <p><span>co</span>Tax</p>
            <p><span>co</span>Super</p>
            </div>
        </div>
    )
}

export default MyChart
