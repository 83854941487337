import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import myLogo from "../../Images/active account_white-02.png";
import { DiCelluloid } from "react-icons/di";
import { BiSearch } from "react-icons/bi";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = (store) => {
  const { buttonText, isLoadingOnLogin, searchedData } = store.mainState;
  return { buttonText, isLoadingOnLogin, searchedData };
};
function InviteOrganization({ buttonText, isLoadingOnLogin, searchedData }) {
  const [clientRange, setClientRange] = useState(null);

  const [searchWord, setSearchWord] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [businessName, setBusinessName] = useState(null);
  const [abn, setAbn] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [contactNo, setContactNo] = useState(null);
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState(null);
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostcode] = useState("");
  const [myState, setMyState] = useState("");

  const [payrolStartDate, setPayrolStartDate] = useState(null);
  const [hoursPerDay, setHoursPerDay] = useState(null);
  const [payPeriod, setPayPeriod] = useState("FORTNIGHTLY");

  const [alsl, setAlsl] = useState(false);
  const [albl, setAlbl] = useState(false);
  const [pclb, setPclb] = useState(false);
  const [enableSTP, setEnableSTP] = useState(false);

  const dispatch = useDispatch();

  const handleSubmitButton = (e) => {
    e.preventDefault();
    if (firstName === "") {
      toast.error("Enter your first name");
    } else if (lastName === "") {
      toast.error("Enter your last name");
    } else if (password === "") {
      toast.error("Enter your password");
    } else if (confirmPassword === "") {
      toast.error("Enter your confirm password");
    } else if (password !== confirmPassword) {
      toast.error("Your password and confirm password doesn't match");
    } else if (businessName == null) {
      toast.error("Enter your business name");
    } else if (abn == null) {
      toast.error("Enter your ABN");
    } else if (abn.length !== 11) {
      toast.error("Your ABN should be exactly 11 digits");
    } else if (companyName === "") {
      toast.error("Enter your company name");
    } else if (contactNo == null) {
      toast.error("Enter your contact number");
    } else if (contactNo.length < 9 || contactNo.length > 10) {
      toast.error("Contact number can be exactly 9 or 10 digit");
    } else if (addressOne === "") {
      toast.error("Enter your address one");
    } else if (suburb === "") {
      toast.error("Enter your suburb");
    } else if (postCode === "") {
      toast.error("Enter your postcode");
    } else if (myState === "") {
      toast.error("Select your state");
    } else if (payrolStartDate == null) {
      toast.error("Enter your payroll start date");
    } else if (hoursPerDay == null) {
      toast.error("Enter your hours per day");
    } else if (hoursPerDay <= 0) {
      toast.error("Hours per day value must be greater than 0");
    } else if (hoursPerDay > 24) {
      toast.error("Hours per day value can not be greater than 24");
    } else {
      dispatch({
        type: "ADDITIONAL_INFO_REQUEST",
        payload: {
          userType: "admin",
          value: {
            password: password,
            conformPassword: confirmPassword,
            firstName: firstName,
            lastName: lastName,
            isFirstAccount: "true",
            organization: {
              organizationDetail: {
                businessName: businessName,
                abnNumber: abn,
                companyLegalName: companyName,
                addressOne: addressOne,
                addressTwo: addressTwo,
                suburb: suburb,
                postCode: postCode,
                state: myState,
                contactNumber: contactNo,
              },
              payRollDetail: {
                hoursPerDay: Number(hoursPerDay),
                payrollStartDate: dayjs(payrolStartDate).format("YYYY-MM-DD"),
                payPeriod: payPeriod,
                enableSTP: enableSTP,
              },
              leaveSettings: {
                allowLongServiceLeave: alsl,
                annualLeaveBalance: albl,
                personalCarerLeaveBalance: pclb,
              },
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (buttonText === "Submitted") {
      setTimeout(() => {
        window.location.replace("/");
        dispatch({
          type: "LOG_OUT_REQUEST",
        });
      }, 2000);
    }
  }, [buttonText]);

  return (
    <div className="information">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="information--container">
        <form>
          <p className="main--title">
            <DiCelluloid /> Personal Details
          </p>
          <div className="form--container">
            {/* <p className="sub--title">Organization Details :-</p> */}
            <div className="organization--field">
              <div className="organization--field--name">
                <p>First Name* : </p>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
              </div>
              <div className="organization--field--para">
                <p>Last Name*: </p>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="organization--field">
              <div className="organization--field--name">
                <p>Password* : </p>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="organization--field--para">
                <p>Confirm Password* : </p>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <hr />
            {/* <p className="sub--title">Organization Details :-</p> */}
            <div className="searchCompany">
              <p>
                <input
                  type="text"
                  placeholder="Search Company"
                  value={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                <span className="search-icons">
                  <BiSearch />
                </span>
              </p>
              <p
                onClick={() => {
                  dispatch({
                    type: "SEARCH_COMPANY_NAME_REQUEST",
                    payload: {
                      searchWord,
                    },
                  });
                }}
              >
                Search
              </p>
            </div>
            <div className="showSearchCompany">
              <div className="showSearchCompany--container">
                {isLoadingOnLogin && (
                  <>
                    <div className="myLoader">
                      <div class="lds-ripple">
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </>
                )}
                <div className="company--details">
                  {searchedData.length > 0 &&
                    searchedData?.map((item) => (
                      <div
                        className="company--details--item"
                        onClick={() => {
                          setCompanyName(item.name);
                          setAbn(item.abnNumber);
                          setPostcode(item.postCode);
                          setMyState(item.state);
                          setSearchWord("");
                          dispatch({
                            type: "SET_SEARCH_DATA_EMPTY",
                          });
                        }}
                      >
                        <p>{item.name}</p>
                        <p>{item.abnNumber}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {abn !== null && (
              <>
                <div className="organization--field">
                  <div className="organization--field--name">
                    <p>Company Name* : </p>
                    <input
                      type="text"
                      placeholder="Company Name"
                      style={{ cursor: "not-allowed" }}
                      disabled
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>

                  <div className="organization--field--para">
                    <p>ABN* : </p>
                    <input
                      type="number"
                      style={{ cursor: "not-allowed" }}
                      disabled
                      placeholder="ABN"
                      value={abn}
                      onChange={(e) => setAbn(e.target.value)}
                    />
                  </div>
                </div>
                <div className="organization--field">
                  <div className="organization--field--name">
                    <p>Business Name* : </p>
                    <input
                      type="text"
                      placeholder="Business Name"
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  </div>
                  <div className="organization--field--para">
                    <p>Contact Number* : </p>
                    <input
                      type="number"
                      placeholder="Contact Number"
                      value={contactNo}
                      onChange={(e) => setContactNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="organization--field">
                  <div className="organization--field--name">
                    <p>Address One* : </p>
                    <input
                      type="text"
                      placeholder="Address One"
                      value={addressOne}
                      onChange={(e) => setAddressOne(e.target.value)}
                    />
                  </div>
                  <div className="organization--field--para">
                    <p>Address Two(Opt) : </p>
                    <input
                      type="text"
                      placeholder="Address Two"
                      value={addressTwo}
                      onChange={(e) => setAddressTwo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="organization--field">
                  <div className="organization--field--name">
                    <p>Suburb* : </p>
                    <input
                      type="text"
                      placeholder="Suburb"
                      value={suburb}
                      onChange={(e) => setSuburb(e.target.value)}
                    />
                  </div>
                  <div className="organization--field--para">
                    <p>Post Code* : </p>
                    <input
                      type="number"
                      placeholder="Post Code"
                      value={postCode}
                      onChange={(e) => setPostcode(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className="organization--field"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="organization--field--name">
                    <p>State* : </p>
                    <select onChange={(e) => setMyState(e.target.value)}>
                      <option>Select</option>
                      <option value="ACT">ACT</option>
                      <option value="NSW">NSW</option>
                      <option value="NT">NT</option>
                      <option value="QLD">QLD</option>
                      <option value="SA">SA</option>
                      <option value="TAS">TAS</option>
                      <option value="VIC">VIC</option>
                      <option value="WA">WA</option>
                    </select>
                  </div>
                  <div className="organization--field--para"></div>
                </div>
              </>
            )}
            <hr />
            <p className="sub--title">Payroll Details :-</p>
            <div className="organization--field">
              <div className="organization--field--name">
                <p>Payroll Start Date* : </p>
                {/* <input type="date" placeholder="Suburb" value={payrolStartDate} onChange={(e) => setPayrolStartDate(e.target.value)} /> */}
                <p className="org--inputDate">
                  <DatePicker
                    selected={payrolStartDate}
                    onChange={(date) => setPayrolStartDate(date)}
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText="dd / mm / yyyy"
                    dateFormat="dd / MM / yyyy"
                  />
                </p>
              </div>
              <div className="organization--field--para">
                <p>Hours Per Day* : </p>
                <input
                  type="number"
                  placeholder="Hours Per Day"
                  value={hoursPerDay}
                  onChange={(e) => setHoursPerDay(e.target.value)}
                />
              </div>
            </div>
            <div
              className="organization--field"
              style={{ marginBottom: "30px" }}
            >
              <div className="organization--field--name">
                <p>Pay Period* : </p>
                <select onChange={(e) => setPayPeriod(e.target.value)}>
                  <option value="WEEKLY">WEEKLY</option>
                  <option value="FORTNIGHTLY">FORTNIGHTLY</option>
                  <option value="MONTHLY">MONTHLY</option>
                </select>
              </div>
              <div className="organization--field--para">
                <p style={{ marginTop: "40px" }}>
                  <input
                    type="checkbox"
                    value={enableSTP}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    onChange={(e) => setEnableSTP(e.target.checked)}
                  />{" "}
                  <label>Enable STP</label>
                </p>
              </div>
            </div>
            <hr />
            <p className="sub--title">Leave Setting :-</p>
            <div className="organization--checkbox">
              <p>
                <input
                  type="checkbox"
                  value={alsl}
                  onChange={(e) => setAlsl(e.target.checked)}
                />{" "}
                <label>Allow Long Service Leave</label>
              </p>
              <p>
                <input
                  type="checkbox"
                  value={albl}
                  onChange={(e) => setAlbl(e.target.checked)}
                />{" "}
                <label>Annual Leave Balance</label>
              </p>
              <p>
                <input
                  type="checkbox"
                  value={pclb}
                  onChange={(e) => setPclb(e.target.checked)}
                />{" "}
                <label>Personal Carrier Leave Balance</label>
              </p>
            </div>
            <hr />
          </div>
          <div className="submit--button">
            <button type="submit" onClick={handleSubmitButton}>
              {buttonText}
            </button>
            <p>
              By clicking Submit, you agree to our{" "}
              <a
                href="https://app.activaccount.com.au/terms-of-service"
                target="_blank"
                rel="noreferrer noopener"
              >
                terms of services
              </a>{" "}
              and{" "}
              <a
                href="https://app.activaccount.com.au/privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                privacy policy
              </a>
              .
            </p>
          </div>
        </form>
      </div>

      <div className="information--logo">
        <p>Manage your Employee Payment in Easy and Fast Way </p>
        <p>
          <img src={myLogo} alt="Logo" />
        </p>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(InviteOrganization);
