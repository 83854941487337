import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        margin: "0px 20px",
        flexDirection: 'row',
        borderBottomColor: '#606683ad',
        backgroundColor: '#606683ad',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        color: "white"
    },
    name: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    email: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    phone: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    address: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
  });

  const EmployeeSummaryHeader = () => (
    <View style={styles.container}>
        <Text style={styles.name}>Employee Name</Text>
        <Text style={styles.email}>Earnings</Text>
        <Text style={styles.phone}>Tax</Text>
        <Text style={styles.address}>Super</Text>
        <Text style={styles.address}>Net Pay</Text>
        <Text style={styles.address}>Amount Paid</Text>
    </View>
  );
  
  export default EmployeeSummaryHeader

