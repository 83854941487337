import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { BsPlus } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { FcBusinessman } from "react-icons/fc";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";
import { getOrganizationId } from "../../FrontendHelper";
import { RiSimCardFill } from "react-icons/ri";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers } = store.mainState;
  return { buttonText, isEnabledUsers };
};

function AdjustPayrun({
  buttonText,
  item,
  payrollId,
  myPayRunResponse,
  isEnabledUsers,
}) {
  const [workHours, setWorkHours] = useState("");
  const [workRate, setWorkRate] = useState("");
  const organizationId = getOrganizationId();

  const [extraHours, setExtraHours] = useState([]);
  const [allowence, setAllowence] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [annualLeave, setAnnualLeave] = useState(null);
  const [isToilDetailsModalOpen, setIsToilDetailsModalOpen] = useState(false);
  const [salarySacrifice, setSalarySacrifice] = useState(0);

  //   added functionality
  const [toilAccrued, settoilAccrued] = useState(0);
  const [toilDto, setToilDto] = useState({
    accruedCashedOut: false,
    accruedCashedOutHours: null,
    accruedCashedOutRate: null,
    cashedOut: false,
    toilHours: 0,
  });

  const [reimbursement, setReimbursement] = useState(null);
  const [directorFee, setDirectorFee] = useState(null);
  const [childSupports, setChildSupport] = useState([]);
  const [workerCompensation, setWorkerCompensation] = useState({
    compensationAmount: null,
    compensationType: null,
  });

  const [parentalLeave, setParentalLeave] = useState(null);
  const [defenceLeave, setDefenceLeave] = useState(null);
  const [termination, setTermination] = useState({
    terminationDate: null,
    terminationType: null,
    terminationUnusedLeaves: null,
    terminationCostPerUnit: null,
    redundancyType: null,
    etpType: null,
  });

  const [sickLeave, setSickLeave] = useState(null);
  const [bonousCommissionOption, setBonouscommissionOption] = useState(null);
  const [bonousAmmount, setBonousAmount] = useState(null);
  const [netPay, setNetPay] = useState(0);

  // buttons
  const [isExtraHours, setIsExtraHours] = useState(false);
  const [isAnnualLeave, setIsAnnualLeave] = useState(false);
  const [isSickLeave, setIsSickLeave] = useState(false);
  const [isAllowence, setIsAllowence] = useState(false);
  const [isDeduction, setIsDeduction] = useState(false);
  const [isBonousCommission, setIsBonousComission] = useState(false);
  const [isSalarySacrifice, setIsSalarySacrifice] = useState(false);

  //added features on button
  const [isToilHours, setIsToilHours] = useState(false);
  const [isReimbursement, setIsReimbursement] = useState(false);
  const [isDiretorFee, setIsDirectorFee] = useState(false);
  const [isBonousSalarySacrifiesToSuper, setIsBonousSalarySacrifiesToSuper] =
    useState(false);
  const [isChildSupportType, setIschildSupportType] = useState(false);
  const [isWorkerCompensation, setIsworkerCompensation] = useState(false);
  const [isParentalLeave, setIsParentalLeave] = useState(false);
  const [isDefenceLeave, setIsDefenceLeave] = useState(false);
  const [isTermination, setIsTermination] = useState(false);

  const dispatch = useDispatch();
  const handleAddExtraHours = () => {
    setIsExtraHours(true);
    setExtraHours([
      ...extraHours,
      {
        option: "ORDINARY_HOURS",
        note: "",
        hours: 0,
        rate: 0,
      },
    ]);
  };

  // code of toil hours
  const handleAddToilHours = () => {
    setIsToilHours(true);
  };

  //code of bonous
  const handleAddReimbursement = () => {
    setIsReimbursement(true);
  };

  const handleAddAllowence = () => {
    setIsAllowence(true);
    setAllowence([
      ...allowence,
      {
        unit: 0,
        costPerUnit: 0,
        allowanceOption: "SUBJECT_TO_TAX_AND_SUPER",
        allowanceType: "CAR",
      },
    ]);
  };
  const handleAddDeduction = () => {
    setIsDeduction(true);
    setDeduction([
      ...deduction,
      {
        option: "PRE_TAX_DEDUCTION",
        type: "FEE",
        amount: 0,
      },
    ]);
  };

  const handleAddChildSupport = () => {
    setIschildSupportType(true);
    setChildSupport([
      ...childSupports,
      {
        supportType: "DEDUCTION",
        supportRate: null,
      },
    ]);
  };

  const handleChildSupportInput = (index, event) => {
    let values = [...childSupports];
    values[index][event.target.name] = event.target.value;
    setChildSupport(values);
  };

  const handleExtraHoursInput = (indexExtra, event) => {
    let values = [...extraHours];
    values[indexExtra][event.target.name] = event.target.value;
    setExtraHours(values);
  };

  const handleAddDirectorFee = () => {
    setIsDirectorFee(true);
  };

  const handleRemoveDirectorFee = () => {
    setIsDirectorFee(false);
    setDirectorFee(null);
  };

  const handleRemoveChildSupport = (index) => {
    let values = [...childSupports];
    values.splice(index, 1);
    setChildSupport(values);
  };

  const handleRemoveSalarySacrifice = () => {
    setIsSalarySacrifice(false);
    setSalarySacrifice(0);
  };

  const handleAddWorkerCompensation = () => {
    setIsworkerCompensation(true);
  };

  const handleRemoveAllWorkerCompensation = () => {
    setIsworkerCompensation(false);
    setWorkerCompensation((prev) => {
      return { ...prev, compensationAmount: null, compensationType: null };
    });
  };

  const handleWorkerCompensationInput = (event) => {
    setWorkerCompensation((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleRemoveParentaLeave = () => {
    setIsParentalLeave(false);
    setParentalLeave(null);
  };

  const handleRemoveDefenceLeave = () => {
    setIsDefenceLeave(false);
    setDefenceLeave(null);
  };

  const handleRemoveTermination = () => {
    setIsTermination(false);
    setTermination((prev) => {
      return {
        ...prev,
        terminationDate: null,
        terminationReason: null,
        unUsedAnnualLeave: null,
        unUsedAnnualAmount: null,
        RedundancyTypeO: null,
        RedundancyTypeR: null,
      };
    });
  };

  const handleTerminationInput = (event) => {
    setTermination((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleAllowenceInput = (index, event) => {
    let values = [...allowence];
    values[index][event.target.name] = event.target.value;
    setAllowence(values);
  };
  const handleDeductionInput = (index, event) => {
    let values = [...deduction];
    values[index][event.target.name] = event.target.value;
    setDeduction(values);
  };

  const handleRemoveExtrahours = (indexExtra) => {
    let values = [...extraHours];
    values.splice(indexExtra, 1);
    setExtraHours(values);
  };

  //toilpay code
  const handleRemoveToilPay = () => {
    setIsToilHours(false);
    setToilDto((prev) => {
      return {
        ...prev,
        accruedCashedOut: false,
        accruedCashedOutHours: 0,
        accruedCashedOutRate: 0,
        cashedOut: false,
        toilHours: 0,
      };
    });
  };

  const handleRemoveReimbursement = () => {
    setIsReimbursement(false);
    setReimbursement(null);
  };

  const handleRemoveAllowence = (index) => {
    let values = [...allowence];
    values.splice(index, 1);
    setAllowence(values);
  };
  const handleRemoveDeduction = (index) => {
    let values = [...deduction];
    values.splice(index, 1);
    setDeduction(values);
  };

  useEffect(() => {
    setWorkHours(item.workHours);
    setWorkRate(item.payRate);
    setNetPay(item.workHours * item.payRate);

    setAnnualLeave(item.annualLeaveTakenHour);
    setSickLeave(item.sickLeaveHours);
    setExtraHours(item.extraHours ? item.extraHours : []);
    setAllowence(item.allowances ? item.allowances : []);
    setDeduction(item.deductions ? item.deductions : []);

    setChildSupport(item.childSupports ? item.childSupports : []);
    setBonousAmount(item.bonusCommissionAmount);
    setBonouscommissionOption(item.bonusOption);
    settoilAccrued(item?.toilLeaveAcquired ? item?.toilLeaveAcquired : 0);
    setToilDto(item?.toilDto);

    setReimbursement(item.reimbursement);
    setDirectorFee(item.directorFee);
    setSalarySacrifice(item?.salarySacrifice);
    setParentalLeave(item.parentalLeave);
    setDefenceLeave(item.defenceLeave);
    setWorkerCompensation(
      item.workerCompensation
        ? item.workerCompensation
        : {
            compensationAmount: null,
            compensationType: null,
          }
    );
    setTermination(
      item.termination
        ? item.termination
        : {
            terminationDate: null,
            terminationType: null,
            terminationUnusedLeaves: null,
            terminationCostPerUnit: null,
            redundancyType: null,
            etpType: null,
          }
    );

    if (item.childSupports) {
      setIschildSupportType(true);
    } else {
      setIschildSupportType(false);
    }

    if (item.annualLeaveTakenHour) {
      setIsAnnualLeave(true);
    } else {
      setIsAnnualLeave(false);
    }

    if (item.sickLeaveHours) {
      setIsSickLeave(true);
    } else {
      setIsSickLeave(false);
    }

    if (item.extraHours) {
      setIsExtraHours(true);
    } else {
      setIsExtraHours(false);
    }

    if (item.allowances) {
      setIsAllowence(true);
    } else {
      setIsAllowence(false);
    }

    if (item.deductions) {
      setIsDeduction(true);
    } else {
      setIsDeduction(false);
    }

    if (item.bonusCommissionAmount) {
      setIsBonousComission(true);
    } else {
      setIsBonousComission(false);
    }
    if (item.toilDto?.toilHours) {
      setIsToilHours(true);
    } else {
      setIsToilHours(false);
    }

    if (item.reimbursement) {
      setIsReimbursement(true);
    } else {
      setIsReimbursement(false);
    }

    if (item.directorFee) {
      setIsDirectorFee(true);
    } else {
      setIsDirectorFee(false);
    }

    if (item?.salarySacrifice) {
      setIsSalarySacrifice(true);
    } else {
      setIsSalarySacrifice(false);
    }

    if (item.parentalLeave) {
      setIsParentalLeave(true);
    } else {
      setIsParentalLeave(false);
    }

    if (item.defenceLeave) {
      setIsDefenceLeave(true);
    } else {
      setIsDefenceLeave(false);
    }

    if (item.workerCompensation?.compensationAmount) {
      setIsworkerCompensation(true);
    } else {
      setIsworkerCompensation(false);
    }

    if (item.termination?.terminationDate) {
      setIsTermination(true);
    } else {
      setIsTermination(false);
    }

    if (item.sickLeaveHours !== undefined) {
      setSickLeave(item?.sickLeaveHours);
    } else {
      setSickLeave(0);
    }
    if (item.annualLeaveHours !== undefined) {
      setAnnualLeave(item?.annualLeaveHours);
    } else {
      setAnnualLeave(0);
    }
  }, [item]);

  const handleAdjustSbmit = (e) => {
    e.preventDefault();

    if (payrollId === null) {
      if (workHours === null) {
        toast.error("Enter work hours");
      } else if (workHours < 0) {
        toast.error("Work hours shouldn't less than 0");
      } else if (workRate === null) {
        toast.error("Enter work rate");
      } else if (workRate <= 0) {
        toast.error("Work rate shouldn't less than 0");
      } else {
        dispatch({
          type: "POST_ADJUST_PAYROLL_REQUEST",
          payload: {
            organizationId: organizationId,
            payPeriodStarting: myPayRunResponse.payPeriodStarting,
            payPeriodEnding: myPayRunResponse.payPeriodEnding,
            paymentDate: myPayRunResponse.paymentDate,
            period: myPayRunResponse.period,
            isPayrollFinished: "false",
            employees: [
              {
                employeeId: item.employeeId,
                workHours: Number(workHours),
                payRate: Number(workRate),
                extraHours: extraHours,
                toilDto: toilDto,
                directorFee: Number(directorFee),
                salarySacrifice: salarySacrifice,
                reimbursement: Number(reimbursement),
                childSupports: childSupports,
                workerCompensation: workerCompensation,
                parentalLeave: Number(parentalLeave),
                defenceLeave: Number(defenceLeave),
                termination: termination,

                annualLeaveHours: isAnnualLeave ? Number(annualLeave) : 0,
                sickLeaveHours: isSickLeave ? Number(sickLeave) : 0,
                bonusCommissionAmount: isBonousCommission
                  ? Number(bonousAmmount)
                  : 0,
                bonusOption: bonousCommissionOption,
                allowances: allowence,
                deductions: deduction,
              },
            ],
          },
        });
      }
    } else {
      if (workHours === null) {
        toast.error("Enter work hours");
      } else if (workHours < 0) {
        toast.error("Work hours shouldn't less than 0");
      } else if (workRate === null) {
        toast.error("Enter work rate");
      } else if (workRate <= 0) {
        toast.error("Work rate shouldn't less than 0");
      } else {
        dispatch({
          type: "UPDATE_ADJUST_PAYROLL_REQUEST",
          payload: {
            payrollId: payrollId,
            employees: [
              {
                employeeId: item.employeeId,
                workHours: Number(workHours),
                payRate: Number(workRate),
                extraHours: extraHours,
                toilDto: toilDto,
                directorFee: Number(directorFee),
                salarySacrifice: salarySacrifice,

                reimbursement: Number(reimbursement),
                childSupports: childSupports,
                workerCompensation: workerCompensation,
                parentalLeave: Number(parentalLeave),
                defenceLeave: Number(defenceLeave),
                termination: termination,

                annualLeaveHours: isAnnualLeave ? Number(annualLeave) : 0,
                sickLeaveHours: isSickLeave ? Number(sickLeave) : 0,
                bonusCommissionAmount: isBonousCommission
                  ? Number(bonousAmmount)
                  : 0,
                bonusOption: bonousCommissionOption,
                allowances: allowence,
                deductions: deduction,
              },
            ],
          },
        });
      }
    }
  };

  useEffect(() => {
    if (isEnabledUsers === "firstAdjustment") {
      setIsExtraHours(false);
      setIsAnnualLeave(false);
      setIsSickLeave(false);
      setIsAllowence(false);
      setIsDeduction(false);
      setIsBonousComission(false);
      //added functionality
      setIsToilHours(false);
      setIsReimbursement(false);
      setIsDirectorFee(false);
      setIschildSupportType(false);
      setIsworkerCompensation(false);
      setIsParentalLeave(false);
      setIsDefenceLeave(false);
      setIsTermination(false);
      setIschildSupportType(false);
      setIsSalarySacrifice(false);

      setChildSupport([]);
      setExtraHours([]);
      setAllowence([]);
      setDeduction([]);
      setAnnualLeave("");
      setSickLeave("");
      setBonouscommissionOption("EXEMPT_FROM_SUPER");
      setBonousAmount("");
      //added Functionality
      setToilDto(null);
      setReimbursement(null);
      setDirectorFee(null);

      setChildSupport([]);
      setWorkerCompensation({
        compensationAmount: null,
        compensationType: null,
      });
      setDefenceLeave(null);
      setTermination({});
    }
  }, [isEnabledUsers]);

  const handleUpdateToilDto = (e) => {
    if (e?.target?.name === "accruedCashedOutHours") {
      e.target.value > toilAccrued
        ? toast.error("Value must be less than or equal to Accrued Toil")
        : setToilDto((prev) => {
            return {
              ...prev,
              [e.target.name]: e.target.value,
              accruedCashedOut: e.target.value > 0 ? true : false,
            };
          });
    } else {
      setToilDto((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  return (
    <div className="adjust--payment--modal">
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Adjust Pay Run</p>
      </div>
      <form onSubmit={handleAdjustSbmit}>
        <div className="myform">
          <div className="adjust--payroll--body">
            <div className="body--items">
              <div className="body--items__name">
                <p>
                  <span>
                    <FcBusinessman />
                  </span>
                  {item.employeeFullName}
                </p>
              </div>

              <div className="body--items__field">
                <div className="organization--field">
                  <div className="organization--field--name">
                    <p>Ordinary work hours : </p>
                    <input
                      type="number"
                      min={0}
                      placeholder="Ordinary work hours"
                      value={workHours}
                      onChange={(e) => setWorkHours(e.target.value)}
                    />
                  </div>
                  <div className="organization--field--para">
                    <p>Rate($) : </p>
                    <input
                      type="number"
                      placeholder="Rate"
                      value={workRate}
                      onChange={(e) => setWorkRate(e.target.value)}
                    />
                  </div>
                </div>

                {isExtraHours &&
                  extraHours.map((item, indexExtra) => (
                    <div key={item}>
                      <MdDeleteForever
                        className="myDeleteButton"
                        onClick={() => handleRemoveExtrahours(indexExtra)}
                      />
                      <div className="extra--hours" key={item.employeeId}>
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Extra Hours : </p>
                            <input
                              type="number"
                              required
                              placeholder="Extra Hours"
                              name="hours"
                              value={item.hours}
                              onChange={(event) =>
                                handleExtraHoursInput(indexExtra, event)
                              }
                            />
                          </div>
                          <div className="organization--field--para">
                            <p>Rate($) : </p>
                            <input
                              type="number"
                              placeholder="Rate"
                              name="rate"
                              required
                              value={item.rate}
                              onChange={(event) =>
                                handleExtraHoursInput(indexExtra, event)
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="organization--field"
                          style={{ marginBottom: "30px" }}
                        >
                          <div className="organization--field--name">
                            <p>Extra Hours Option : </p>
                            <select
                              value={item.option}
                              name="option"
                              required
                              onChange={(event) =>
                                handleExtraHoursInput(indexExtra, event)
                              }
                            >
                              <option hidden disabled selected>
                                Select option
                              </option>
                              <option value="ORDINARY_HOURS">
                                Ordinary Hours
                              </option>
                              <option value="OVER_TIME_EXEMPT_FROM_SUPER">
                                Over Time Exempt From Super
                              </option>
                            </select>
                          </div>
                          <div className="organization--field--para">
                            <div className="organization--field--para">
                              <p>Note : </p>
                              <input
                                required
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={item.note}
                                onChange={(event) =>
                                  handleExtraHoursInput(indexExtra, event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}

                <div className="annual--leave">
                  <div className="organization--field">
                    <div className="organization--field--name">
                      {isAnnualLeave && (
                        <>
                          <MdDeleteForever
                            className="myDeleteButton"
                            onClick={() => setIsAnnualLeave(false)}
                          />
                          <p>Annual Leave Hours :</p>
                          <input
                            required
                            type="number"
                            min={0}
                            placeholder="Annual Leave Hours"
                            value={annualLeave}
                            onChange={(e) => setAnnualLeave(e.target.value)}
                          />
                        </>
                      )}
                    </div>
                    <div className="organization--field--para">
                      {isSickLeave && (
                        <>
                          <MdDeleteForever
                            className="myDeleteButton"
                            onClick={() => setIsSickLeave(false)}
                          />
                          <p>Sick Leave Hours : </p>
                          <input
                            type="number"
                            required
                            placeholder="Sick Leave Hours"
                            value={sickLeave}
                            onChange={(e) => setSickLeave(e.target.value)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* <hr /> */}
                </div>

                {isBonousCommission && (
                  <>
                    <MdDeleteForever
                      className="myDeleteButton"
                      onClick={() => setIsBonousComission(false)}
                    />
                    <div className="Bonous--commission">
                      <div className="organization--field">
                        <div className="organization--field--name">
                          <p>Bonus Option : </p>
                          <select
                            value={bonousCommissionOption}
                            onChange={(e) =>
                              setBonouscommissionOption(e.target.value)
                            }
                          >
                            <option hidden selected disabled>
                              Select bonus
                            </option>
                            <option value="EXEMPT_FROM_SUPER">
                              Exempt From Super
                            </option>
                            <option value="SUBJECT_TO_SUPER">
                              Subject To Super
                            </option>
                          </select>
                        </div>
                        <div className="organization--field--para">
                          <p>Amount : </p>
                          <input
                            type="number"
                            placeholder="Amount"
                            value={bonousAmmount}
                            onChange={(e) => setBonousAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </>
                )}

                {/* {isAllowence &&  } */}
                {isAllowence &&
                  allowence.map((item, index) => (
                    <>
                      <MdDeleteForever
                        className="myDeleteButton"
                        onClick={() => handleRemoveAllowence(index)}
                      />

                      <div className="allowence">
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Allowance Unit : </p>
                            <input
                              required
                              type="number"
                              placeholder="Unit"
                              name="unit"
                              value={item.unit}
                              // value={allowence.unit}
                              onChange={(event) =>
                                handleAllowenceInput(index, event)
                              }
                            />
                          </div>
                          <div className="organization--field--para">
                            <p>Costs Per Unit : </p>
                            <input
                              required
                              type="number"
                              placeholder="Rate"
                              name="costPerUnit"
                              // value={allowence.costPerUnit}
                              value={item.costPerUnit}
                              onChange={(event) =>
                                handleAllowenceInput(index, event)
                              }
                            />
                          </div>
                        </div>
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Allowance Option : </p>
                            <select
                              name="allowanceOption"
                              value={item.allowanceOption}
                              onChange={(event) =>
                                handleAllowenceInput(index, event)
                              }
                            >
                              <option value="SUBJECT_TO_TAX_AND_SUPER">
                                Subject To Tax And Super
                              </option>
                              <option value="EXEMPT_FROM_TAX_SUPER">
                                Exempt From Tax Super
                              </option>
                              <option value="SUBJECT_TO_TAX_EXEMPT_FROM_SUPER">
                                Subject To Tax Exempt From Super
                              </option>
                            </select>
                          </div>
                          <div className="organization--field--para">
                            <p>Allowance Type : </p>
                            <select
                              name="allowanceType"
                              value={item.allowanceType}
                              onChange={(event) =>
                                handleAllowenceInput(index, event)
                              }
                            >
                              <option value="CAR">Car</option>
                              <option value="FIRST_AID">First Aid</option>
                              <option value="TRANSPORT">Transport</option>
                              <option value="LAUNDRY">Laundry</option>
                              <option value="MEALS">Meals</option>
                              <option value="TRAVEL">Travel</option>
                              <option value="TOOL">Tool</option>
                              <option value="OTHER">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}

                {/* {isDeduction && } */}
                {isDeduction &&
                  deduction.map((item, index) => (
                    <>
                      <MdDeleteForever
                        className="myDeleteButton"
                        onClick={() => handleRemoveDeduction(index)}
                      />
                      <div className="deduction">
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Deduction Type : </p>
                            <select
                              name="type"
                              value={item.type}
                              onChange={(event) =>
                                handleDeductionInput(index, event)
                              }
                            >
                              <option value="FEE">Free</option>
                              <option value="WORK_PLACE_GIVING">
                                Work Place Giving
                              </option>
                            </select>
                          </div>
                          <div className="organization--field--para">
                            <p>Deduction From : </p>
                            <select
                              name="option"
                              value={item.option}
                              onChange={(event) =>
                                handleDeductionInput(index, event)
                              }
                            >
                              <option value="PRE_TAX_DEDUCTION">
                                Pre Tax Deduction
                              </option>
                              <option value="POST_TAX_DEDUCTION">
                                Post Tax Deduction
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Amount : </p>
                            <input
                              type="number"
                              required
                              placeholder="Amount"
                              name="amount"
                              value={item.amount}
                              onChange={(event) =>
                                handleDeductionInput(index, event)
                              }
                            />
                          </div>
                          <div className="organization--field--para"></div>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}

                {/* toil &  reimbursement*/}
                {/* <div className="toil--reimbursement" id="toil--reimbursement"> */}
                <div>
                  <div className="organization--field">
                    <div className="organization--field--name" id="toil">
                      {isToilHours && (
                        <>
                          <MdDeleteForever
                            className="myDeleteButton"
                            onClick={() => handleRemoveToilPay()}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p className="d-flex flex-row justify-content-between">
                              Time off in lieu(Hrs) :
                              <div className="d-flex flex-row gap-2 align-items-center">
                                <span className="text-primary">CashToil</span>
                                <div class="form-check form-switch form-check-reverse">
                                  <input
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "-2rem",
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    value={toilDto?.cashedOut}
                                    onClick={() => {
                                      setIsToilDetailsModalOpen(true);
                                      setToilDto((prev) => {
                                        return {
                                          ...prev,
                                          cashedOut: true,
                                        };
                                      });
                                    }}
                                    checked={
                                      isToilDetailsModalOpen ? true : false
                                    }
                                  />
                                </div>
                              </div>
                            </p>
                            {toilDto?.accruedCashedOutHours > 0 && (
                              <p
                                style={{
                                  color: "#FD8D14",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                  marginTop: "-1rem",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                Accrued TOIL cashed-out Hours :{" "}
                                {toilDto?.accruedCashedOutHours}
                              </p>
                            )}
                          </div>
                          {/* <div class="form-check form-switch form-check-reverse"></div> */}
                          <input
                            type="number"
                            required
                            name="toilHours"
                            placeholder="Time offin lieu hours"
                            value={toilDto?.toilHours}
                            onChange={(e) =>
                              setToilDto((prev) => {
                                return {
                                  ...prev,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                          />

                          {/* toil modal */}
                          <div
                            className="modal fade toil--modal"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            // tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1
                                    class="modal-title fw-2"
                                    id="staticBackdropLabel"
                                    style={{ fontSize: "1.2rem !important" }}
                                  >
                                    Employee TOIL details
                                  </h1>
                                </div>
                                {Number(toilAccrued) === 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      padding: "2rem 3rem",
                                    }}
                                  >
                                    <h3 style={{ color: "orange" }}>
                                      {" "}
                                      Accrued Toil hours :{toilAccrued}
                                    </h3>
                                  </div>
                                ) : (
                                  <div
                                    class="modal-body text-dark"
                                    style={{
                                      padding: "2.5rem 0 2.5rem 4rem",
                                      backgroundColor: "#f8f9fa",
                                    }}
                                  >
                                    <div className="d-flex flex-row justify-content-between">
                                      <span
                                        style={{
                                          color: "#2B3467",
                                          fontWeight: "bolder",
                                          fontSize: "1.5rem",
                                          padding: "0 0 2rem",
                                          width: "50%",
                                        }}
                                      >
                                        Accrued Toil hours :{toilAccrued}
                                      </span>
                                      <div className="d-flex flex-row gap-2">
                                        <div class="form-check form-switch form-check-reverse ">
                                          <div
                                            style={{
                                              paddingLeft: "1.5rem",
                                            }}
                                          >
                                            <span>cash current Toil :hr</span>
                                            <input
                                              type="number"
                                              name="toilHours"
                                              style={{ width: "85%" }}
                                              placeholder="Time offin lieu hours"
                                              value={toilDto?.toilHours}
                                              onChange={handleUpdateToilDto}
                                              // onChange={(e) =>
                                              //   setToilDto((prev) => {
                                              //     return {
                                              //       ...prev,
                                              //       [e.target.name]:
                                              //         e.target.value,
                                              //     };
                                              //   })
                                              // }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row">
                                      <div>
                                        <span htmlFor="accrued-to-cash">
                                          Accrued TOIL cashed-out Hours
                                        </span>

                                        <input
                                          style={{ width: "85%" }}
                                          type="number"
                                          id="accrued-to-cash"
                                          name="accruedCashedOutHours"
                                          value={toilDto?.accruedCashedOutHours}
                                          onChange={handleUpdateToilDto}
                                          // onChange={(e) => {
                                          //   setToilDto((prev) => {
                                          //     if (
                                          //       e.target.value !== 0 ||
                                          //       e.target.value !== null ||
                                          //       e.target.value !== undefined
                                          //     ) {
                                          //       return {
                                          //         ...prev,

                                          //         accruedCashedOut: true,
                                          //         [e.target.name]:
                                          //           e.target.value,
                                          //       };
                                          //     } else {
                                          //       return {
                                          //         ...prev,

                                          //         accruedCashedOut: false,

                                          //         [e.target.name]:
                                          //           e.target.value,
                                          //       };
                                          //     }
                                          //   });
                                          // }}
                                        />
                                      </div>
                                      <div>
                                        <span htmlFor="cash-to-accrued">
                                          Accrued TOIL cashed-out rate :$
                                        </span>

                                        <input
                                          style={{ width: "85%" }}
                                          type="number"
                                          id="cash-to-accrued"
                                          name="accruedCashedOutRate"
                                          value={toilDto?.accruedCashedOutRate}
                                          onChange={handleUpdateToilDto}
                                          // onChange={(e) => {
                                          //   setToilDto((prev) => {
                                          //     return {
                                          //       ...prev,
                                          //       [e.target.name]: e.target.value,
                                          //     };
                                          //   });
                                          // }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                      setIsToilDetailsModalOpen(false);
                                      setToilDto((prev) => {
                                        return {
                                          ...prev,
                                          accruedCashedOut: false,
                                          accruedCashedOutHours: 0,
                                          accruedCashedOutRate: 0,
                                          cashedOut: false,
                                        };
                                      });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                      setIsToilDetailsModalOpen(false);
                                    }}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="organization--field">
                  <div className="organization--field--para" id="reimbursement">
                    {isReimbursement && (
                      <>
                        <MdDeleteForever
                          className="myDeleteButton"
                          onClick={() => handleRemoveReimbursement()}
                        />

                        <p>Reimbursement($) : </p>
                        <input
                          type="number"
                          required
                          min={0}
                          placeholder="Enter Reimbursement"
                          value={reimbursement}
                          onChange={(e) => setReimbursement(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="organization--field"
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  {/* directorFee */}
                  {isDiretorFee && (
                    <div className="organization--field--name" id="parental">
                      <MdDeleteForever
                        className="myDeleteButton"
                        onClick={() => handleRemoveDirectorFee()}
                      />
                      <div className="director--fee" id="director--fee">
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Director Fee($) : </p>
                            <input
                              type="number"
                              required
                              min={0}
                              placeholder="Enter Director Fee"
                              value={directorFee}
                              onChange={(e) => setDirectorFee(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}

                  {isSalarySacrifice && (
                    <div className="organization--field--name" id="parental">
                      <MdDeleteForever
                        className="myDeleteButton"
                        onClick={() => handleRemoveSalarySacrifice()}
                      />
                      <div className="salary--sacrifice" id="salary--sacrifice">
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Salary Sacrifice($) : </p>
                            <input
                              type="number"
                              required
                              min={0}
                              placeholder="Enter Salary Sacrifice Amount"
                              value={salarySacrifice}
                              onChange={(e) =>
                                setSalarySacrifice(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}
                </div>

                {/* test code for childSupport */}
                {isChildSupportType &&
                  childSupports.map((childSupport, index) => (
                    <>
                      <MdDeleteForever
                        className="myDeleteButton"
                        onClick={() => handleRemoveChildSupport(index)}
                      />
                      <div className="child--support" id="child--support">
                        <div className="organization--field">
                          <div className="organization--field--name">
                            <p>Child Support Type : </p>
                            <select
                              name="supportType"
                              value={childSupport.supportType}
                              onChange={(event) =>
                                handleChildSupportInput(index, event)
                              }
                            >
                              <option disabled selected hidden value={null}>
                                child support type
                              </option>
                              <option value="DEDUCTION">Deduction</option>
                              <option value="GARNISHEE">Garnishee</option>
                            </select>
                          </div>
                          <div className="organization--field--para">
                            <p>Amount($) : </p>
                            <input
                              type="number"
                              required
                              placeholder="amount"
                              name="supportRate"
                              min={0}
                              value={childSupport.supportRate}
                              onChange={(event) =>
                                handleChildSupportInput(index, event)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}

                {/* {isAllowence &&  } */}
                {isWorkerCompensation && (
                  <>
                    <MdDeleteForever
                      className="myDeleteButton"
                      onClick={() => handleRemoveAllWorkerCompensation()}
                    />
                    <div
                      className="worker--compensation"
                      id="worker--compensation"
                    >
                      <div className="organization--field">
                        <div className="organization--field--name">
                          <p>Compensation Amount($) : </p>
                          <input
                            required
                            type="number"
                            min={0}
                            placeholder="Compensation Amount"
                            name="compensationAmount"
                            value={workerCompensation.compensationAmount}
                            onChange={(event) =>
                              handleWorkerCompensationInput(event)
                            }
                          />
                        </div>
                        <div className="organization--field--name">
                          <p>Compensation Type : </p>
                          <select
                            label="worker compensation type"
                            name="compensationType"
                            required
                            // max={}
                            value={workerCompensation.compensationType}
                            onChange={(event) =>
                              handleWorkerCompensationInput(event)
                            }
                          >
                            <option disabled selected hidden value={null}>
                              select compensation Type
                            </option>
                            <option value="SUBJECT_TO_SUPER">
                              Subject to Super
                            </option>
                            <option value="EXEMPT_FROM_SUPER">
                              Exempt from Super
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}

                {/* parentalLeave & defenceLeave  */}
                <div>
                  <div className="organization--field">
                    <div className="organization--field--name" id="parental">
                      {isParentalLeave && (
                        <>
                          <MdDeleteForever
                            className="myDeleteButton"
                            onClick={() => handleRemoveParentaLeave()}
                          />
                          <p>Parental Leave($): </p>
                          <input
                            type="number"
                            pattern="^[0-9]+([.][0-9]+)?$"
                            required
                            min={0}
                            placeholder="Parental Leave"
                            value={parentalLeave}
                            onChange={(e) => setParentalLeave(e.target.value)}
                          />
                        </>
                      )}
                    </div>
                    <div className="organization--field--para" id="defence">
                      {isDefenceLeave && (
                        <>
                          <MdDeleteForever
                            className="myDeleteButton"
                            onClick={() => handleRemoveDefenceLeave()}
                          />

                          <p>Defence Leave($) : </p>
                          <input
                            type="number"
                            required
                            min={0}
                            pattern="^[0-9]+([.][0-9]+)?$"
                            placeholder="Enter defence leave"
                            value={defenceLeave}
                            onChange={(e) => setDefenceLeave(e.target.value)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* <hr /> */}
                </div>

                {/* Termination */}
                {isTermination && (
                  <>
                    <MdDeleteForever
                      className="myDeleteButton"
                      onClick={() => handleRemoveTermination()}
                    />
                    <div className="termination" id="termination">
                      <div className="organization--field">
                        <div className="organization--field--name">
                          <p>Termination Date : </p>
                          <input
                            type="date"
                            required
                            placeholder="Termination Date"
                            name="terminationDate"
                            value={
                              termination.terminationDate
                                ? termination.terminationDate
                                : null
                            }
                            onChange={(event) => handleTerminationInput(event)}
                          />
                        </div>
                        <div className="organization--field--para">
                          <p>Termination Reason : </p>
                          <select
                            name="terminationType"
                            required
                            value={
                              termination.terminationType
                                ? termination.terminationType
                                : null
                            }
                            onChange={(event) => handleTerminationInput(event)}
                          >
                            <option disabled hidden selected value={null}>
                              Select type
                            </option>
                            <option value="VOLUNTARY">Voluntary</option>
                            <option value="ILL_HEALTH">Ill health</option>
                            <option value="DECREASED">Decreased</option>
                            <option value="REDUNDANCY">Redundancy</option>
                            <option value="DISMISSAL">Dismisssal</option>
                            <option value="CONTRACT_ENDED">
                              Contract ended
                            </option>
                            <option value="TRANSFER">Transfer</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className="organization--field"
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="organization--field--name">
                          <p>Termination unused annual leave(Hrs): </p>
                          <input
                            type="number"
                            required
                            placeholder="Enter unused annaual leave"
                            name="terminationUnusedLeaves"
                            value={
                              termination.terminationUnusedLeaves
                                ? termination.terminationUnusedLeaves
                                : null
                            }
                            onChange={(event) => handleTerminationInput(event)}
                          />
                        </div>
                        <div className="organization--field--name">
                          <p>Redundancy Type: </p>
                          <input
                            type="number"
                            placeholder="Type"
                            name="redundancyType"
                            value={
                              termination.redundancyType
                                ? termination.redundancyType
                                : null
                            }
                            onChange={(event) => handleTerminationInput(event)}
                          />
                        </div>
                      </div>
                      <div
                        className="organization--field"
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="organization--field--para">
                          <div className="organization--field--para">
                            <p>TCPU($) : </p>
                            <input
                              required
                              type="number"
                              placeholder="Termination cost per unit"
                              name="terminationCostPerUnit"
                              value={
                                termination.terminationCostPerUnit
                                  ? termination.terminationCostPerUnit
                                  : null
                              }
                              onChange={(event) =>
                                handleTerminationInput(event)
                              }
                            />
                          </div>
                        </div>

                        <div className="organization--field--para">
                          <div className="organization--field--para">
                            <p>etpType($):</p>
                            <input
                              type="number"
                              placeholder="etpType"
                              name="etpType"
                              value={termination.etpType}
                              onChange={(event) =>
                                handleTerminationInput(event)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
              </div>
            </div>
            <div className="adjust--payRoll--footer">
              <div className="adjust--payRoll--footer--content">
                <p></p>
                <div>
                  <p onClick={() => handleAddExtraHours()}>
                    <BsPlus /> Extra Hours
                  </p>
                  {!isAnnualLeave && (
                    <p onClick={() => setIsAnnualLeave(!isAnnualLeave)}>
                      <BsPlus />
                      Annual Leave
                    </p>
                  )}
                  {!isSickLeave && (
                    <p onClick={() => setIsSickLeave(!isSickLeave)}>
                      <BsPlus /> Sick Leave
                    </p>
                  )}
                  {!isBonousCommission && (
                    <p
                      onClick={() => setIsBonousComission(!isBonousCommission)}
                    >
                      <BsPlus /> Bonus/Commission
                    </p>
                  )}
                  <p onClick={() => handleAddAllowence()}>
                    <BsPlus /> Allowance
                  </p>
                  <p onClick={() => handleAddDeduction()}>
                    <BsPlus /> Deduction
                  </p>
                </div>
              </div>
            </div>

            <div className="adjust--payRoll--footer">
              <div className="adjust--payRoll--footer--content">
                <p></p>
                <div className="d-flex flex-row-reverse">
                  <p
                    style={{ fontSize: "1.5rem" }}
                    class="btn btn-primary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    View more ...
                  </p>

                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{
                      padding: "2rem",
                      border: "none",
                      borderRadius: "3px",
                      backgroundColor: "#dbd8d8",
                      height: "object-fit",
                      // opacity: "0.8",
                    }}
                  >
                    {!isToilHours && (
                      <li>
                        <p onClick={() => handleAddToilHours()}>
                          <BsPlus /> TOIL Pays
                        </p>
                      </li>
                    )}
                    {!isReimbursement && (
                      <li>
                        <p onClick={() => handleAddReimbursement()}>
                          <BsPlus /> Reimbursement
                        </p>
                      </li>
                    )}

                    {!isDiretorFee && (
                      <li>
                        <p onClick={() => handleAddDirectorFee()}>
                          <BsPlus /> Director Fee
                        </p>
                      </li>
                    )}
                    {!isSalarySacrifice && (
                      <li>
                        <p onClick={() => setIsSalarySacrifice(true)}>
                          <BsPlus />
                          Salary sacrifice
                        </p>
                      </li>
                    )}

                    <li>
                      <p onClick={() => handleAddChildSupport()}>
                        <BsPlus /> Child Support
                      </p>
                    </li>

                    {!isParentalLeave && (
                      <li>
                        <p onClick={() => setIsParentalLeave(true)}>
                          <BsPlus /> Parental Leave
                        </p>
                      </li>
                    )}

                    {!isDefenceLeave && (
                      <li>
                        <p onClick={() => setIsDefenceLeave(true)}>
                          <BsPlus /> Defence Leave
                        </p>
                      </li>
                    )}

                    {!isWorkerCompensation && (
                      <li>
                        <p onClick={() => handleAddWorkerCompensation()}>
                          <BsPlus /> Worker Compensation
                        </p>
                      </li>
                    )}

                    {!isTermination && (
                      <li>
                        <p onClick={() => setIsTermination(true)}>
                          <FcCancel /> Termination
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {/* checking */}

            <div className="modal--container__group--buttons">
              <button type="submit" style={{ marginBottom: "50px" }}>
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(mapStateToProps, null)(AdjustPayrun);
