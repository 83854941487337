import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import TableHeader from "./TableHeader";
import CompensationTableHeader from "./CompensationTableHeader";
import WorkerCompensationRow from "../Payslip_component/WorkerCompensationRow";


const tableRowsCount = 1;
const styles = StyleSheet.create({
    tableMargin: {
        marginTop: "30px"
    }
})

function CompensationTable({ item }) {

  return (
    <View style={styles.tableMargin} >
        <CompensationTableHeader/>
        <WorkerCompensationRow items={item} />
    </View>
  );
}

export default CompensationTable;
