import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsCheckCircle } from 'react-icons/bs'
import { connect, useDispatch } from 'react-redux'
import Footer from '../Z_Footer/Footer'
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import { BsX } from 'react-icons/bs'
import LoadingComponent from '../LoadingComponent'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import UpdateLeave from './UpdateLeave'
import dayjs from "dayjs"


const mapStateToProps = store => {
    const { leaveList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully } = store.mainState
    return { leaveList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully }
}

function LeaveList({ leaveList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully }) {
    const [updateMenuModal, setUpdateMenuModal] = useState(false)
    const [editingItem, setEditingItem] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [activeCategoryList, setActiveCategoryList] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    // Enable Disable category
    const [showSmModal, setShowSmModal] = useState(false);
    const [deletingId, setDeletingId] = useState(null);
    const dispatch = useDispatch()


    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    const currentPosts = leaveList.data !== undefined && leaveList.data.slice(indexOfFirstPost, indexOfLastPost)

    for (let i = 1; i <= Math.ceil(leaveList.data !== undefined && leaveList.totalData / postsPerPage); i++) {
        pageNumbers.push(i)
    }

    useEffect(() => {
       if(currentPage !== undefined & postsPerPage !==undefined) {
           console.log({currentPage,postsPerPage})
        dispatch({
            type: "FETCH_LEAVE_REQUEST",
            payload: { currentPage, postsPerPage }
        })
       }
    }, [currentPage,postsPerPage])
    useEffect(() => {
        if (isEnabledUsers === "b") {
            dispatch({
                type: "FETCH_LEAVE_REQUEST",
                payload: { currentPage, postsPerPage }
            })
            setShowSmModal(false);
            setUpdateMenuModal(false)
        }
    }, [isEnabledUsers])
    useEffect(() => {
        if (isEnabledUsers === "added") {
            setTimeout(()=> {
                dispatch({
                    type: "FETCH_LEAVE_REQUEST",
                    payload: { currentPage, postsPerPage }
                })
                dispatch({
                    type:"ADD_MENU_ITEM_MODAL"
                })
            },500)
        }

    }, [isEnabledUsers])

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Leave</h4>
                </div>
                <div className="user_groups" style={{minHeight:"60vh"}}>
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Leave</h4>
                            <button onClick={() => dispatch({ type: "ADD_MENU_ITEM_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}> Per Page</label>
                            <select style={{ width: "80px" }} name="pageSize" onClick={(e) => setPostsPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            {/* <input type="text" placeholder="Search" /> */}
                        </div>
                    </div>
                    <p className="myloading--paragraph">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="leaveList--id">S.N.</th>
                                    <th className="leaveList--name">Employee Name</th>
                                    <th className="leaveList--leaveType">Leave Type</th>
                                    <th className="leaveList--startDate">Start Date</th>
                                    <th className="leaveList--endDate">End Date</th>
                                    <th className="leaveList--note">Note</th>
                                    {/* <th className="category--addedBy">Status</th> */}
                                    <th className="leaveList--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {leaveList.data !== undefined && currentPosts.map((item, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.employeeName}</td>
                                        <td>{item.leaveType}</td>
                                        <td>{dayjs(item.startDate).format('DD MMM YYYY')}</td>
                                        <td>{dayjs(item.endDate).format('DD MMM YYYY')}</td>
                                        <td>{item.notes}</td>
                                        <td>
                                            {/* <Tippy content={<span>{item.isActive ? "De-activate" : "Activate"}</span>}>
                                                <span><BsCheckCircle onClick={() => {
                                                    setShowSmModal(true);
                                                    setEnableDisableItem(item)
                                                }} style={{ cursor: "pointer" }} /></span>
                                            </Tippy> */}
                                            <Tippy content="Edit">
                                                <span><AiFillEdit className="report-edit" style={{ cursor: "pointer" }} onClick={() => {
                                                    setUpdateMenuModal(true);
                                                    setEditingItem(item)
                                                }} /></span>
                                            </Tippy>
                                            <Tippy content="Delete">
                                                <span><AiFillDelete className="report-edit" style={{ cursor: "pointer" }} onClick={() => {
                                                    setShowSmModal(true);
                                                    setDeletingId(item.leaveId)
                                                }} /></span>
                                            </Tippy>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className={`${updateMenuModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {editingItem !== null && <UpdateLeave item={editingItem} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setUpdateMenuModal(!updateMenuModal);
                                        dispatch({
                                            type: "FETCH_LEAVE_REQUEST",
                                            payload: { currentPage, postsPerPage }
                                        })
                                    }}><BsX /></span><p>Edit</p></div>
                                </div>
                            </div>
                        </div>
                        {/* Delete leave  */}

                        <div className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`} >
                            <div className="delete--admin--container" >
                                <div className="delete--admin--container__heading"></div>
                                <><div className="delete--admin--container__body">
                                    <h1>Are you sure to Delete this ??</h1>
                                    <div>{isEnabledUsers === "a" && <div className="deleting--something">  <p>Processing...</p></div>}</div>
                                    <div> {isEnabledUsers === "b" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div>}</div>
                                    <div> {isEnabledUsers === "c" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p>

                                    </div>}</div>
                                </div>
                                    <div className="delete--admin--container__footer">
                                        <button onClick={() => setShowSmModal(!showSmModal)}>Cancel</button>
                                        <button onClick={() => {
                                            dispatch({
                                                type: "DELETE_LEAVE_REQUEST",
                                                payload: {
                                                    deletingId
                                                }
                                            })
                                        }}>Ok</button>
                                    </div></>

                            </div>
                        </div>
                        <div className="pagination">
                            {leaveList.data !== undefined && <p>Showing {indexOfFirstPost} to {indexOfLastPost > leaveList.totalData ? leaveList.totalData : indexOfLastPost} of {leaveList.totalData} entries</p>}
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}


export default connect(mapStateToProps, null)(LeaveList)
