import React from 'react'
import {BrowserRouter,Switch,Route} from 'react-router-dom'
import AdminController from '../Components/Admin Controller/AdminController'
import ChangePassword from '../Components/auth/ChangePassword'
import LogIn from '../Components/auth/SignIn/LogIn'
import Dashboard from '../Components/Dashboard/Dashboard'
import Employee from '../Components/Employee/Employee'
import LeaveList from '../Components/Leave/LeaveList'
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute'
import SignUp from '../Components/auth/SignIn/SignUp'
import SignUpInformation from '../Components/auth/SignUpInformation'
import Setting from '../Components/Setting/Setting'
import InvitationForm from '../Components/auth/InvitationForm'
import PrivacyPolicy from '../Components/PrivacyAndTerms/PrivacyPolicy'
import TermsCondition from '../Components/PrivacyAndTerms/TermsCondition'
import ForgotPassword from '../Components/auth/SignIn/ForgotPassword'
import NewPassword from '../Components/auth/SignIn/NewPassword'
import ValidateOtpTSV from '../Components/auth/SignIn/ValidateOtpTSV'
import TSVResetAuth from '../Components/auth/TSVResetAuth'
import Reports from '../Components/Reports/Reports'
import BillingSubscription from '../Components/Setting/Billing/BillingSubscription'

import SuccesssMessage from '../Components/Setting/Billing/SuccesssMessage'
import PricingInfo from '../Components/Setting/Billing/PricingInfo'
import SubscriptionErrorMessage from '../Components/Setting/Billing/SubscriptionErrorMessage'
import PaymentHistory from '../Components/Setting/Billing/PaymentHistory/PaymentHistory'
import UserType from '../Components/auth/UserType'
import { getAdmin_accountant, getLoginType } from '../FrontendHelper'
import AccDashboard from '../AccComponent/AccDashboard/AccDashboard'
import BulkPayroll from '../AccComponent/Bulk Payroll/BulkPayroll'
import AccSetting from '../AccComponent/Settings/AccSetting'
import InviteOrganization from '../AccComponent/InviteOrganization/InviteOrganization'
import InviteAccAddInfo from '../AccComponent/InviteAccoutant/InviteAccAddInfo'
import AccountantSetting from '../Components/Setting/AccountantSetting'
import InviteOrgAccountantAddInfo from '../Components/InviteAccountant/InviteOrgAccountantAddInfo'
import TwoStepVerification from '../Components/auth/TwoStepVerification'
import BulkPayrolIndividual from '../AccComponent/Bulk Payroll/BulkPayrolIndividual'
import EmployeeInviteSuccessPage from '../Components/auth/SignIn/EmployeeInviteSuccessPage'


function MainRoutes() {
    const loginType = getLoginType() || "admin"
    const admin_accountant = getAdmin_accountant()
    return (
        <BrowserRouter>
            <Switch>
                <Route path = '/' exact component = {LogIn} />
                <Route path='/invite/accountant/:jwtToken' exact component={InviteAccAddInfo} />
                {/* <Route path='/invite/sub-accountant/:jwtToken' exact component={InviteAccAddInfo} /> */}
                <Route path='/link/organization/:userEmail/:jwtToken' exact component={InviteOrgAccountantAddInfo} />
                <Route path = '/get-newPassword/:userType' exact component = {NewPassword} />
                <Route path = '/forgot-password/:userType' exact component = {ForgotPassword} />
                <Route path = '/user-type' exact component = {UserType} />
                <Route path = '/signup-getEmail/:userType' exact component = {SignUp} />
                <Route path = '/:userType/add-information' exact component = {SignUpInformation} />
                <Route path = '/:userType/two-step-verification' exact component = {TwoStepVerification} />
                <Route path = '/two-factor-authentication/:token' exact component = {TSVResetAuth} />
                <Route path = '/:userType/verify-via-email' exact component = {ValidateOtpTSV} />
                <Route path = '/change-password' exact component = {ChangePassword} />

                <Route path = '/privacy-policy' exact component = {PrivacyPolicy} />
                <Route path = '/terms-of-service' exact component = {TermsCondition} />
                <Route path = '/invitaion/:token' exact component = {InvitationForm} />
                <Route path = '/invitaion-success' exact component = {EmployeeInviteSuccessPage} />

                <Route path = '/stripe-payment/success' exact component = {SuccesssMessage} />
                <Route path = '/stripe-payment/cancel' exact component = {SubscriptionErrorMessage} />

                {loginType === "admin" && <>
                <>
                
                <PrivateRoute path = '/dashboard' exact component = {Dashboard} />
                <PrivateRoute path = '/admin-room' exact component = {AdminController} />
                <PrivateRoute path = '/employee' exact component = {Employee} />
                <PrivateRoute path = '/setting' exact component = {Setting} />
                <PrivateRoute path = '/billing-subscription' exact component = {BillingSubscription} />
                <PrivateRoute path = '/payment-history' exact component = {PaymentHistory} />

                <PrivateRoute path = '/product-price-info/:employeeCount/:duration' exact component = {PricingInfo} />
                <PrivateRoute path = '/leave' exact component = {LeaveList} />
                <PrivateRoute path = '/reports' exact component = {Reports} />
                <PrivateRoute path = '/accountant-setting' exact component = {AccountantSetting} />


                </>
                
                </>}
                {(loginType === "accountant" & admin_accountant=== false) &&
                <>
                <PrivateRoute path = '/dashboard' exact component = {AccDashboard} />
                {/* <PrivateRoute path = '/bulk-payroll' exact component = {BulkPayroll} /> */}
                <PrivateRoute path = '/accountant-settings' exact component = {AccSetting} />
                <PrivateRoute path = '/bulk-payroll-individual' exact component = {BulkPayrolIndividual} />
                <Route path = '/organization-add-information' exact component = {InviteOrganization} />
                </> }
                {(loginType === "accountant" & admin_accountant=== true) &&
                <>
                
                <PrivateRoute path = '/dashboard' exact component = {Dashboard} />
                <PrivateRoute path = '/admin-room' exact component = {AdminController} />
                <PrivateRoute path = '/employee' exact component = {Employee} />
                <PrivateRoute path = '/setting' exact component = {Setting} />
                <PrivateRoute path = '/billing-subscription' exact component = {BillingSubscription} />
                <PrivateRoute path = '/payment-history' exact component = {PaymentHistory} />
                <PrivateRoute path = '/product-price-info/:employeeCount/:duration' exact component = {PricingInfo} />
                <PrivateRoute path = '/leave' exact component = {LeaveList} />
                <PrivateRoute path = '/reports' exact component = {Reports} />
                </>}
            </Switch>
        </BrowserRouter>
    )
}

export default MainRoutes

