import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../Layout/Layout'
import RunPayroll from './RunPayroll'
import {BsX} from 'react-icons/bs'
import {FcBusinessman} from 'react-icons/fc'
import dayjs from 'dayjs'
import {Link} from 'react-router-dom'

function BulkPayrolIndividual() {
  const dispatch = useDispatch()
    const {bulkPayrollOrgList} = useSelector(state=> state.accountantState)
    const {isToogleSidebar,payRunResponse,buttonText} = useSelector(state=> state.mainState)
    const [currentCounter,setCurrentCounter] = useState(0)
    const [payrollId,setPayrollId] = useState(null)
    const [currentOrg,setCurrentOrg] = useState(null)
    const [showRunPayroll,setShowRunPayroll] =useState(false)


    const runPayrollDashboard = () =>
    payRunResponse?.employee !== undefined &&
    payRunResponse.employee.map((item) => (
                <div key={item.employeeId}>
                  <div className="employee--container">
                    <div className="employee--details">
                      <div className="employee--details--heading">
                        <p>
                          <FcBusinessman className="myEmployee--icon" />
                        </p>
                        <div className="employee--details--heading--para">
                          <p>{item.employeeFullName}</p>
                          <p>{item.jobTitle}</p>
                        </div>
                      </div>
                      <div
                        className="employee--details--footer"
                      >
                        View Details
                      </div>
                    </div>
                    <div
                      className="employee--container__details"
                    >
                      <div className="general--details">
                        <p>
                          Work Hours : <span>{item.workHours} Hours</span>
                        </p>
                        <p>
                          Work Hours Rate : <span>{item.payRate} </span>
                        </p>
                        <p>
                          Super Pay : <span>${item.superPay}</span>
                        </p>
                      </div>
                      <div className="major--details">
                        <div className="grossEarnings">
                          <p>
                            Tax Pay : <span>${item.taxPay}</span>
                          </p>
                          <p>
                            Gross Pay : <span>${item.grossPay}</span>
                          </p>
                          <p>
                            Net Pay : <span>${item.netPay}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                        </div>
    ));

    useEffect(()=> {
      setCurrentOrg(bulkPayrollOrgList[currentCounter])
    },[currentCounter,bulkPayrollOrgList])
    
    useEffect(()=> {
      setShowRunPayroll(true)
    },[currentOrg])

    const onHandleSubmit = (e)=> {
      e.preventDefault()
    }

  return (
    <Layout>
      <div className="dashboard--heading">
            <p>Run Payroll</p>
          </div>
      <div>
      <div>
              <div className="adjust--payroll">
                <div className="adjust--payroll--heading">
                  <div className="adjust--payroll--heading__button">
                    <p className="adjust">Adjust</p>
                    <div className="underLine"></div>
                  </div>
                  <div className="adjust--payroll--heading__dates">
                    <p>
                      Payment Date :{" "}
                      {dayjs(payRunResponse.paymentDate).format("DD MMM YYYY")}
                    </p>
                    <p>
                      {dayjs(payRunResponse.payPeriodStarting).format(
                        "DD MMM YYYY"
                      )}{" "}
                      to{" "}
                      {dayjs(payRunResponse.payPeriodEnding).format(
                        "DD MMM YYYY"
                      )}
                    </p>
                  </div>
                </div>
                <hr />
                {runPayrollDashboard()}

                <div>
                  <div className="modal--container__group--buttons">
                    <button
                      type="submit"
                      style={{ marginBottom: "50px" }}
                      onClick={onHandleSubmit}
                    >
                      {buttonText}
                    </button>
                    {/* {payrollId !== null && (
                      <Link to="/dashboard">
                        <button
                          type="submit"
                          style={{ marginBottom: "50px" }}
                          onClick={handleSaveAsDraft}
                        >
                          Save As Draft
                        </button>
                      </Link>
                    )} */}
                    {payrollId !== null && (
                      <Link to="/dashboard">
                        <button
                          type="submit"
                          style={{ marginBottom: "50px" }}
                          onClick={()=> {
                            let id = payrollId
                            dispatch({
                              type: "DELETE_PAYROLL_REQUEST",
                              payload: { id },
                            });
                          }}
                        >
                          Delete
                        </button>
                      </Link>
                    )}
                  </div>
                </div>

                
                
              </div>
            </div>
      </div>
      <button onClick={()=> setCurrentCounter((prevCounter)=> prevCounter+1)}>Increase {currentCounter}</button>
                  {/* add leave modal */}
            <div className={`${showRunPayroll ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    {currentOrg !== null && <RunPayroll item={currentOrg} />}
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            setShowRunPayroll(false)
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div>
    </Layout>
  )
}

export default BulkPayrolIndividual