import { toast } from "react-toastify";
import {
  authenticateAdmin,
  authenticateTwoSVAdmin,
  validateUserAdmin,
  getNameAdmin,
  validateOTPForgotPassword,
} from "../FrontendHelper";
import {
  ADD_CATEGORY_INPUT,
  ADMIN_AUTH_FAILURE,
  ADMIN_AUTH_REQUEST,
  ADMIN_AUTH_SUCCESS,
  ADMIN_CREDENCIAL_INPUT,
  TOOGLE_SIDEBAR,
} from "./actionType";

const initialState = {
  isPostUpdateCompleted: false,
  isSuccessRunPayrollError: "",
  buttonText: "Submit",
  verifyingButton: "Verify",
  resendButtonText: "Resend OTP",
  reportButtonText: "Report STP",
  payButtonText: "Pay",
  sendButtonText: "Send",
  removeButton: "Remove",
  verificationState: "",
  editButton: "Edit",
  updateButtonText: "Update",
  isToogleSidebar: false,
  isLoadingOnLogin: false,
  isSuccessOnLogin: false,
  forgotTSVAuth: false,
  emptyDashBoardData: false,
  payRollStatus: "Normal",
  isEnabledUsers: "",
  adminCredencial: {
    userName: "",
    password: "",
  },
  loginResponse: {},
  twoStepOtpVerifyFlag: false,
  responseOnPostingEmail: {},
  responseAfterValidateOtp: {},
  leaveList: {},
  employeeDropdownList: [],
  employeeDataList: {},
  reportData: {},
  orderList: {},
  orderDetails: {},
  adminProfileData: {},
  payRunResponse: {},
  adjustedEmployeeData: {},
  myPayRollList: [],
  organizationSettingData: {},
  employeePaymentSetting: {},
  dashboardData: {},
  addPayrunDatePeriod: {},
  payrollInfo: {},
  privacyData: {},
  payslipData: {},
  forgotPassportResData: {},
  responseValidateForgotPassOtp: {},
  leaveBalanceReportData: {},
  employeeSummaryReportData: {},
  activitySummaryReportData: {},
  searchedData: {},
  searchSuperFundName: {},
  activityLogList: {},
  productInfoData: {},
  billingPriceData: {},
  billingGeneratedUrl: {},
  paymentHistoryData: {},
  orgAccountantInfo: {},
  // MODAL
  isAddCouponModal: false,
  isAddReataurantModal: false,
  isAddAdminRegisterModal: false,
  isAddCategoryModal: false,
  isInviteEmployeeModal: false,
  isAddMenuItemModal: false,
  isServiceRateModal: false,
  isAddRunPayRoll: false,
  isAddOrganization: false,
  isAdjustPayrunModal: false,
  isAddPersonalSetting: false,
  isPaymentSetting: false,
  isReviewPayroll: false,
  isEmployeeContactsReportModal: false,
  isLeaveBalanceReportModal: false,
  isActivePayrollReportModal: false,
  isEmployeeSummaryReportModal: false,

  //finalise stp data
  isLoadingFinaliseStp: false,
  isFinaliseSTpPayload: false,
  employeeFinaliseStp: {},
  updatedEmployeeFinaliseStp: {
    finalizedSTPDataEntityList: [],
    startYear: null,
    endYear: null,
  },

  //validating user
  checkingEmployeId: null,
};

const orkReducer = (state = initialState, action) => {
  // sidebar toggler
  if (action.type === TOOGLE_SIDEBAR) {
    return { ...state, isToogleSidebar: !state.isToogleSidebar };
  }
  // Admin Controller Section

  // post email for signup
  if (action.type === "GET_EMAIL_SIGNUP_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "GET_EMAIL_SIGNUP_SUCCESS") {
    toast.success("Successful !!! OTP has been sent to your email");
    return {
      ...state,
      buttonText: "Submit",
      responseOnPostingEmail: action.payload,
    };
  }
  if (action.type === "GET_EMAIL_SIGNUP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, buttonText: "Submit" };
  }
  // post email for signup
  if (action.type === "GET_EMAIL_SIGNUP_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "GET_EMAIL_SIGNUP_SUCCESS") {
    toast.info("Successfull !!! OTP has been sent to your email.");
    return {
      ...state,
      buttonText: "Submit",
      responseOnPostingEmail: action.payload,
    };
  }
  if (action.type === "GET_EMAIL_SIGNUP_FAILURE") {
    toast.error("Something Went Wrong. Try Again.");
    return { ...state, buttonText: "Submit" };
  }
  // validate otp for signup
  if (action.type === "VALIDATE_OTP_SIGNUP_REQUEST") {
    return { ...state, verifyingButton: "Submitting" };
  }
  if (action.type === "VALIDATE_OTP_SIGNUP_SUCCESS") {
    toast.success("OTP verification Completed.");
    validateUserAdmin(action.payload, () => {
      console.log("the data is saved in locaStorage");
    });
    return {
      ...state,
      verifyingButton: "Verifing",
      responseAfterValidateOtp: action.payload,
    };
  }
  if (action.type === "VALIDATE_OTP_SIGNUP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }

    return { ...state, verifyingButton: "Verify" };
  }
  // validate otp for two step verification
  if (action.type === "VALIDATE_OTP_TSV_SIGNUP_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "VALIDATE_OTP_TSV_SIGNUP_SUCCESS") {
    toast.success("Login Successfull !!!.");
    authenticateAdmin(action.payload, () => {
      console.log("the data is saved in locaStorage");
    });
    return { ...state, buttonText: "Submit", twoStepOtpVerifyFlag: true };
  }
  if (action.type === "VALIDATE_OTP_TSV_SIGNUP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }

    return { ...state, buttonText: "Submit" };
  }
  // forgot authentication two step verification
  if (action.type === "FORGOT_AUTHENTICATION_TSV_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "FORGOT_AUTHENTICATION_TSV_SUCCESS") {
    toast.success("Email has been sent to rest two-step verification.");
    return { ...state, buttonText: "Submit", forgotTSVAuth: true };
  }
  if (action.type === "FORGOT_AUTHENTICATION_TSV_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }

    return { ...state, buttonText: "Submit" };
  }
  // Resend otp from signup
  if (action.type === "RESEND_OTP_REQUEST") {
    return { ...state, resendButtonText: "Resending OTP", isEnabledUsers: "a" };
  }
  if (action.type === "RESEND_OTP_SUCCESS") {
    toast.success("New OTP has been sent successfully !!! Check your Email");
    return {
      ...state,
      resendButtonText: "Resend OTP",
      isEnabledUsers: "b",
      responseOnPostingEmail: action.payload,
    };
  }
  if (action.type === "RESEND_OTP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }

    return { ...state, resendButtonText: "Resend OTP", isEnabledUsers: "" };
  }
  // Resend otp from forgot password
  if (action.type === "RESEND_OTP_FORPASS_REQUEST") {
    return { ...state, resendButtonText: "Resending OTP" };
  }
  if (action.type === "RESEND_OTP_FORPASS_SUCCESS") {
    toast.success("New OTP has been sent successfully !!! Check your Email");
    return { ...state, resendButtonText: "Resend OTP" };
  }
  if (action.type === "RESEND_OTP_FORPASS_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }

    return { ...state, resendButtonText: "Resend OTP" };
  }
  // additional info on Signup
  if (action.type === "ADDITIONAL_INFO_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "ADDITIONAL_INFO_SUCCESS") {
    getNameAdmin(action.payload, () => {
      console.log("the data is saved in locaStorage");
    });
    toast.success("Submitted Successfully. Welcome To The Activ Account !!!");
    return {
      ...state,
      buttonText: "Submitted",
      responseAfterValidateOtp: action.payload,
    };
  }
  if (action.type === "ADDITIONAL_INFO_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something wrong happened. Try Again Later !!!");
    }

    return { ...state, buttonText: "Submit" };
  }
  // post data to authorize
  if (action.type === ADMIN_AUTH_REQUEST) {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === ADMIN_AUTH_SUCCESS) {
    authenticateTwoSVAdmin(action.payload, () => {
      console.log("the data is saved in locaStorage");
    });
    return {
      ...state,
      isSuccessOnLogin: true,
      loginResponse: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === ADMIN_AUTH_FAILURE) {
    if (action.payload !== undefined) {
      toast.error(action.payload.error?.data.message);
    } else {
      toast.error("Internal Server Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // Log out
  if (action.type === "LOG_OUT_REQUEST") {
    return { ...state, isSuccessOnLogin: false };
  }
  // input form data
  if (action.type === ADMIN_CREDENCIAL_INPUT) {
    return { ...state, adminCredencial: action.payload.values };
  }
  if (action.type === ADD_CATEGORY_INPUT) {
    return { ...state, isPostingCategory: action.payload.values };
  }

  // verify two step verificatin
  if (action.type === "VERIFY_TWO_SV_REQUEST") {
    return { ...state, verificationState: "a" };
  }
  if (action.type === "VERIFY_TWO_SV_SUCCESS") {
    toast.success("Verification Success.");
    authenticateAdmin(action.payload, () => {
      console.log("the data is saved in locaStorage");
    });
    toast.success("Two Step Verification System Verified");
    return { ...state, verificationState: "b" };
  }
  if (action.type === "VERIFY_TWO_SV_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, verificationState: "" };
  }
  // fetch dashboard payrun data
  if (action.type === "FETCH_DASHBOARD_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DASHBOARD_DATA_SUCCESS") {
    //  console.log("checking the data from the reducers", action.payload);
    return {
      ...state,
      dashboardData: action.payload,
      isLoadingOnLogin: false,
      emptyDashBoardData: false,
    };
  }
  if (action.type === "FETCH_DASHBOARD_DATA_FAILURE") {
    toast.error("Sorry!!! Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }

  // make Dashboard data clear
  if (action.type === "RESET_DASHBOARD_LIST") {
    return {
      ...state,
      dashboardData: {},
      payRollStatus: "Normal",
      emptyDashBoardData: true,
    };
  }

  // fetch employee Data
  if (action.type === "FETCH_EMPLOYEE_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_EMPLOYEE_DATA_SUCCESS") {
    return {
      ...state,
      employeeDataList: action.payload,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_EMPLOYEE_DATA_FAILURE") {
    toast.error("Sorry!!! Network Error.");
    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  // post employee data
  if (action.type === "POST_EMPLOYEE_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_EMPLOYEE_SUCCESS") {
    toast.success("Employee Added Successfully !!");
    return {
      ...state,
      buttonText: "Submitted",
      isEnabledUsers: "employeeAdded",
    };
  }
  if (action.type === "POST_EMPLOYEE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit", isEnabledUsers: "" };
  }
  // Updating Employee
  if (action.type === "PUT_EMPLOYEE_REQUEST") {
    return { ...state, updateButtonText: "Updating", isEnabledUsers: "a" };
  }
  if (action.type === "PUT_EMPLOYEE_SUCCESS") {
    toast.success("Employee updated Successfully !!");
    return { ...state, updateButtonText: "Update", isEnabledUsers: "b" };
  }
  if (action.type === "PUT_EMPLOYEE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update", isEnabledUsers: "" };
  }
  // fetch leave
  if (action.type === "FETCH_LEAVE_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_LEAVE_SUCCESS") {
    return {
      ...state,
      leaveList: action.payload,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_LEAVE_FAILURE") {
    toast.error("Sorry!!! Network Error.");
    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  // Add leave
  if (action.type === "POST_LEAVE_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_LEAVE_SUCCESS") {
    toast.success("Leave Added Successfully !!!");
    return { ...state, buttonText: "Submit", isEnabledUsers: "added" };
  }
  if (action.type === "POST_LEAVE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit", isEnabledUsers: "" };
  }
  // Update leave
  if (action.type === "UPDATE_LEAVE_REQUEST") {
    return { ...state, updateButtonText: "Updatting", isEnabledUsers: "a" };
  }
  if (action.type === "UPDATE_LEAVE_SUCCESS") {
    toast.success("Leave Updated Successfully !!!");
    return { ...state, updateButtonText: "Update", isEnabledUsers: "b" };
  }
  if (action.type === "UPDATE_LEAVE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update", isEnabledUsers: "" };
  }
  // Delete Leave
  if (action.type === "DELETE_LEAVE_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DELETE_LEAVE_SUCCESS") {
    toast.success("Leave Deleted Successfully !!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DELETE_LEAVE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // FETCH EMPLOYEE DROPDOWN
  if (action.type === "FETCH_EMPLOYEE_DROPDOWN_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_EMPLOYEE_DROPDOWN_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      employeeDropdownList: action.payload,
    };
  }
  if (action.type === "FETCH_EMPLOYEE_DROPDOWN_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isLoadingOnLogin: false };
  }
  // FETCH payroll data
  if (action.type === "FETCH_PAYROLL_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PAYROLL_DATA_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      myPayRollList: action.payload,
      payRollStatus: "Normal",
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_PAYROLL_DATA_FAILURE") {
    toast.error("Something Went Wrong. Try Again Later !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // FETCH add pay run date and period
  if (action.type === "FETCH_ADD_PAYRUN_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ADD_PAYRUN_DATA_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      addPayrunDatePeriod: action.payload,
      payRollStatus: "Normal",
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_ADD_PAYRUN_DATA_FAILURE") {
    // if(action.payload)
    console.log("The justin error messsage", action.payload);
    toast.error(action.payload.data.message);
    return { ...state, isLoadingOnLogin: false };
  }
  // FETCH payroll info by id
  if (action.type === "FETCH_PAYROLL_INFO_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PAYROLL_INFO_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, payrollInfo: action.payload };
  }
  if (action.type === "FETCH_PAYROLL_INFO_FAILURE") {
    toast.error("Something Went Wrong. Try Again Later !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // add pay run
  if (action.type === "POST_PAY_RUN_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_PAY_RUN_SUCCESS") {
    toast.success("Run Pay Roll Successfully Completed !!!");
    return {
      ...state,
      buttonText: "Submit",
      payRunResponse: action.payload,
      payRollStatus: "Draft",
      isEnabledUsers: "runPayroll",
    };
  }
  if (action.type === "POST_PAY_RUN_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
      isSuccessRunPayrollError: action.payload.data.message,
    };
  }
  // edit button if payrollStatus is draft
  if (action.type === "EDIT_PAYROLL_REQUEST") {
    return { ...state };
  }
  if (action.type === "EDIT_PAYROLL_SUCCESS") {
    return { ...state, payRunResponse: action.payload, payRollStatus: "Draft" };
  }
  if (action.type === "EDIT_PAYROLL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Internal Server Error. Try Again.");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      editButton: "Edit",
    };
  }
  // post adjustment data individual
  if (action.type === "POST_ADJUST_PAYROLL_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_ADJUST_PAYROLL_SUCCESS") {
    toast.success("Sucessfully Adjusted Employee Data !!!");
    return {
      ...state,
      buttonText: "Submit",
      payRollStatus: "Draft",
      isEnabledUsers: "firstAdjustment",
      adjustedEmployeeData: action.payload,
    };
  }
  if (action.type === "POST_ADJUST_PAYROLL_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
    };
  }
  // update adjustment data individual
  if (action.type === "UPDATE_ADJUST_PAYROLL_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "UPDATE_ADJUST_PAYROLL_SUCCESS") {
    toast.success("Sucessfully Adjusted Employee Data !!!");
    return {
      ...state,
      buttonText: "Submit",
      payRollStatus: "Draft",
      isEnabledUsers: "firstAdjustment",
      adjustedEmployeeData: action.payload,
    };
  }
  if (action.type === "UPDATE_ADJUST_PAYROLL_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
    };
  }
  // post adjustment data from dashboard
  if (action.type === "POST_ADJUST_PAYROLL2_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_ADJUST_PAYROLL2_SUCCESS") {
    toast.success("Payroll has been added successfully ");
    return {
      ...state,
      buttonText: "Submit",
      payRollStatus: "Normal",
      isEnabledUsers: "Completed",
    };
  }
  if (action.type === "POST_ADJUST_PAYROLL2_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Internal Server Error. Try Again Later");
    }

    return {
      ...state,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
    };
  }

  // post organization setting
  if (action.type === "POST_ORGANIZATION_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_ORGANIZATION_SUCCESS") {
    toast.success("Organization Setting Updated Succesfully");
    return { ...state, buttonText: "Submit", isEnabledUsers: "b" };
  }
  if (action.type === "POST_ORGANIZATION_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  // FETCH organization setting
  if (action.type === "FETCH_ORGANIZATION_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ORGANIZATION_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      organizationSettingData: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_ORGANIZATION_FAILURE") {
    toast.error("Something Went Wrong. Try Again Later !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // post personal setting
  if (action.type === "POST_PERSONAL_SETTING_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_PERSONAL_SETTING_SUCCESS") {
    toast.success("Personal Setting Updated Succesfully");
    return { ...state, buttonText: "Submit", isEnabledUsers: "x" };
  }
  if (action.type === "POST_PERSONAL_SETTING_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  //  FETCH employee payment setting
  if (action.type === "FETCH_PAYMENT_SETTING_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PAYMENT_SETTING_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      employeePaymentSetting: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_PAYMENT_SETTING_FAILURE") {
    toast.error("Something wrong happened. Try Again Later !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // post employee payment setting
  if (action.type === "POST_PAYMENT_SETTING_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_PAYMENT_SETTING_SUCCESS") {
    toast.success("Employee Payment Setting Updated Succesfully");
    return { ...state, buttonText: "Submit", isEnabledUsers: "y" };
  }
  if (action.type === "POST_PAYMENT_SETTING_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  // invite employee
  if (action.type === "INVITE_EMPLOYEE_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "INVITE_EMPLOYEE_SUCCESS") {
    toast.success("Invitation Request Sent Succesfully !!!");
    return {
      ...state,
      buttonText: "Submit",
      isEnabledUsers: "employeeInvited",
    };
  }
  if (action.type === "INVITE_EMPLOYEE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  // submit employee data
  if (action.type === "SUBMIT_INVITED_EMPLOYEE_DATA_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "SUBMIT_INVITED_EMPLOYEE_DATA_SUCCESS") {
    toast.success("Congrats!!! Employee Information Submitted Succesfully.");
    return { ...state, buttonText: "Submit", isEnabledUsers: "y" };
  }
  if (action.type === "SUBMIT_INVITED_EMPLOYEE_DATA_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  // fetch privacy terms condition
  if (action.type === "FETCH_PRIVACY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PRIVACY_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, privacyData: action.payload };
  }
  if (action.type === "FETCH_PRIVACY_FAILURE") {
    toast.error("Something Went Wrong. Try Again Later !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch payslip data
  if (action.type === "FETCH_PAYSLIP_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PAYSLIP_DATA_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, payslipData: action.payload };
  }
  if (action.type === "FETCH_PAYSLIP_DATA_FAILURE") {
    toast.error("Something Went Wrong. Try Again Later !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // post organization logo
  if (action.type === "UPLOAD_ORG_LOGO_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "UPLOAD_ORG_LOGO_SUCCESS") {
    toast.success("Organization logo Uploaded Successfully !!!");
    return { ...state, isEnabledUsers: "c" };
  }
  if (action.type === "UPLOAD_ORG_LOGO_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // report stp
  if (action.type === "REPORT_STP_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "REPORT_STP_SUCCESS") {
    toast.success("STP will reported within 20 - 30 minutes !!!");
    return { ...state, isEnabledUsers: "reported", buttonText: "Submit" };
  }
  if (action.type === "REPORT_STP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  // send payslip
  if (action.type === "SEND_PAYSLIP_REQUEST") {
    return { ...state, sendButtonText: "Sending", isEnabledUsers: "a" };
  }
  if (action.type === "SEND_PAYSLIP_SUCCESS") {
    toast.success("Pay slip has been successfully send to email! !!");
    return { ...state, isEnabledUsers: "emailSent", sendButtonText: "Send" };
  }
  if (action.type === "SEND_PAYSLIP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "", sendButtonText: "Send" };
  }
  // pay to employee
  if (action.type === "PAY_TO_EMPLOYEE_REQUEST") {
    return { ...state, isEnabledUsers: "a", payButtonText: "Paying" };
  }
  if (action.type === "PAY_TO_EMPLOYEE_SUCCESS") {
    toast.success("Payment sucessful !!!");
    return { ...state, isEnabledUsers: "b", payButtonText: "Paid" };
  }
  if (action.type === "PAY_TO_EMPLOYEE_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "", payButtonText: "Pay" };
  }
  // get email for forgot password
  if (action.type === "GET_EMAIL_FOR_FORGOT_PASSWORD_REQUEST") {
    return {
      ...state,
      isEnabledUsers: "a",
      buttonText: "Submitting",
      resendButtonText: "Resending OTP",
    };
  }
  if (action.type === "GET_EMAIL_FOR_FORGOT_PASSWORD_SUCCESS") {
    toast.success(
      "OTP has been sent in your email. Check your email address !!!"
    );
    return {
      ...state,
      isEnabledUsers: "b",
      forgotPassportResData: action.payload,
      buttonText: "Submit",
      resendButtonText: "Resend OTP",
    };
  }
  if (action.type === "GET_EMAIL_FOR_FORGOT_PASSWORD_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return {
      ...state,
      isEnabledUsers: "",
      buttonText: "Submit",
      resendButtonText: "Resend OTP",
    };
  }
  // validate otp for forgot password
  if (action.type === "VALIDATE_OTP_FORGOT_PASSWORD_REQUEST") {
    return { ...state, isEnabledUsers: "a", buttonText: "Submitting" };
  }
  if (action.type === "VALIDATE_OTP_FORGOT_PASSWORD_SUCCESS") {
    toast.success("OTP Validation Success !!!");
    validateOTPForgotPassword(action.payload, () => {
      console.log("the token is saved in localstorage");
    });
    return {
      ...state,
      isEnabledUsers: "b",
      responseValidateForgotPassOtp: action.payload,
      buttonText: "Submit",
    };
  }
  if (action.type === "VALIDATE_OTP_FORGOT_PASSWORD_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "", buttonText: "Submit" };
  }
  // Enable employee
  if (action.type === "ENABLE_EMPLOYEE_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ENABLE_EMPLOYEE_SUCCESS") {
    toast.success("Employee Enable Successful !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ENABLE_EMPLOYEE_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // disable employee
  if (action.type === "DISABLE_EMPLOYEE_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DISABLE_EMPLOYEE_SUCCESS") {
    toast.success("Employee Disable Successful !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DISABLE_EMPLOYEE_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // delete employee
  if (action.type === "DELETE_EMPLOYEE_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DELETE_EMPLOYEE_SUCCESS") {
    toast.success("Employee Deleted Successful !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DELETE_EMPLOYEE_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // approve employee
  if (action.type === "APPROVE_EMPLOYEE_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "APPROVE_EMPLOYEE_SUCCESS") {
    toast.success("Employee Approve Successful !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "APPROVE_EMPLOYEE_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // resend invitation employee
  if (action.type === "RESEND_INVITATION_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "RESEND_INVITATION_SUCCESS") {
    toast.success("Employee Invitation Sent Successfully !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "RESEND_INVITATION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // delete payroll
  if (action.type === "DELETE_PAYROLL_REQUEST") {
    return { ...state, isEnabledUsers: "a", removeButton: "Removing" };
  }
  if (action.type === "DELETE_PAYROLL_SUCCESS") {
    toast.success("Payroll Deleted Successfully !!!");
    return {
      ...state,
      isEnabledUsers: "deletedPayroll",
      removeButton: "Remove",
    };
  }
  if (action.type === "DELETE_PAYROLL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "", removeButton: "Remove" };
  }
  // fetch report data
  if (action.type === "FETCH_REPORT_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_REPORT_DATA_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, reportData: action.payload };
  }
  if (action.type === "FETCH_REPORT_DATA_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch leave balance report
  if (action.type === "FETCH_LEAVE_BALANCE_REPORT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_LEAVE_BALANCE_REPORT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      leaveBalanceReportData: action.payload,
    };
  }
  if (action.type === "FETCH_LEAVE_BALANCE_REPORT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch employee summary report data
  if (action.type === "FETCH_EMPLOYEE_SUMMARY_REPORT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_EMPLOYEE_SUMMARY_REPORT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      employeeSummaryReportData: action.payload,
    };
  }
  if (action.type === "FETCH_EMPLOYEE_SUMMARY_REPORT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch payroll activity summary report
  if (action.type === "FETCH_ACTIVITY_SUMMARY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ACTIVITY_SUMMARY_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      activitySummaryReportData: action.payload,
    };
  }
  if (action.type === "FETCH_ACTIVITY_SUMMARY_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // Search company Name for organization
  if (action.type === "SEARCH_COMPANY_NAME_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_COMPANY_NAME_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, searchedData: action.payload };
  }
  if (action.type === "SEARCH_COMPANY_NAME_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "SET_SEARCH_DATA_EMPTY") {
    return { ...state, searchedData: [] };
  }
  // Search super fund Name and details
  if (action.type === "SEARCH_SUPERFUND_NAME_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "SEARCH_SUPERFUND_NAME_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      searchSuperFundName: action.payload,
      isEnabledUsers: "searched",
    };
  }
  if (action.type === "SEARCH_SUPERFUND_NAME_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "" };
  }
  // fetch activity log
  if (action.type === "FETCH_ACTIVITY_LOG_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ACTIVITY_LOG_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      activityLogList: action.payload,
    };
  }
  if (action.type === "FETCH_ACTIVITY_LOG_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch billing
  if (action.type === "FETCH_PRODUCT_INFO_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isSuccessRunPayrollError: "" };
  }
  if (action.type === "FETCH_PRODUCT_INFO_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      productInfoData: action.payload,
    };
  }
  if (action.type === "FETCH_PRODUCT_INFO_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // calculate billing price
  if (action.type === "CALCULATE_BILLING_PRICE_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "CALCULATE_BILLING_PRICE_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      billingPriceData: action.payload,
    };
  }
  if (action.type === "CALCULATE_BILLING_PRICE_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch CHECKOUT URL
  if (action.type === "FETCH_CHECKOUT_URL_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_CHECKOUT_URL_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      billingGeneratedUrl: action.payload,
    };
  }
  if (action.type === "FETCH_CHECKOUT_URL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // Post success payment message
  if (action.type === "POST_PAYMENT_SUCCESSFULL_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "POST_PAYMENT_SUCCESSFULL_SUCCESS") {
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "POST_PAYMENT_SUCCESSFULL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // fetch payment history
  if (action.type === "FETCH_PAYMENT_HISTORY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PAYMENT_HISTORY_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      paymentHistoryData: action.payload,
    };
  }
  if (action.type === "FETCH_PAYMENT_HISTORY_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // Cancel subscription
  if (action.type === "CANCEL_SUBSCRIPTION_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "CANCEL_SUBSCRIPTION_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "CANCEL_SUBSCRIPTION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "" };
  }
  // fetch organization accountatn details
  if (action.type === "FETCH_ORG_ACCOUNTANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ORG_ACCOUNTANT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      orgAccountantInfo: action.payload,
    };
  }
  if (action.type === "FETCH_ORG_ACCOUNTANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // invite organization accountatn details
  if (action.type === "INVITE_ORG_ACCOUNTANT_REQUEST") {
    return { ...state, isPostUpdateCompleted: true };
  }
  if (action.type === "INVITE_ORG_ACCOUNTANT_SUCCESS") {
    toast.success("Your accountant invited successfully.");
    return { ...state, isPostUpdateCompleted: false };
  }
  if (action.type === "INVITE_ORG_ACCOUNTANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isPostUpdateCompleted: false };
  }
  // remove organization accountant
  if (action.type === "REMOVE_ACCOUNTANT_REQUEST") {
    return { ...state, isPostUpdateCompleted: true };
  }
  if (action.type === "REMOVE_ACCOUNTANT_SUCCESS") {
    toast.success("Your accountant removed successfully.");
    return { ...state, isPostUpdateCompleted: false };
  }
  if (action.type === "REMOVE_ACCOUNTANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again.");
    }
    return { ...state, isPostUpdateCompleted: false };
  }

  // handle modals
  if (action.type === "ADD_COUPON_MODAL") {
    return { ...state, isAddCouponModal: !state.isAddCouponModal };
  }
  if (action.type === "ADD_RESTAURANT_MODAL") {
    return { ...state, isAddReataurantModal: !state.isAddReataurantModal };
  }
  if (action.type === "ADD_ADMIN_REGISTER_MODAL") {
    return {
      ...state,
      isAddAdminRegisterModal: !state.isAddAdminRegisterModal,
    };
  }
  if (action.type === "ADD_CATEGORY_MODAL") {
    return { ...state, isAddCategoryModal: !state.isAddCategoryModal };
  }
  if (action.type === "INVITE_EMPLOYEE_MODAL") {
    return { ...state, isInviteEmployeeModal: !state.isInviteEmployeeModal };
  }
  if (action.type === "ADD_MENU_ITEM_MODAL") {
    return { ...state, isAddMenuItemModal: !state.isAddMenuItemModal };
  }
  if (action.type === "OPEN_SERVICE_RATE_MODAL") {
    return { ...state, isServiceRateModal: !state.isServiceRateModal };
  }
  if (action.type === "OPEN_ADD_PAY_ROLL_MODAL") {
    return { ...state, isAddRunPayRoll: !state.isAddRunPayRoll };
  }
  if (action.type === "ADJUST_PAYRUN_MODAL") {
    return { ...state, isAdjustPayrunModal: !state.isAdjustPayrunModal };
    // console.log("checking the emplyee id from the reducer",action.payload.employeeId)
    // return { ...state, isAdjustPayrunModal: !state.isAdjustPayrunModal,checkingEmployeId:action.payload.employeeId}
  }
  if (action.type === "OPEN_ADD_ORGANIZATION_MODAL") {
    return { ...state, isAddOrganization: !state.isAddOrganization };
  }
  if (action.type === "OPEN_ADD_PERSONAL_MODAL") {
    return { ...state, isAddPersonalSetting: !state.isAddPersonalSetting };
  }
  if (action.type === "OPEN_ADD_EMPLOYEE_PAYMENT_MODAL") {
    return { ...state, isPaymentSetting: !state.isPaymentSetting };
  }
  if (action.type === "OPEN_REVIEW_PAYROLL_MODAL") {
    return { ...state, isReviewPayroll: !state.isReviewPayroll };
  }
  if (action.type === "OPEN_EMPLOYEE_CONTACTS_REPORT_MODAL") {
    return {
      ...state,
      isEmployeeContactsReportModal: !state.isEmployeeContactsReportModal,
    };
  }
  if (action.type === "OPEN_LEAVE_BALANCE_REPORT_MODAL") {
    return {
      ...state,
      isLeaveBalanceReportModal: !state.isLeaveBalanceReportModal,
    };
  }

  if (action.type === "OPEN_ACTIVITY_SUMMARY_REPORT_MODAL") {
    return {
      ...state,
      isActivePayrollReportModal: !state.isActivePayrollReportModal,
    };
  }
  if (action.type === "OPEN_EMPLOYEE_SUMMARY_REPORT_MODAL") {
    return {
      ...state,
      isEmployeeSummaryReportModal: !state.isEmployeeSummaryReportModal,
    };
  }

  //employee finalise stp
  if (action.type === "FETCH_EMPLOYEE_FINALISE_STP_REQUEST") {
    return {
      ...state,
      isLoadingFinaliseStp: true,
      isFinaliseSTpPayload: false,
      employeeFinaliseStp: {},
    };
  }
  if (action.type === "FETCH_EMPLOYEE_FINALISE_STP_SUCCESS") {
    const tempdata1 = [...action.payload.finalizedSTPDataEntityList];
    let tempdata = JSON.parse(JSON.stringify(tempdata1));

    tempdata = tempdata.map((record) => {
      delete record.employeeDetailResponse.bankAndSuper;
      delete record.employeeDetailResponse.tax;
      delete record.employeeDetailResponse.addedAccountantId;
      delete record.employeeDetailResponse.addedAdminId;
      delete record.employeeDetailResponse.addedDate;
      delete record.employeeDetailResponse.annualLeaveRemaining;
      delete record.employeeDetailResponse.deleted;
      delete record.employeeDetailResponse.disabled;
      delete record.employeeDetailResponse.employeeStatus;
      delete record.employeeDetailResponse.employment;
      delete record.employeeDetailResponse.leaveRemaining;
      delete record.employeeDetailResponse.openingBalance;
      delete record.employeeDetailResponse.personal;
      delete record.employeeDetailResponse.scheduledLeave;
      delete record.employeeDetailResponse.sickLeaveRemaining;
      delete record.employeeDetailResponse.updatedAccountantId;
      delete record.employeeDetailResponse.updatedAdminId;
      delete record.employeeDetailResponse.updatedDate;

      return record;
    });

    return {
      ...state,
      isLoadingFinaliseStp: false,
      isFinaliseSTpPayload:
        action.payload.finalizedSTPDataEntityList.length > 0 ? true : false,
      employeeFinaliseStp: action.payload,
      updatedEmployeeFinaliseStp: {
        finalizedSTPDataEntityList: tempdata,
        startYear: action.payload.startYear,
        endYear: action.payload.endYear,
      },
    };
  }
  if (action.type === "FETCH_EMPLOYEE_FINALISE_STP_FAILURE") {
    return {
      ...state,
      isLoadingFinaliseStp: false,
      isFinaliseSTpPayload: false,
    };
  }

  if (action.type === "UPDATE_ADJUST_STP_OF_EMPLOYEE") {
    toast.success("Finalise Stp adjustment updated successfully");
    return {
      ...state,
      // employeeFinaliseStp: action.payload.tempFinaliseStpEmployee,
      updatedEmployeeFinaliseStp: action.payload.updatedEmployeeFinaliseStp,
    };
  }

  if (action.type === "UPDATE_ADJUST_EMPLOYEE_FINALISESTP_REQUEST") {
    return {
      ...state,
    };
  }
  if (action.type === "UPDATE_ADJUST_EMPLOYEE_FINALISESTP_SUCCESS") {
    console.log("your finalise is successs");
    toast.success(action.payload.data.message);
    return {
      ...state,
    };
  }
  if (action.type === "UPDATE_ADJUST_EMPLOYEE_FINALISESTP_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
    };
  }

  if (action.type === "CLEAR_THE_FINALISESTP_EMPLOYEE_DATA") {
    return {
      ...state,
      employeeFinaliseStp: {},
      updatedEmployeeFinaliseStp: {
        finalizedSTPDataEntityList: [],
        startYear: null,
        endYear: null,
      },
    };
  }

  if (action.type === "SAVE_EMPLOYEE_FINALISE_STP_REQUEST_SUCCESS") {
    toast.success("Employee finaliseStp save successful");
    return {
      ...state,
    };
  }

  if (action.type === "SAVE_EMPLOYEE_FINALISE_STP_REQUEST_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
    };
  }

  return state;
};

export default orkReducer;
