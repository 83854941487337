import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'

import { ImUsers } from 'react-icons/im'
import { MdReportProblem } from 'react-icons/md'
import { AiTwotoneApi } from 'react-icons/ai'
import { FaRunning } from 'react-icons/fa'
import { IoIosAdd, IoIosRemove } from 'react-icons/io'
import { HiOutlineDocumentDuplicate, HiOutlinePlusCircle, HiTrendingUp } from 'react-icons/hi'
import { BsQuestionSquare, BsLock } from 'react-icons/bs'
import { toogleSidebar } from '../../Redux/actions'
import { BsX, BsChevronDoubleRight } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { IoLayers,IoSettings } from "react-icons/io5";
// import AddAdmin from '../Admin Controller/AddAdmin'

// import AddLeave from '../Leave/AddLeave'
// import AddEmployee from '../Employee/AddEmployee'
// import { getOrganizationId } from '../../FrontendHelper'


const mapStateToProps = store => {
    const { isToogleSidebar, isAddCouponModal, isAddReataurantModal, isAddAdminRegisterModal, isAddCategoryModal, isAddMenuItemModal, isServiceRateModal, adminProfileData,payRollStatus } = store.mainState;
    return { isToogleSidebar, isAddCouponModal, isAddReataurantModal, isAddAdminRegisterModal, isAddCategoryModal, isAddMenuItemModal, isServiceRateModal, adminProfileData,payRollStatus }
}

function AccSidebar({ isToogleSidebar, isAddCouponModal, isAddReataurantModal, isAddAdminRegisterModal, isAddCategoryModal, isAddMenuItemModal, isServiceRateModal, adminProfileData,payRollStatus }) {
    const [people, setPeople] = useState(false);
    const [leave, setLeave] = useState(false)
    const [reports, setReports] = useState(false)
    const [setting, setSetting] = useState(false);
    // const organizationId = getOrganizationId()
    const dispatch = useDispatch();
    // const handleFetchDashboard = ()=> {
    //     if(payRollStatus === "Draft") {
    //         dispatch({
    //             type: "FETCH_PAYROLL_DATA_REQUEST",
    //             payload: {
    //                 organizationId
    //             }
    //         })
    //     }
    // }

    return (
        <> <section id={`${isToogleSidebar ? "sidebar_sm" : "sidebar"}`}>
            <div className={`${isToogleSidebar ? "_sidebar_sm" : "_sidebar"}`}>
                <div className="_sidebar--main__para">Main</div>
                 <Link to="/dashboard" style={{ textDecoration: "none" }}><div className="_sidebar--main__dashboard">
                   <ul style={{ color: "white" }}><span><BsPeopleFill /></span>Clients</ul>
                </div></Link>
                <div className="_sidebar--main__para">Components</div>
                {/* <div className={`${people ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setPeople(!people)}>
                    <ul><span><IoLayers /></span> Bulk Payroll {people ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${people ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li style={{color:"white"}}> <Link to="bulk-payroll" style={{ textDecoration: "none",color:"white" }}>Payroll </Link><span><HiOutlinePlusCircle style={{color:"white" }} className='add_task--icon' onClick={() => dispatch({ type: "ADD_CATEGORY_MODAL" })} /></span></li>
                </div> */}
                <div className={`${leave ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setLeave(!leave)}>
                    <ul><span><IoSettings /></span> Settings {leave ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${leave ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li> <Link to="accountant-settings" style={{ textDecoration: "none",color:"white" }}> Settings</Link><span><HiOutlinePlusCircle style={{color:"white" }} className='add_task--icon' onClick={() => dispatch({ type: "ADD_MENU_ITEM_MODAL" })} /></span></li>
                </div>
                {/* <div className={`${reports ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setReports(!reports)}>
                    <ul><span><TiDocumentText /></span> Reports {reports ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${reports ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li> <Link to="/reports" style={{ textDecoration: "none",color:"white" }}> Reports</Link></li>
                </div>
                <div className={`${setting ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setSetting(!setting)}>
                    <ul><span><IoSettings /></span> Settings {setting ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${setting ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="setting" style={{ textDecoration: "none",color:"white" }}>Settings</Link></li>
                </div> */}

            </div>

        </section>
            {/* add employee */}
            {/* <div className={`${isAddCategoryModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <AddEmployee />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            dispatch({ type: "ADD_CATEGORY_MODAL" });
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div> */}
            {/* add leave modal */}
            {/* <div className={`${isAddMenuItemModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <AddLeave />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            dispatch({ type: "ADD_MENU_ITEM_MODAL" });
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div> */}

        </>

    )
}

const mapDispatchToProps = dispatch => {
    return {
        toogleSidebar: () => dispatch(toogleSidebar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccSidebar)
