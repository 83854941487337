import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { toast } from 'react-toastify'
import { getOrganizationId, isAuth, isAuth2 } from '../../FrontendHelper'

const mapStateToProps = store => {
    const { buttonText, employeePaymentSetting, isEnabledUsers } = store.mainState
    return { buttonText, employeePaymentSetting, isEnabledUsers }
}

function PaymentSetting({ buttonText, employeePaymentSetting, isEnabledUsers }) {
    const organizationId = getOrganizationId()
    const [accountName, setAccountName] = useState("")
    const [accountNo, setAccountNo] = useState("")
    const [bsbNo, setBsbNo] = useState("")
    const [institutionCode, setInstitutionCode] = useState(null)
    const [apcaUserId, setApcaUserId] = useState("")
    const [isbt, setIsbt] = useState(false)

    const dispatch = useDispatch();

    const handleSubmitButton = (e) => {
        e.preventDefault()
        if(accountName === "") {
            toast.error("Enter account name")
        }
        else if(accountNo === "") {
            toast.error("Enter account number")
        }
        else if(bsbNo === "") {
            toast.error("Enter BSB number")
        }
        else if(institutionCode === null) {
            toast.error("Enter institutionCode ")
        }
        else if(apcaUserId === "") {
            toast.error("Enter apcaUserId field ")
        }
        else {
            dispatch({
                type: "POST_PAYMENT_SETTING_REQUEST",
                payload: {
                    "organizationId": organizationId,
                    "bsb": bsbNo,
                    "accountNumber": accountNo,
                    "accountName": accountName,
                    "institutionCode": institutionCode,
                    "apcaUserId": apcaUserId,
                    "includeSelfBalanceTransaction": isbt
                }
            })
        }


    }
    useEffect(() => {
        dispatch({ type: "FETCH_PAYMENT_SETTING_REQUEST" })

    }, [])



    useEffect(() => {
        if (isEnabledUsers === "y") {
            setTimeout(() => {
                dispatch({
                    type: "OPEN_ADD_EMPLOYEE_PAYMENT_MODAL"
                })
            }, 1000)
        }

    }, [isEnabledUsers])
    useEffect(() => {
        setAccountName(employeePaymentSetting.accountName)
        setAccountNo(employeePaymentSetting.accountNumber)
        setBsbNo(employeePaymentSetting.bsb)
        setInstitutionCode(employeePaymentSetting.institutionCode)
        setApcaUserId(employeePaymentSetting.apcaUserId)
        setIsbt(employeePaymentSetting.includeSelfBalanceTransaction)

    }, [employeePaymentSetting])

    return (
        <div>
            <div className="Modal--heading">
                <p style={{ color: "black" }}>Employee Payment Setting</p>
            </div>
            <form>
                <div className="personal">
                    <div className="personal--container">
                        <div className="personal--container--firstName">
                            <p>Account Name :-</p>
                            <input type="text" placeholder="Account Name" value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>Account Number :-</p>
                            <input type="text" placeholder="Account Number" value={accountNo} onChange={(e) => setAccountNo(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>BSB Number :-</p>
                            <input type="text" placeholder="BSB Number" value={bsbNo} onChange={(e) => setBsbNo(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>Institution Code :-</p>
                            <input type="text" placeholder="Institution Code" value={institutionCode} onChange={(e) => setInstitutionCode(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p>APCA User Id :-</p>
                            <input type="text" placeholder="APCA User Id" value={apcaUserId} onChange={(e) => setApcaUserId(e.target.value)} />
                        </div>
                        <div className="personal--container--firstName">
                            <p style={{ display: "flex", alignItems: "center" }}><input type="checkbox" style={{ width: "20px", marginRight: "20px" }} value={isbt} checked={isbt} onChange={(e)=> setIsbt(e.target.checked)} /> <label>Include Self Balance Transaction</label></p>

                        </div>
                        <div className="personal--container--firstName">
                        </div>
                        <div className="modal--container__group--buttons">
                            <button type="submit" onClick={handleSubmitButton} style={{ marginTop: "20px" }}>
                                {buttonText}
                            </button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default connect(mapStateToProps, null)(PaymentSetting)

