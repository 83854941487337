import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsX } from "react-icons/bs";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import UpdateAdmin from "./UpdateAdmin";

const mapStateToProps = (store) => {
  const {
    adminUsersList,
    isToogleSidebar,
    isDeletedAdmin,
    isLoadingOnLogin,
    buttonText,
  } = store.mainState;
  return {
    adminUsersList,
    isToogleSidebar,
    isDeletedAdmin,
    isLoadingOnLogin,
    buttonText,
  };
};

function AdminController({
  adminUsersList,
  isDeletedAdmin,
  isLoadingOnLogin,
  isToogleSidebar,
  buttonText,
}) {
  const [showSmModal, setShowSmModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [myKeyword, setMyKeyWord] = useState("");
  const [sortingParam, setSortingParam] = useState("NOT_VERIFIED");
  const [postsPerPage, setPostsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [openUpdateModal, setUpdateModal] = useState(false);
  const [updatingItem, setUpdatingItem] = useState(null);

  let pageNumbers = [];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_ADMIN_USERS_REQUEST",
      payload: {
        currentPage,
        postsPerPage,
        myKeyword,
        sortingParam,
      },
    });
  }, [currentPage, postsPerPage, myKeyword, sortingParam]);

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (
    let i = 1;
    i <= Math.ceil(adminUsersList.totalData / postsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const str = () =>
    adminUsersList.data !== undefined &&
    adminUsersList.data.map((item) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.fullName}</td>
        <td>{item.role}</td>
        <td>{item.phoneNo}</td>
        <td>{item.email}</td>
        <td>{item.addedByUserName}</td>
        <td>
          <span className="advertisement--activeStatus">
            {item.isActive ? "Active" : "Not Active"}
          </span>
        </td>
        <td>
          <span>
            <AiFillEdit
              className="action--edit__admin"
              onClick={() => {
                setUpdatingItem(item);
                setUpdateModal(!openUpdateModal);
              }}
            />{" "}
            <MdDelete
              className="action--eye__admin"
              onClick={() => {
                setShowSmModal(!showSmModal);
                setDeletingId(item.id);
              }}
            />{" "}
          </span>
        </td>
      </tr>
    ));
  useEffect(() => {
    if (isDeletedAdmin === "success") {
      setTimeout(() => {
        setShowSmModal(false);
        let values = {
          pageNo: currentPage,
          pageSize: postsPerPage,
          sortBy: "descending",
          sortParameter: "id",
        };
        dispatch({
          type: "FETCH_ADMIN_USERS_REQUEST",
          payload: values,
        });
      }, 1000);
    }
  }, [isDeletedAdmin]);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Admin Controll Room</h4>
        </div>
        <div className="user_groups" style={{ minHeight: "62vh" }}>
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Admin</h4>
              <button
                onClick={() => dispatch({ type: "ADD_ADMIN_REGISTER_MODAL" })}
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                value={postsPerPage}
                onClick={(e) => setPostsPerPage(e.target.value)}
                style={{ width: "80px" }}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Sort By
              </label>
              <select
                name="pageSize"
                value={sortingParam}
                onClick={(e) => setSortingParam(e.target.value)}
              >
                <option value="VERIFIED">Verified</option>
                <option value="NOT_VERIFIED">Not Verified</option>
              </select>
            </div>
            <div className="user_groups--button_n_search--search">
              <input
                type="text"
                placeholder="Search"
                value={myKeyword}
                onChange={(e) => setMyKeyWord(e.target.value)}
              />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="adminRoom--id">ID</th>
                  <th className="adminRoom--name">Admin Name</th>
                  <th className="adminRoom--role">Role</th>
                  <th className="adminRoom--mobile">Phone Number</th>
                  <th className="adminRoom--email">Email</th>
                  <th className="adminRoom--madeBy">Made By</th>
                  <th className="adminRoom--status">Status</th>
                  <th className="adminRoom--actions">Actions</th>
                </tr>
              </thead>
              <tbody>{str()}</tbody>
            </table>

            {/* for delete Modal */}

            {/* <div className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`} >
                            <div className="delete--admin--container" >
                                <div className="delete--admin--container__heading"></div>
                                <div className="delete--admin--container__body">
                                    <h1>Are you sure to delete this ??</h1>
                                    <div>{isDeletedAdmin === "processing" && <div className="deleting--something">  <p>Deleting...</p></div>}</div>
                                    <div> {isDeletedAdmin === "success" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div> }</div>
                                    
                                    <div> {isDeletedAdmin === "failed" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p></div>}</div>
                                </div>
                                <div className="delete--admin--container__footer">
                                    <button onClick={() => setShowSmModal(!showSmModal)}>Cancel</button>
                                    <button onClick={() => {
                                        dispatch({
                                            type: "DELETE_ADMIN_USERS_REQUEST",
                                            payload: deletingId
                                        })
                                    }}>Ok</button>
                                </div>

                            </div>
                        </div> */}

            <div
              className={`${
                openUpdateModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {updatingItem !== null && <UpdateAdmin item={updatingItem} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setUpdateModal(!openUpdateModal);
                        dispatch({
                          type: "FETCH_ADMIN_USERS_REQUEST",
                          payload: {
                            currentPage,
                            postsPerPage,
                            myKeyword,
                            sortingParam,
                          },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > adminUsersList.totalData
                  ? adminUsersList.totalData
                  : indexOfLastPost}{" "}
                of {adminUsersList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(AdminController);
