import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    padding: "20px",
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: "9px",
  },
  name: {
    width: '40%',
    marginLeft : "10",
    textAlign: "left",
  },
  leaveContainer: {
    width: "20%",
  },
  hoursContainer: {
    width: "20%",
  },

  leaveTypeText: {
    textAlign: "center",
    paddingTop: 8,
  },
  leavHoursText: {
    textAlign: "center",
    paddingTop: 8,
  },

  address: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const LeaveBalanceRowData = ({ leaveBalanceReportData }) => {
  return (
    <>
      {leaveBalanceReportData.leaveBalance?.map((item, index) => (
        <View style={styles.row} key={index}>
          <Text style={styles.name}>{item.employeeFullName}</Text>
          <View style={styles.leaveContainer}>
            <Text style={styles.leaveTypeText}>Sick Leave</Text>
            <Text style={styles.leaveTypeText}>Annual Leave</Text>
          </View>
          <View style={styles.hoursContainer}>
            <Text style={styles.leavHoursText}>{item.sickLeaveHours}</Text>
            <Text style={styles.leavHoursText}>{item.annualLeaveHours}</Text>
          </View>
          <Text style={styles.address}>{item.annualLeaveValue}</Text>
        </View>
      ))}
    </>
  );
};

export default LeaveBalanceRowData;
