import React from "react";
import { Link } from "react-router-dom";

function UserType() {
  return (
    <div className="class-qtn">
      <div className="qtn-container">
        <div className="qtn--heading">What's your role?</div>
        <hr />
        <div className="qtn--body">
          <Link to="signup-getEmail/admin" style={{ textDecoration: "none" }}>
            <p >
              Employer / Organization Admin
            </p>
          </Link>
          <Link to="signup-getEmail/accountant" style={{ textDecoration: "none" }}>
            <p >
              Tax Agent / Accountant Agent
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UserType;
