import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {useSelector} from 'react-redux'
import LeaveBalanceHeader from './LeaveBalanceHeader';
import LeaveBalanceRowData from './LeaveBalanceRowData';

const tableRowsCount = 1;

const styles = StyleSheet.create({
    tableMargin: {
        marginTop: "30px"
    }
})

function LeaveBalanceTable({leaveBalanceReportData}) {

    return (
        <View style={styles.tableMargin}>
            <LeaveBalanceHeader />
            <LeaveBalanceRowData leaveBalanceReportData={leaveBalanceReportData} />
            {/* <EmployeeContactsRowData reportData={reportData} /> */}
            {/* <LeaveTableRowData  desc="Sick Leave"  acured={item.sickLeaveAcquired} taken={item.sickLeaveTaken} remaining={item.sickLeaveRemaning} />
            <LeaveTableRowData  desc="Annual Leave" acured={item.annualLeaveAcquired} taken={item.annualLeaveTaken} remaining={item.annualLeaveRemaning} /> */}
        </View>
    )
}

export default LeaveBalanceTable

