import { View,Text,StyleSheet } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    container: {
        padding: "20",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        fontStyle: 'bold',
    },
    text: {
        fontSize: "18"
    }
})

function EmployeeContactsTitle({reportData}) {
    return (
        <View style={styles.container}>
            <Text style={styles.text}>{reportData?.companyLegalName}</Text>
            <Text style={styles.text}>Employee Contacts</Text>
        </View>
    )
}

export default EmployeeContactsTitle
