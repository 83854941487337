import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Document, Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import EmployeeSummaryTitle from './EmployeeSummaryTitle';
import EmployeeSummaryTable from './EmployeeSummaryTable';

const mapStateToProps = store => {
    const { buttonText,employeeSummaryReportData, employeeDropdownList, isEnabledUsers,isEmployeeContactsReportModal } = store.mainState
    return { buttonText,employeeSummaryReportData, employeeDropdownList, isEnabledUsers,isEmployeeContactsReportModal }
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        fontSize: "10px",
        textAlign: "right",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    text: {
        fontSize: "10px",
        color: "yellow"
    },

});

function EmployeeSummaryReport({ buttonText, employeeDropdownList, isEnabledUsers,isEmployeeContactsReportModal,employeeSummaryReportData }) {

        // Create Document Component
        const MyDocument = () => (
            <Document className="document">
                <Page size="A4" style={styles.page} wrap>
                    <EmployeeSummaryTitle employeeSummaryReportData={employeeSummaryReportData} />
                    <EmployeeSummaryTable employeeSummaryReportData={employeeSummaryReportData} />
                </Page>
            </Document>
        );

    return (
        <div>
            <div className="Modal--heading">
                <p style={{ color: "black" }}>Payroll Employee Summary Report</p>
            </div>
            <div className="pdf--container">
                <PDFViewer className="pdf--container__pdf">
                    <MyDocument />
                </PDFViewer>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(EmployeeSummaryReport)

