import React, { useState, useEffect,useRef,useCallback } from 'react'
import Layout from '../../../../Layout/Layout'
import { HiEmojiHappy } from 'react-icons/hi'
import { BiX } from 'react-icons/bi'
import { connect, useDispatch } from 'react-redux'
import Footer from '../../../Z_Footer/Footer'
import { useHistory } from "react-router";


import { AiFillCheckSquare } from 'react-icons/ai'
import { FcBusinessman } from 'react-icons/fc'
import { ImCreditCard } from 'react-icons/im'
import LoadingComponent from '../../../LoadingComponent'
import { BsX } from 'react-icons/bs'
import { BiDollar } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import useFetchDashboard from "./useFetchPaymentHistory";
import { getOrganizationId } from '../../../../FrontendHelper'
import dayjs from 'dayjs'


const mapStateToProps = store => {
    const { paymentHistoryData,isLoadingOnLogin,isEnabledUsers } = store.mainState
    return { paymentHistoryData,isLoadingOnLogin,isEnabledUsers}
}

function PaymentHistory({ paymentHistoryData,isLoadingOnLogin,isEnabledUsers }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [currentPage, setCurrentPage] = useState(1)
    const [showCancelSubscriptionModal,setShowCancelSubscription] = useState(false)

    const [reason,setReason] = useState("")

    const [containerClassName,setContainerClassName] = useState("leaveBalance_SM--containerXX")

    const {loading,paymentsData,hasMore,latestPayment} = useFetchDashboard(currentPage);
    const observer = useRef();
    const organizationId = getOrganizationId()
    // console.log("The latestPayment from useFetchPayment custom hook",latestPayment)


    const lastPayments = useCallback((node)=> {
        if(isLoadingOnLogin) return
        if(observer.current) observer.current.disconnect()
        if(hasMore) {
          observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting) {
              setCurrentPage(currentPage + 1)
            }
          })
        }
        if(node) observer.current.observe(node)
      });
    
    useEffect(()=> {
      if(isEnabledUsers=== "b") {
        setContainerClassName("leaveBalance_SM--containerXX")
          setTimeout(()=> {
            setShowCancelSubscription(false)
          history.push(`/dashboard`)
          },300)
      }
    },[isEnabledUsers])


    return (
        <Layout>
            <div>
                <p className='subscription--info__heading'>Subscription Plan Details :-</p>
                <div className="subscription--info">
                    
                    <div className="subscription--info__services">
                        <p>Services :-</p>
                        <p><AiFillCheckSquare className="AiFillCheckSquare--icon" /> Leave Management</p>
                        <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Employee Payment Management</p>
                        <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Payroll & STP on Web</p>
                        <p><AiFillCheckSquare className="AiFillCheckSquare--icon"/> Download Report</p>
                    </div>
                    <div className="subscription--info__details">
                        <h1>( {dayjs(latestPayment?.startDate).format("DD-MMM-YYYY")} ~ {dayjs(latestPayment?.endDate).format("DD-MMM-YYYY")} )</h1>
                        <div className="subscription--info__details--info">
                        <p>Employee Count is : {latestPayment?.employeeCount}</p>
                        <p>Total Payment : AU$ {latestPayment?.totalPayment}</p>
                        </div>
                        <button onClick={()=> {
                            setShowCancelSubscription(true)
                            setTimeout(()=> {
                                setContainerClassName("leaveBalance_SM--container")
                              },2)
                            }}>Cancel Subscription</button>
                    </div>
                </div>
                
                <p className='subscription--info__heading'>Payment History :-</p>
                <div className="payment--history">
                    {paymentsData?.map((item,index)=> 
                        {
                            if(paymentsData.length === index + 1) {
                                return (
                                    <div className="payment--history__items" key={index} ref={lastPayments}>

                                    <div className="payment--history__duration">
                                        <p>Duration</p>
                                        <p>{dayjs(item?.durationStartDate).format("DD-MMM-YYYY")} - {dayjs(item?.durationEndDate).format("DD-MMM-YYYY")}</p>
                                    </div>
                                    <div className="payment--history__paymentDate">
                                        <p>Payment Date</p>
                                        <p>{dayjs(item?.paymentDate).format("DD-MMM-YYYY")}</p>
                                    </div>
                                    <div className="payment--history__employeeCount">
                                        <p>Employee Count</p>
                                        <p>{item.employeeCount}</p>
                                    </div>
                                    <div className="payment--history__employeeRate">
                                        <p>Per Employee Charge</p>
                                        <p>AU$ {item.pricePerEmployee}</p>
                                    </div>
                                    <div className="payment--history__totalPayment">
                                        <p>Total Payment</p>
                                        <p>AU$ {item.totalPrice}</p>
                                    </div>
                                    <div className="payment--history__paymentMethod">
                                        <p>Payment Method</p>
                                        <p>{item.transactionType}</p>
                                    </div>
                                    </div>
                                )
                            } else {
                               return (
                                <div className="payment--history__items" key={index}>

                                    <div className="payment--history__duration">
                                        <p>Duration</p>
                                        <p>{dayjs(item?.durationStartDate).format("DD-MMM-YYYY")} - {dayjs(item?.durationEndDate).format("DD-MMM-YYYY")}</p>
                                    </div>
                                    <div className="payment--history__paymentDate">
                                        <p>Payment Date</p>
                                        <p>{dayjs(item?.paymentDate).format("DD-MMM-YYYY")}</p>
                                    </div>
                                    <div className="payment--history__employeeCount">
                                        <p>Employee Count</p>
                                        <p>{item.employeeCount}</p>
                                    </div>
                                    <div className="payment--history__employeeRate">
                                        <p>Per Employee Charge</p>
                                        <p>AU$ {item.pricePerEmployee}</p>
                                    </div>
                                    <div className="payment--history__totalPayment">
                                        <p>Total Payment</p>
                                        <p>AU$ {item.totalPrice}</p>
                                    </div>
                                    <div className="payment--history__paymentMethod">
                                        <p>Payment Method</p>
                                        <p style={{fontSize: "10px"}}>{item.transactionType}</p>
                                    </div>
                                </div>
                               )
                            }
                        }
                    
                    )}
                </div>
                <p style={{textAlign: "center",margin: "40px" }}>{loading && <LoadingComponent />}</p>

                <Footer />

                {showCancelSubscriptionModal && <div className="leaveBalance_SM">
        <div className={containerClassName}>
          <div className="leavBalance_sm--heading">
            <p>ActivAccount Says <HiEmojiHappy /><HiEmojiHappy /></p>
            <p onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowCancelSubscription(false)
              },300)
            }}><BiX /></p>
          </div>
          <div className="leavBalance_sm--body">
            <div className="leaveBalance--inputs">
              <div className="getEmployee--count">
              <p style={{textAlign: "center",fontSize: "18px"}}>Are you sure to cancel subscription ?</p>
              <p>Reason : </p>
              <input type="text" placeholder='Reason' value={reason} onChange={(e)=> setReason(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="leaveBalance--buttons">
            <button onClick={()=> {
              setContainerClassName("leaveBalance_SM--containerXX")
              setTimeout(()=> {
                setShowCancelSubscription(false)
              },300)
              }}>Close</button>
            <button onClick={()=> {
              if(reason.length >0) {
                  dispatch({
                    type: "CANCEL_SUBSCRIPTION_REQUEST",
                    payload: {
                      organizationId
                    }
                  })
              }
            }}>Ok</button>
          </div>
        </div>
      </div>}
            </div>

        </Layout>
    )
}


export default connect(mapStateToProps, null)(PaymentHistory)
