import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getOrganizationId } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { buttonText, employeeDropdownList, isEnabledUsers } = store.mainState;
  return { buttonText, employeeDropdownList, isEnabledUsers };
};

function AddLeave({ buttonText, employeeDropdownList, isEnabledUsers }) {
  // console.log("the l")
  const [myEmployeeId, setEmployeeId] = useState(null);
  const [leaveType, setLeaveType] = useState("SICK_LEAVE");
  const [notes, setNotes] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState(null);
  const [leaveEndDate, setLeaveEndDate] = useState(null);

  const organizationId = getOrganizationId();
  const dispatch = useDispatch();

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    if (leaveType === null) {
      toast.error("Select at least one leave type");
    } else if (myEmployeeId === null) {
      toast.error("Select at least one employee");
    } else if (notes.length <= 0) {
      toast.error("Enter description");
    } else if (notes.length >= 200) {
      toast.error("Description charecters shouldn't greater than 200");
    } else if (notes.length <= 0) {
      toast.error("Enter description");
    } else if (leaveStartDate === null) {
      toast.error("Enter leave start date");
    } else if (leaveEndDate === null) {
      toast.error("Enter leave end date");
    } else {
      dispatch({
        type: "POST_LEAVE_REQUEST",
        payload: {
          employeeId: myEmployeeId,
          startDate: dayjs(leaveStartDate).format("YYYY-MM-DD"),
          endDate: dayjs(leaveEndDate).format("YYYY-MM-DD"),
          notes: notes,
          leaveType: leaveType,
          organizationId: organizationId,
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_EMPLOYEE_DROPDOWN_REQUEST",
    });
  }, []);
  useEffect(() => {
    if (isEnabledUsers === "added") {
      setEmployeeId(null);
      setLeaveType("SICK_LEAVE");
      setNotes("");
      setLeaveStartDate(null);
      setLeaveEndDate(null);
    }
  }, [isEnabledUsers]);

  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Add Leave</p>
      </div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Leave Type* : </label>
              <select
                name="pageSize"
                onClick={(e) => {
                  setLeaveType(e.target.value);
                }}
              >
                <option value="SICK_LEAVE">SICK LEAVE</option>
                <option value="ANNUAL_LEAVE">ANNUAL LEAVE</option>
              </select>
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Employee Name* : </label>
              <select
                value={myEmployeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              >
                <option value={null}>Select</option>
                {employeeDropdownList.map((employee) =>
                  !employee.isActive ? (
                    <option
                      key={employee.employeeId}
                      value={employee.employeeId}
                    >
                      {employee.employeeFullName}
                    </option>
                  ) : null
                )}
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Notes* : </label>
              <textarea
                style={{ minHeight: "60px", padding: "10px" }}
                type="text"
                name="title"
                value={notes}
                placeholder="Description"
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </p>
          </div>
          <div className="customInput--title">
            <p>Start Date * :</p>
            <p>
              <DatePicker
                selected={leaveStartDate}
                onChange={(date) => setLeaveStartDate(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
                maxDate={leaveEndDate}
                minDate={new Date()}
              />
            </p>
          </div>
          <div className="customInput--title">
            <p>End Date * :</p>
            <p>
              <DatePicker
                selected={leaveEndDate}
                onChange={(date) => setLeaveEndDate(date)}
                disabledKeyboardNavigation
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
                minDate={leaveStartDate}
              />
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              onClick={handleAddCategorySubmit}
              style={{ marginBottom: "50px" }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddLeave);
