import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  heading: {
    fontSize: "10px",
    fontStyle: "bold",
    paddingLeft: 8,
    textAlign: "left",
    marginTop: "20px",
  },
  row: {
    padding: "20px",
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: "9px",
  },
  name: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    fontSize: "9px",
  },
  email: {
    width: "40%",

    textAlign: "center",
    paddingRight: 8,
  },
  phone: {
    width: "20%",

    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
  address: {
    width: "30%",

    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const EmployeeContactsRowData = ({ reportData }) => {
  return (
    <>
      {reportData.employeeContract?.map((item,index) => (
        <View style={styles.row} key={index}>
          <Text style={styles.name}>{item.name}</Text>
          <Text style={styles.email}>{item.email}</Text>
          <Text style={styles.phone}>{item.phone}</Text>
          <Text style={styles.address}>{item.address}</Text>
        </View>
      ))}
    </>
  );
};

export default EmployeeContactsRowData;
