import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import logoImage from '../../Images/active account_white-02.png'
import { Link } from 'react-router-dom'
import Footer from '../Z_Footer/Footer'

const mapStateToProps = (store) => {
    const { privacyData } = store.mainState;
    return { privacyData }
}

function PrivacyPolicy({ privacyData }) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: "FETCH_PRIVACY_REQUEST" })
    }, [])
    return (
        <div>
            <div className="privacy--container">
                <div className="privacy--container__navbar">
                    <div className="logo--container">
                        <img src={logoImage} alt="logo" />
                    </div>
                    <div className="navigation--container">
                        <Link to="/terms-of-service" style={{textDecoration: "none"}}><p>Terms of Service</p></Link>
                        <Link to="/" style={{textDecoration: "none"}}><p>Login</p></Link>
                    </div>
                </div>
                <div className="privacy--container__body">
                    <div className="body--header">
                        <p>Privacy And Policy</p>
                    </div>
                    <div className="body--text" >
                     <div dangerouslySetInnerHTML={{__html: privacyData.privacyPolocy}} />
                      
                    </div>
                </div>
                <div className="privacy--container__footer">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(PrivacyPolicy)
