import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  heading: {
    fontSize: "10px",
    fontStyle: "bold",
    paddingLeft: 8,
    textAlign: "left",
    marginTop: "20px",
  },
  row: {
    padding: "20px",
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: "9px",
  },
  name: {
    width: "15%",
    textAlign: "left",
    paddingLeft: 8,
    fontSize: "9px",
  },
  email: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
  },
  phone: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
  address: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: "9px",
  },
});

const EmployeeSummaryRowData = ({ employeeSummaryReportData }) => {
  return (
    <>
      {employeeSummaryReportData.employees?.map((item,index) => (
        <View style={styles.row} key={index}>
          <Text style={styles.name}>{item.employeeFullName}</Text>
          <Text style={styles.email}>${item.earningsPay}</Text>
          <Text style={styles.phone}>${item.taxPay}</Text>
          <Text style={styles.address}>${item.superPay}</Text>
          <Text style={styles.address}>${item.netPay}</Text>
          <Text style={styles.address}>${item.amountPaid}</Text>
        </View>
      ))}
    </>
  );
};

export default EmployeeSummaryRowData;
