import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { IoLogoAppleAppstore } from "react-icons/io5";
import { IoIosSend } from "react-icons/io";
import { FcBusinessman } from "react-icons/fc";
import { connect, useDispatch } from "react-redux";
import Footer from "../Z_Footer/Footer";
import { BsCheckCircle } from "react-icons/bs";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { BsX } from "react-icons/bs";
import LoadingComponent from "../LoadingComponent";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import EditEmployee from "./EditEmployee";
import businessLogo from "../../Images/61634-vector-businessman-client-design-icon-free-frame.png";
import InviteEmployeeForm from "./InviteEmployeeForm";
import { getOrganizationId } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const {
    employeeDataList,
    isEnabledUsers,
    isLoadingOnLogin,
    isToogleSidebar,
    isInviteEmployeeModal,
  } = store.mainState;
  return {
    employeeDataList,
    isEnabledUsers,
    isLoadingOnLogin,
    isToogleSidebar,
    isInviteEmployeeModal,
  };
};

function Employee({
  employeeDataList,
  isEnabledUsers,
  isLoadingOnLogin,
  isToogleSidebar,
  isInviteEmployeeModal,
}) {
  const organizationId = getOrganizationId();
  const [updateMenuModal, setUpdateMenuModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  // Enable Disable category
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [showEnableDisableModal, setShowEnableDisableModal] = useState(false);
  // Shhow employee details
  const [showEmployeeDetailModal, setShowEmployeeDetailsModal] =
    useState(false);
  const [detailShowingItem, setDetailsShowingItem] = useState({});
  // Shhow delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingItemItem, setDeletingItemItem] = useState({});
  // Shhow approve modal
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [approvingItem, setApprovingItem] = useState({});
  // Shhow resend invitation modal
  const [showResendInModal, setShowResendInModal] = useState(false);
  const [resendingEmployeeItem, setResendingEmployeeItem] = useState({});
  const [resendEmail, setResendEmail] = useState("");

  const wrapperRef = useRef(null);
  useEffect(() => {
    if (showSmModal) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShowSmModal(!showSmModal);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showSmModal]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_EMPLOYEE_DATA_REQUEST",
      payload: { currentPage, postsPerPage, organizationId },
    });
  }, [currentPage, postsPerPage, organizationId]);

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts =
    employeeDataList.data !== undefined &&
    employeeDataList.data.slice(indexOfFirstPost, indexOfLastPost);

  for (
    let i = 1;
    i <=
    Math.ceil(
      employeeDataList.data !== undefined &&
        employeeDataList.totalData / postsPerPage
    );
    i++
  ) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setShowEnableDisableModal(false);
      setShowResendInModal(false);
      setShowDeleteModal(false);
      setShowApproveModal(false);
      setUpdateMenuModal(false);
      dispatch({
        type: "FETCH_EMPLOYEE_DATA_REQUEST",
        payload: { currentPage, postsPerPage, organizationId },
      });
    }
  }, [isEnabledUsers]);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Employee</h4>
        </div>
        <div className="user_groups" style={{ minHeight: "60vh" }}>
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Employee</h4>
              <button onClick={() => setShowSmModal(true)}>
                Add <i className="bi bi-plus c-plus" />
              </button>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                {" "}
                Per Page
              </label>
              <select
                style={{ width: "80px" }}
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <div className="user_groups--button_n_search--search">
              {/* <input type="text" placeholder="Search" /> */}
            </div>
          </div>
          <p className="myloading--paragraph">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          {employeeDataList.totalData > 0 ? (
            <div className="table-container">
              <table className="table_x">
                <thead>
                  <tr>
                    <th className="employeelist--id">S.N.</th>
                    <th className="employeelist--name">Employee Name</th>
                    <th className="employeelist--email">Email</th>
                    <th className="employeelist--email">Phone No.</th>
                    <th className="employeelist--addresss">Address</th>
                    <th className="employeelist--jobTitle">Job Title</th>
                    <th className="employeelist--jobTitle">Status</th>
                    <th className="employeelist--jobTitle">Is Disabled</th>
                    <th className="employeelist--actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeDataList.data !== undefined &&
                    employeeDataList.data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {item.personal.firstName} {item.personal.lastName}
                        </td>
                        <td>{item.personal.email}</td>
                        <td>{item.personal.contactNumber}</td>
                        <td>
                          {item.personal.addressOne} {item.personal.addressTwo}
                        </td>
                        <td>{item.employment.jobTitle}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "#3e445e",
                              color: "white",
                              padding: "2px 5px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.employeeStatus === "VERIFICATION_PENDING"
                              ? "INFO_ADDED"
                              : item.employeeStatus}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "#3e445e",
                              color: "white",
                              padding: "2px 5px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.disabled ? "Disabled" : "Enabled"}
                          </span>
                        </td>
                        <td>
                          <Tippy
                            content={`${
                              item.disabled === true ? "Enable" : "Disable"
                            }`}
                            placement="bottom"
                          >
                            <span>
                              <BsCheckCircle
                                className="report-edit"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowEnableDisableModal(
                                    !showEnableDisableModal
                                  );
                                  setEnableDisableItem(item);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                          {item.employeeStatus !== "DRAFT" && (
                            <Tippy content="Edit">
                              <span>
                                <AiFillEdit
                                  className="report-edit"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setUpdateMenuModal(true);
                                    setEditingItem(item);
                                  }}
                                />
                              </span>
                            </Tippy>
                          )}
                          {/* <Tippy content="Delete" placement="bottom">
                                                <span><AiOutlineDelete className="report-edit" style={{ cursor: "pointer" }} onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setDeletingItemItem(item)
                                                }} /></span>
                                            </Tippy> */}
                          {item.employeeStatus === "VERIFICATION_PENDING" && (
                            <Tippy content="Approve">
                              <span>
                                <IoLogoAppleAppstore
                                  className="report-edit"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShowApproveModal(true);
                                    setApprovingItem(item);
                                  }}
                                />
                              </span>
                            </Tippy>
                          )}
                          {item.employeeStatus === "DRAFT" && (
                            <Tippy content="Resend Invitation">
                              <span>
                                <IoIosSend
                                  className="report-edit"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShowResendInModal(true);
                                    setResendingEmployeeItem(item);
                                    setResendEmail(item.personal.email);
                                  }}
                                />
                              </span>
                            </Tippy>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="pagination">
                {employeeDataList.data !== undefined && (
                  <p>
                    Showing {indexOfFirstPost} to{" "}
                    {indexOfLastPost > employeeDataList.totalData
                      ? employeeDataList.totalData
                      : indexOfLastPost}{" "}
                    of {employeeDataList.totalData} entries
                  </p>
                )}
                <nav aria-label="...">
                  <ul className="pagination">
                    {currentPage === 1 ? (
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <BiChevronLeft />
                        </a>
                      </li>
                    ) : (
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <BiChevronLeft
                            onClick={() => setCurrentPage(currentPage - 1)}
                          />
                        </a>
                      </li>
                    )}
                    {pageNumbers.map((x) => (
                      <li key={x} className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => setCurrentPage(x)}
                        >
                          {x}
                        </a>
                      </li>
                    ))}
                    {currentPage == pageNumbers.length ? (
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <BiChevronRight />
                        </a>
                      </li>
                    ) : (
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <BiChevronRight
                            onClick={() => setCurrentPage(currentPage + 1)}
                          />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          ) : (
            <div className="empty--employee">
              <div className="empty--employee_container">
                <div className="businessLogo--container">
                  <img src={businessLogo} />
                </div>
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {" "}
                  Let’s add your employees
                </div>
                <div>
                  {" "}
                  Add your all employee so you can pay them with ActivAccount
                </div>
                <button onClick={() => setShowSmModal(true)}>
                  Add Your First Employee
                </button>
              </div>
            </div>
          )}
          {/* Show small modal to choose to invite emploee or add mannually */}
          <div
            className={`${
              showSmModal
                ? "small--modal--container"
                : "small--modal--container--colosed"
            }`}
          >
            <div className="small--modal" ref={wrapperRef}>
              <div className="small--modal--body">
                <p
                  onClick={() => {
                    setShowSmModal(false);
                    dispatch({ type: "ADD_CATEGORY_MODAL" });
                  }}
                >
                  Add Mannually
                </p>
                <p
                  onClick={() => {
                    setShowSmModal(false);
                    dispatch({ type: "INVITE_EMPLOYEE_MODAL" });
                  }}
                >
                  Invite Employee
                </p>
              </div>
            </div>
          </div>
          <div
            className={`${
              updateMenuModal ? "mymodal modal_activated" : "mymodal"
            }`}
          >
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              {editingItem !== null && <EditEmployee item={editingItem} />}
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      setUpdateMenuModal(!updateMenuModal);
                      // dispatch({
                      //     type: "FETCH_EMPLOYEE_DATA_REQUEST",
                      //     payload: { currentPage, postsPerPage,organizationId }
                      // })
                    }}
                  >
                    <BsX />
                  </span>
                  <p>Edit</p>
                </div>
              </div>
            </div>
          </div>
          {/* invite employee modal */}
          <div
            className={`${
              isInviteEmployeeModal ? "mymodal modal_activated" : "mymodal"
            }`}
          >
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              <InviteEmployeeForm />
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      dispatch({
                        type: "INVITE_EMPLOYEE_MODAL",
                      });
                    }}
                  >
                    <BsX />
                  </span>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          {/* disable/enable employee */}
          <div
            className={`${
              showEnableDisableModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading"></div>
              <>
                <div className="delete--admin--container__body">
                  {enableDisableItem.disabled ? (
                    <h1>Are you sure to Enable this ?</h1>
                  ) : (
                    <h1>Are you sure to disable this ??</h1>
                  )}
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={() =>
                      setShowEnableDisableModal(!showEnableDisableModal)
                    }
                  >
                    Cancel
                  </button>
                  {enableDisableItem.disabled ? (
                    <button
                      onClick={() => {
                        let id = enableDisableItem.employeeId;
                        dispatch({
                          type: "ENABLE_EMPLOYEE_REQUEST",
                          payload: {
                            id,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        let id = enableDisableItem.employeeId;
                        dispatch({
                          type: "DISABLE_EMPLOYEE_REQUEST",
                          payload: {
                            id,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  )}
                </div>
              </>
            </div>
          </div>
          {/* delete employee */}
          <div
            className={`${
              showDeleteModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading"></div>
              <>
                <div className="delete--admin--container__body">
                  <h1>Are you sure to delete this employee ?</h1>
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button onClick={() => setShowDeleteModal(!showDeleteModal)}>
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      let id = deletingItemItem.employeeId;
                      dispatch({
                        type: "DELETE_EMPLOYEE_REQUEST",
                        payload: {
                          id,
                        },
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </>
            </div>
          </div>
          {/* approve employee */}
          <div
            className={`${
              showApproveModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading"></div>
              <>
                <div className="delete--admin--container__body">
                  <h1>Are you sure to approve this employee ?</h1>
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={() => setShowApproveModal(!showApproveModal)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      let id = approvingItem.employeeId;
                      dispatch({
                        type: "APPROVE_EMPLOYEE_REQUEST",
                        payload: {
                          id,
                        },
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </>
            </div>
          </div>
          {/* resend invitation */}
          <div
            className={`${
              showResendInModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading"></div>
              <>
                <div className="delete--admin--container__body">
                  <h1>Are you sure to resend employee invitation ?</h1>
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                  <div className="resendEmail--container">
                    <label>
                      Email Address :{" "}
                      <input
                        type="email"
                        value={resendEmail}
                        onChange={(e) => setResendEmail(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={() => setShowResendInModal(!showResendInModal)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      let id = resendingEmployeeItem.employeeId;
                      let emailId = resendEmail;
                      dispatch({
                        type: "RESEND_INVITATION_REQUEST",
                        payload: {
                          id,
                          emailId,
                        },
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Employee);
